<section class="animate__animated animate__fadeIn d-flex align-items-center">
	<div class="container">
        <div class="section-title">
            <h2>Listado de Obras</h2>
        </div>
        <div class="section-content">
            <table mat-table [dataSource]="listadoObras" class="mat-elevation-z8">
                <!-- Position Column -->
                <ng-container matColumnDef="id_orden">
                  <th mat-header-cell *matHeaderCellDef> Id Orden </th>
                  <td mat-cell *matCellDef="let element"> {{element.id_orden}} </td>
                </ng-container>
              
                <!-- Name Column -->
                <ng-container matColumnDef="id_oferta">
                  <th mat-header-cell *matHeaderCellDef> Id Oferta </th>
                  <td mat-cell *matCellDef="let element"> {{element.id_oferta}} </td>
                </ng-container>
              
                <!-- Weight Column -->
                <ng-container matColumnDef="id_oferente">
                  <th mat-header-cell *matHeaderCellDef> Id Oferente </th>
                  <td mat-cell *matCellDef="let element"> {{element.id_oferente}} </td>
                </ng-container>
              
                <!-- Symbol Column -->
                <ng-container matColumnDef="nombre">
                  <th mat-header-cell *matHeaderCellDef> Nombre </th>
                  <td mat-cell *matCellDef="let element"> {{element.nombre_del_frente}} </td>
                </ng-container>

                <ng-container matColumnDef="colonia">
                    <th mat-header-cell *matHeaderCellDef> Colonia </th>
                    <td mat-cell *matCellDef="let element"> {{element.colonia}} </td>
                </ng-container>

                <ng-container matColumnDef="acciones">
                    <th mat-header-cell *matHeaderCellDef> Acciones </th>
                    <td mat-cell *matCellDef="let element">
						            <a [routerLink]="['/info-obra']" [queryParams]="{orden: element.id_orden }" (click)="switchOrden(element.id_orden)">
                          <mat-icon class="pointer" title="Info Obra" aria-hidden="false" aria-label="Info Obra">border_color</mat-icon></a>
                        <a [routerLink]="['/revision-documental']" [queryParams]="{orden: element.id_orden }" (click)="switchOrden(element.id_orden)">
                          <mat-icon class="pointer" title="Revision Documental" aria-hidden="false" aria-label="Revision Documental">book</mat-icon>
                        </a>
                        <a [routerLink]="['/sembrado']" [queryParams]="{orden: element.id_orden }" (click)="switchOrden(element.id_orden)">
                          <mat-icon class="pointer" title="Sembrado" aria-hidden="false" aria-label="Sembrado">location_on</mat-icon>
                        </a>
                        <a [routerLink]="['/seleccionar-proceso']" [queryParams]="{orden: element.id_orden }" (click)="switchOrden(element.id_orden)">
                          <mat-icon class="pointer" title="Verificaciones" aria-hidden="false" aria-label="Verificaciones">check_box</mat-icon>
                        </a>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
    </div>
</section>
<app-loader [isLoading]="isLoading"></app-loader>