<div class="row background-container px-0" style="background: #000">
	<div class="col-12">
		<div class="row justify-content-center align-items-center mx-0">
			<div class="col-xl-3 col-md-4 col-10 d-flex vh-100">
				<div class="w-100 card animated fadeInUp delay-2s o-hidden border-0 shadow-lg justify-content-center align-self-center my-5 bg-flcnt">
					<div class="card-body p-0">
						<!-- Nested Row within Card Body -->
						<div class="row">
							<div class="col-12">
								<div class="">
									<div class="text-center">
										<!-- <h1 class="h4 text-gray-900 mb-4"><img class="img-fluid login-logo" src="" alt="">
											<p class="d-none">Sign in!</p>
										</h1> -->
										<div class="title-login">¡Bienvenido!</div>
										<div class="subtitle-login">Ingrese sus datos para iniciar sesión.</div>
										<form name="loginform" [formGroup]="loginForm" (ngSubmit)="onSubmit()" id="loginform">
											<div class="col-12" *ngIf="!authValid">
												<small id="passwordHelp" class="text-danger">
													El usuario o contraseña ingresados no son correctos. Inténtalo de nuevo.
												</small>      
											</div>
	
											<label for="email"></label>
											<div class="button-container audit-login row" data-selection="#create-story">
												<div class="input-container col-12">
													<img class="icon-login" src="">
													<input type="email" name="email" id="email" class="audit-login-input col-12" value="" formControlName="email" placeholder="ejemplo@dominio.com" required>
												</div>
												<span class="login-line"></span>
											</div>
	
											<label for="password"></label>
											<div class="button-container audit-login row" data-selection="#create-story">
												<div class="input-container col-12">
													<img class="icon-login" src="">
													<input type="password" name="password" id="password" class="audit-login-input col-12" value="" size="20" formControlName="password" placeholder="***********" required autocomplete>
												</div>
												<span class="login-line"></span>
											</div>
											<div class="button-container audit-login row">
												<div class="button-container audit-login py-3 w-100" data-selection="#create-story">
													<button type="submit" class="btn btn-primary w-100 py-3">Entrar</button>
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<app-loader [isLoading]="isLoading"></app-loader>