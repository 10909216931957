import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { EcotecniasService } from 'src/app/services/ecotecniasService.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-dialogo-ecotecnias',
  templateUrl: './dialogo-ecotecnias.component.html',
  styleUrls: ['./dialogo-ecotecnias.component.scss']
})
export class DialogoEcotecniasComponent implements OnInit {
  	ecotecnia: any = null;
  	allowButton: boolean = false;
  	isLoading: boolean = false;
	isUpdate: boolean = false;
  	ecoForm: UntypedFormGroup;
  	subUse = new Subject<any>();
	viewId = 0;

	constructor(private ecotecniasService: EcotecniasService,
				public dialogRef: MatDialogRef<DialogoEcotecniasComponent>,
				private formBuilder: UntypedFormBuilder,
				@Inject(MAT_DIALOG_DATA) public data: any) {
		this.subUse = this.data.subUse;
		this.viewId = this.data.viewId;
	
		this.ecoForm = this.formBuilder.group({
			id: new UntypedFormControl(""),
			ecotecnia: new UntypedFormControl("")
		});
	}

  	ngOnInit(): void {
	    if (this.data.bool === 1) {
	    	this.isUpdate = true;
	      	this.ecoForm.patchValue(this.data.element)
	    } else {
	      	this.isUpdate = false;
	    }
  	}

	close() {
		this.dialogRef.close();
	}

  	ecotecniaChange(event) {
		this.ecotecnia = event;
		if (this.ecotecnia != null) {
			this.allowButton = true;
    	}
	}

  	updateEco(ecotecnologia) {
		let ecotecnia = ecotecnologia.value;
		this.isLoading = true;
		this.ecotecniasService.updateEcotecnia(ecotecnia).subscribe(res => {
			this.isLoading = false;
			this.subUse.next(res);
			this.dialogRef.close('actalizar');
		}, error => {
			var errorF;
			(error.error.message) != null ?
				errorF = error.error.message :
				errorF = 'Hubo un error al actualizar la ecotecnia!';
			this.isLoading = false;
			Swal.fire({
				icon: 'error',
				title: 'Error...',
				text: errorF,
				footer: 'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.'
			}).then((result) => {
				if (result.isConfirmed) {
					this.dialogRef.close();
				}
			})
		});
	}

  	storeEco(ecotecnologia) {
		let ecotecnia = ecotecnologia.value;
		this.isLoading = true;
		this.ecotecniasService.storeEcotecnia(ecotecnia).subscribe(res => {
			this.isLoading = false;
      		this.subUse.next(res);
      		this.dialogRef.close('guardar');
		}, error => {
			this.isLoading = false;
			var errorF;
			(error.error.message) != null ?
				errorF = error.error.message :
				errorF = 'Hubo un error al crear la ecotecnia!';
			this.isLoading = false;
			Swal.fire({
				icon: 'error',
				title: 'Error...',
				text: errorF,
				footer: 'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.'
			}).then((result) => {
				if (result.isConfirmed) {
					this.dialogRef.close();
				}
			})
		})
	}
}
