<section class="animate__animated animate__fadeIn h-90 d-flex align-items-center py-3">
    <div class="container">
        <div class="card">
            <div class="card-body">
                <form [formGroup]="userForm">
                    <div class="row">
                        <div class="col-sm-12 col-md-4 col-lg-4 text-center mb-4 mb-md-0 iconImg">
                            <div>
                                <i class="material-icons" style="font-size: 10em;">
                                    person_pin
                                </i>
                            </div>
                            <hr>
                            <div>
                                <mat-form-field class="w-100">
                                    <mat-label>Nombre (s)</mat-label>
                                    <input required type="text" matInput (ngModelChange)="userChange($event)"
                                        formControlName="nombre">
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-8 col-lg-8">
                            <div class="row">
                                <div class="col col-sm-6 col-md-6 col-lg-6">
                                    <mat-form-field class="w-100">
                                        <mat-label>Primer Apellido</mat-label>
                                        <input type="text" matInput (ngModelChange)="userChange($event)"
                                            formControlName="primer_apellido">
                                    </mat-form-field>
                                </div>
                                <div class="col col-sm-6 col-md-6 col-lg-6">
                                    <mat-form-field class="w-100">
                                        <mat-label>Segundo Apellido</mat-label>
                                        <input type="text" matInput (ngModelChange)="userChange($event)"
                                            formControlName="segundo_apellido">
                                    </mat-form-field>
                                </div>
                                <div class="col col-sm-6 col-md-6 col-lg-6">
                                    <mat-form-field class="w-100">
                                        <mat-label>Cedula</mat-label>
                                        <input type="text" matInput (ngModelChange)="userChange($event)"
                                            formControlName="cedula">
                                    </mat-form-field>
                                </div>
                                <div class="col col-sm-6 col-md-6 col-lg-6">
                                    <mat-form-field class="w-100">
                                        <mat-label>Telefono</mat-label>
                                        <input required type="text" matInput (ngModelChange)="userChange($event)"
                                            formControlName="telefono" maxlength="10">
                                    </mat-form-field>
                                </div>
                                <div class="col col-sm-6 col-md-6 col-lg-6">
                                    <mat-form-field appearance="fill" style="display: inherit;">
                                        <mat-label>
                                            Rol
                                        </mat-label>
                                        <mat-select [(ngModel)]="selectedRole" required formControlName="id_rol"
                                            (selectionChange)="userChange($event)" [disabled]="!isAdmin">
                                            <mat-option *ngFor="let rol of roles" [value]="rol.id_rol">
                                                {{rol.nombre}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col col-sm-6 col-md-6 col-lg-6">
                                    <mat-form-field appearance="fill" style="display: inherit;">
                                        <mat-label>Estado</mat-label>
                                        <mat-select [(ngModel)]="usuarioActivo" required formControlName="activo"
                                            (selectionChange)="userChange($event)" [disabled]="!isAdmin">
                                            <mat-option [value]="1">Activo</mat-option>
                                            <mat-option [value]="0">Inactivo</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col col-sm-12 col-md-12 col-lg-12">
                                    <mat-form-field class="w-100">
                                        <mat-label>Correo</mat-label>
                                        <input required type="email" matInput (ngModelChange)="userChange($event)"
                                            formControlName="email">
                                    </mat-form-field>
                                </div>
                                <div class="col col-sm-12 col-md-12 col-lg-12">
                                    <mat-form-field class="w-100">
                                        <mat-label>Contraseña</mat-label>
                                        <input required type="password" matInput (ngModelChange)="userChange($event)"
                                            formControlName="password" autocomplete="off">
                                    </mat-form-field>
                                </div>
                                <div class="col col-sm-12 col-md-12 col-lg-12">
                                    <mat-form-field class="w-100">
                                        <mat-label>Confirmar Contraseña</mat-label>
                                        <input required type="password" matInput (ngModelChange)="userChange($event)"
                                            formControlName="password_confirmation" autocomplete="off">
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <div class="text-right">
                    <button type="button" class="btn btn-primary" (click)="updateUser(userForm)" [disabled]="!(allowButton)">
                        Guardar
                    </button>
                </div>
            </div>
        </div>
    </div>
</section>
<app-loader [isLoading]="isLoading"></app-loader>