<section class="animate__animated animate__fadeIn d-flex align-items-center">
	<div class="container">
		<div class="section-title">
			<h2>Listado de Ecotecnias</h2>
		</div>
		<div class="section-content">
			<div class="row">
				<div class="col-sm-12 col-md-8 col-lg-8 py-2">
					<mat-form-field>
						<mat-label>Filtro</mat-label>
						<input matInput (keyup)="applyFilter($event)" placeholder="ej. Regadera Ahorradora	" #input>
					</mat-form-field>
				</div>
				<div class="col-sm-12 col-md-4 col-lg-4 py-2">
					<button type="button" class="btn btn-primary btn-sm" data-toggle="modal"
						data-target="#exampleModalCenter" (click)="toggleModal(0, null, 0)">
						Nueva Ecotecnia
					</button>
				</div>
			</div>

			<div class="row tabla-datos">
				<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
					<!-- Position Column -->
					<ng-container matColumnDef="id">
						<th mat-header-cell *matHeaderCellDef> ID. </th>
						<td mat-cell *matCellDef="let element; let idx = index"> {{ idx + 1 }} </td>
					</ng-container>

					<!-- Name Column -->
					<ng-container matColumnDef="ecotecnia">
						<th mat-header-cell *matHeaderCellDef> Nombre </th>
						<td mat-cell *matCellDef="let element"> {{element.ecotecnia}} </td>
					</ng-container>

					<!-- Name Column -->
					<ng-container matColumnDef="created_at">
						<th mat-header-cell *matHeaderCellDef> Fecha de Creación </th>
						<td mat-cell *matCellDef="let element"> {{element.created_at | date: 'dd/MM/yyyy hh:mm a'}} </td>
					</ng-container>

					<!-- Position Column -->
					<ng-container matColumnDef="acciones">
						<th mat-header-cell *matHeaderCellDef> Acciones </th>
						<td mat-cell *matCellDef="let element; let idx = index">
							<button mat-icon-button color="primary" title='Modificar' data-toggle="modal"
								data-target="#exampleModalCenter" (click)="toggleModal(1, element, idx + 1)">
								<mat-icon>update</mat-icon>
							</button>
							<button mat-icon-button color="warn" title='Eliminar' (click)="deleteEco(element.id)">
								<mat-icon>delete</mat-icon>
							</button>
						</td>
					</ng-container>

					<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
					<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

					<!-- Row shown when there is no matching data. -->
					<tr class="mat-row" *matNoDataRow>
						<td class="mat-cell" colspan="4">No hay resultados para: "{{input.value}}"</td>
					</tr>
				</table>
			</div>
		</div>
	</div>
</section>
<app-loader [isLoading]="isLoading"></app-loader>
