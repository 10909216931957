import { Component, OnInit } from '@angular/core';
import { Image } from 'konva/types/shapes/Image';
import Konva from 'konva';
import { SembradoService } from 'src/app/services/sembrado.service';
import { ActivatedRoute } from '@angular/router';
import { Layer } from 'konva/types/Layer';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
	selector: 'app-sembrado',
	templateUrl: './sembrado.component.html',
	styleUrls: ['./sembrado.component.scss']
})
export class SembradoComponent implements OnInit {
	public idOrden: any = this.activatedRoute.snapshot.queryParams["orden"];;
	public files: any[] = [];
	currentMap: string = "";
	public map: any = "";
	public rect_width: number = 100;
	public rect_height: number = 90;
	public rect_angle: number = 0;
	public allCuvs: Array<any> = [];
	public availableCuvs: Array<any> = [];
	public layer: any = new Konva.Layer();
	public rect_color: string = '#ffff00';
	public isLoading: boolean = false;
	public isNewFigure: boolean = true;
	public idFigura: number = 0;
	public ext: number = 0;
	public lote: number = 0;
	public manzana: number = 0;
	public selectedCuv: string = "";
	public selectedFigura: any;
	public isMobile: boolean;

	constructor(
		private sembradoService: SembradoService,
		private activatedRoute: ActivatedRoute
	) { 
		this.sidebarToggle();
	}

	ngOnInit(): void {
		this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
		this.getCuvs();
		this.getExistingMap();
		this.initCanvas();
	}

	sidebarToggle(){
		var sidebar = document.getElementById("sidebar");
		sidebar.classList.add('active');
		var contenedor = document.getElementById("content");
		if(sidebar.classList.value == 'active'){
			contenedor.style.width = 'calc(100% - 80px )';
		}
	}

	initCanvas() {
		let canvas = (<HTMLInputElement>document.getElementById('canvas'));
		let canvas_width = canvas.offsetWidth;
		let canvas_height = canvas.offsetHeight;
		let stage = new Konva.Stage({
			container: 'canvas',
			width: canvas_width,
			height: canvas_height,
		});
		let currentShape;
		let menuNode = document.getElementById('menu');
		if (this.isMobile == false) {

			let max_width: number = 150,
				max_height: number = 150,
				min_width: number = 10,
				min_height: number = 10,
				rect_id: any = 0,
				rect_opacity: number = 0.8,
				rect_stroke = '#000000';

			stage.on('dblclick dbltap', () => {
				/**/
				if (this.availableCuvs.length > 0) {
					let rect = new Konva.Rect({
						x: stage.getPointerPosition().x,
						y: stage.getPointerPosition().y,
						width: this.rect_width,
						height: this.rect_height,
						rotation: this.rect_angle,
						fill: this.rect_color,
						id: rect_id,
						opacity: rect_opacity,
						stroke: rect_stroke,
						draggable: true,
					});
					this.layer.add(rect);

					var text = new Konva.Text({
						x: 5,
						y: 5,
					});

					// create new transformer
					var tr = new Konva.Transformer({
						boundBoxFunc: function (oldBoundBox, newBoundBox) {
							if (Math.abs(newBoundBox.width) > max_width) {
								return oldBoundBox;
							}

							if (Math.abs(newBoundBox.height) > max_height) {
								return oldBoundBox;
							}

							if (Math.abs(newBoundBox.width) < min_width) {
								return oldBoundBox;
							}

							if (Math.abs(newBoundBox.height) < min_height) {
								return oldBoundBox;
							}

							return newBoundBox;
						},
					});

					this.layer.add(tr);
					tr.nodes([rect]);
					this.layer.draw();
				} else {
					Swal.fire({
						title: '<strong>Error!</strong>',
						icon: 'info',
						html:
							'No hay más CUVS disponibles para la obra actual.',
						focusConfirm: false,
						confirmButtonText: 'Ok',
						footer: 'Favor de verificar los cuvs asignados a esta obra. Si el problema persiste comunicarse a soporte.'
					});/* .then((result) => {
						if (result.isConfirmed) {
							window.location.reload();
						}
					}) */
				}
			});

			// setup menu
			document.getElementById('lock-button').addEventListener('click', () => {
				if (currentShape.draggable()) {
					this.rect_width *= currentShape.scaleX();
					this.rect_height *= currentShape.scaleY();
					this.rect_angle = currentShape.rotation();

					const tr = this.layer.find('Transformer').toArray().find((tr: any) => tr.nodes()[0] === currentShape);
					tr.destroy();
					currentShape.to({
						draggable: false,
						onFinish: () => {
							currentShape.to({ draggable: false });
						},
					});

					//store current shape data
					//validate if currentShape ID is a cuv or not
					if (currentShape.id() == 0) {
						let nuevaFigura = this.guardarNuevaFigura(
							currentShape.height(), currentShape.width(),
							currentShape.scaleX(), currentShape.scaleY(),
							currentShape.x(), currentShape.y(), currentShape.rotation()
						);
						currentShape.id(nuevaFigura.cuv);
					} else if (currentShape.id() != 0) {
						this.guardarCambioFigura(
							currentShape.id(), currentShape.height(),
							currentShape.width(), currentShape.scaleX(),
							currentShape.scaleY(), currentShape.x(),
							currentShape.y(), currentShape.rotation()
						);
					}
				} else {
					// create new transformer
					var tr = new Konva.Transformer({
						boundBoxFunc: function (oldBoundBox, newBoundBox) {
							if (Math.abs(newBoundBox.width) > max_width) {
								return oldBoundBox;
							}

							if (Math.abs(newBoundBox.height) > max_height) {
								return oldBoundBox;
							}

							if (Math.abs(newBoundBox.width) < min_width) {
								return oldBoundBox;
							}

							if (Math.abs(newBoundBox.height) < min_height) {
								return oldBoundBox;
							}

							return newBoundBox;
						},
					});

					this.layer.add(tr);
					tr.nodes([currentShape]);
					this.layer.draw();

					currentShape.to({
						draggable: true,
						onFinish: () => {
							currentShape.to({ draggable: true });
						},
					});

				}

			});

			document.getElementById('delete-button').addEventListener('click', () => {
				const tr = this.layer.find('Transformer').toArray().find((tr: any) => tr.nodes()[0] === currentShape);
				tr.destroy();
				currentShape.destroy();
				this.layer.draw();
			});

			window.addEventListener('click', () => {
				// hide menu
				menuNode.style.display = 'none';
			});

			stage.on('contextmenu', (e) => {
				// prevent default behavior
				e.evt.preventDefault();
				if (e.target === stage) {
					// if we are on empty place of the stage we will do nothing
					return;
				}
				currentShape = e.target;
				// show menu
				if (currentShape.draggable()) {
					this.isNewFigure = false;
					document.getElementById('lock-button').innerHTML = 'Bloquear';
				} else {
					this.isNewFigure = true;
					this.idFigura = currentShape.id();
					this.ext = currentShape.getAttr("ext");
					this.manzana = currentShape.getAttr("manzana");
					this.lote = currentShape.getAttr("lote");
					this.selectedFigura = currentShape;
					document.getElementById('lock-button').innerHTML = 'Desbloquear';
				}
				menuNode.style.display = 'initial';
				let containerRect = stage.container().getBoundingClientRect();
				menuNode.style.top = (stage.getPointerPosition().y + 10) + 'px';
				menuNode.style.left = (stage.getPointerPosition().x + 20) + 'px';
			});
			window.addEventListener('click', () => {
				// hide menu
				menuNode.style.display = 'none';
			});
		}

		stage.add(this.layer);
	}

	getExistingMap() {
		this.isLoading = true;
		this.idOrden = this.activatedRoute.snapshot.queryParams["orden"];
		this.sembradoService.getExistingMap(this.idOrden).subscribe(res => {
			(<HTMLInputElement>document.getElementById('canvas')).style.background = "url(" + 'data:image/png;base64,' + res + ")";
			(<HTMLInputElement>document.getElementById('canvas')).style.backgroundRepeat = 'no-repeat';
			(<HTMLInputElement>document.getElementById('canvas')).style.backgroundSize = 'contain';
		}, error => {
			Swal.fire({
				icon: 'error',
				title: 'Error...',
				text: 'Hubo un error al cargar mapa de sembrado!',
				footer: 'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.'
			});
		});

	}

	cargarMapa(event) {
		this.isLoading = true;
		let formData = new FormData();
		event.currentTarget.closest('.modal').classList.remove('show');
		if (this.files.length > 0) {
			formData.append('import_file', this.files[0]);
			formData.append('id_orden', this.idOrden);
			formData.append('nombre_archivo', this.files[0].name);
			this.sembradoService.cargarNuevoSembradoMapa(formData).subscribe(res => {
				this.isLoading = false;
				// window.location.reload();
			}, error => {
				//loader false
				var errorF;
				(error.error.message) != null ?
					errorF = error.error.message :
					errorF = 'Hubo un error al cargar el Mapa!';
				this.isLoading = false;
				Swal.fire({
					icon: 'error',
					title: 'Error...',
					text: errorF,
					footer: 'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.'
				}).then((result) => {
					if (result.isConfirmed) {
						window.history.back();
					}
				});
			});
		}
	}

	prepararArchivo(files: Array<any>) {
		for (const item of files) {
			item.progress = 0;
			this.files.push(item);
		}
	}

	getCuvs() {
		this.sembradoService.getCuvsSembrado(this.idOrden).subscribe(res => {
			this.allCuvs = res;
			for (let i = 0; i < res.length; i++) {
				this.availableCuvs.push(res[i]);
			}
			this.availableCuvs.sort();
			this.getExistingShapes();
		}, error => {
			console.error(error);
		});
	}

	getExistingShapes() {
		this.isLoading = true;
		this.sembradoService.getShapes(this.idOrden).subscribe(res => {
			this.isLoading = false;
			if (res.length > 0) {
				for (let i = 0; i < res.length; i++) {
					let rect = new Konva.Rect({
						x: res[i].pos_x,
						y: res[i].pos_y,
						width: (res[i].ancho_inicial * res[i].escala_x),
						height: (res[i].alto_inicial * res[i].escala_y),
						rotation: res[i].inclinacion,
						fill: res[i].avance >= 0.8 ? '#fcd5b4' : '#808080',
						id: res[i].cuv.cuv,
						ext: res[i].cuv.numero_exterior,
						lote: res[i].cuv.lote,
						manzana: res[i].cuv.manzana,
						opacity: 0.8,
						stroke: '#000000',
						draggable: false
					});
					this.layer.add(rect);
					this.layer.draw();

					this.rect_width = res[i].ancho_inicial * res[i].escala_x;
					this.rect_height = res[i].alto_inicial * res[i].escala_y;
					this.rect_angle = res[i].inclinacion;
				}
			}
		}, error => {
			console.error(error);
			this.isLoading = false;
		})
	}

	guardarNuevaFigura(initialHeight, initialWidth, scaleX, scaleY, positionX, positionY, rotation) {
		let formData = new FormData();
		if (this.availableCuvs.length > 0) {
			let cuvToSave = this.availableCuvs[0];
			formData.append('id_orden', this.idOrden);
			formData.append('cuv', cuvToSave.cuv);
			formData.append('height', initialHeight);
			formData.append('width', initialWidth);
			formData.append('scaleX', scaleX);
			formData.append('scaleY', scaleY);
			formData.append('positionX', positionX);
			formData.append('positionY', positionY);
			formData.append('rotation', rotation);
			this.sembradoService.guardarNuevaFigura(formData).subscribe(res => {
				this.availableCuvs.shift();
				Swal.fire({
					icon: 'success',
					title: 'Guardado'
				});
			}, error => {
				console.error(error);
			});
			return cuvToSave;
		}
	}

	guardarCambioFigura(cuv, initialHeight, initialWidth, scaleX, scaleY, positionX, positionY, rotation) {
		let formData = new FormData();
		formData.append('id_orden', this.idOrden);
		formData.append('cuv', cuv);
		formData.append('height', initialHeight);
		formData.append('width', initialWidth);
		formData.append('scaleX', scaleX);
		formData.append('scaleY', scaleY);
		formData.append('positionX', positionX);
		formData.append('positionY', positionY);
		formData.append('rotation', rotation);
		this.sembradoService.guardarCambioFigura(formData).subscribe(res => {
			Swal.fire({
				icon: 'success',
				title: 'Guardado',
				text: 'Figura Actualizada'
			});
		}, error => {
			console.error(error);
		});
	}

	cancelarSubida() {
		(<HTMLInputElement>document.getElementById("import_file")).value = "";
	}

	cambiarCuvFigura(e, cuv: any) {
		let formData = new FormData();
		e.currentTarget.closest('.modal').classList.remove('show');
		if (this.availableCuvs.length > 0) {
			formData.append('id_orden', this.idOrden);
			formData.append('cuv_actual', this.selectedFigura.id());
			formData.append('cuv_nuevo', cuv.cuv);
			this.sembradoService.guardarCambioFigura(formData).subscribe(res => {
				Swal.fire({
					icon: 'success',
					title: 'Guardado',
					text: 'El cambio de CUV fue exitoso'
				});
				//change id for cuv
				this.availableCuvs.push(this.selectedFigura.id());
				let removal = this.availableCuvs.indexOf(cuv);
				this.availableCuvs.splice(removal, 1);
				this.availableCuvs.sort();
				// window.location.reload();
			}, error => {
				Swal.fire({
					icon: 'error',
					title: 'Error...',
					text: 'Error de comunicación!: ' + error,
					footer: 'Si el error persiste, favor de comunicarse con soporte'
				});
			});
		}
	}
}