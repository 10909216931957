import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
    providedIn: 'root'
})
export class ReporteService {
    public headers: Headers;
    public requestOptions;

    constructor(private http: HttpClient) {
        httpOptions.headers.append("Authorization", "Bearer " + localStorage.getItem("SESSION"));
        this.requestOptions = {
          headers: {"Authorization": "Bearer " + localStorage.getItem("SESSION_TOKEN")}
        }
    }

    getReporteData(idOrden): Observable<any>{
        return this.http.post<any>(environment.api + "get-datos-reporte", idOrden, this.requestOptions);
    }

    updateFirstReport(ponderacion): Observable<any>{
        return this.http.post<any>(environment.api + "update-reporte", ponderacion, this.requestOptions);
    }

    getReportes(userId): Observable<any>{
        return this.http.post<any>(environment.api + "get-historial-reportes", userId, this.requestOptions);
    }

    descargarReportes(idOrden): Observable<any>{
        return this.http.post<any>(environment.api + "get-reportes", idOrden, this.requestOptions);
    }

    generarReporteAvance(datos): Observable<any>{
        return this.http.post<any>(environment.api + "generar-reporte-avance", datos, this.requestOptions);
    }

    getOrdenRelacionada(datosVerificador): Observable<any>{
        return this.http.post<any>(environment.api + "get-orden-relacionada", datosVerificador, this.requestOptions);
    }

    saveReporte(reporte): Observable<any>{
        return this.http.post<any>(environment.api + "save-reporte-avance", reporte, this.requestOptions);
    }

    getArchivosReportes(datos): Observable<any>{
        return this.http.post<any>(environment.api + "get-archivos-reportes", datos, this.requestOptions);
    }

    descargarArchivoReporte(url): Observable<any>{
        return this.http.post<any>(environment.api + "download-archivo-reporte", url, this.requestOptions);
    }

    getEvidencias(datos): Observable<any>{
        return this.http.post<any>(environment.api + "get-evidencias", datos, this.requestOptions);
    }

    setEvidencias(datos): Observable<any>{
        return this.http.post<any>(environment.api + "set-evidencias", datos, this.requestOptions);
    }

    updateEvidencias(datos): Observable<any>{
        return this.http.post<any>(environment.api + "update-evidencias", datos, this.requestOptions);
    }

    updateReporteDescripcion(datos): Observable<any>{
        return this.http.post<any>(environment.api + "update-reporte-descripcion", datos, this.requestOptions);
    }
    isReporteSetted(datos): Observable<any>{
        return this.http.post<any>(environment.api + "is-reporte-avance-setted", datos, this.requestOptions);
    }
}