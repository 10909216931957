import { Component, OnInit, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { DTUService } from 'src/app/services/dtuService.service';
import { cuvsService } from 'src/app/services/cuvsService.service';
import { SembradoService } from 'src/app/services/sembrado.service';
import Konva from 'konva';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { UntypedFormBuilder, UntypedFormControl, FormControlName, UntypedFormGroup } from '@angular/forms';

import Swal from 'sweetalert2';
// import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

export interface Dtu {
	id: number;
	id_oden: number;
	created_at: string;
	updated_at: string;
	acciones: any;
}

@Component({
	selector: 'app-dtu',
	templateUrl: './dtu.component.html',
	styleUrls: ['./dtu.component.scss'],
	animations: [
	  trigger('detailExpand', [
		state('collapsed', style({height: '0px', minHeight: '0'})),
		state('expanded', style({height: '*'})),
		transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
	  ]),
	]
})
export class DtuComponent implements AfterViewInit, OnInit {

	public method: any = this.activatedRoute.snapshot.queryParams["method"];
	public idOrden: any = this.activatedRoute.snapshot.queryParams["orden"];
	public idDtu: any = this.activatedRoute.snapshot.queryParams["dtu"];
	public dtu: any;
	public datosObra: any;
	public orden: any;
	public currentDate: any;
	public ecotecnias: any;
	public verificador: any;
	public userId: any;
	public cuvs: any;
	public cuvsAnteriores: any;
	public cuvsECO: number = 0;
	public isLoading: boolean;
	public dataSource: any;
	public conceptos: any;
	public cuvsSelected: Array<{}>;
	public conceptosForm: UntypedFormGroup;
	public allowButton: boolean = false;
	public cuvSinSembrado: boolean = false;
	public expandedElement: any | null;
	subColumnsToDisplay: string[] = ['cuv', 'id_vivienda', 'lote', 'manzana'];
	displayedColumns: string[] = ['id', 'id_orden', 'created_at', 'updated_at', 'acciones'];
	displayedColumnsB: string[] = ['id', 'id_orden', 'created_at', 'updated_at'];

	public conceptosDictaminados: any;
	public ecotecnologias: any;
	public conceptosPorEjecutar: any;
	
    @ViewChild('canvas', { static: false }) public canvas: ElementRef;
	public layer: any = new Konva.Layer();
	public rect_width: number = 100;
	public rect_height: number = 90;
	public rect_angle: number = 0;

	constructor(
		private activatedRoute: ActivatedRoute,
		private dtuService: DTUService,
		private cuvsService: cuvsService,
		private sembradoService: SembradoService,
		private formBuilder: UntypedFormBuilder,
	) { 
		this.sidebarToggle();
	}

	ngOnInit(): void {
		this.userId = localStorage.getItem('SESSION_USER_ID');
		this.currentDate = new Date();
		
		if (this.idOrden == null) {
			this.hasObra();
		}
		if(this.method == 'form' && this.idOrden != null){
			this.getCuvsDtu();
		}
		if(this.method == 'index' && this.idOrden != null){
			this.indexDtu();
		}
		if(this.method == 'show' && this.idOrden != null && this.idDtu){
			this.showDtu();

			this.conceptosForm = this.formBuilder.group({
				cuv: new UntypedFormControl("")
			});
		}
	}

	ngAfterViewInit(){
		
		if(this.method == 'show' && this.idOrden != null && this.idDtu){
			this.getExistingMap();
			this.initCanvas();
		}
	}
	
	initCanvas() {
		let canvas = this.canvas.nativeElement;
		let stage = new Konva.Stage({
			container: canvas,
			width: 900,
			height: 600,
		});
		
		stage.add(this.layer);
	}
	
	getExistingMap() {
		this.isLoading = true;
		this.idOrden = this.activatedRoute.snapshot.queryParams["orden"];
		this.sembradoService.getExistingMap(this.idOrden).subscribe(res => {
			this.canvas.nativeElement.style.background = "url(" + 'data:image/png;base64,' + res + ")";
			this.canvas.nativeElement.style.backgroundRepeat = 'no-repeat';
			this.canvas.nativeElement.style.backgroundSize = 'contain';
		}, error => {
			Swal.fire({
				icon: 'error',
				title: 'Error...',
				text: 'Hubo un error al cargar mapa de sembrado!',
				footer: 'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.'
			});
		});

	}

	sidebarToggle(){
		var sidebar = document.getElementById("sidebar");
		sidebar.classList.add('active');
		var contenedor = document.getElementById("content");
		if(sidebar.classList.value == 'active'){
			contenedor.style.width = 'calc(100% - 80px )';
		}
	}

	applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dataSource.filter = filterValue.trim().toLowerCase();
	}

	hasObra(){
		this.dtuService.hasObra(this.userId).subscribe(res => {
			const urlParams = new URLSearchParams(window.location.search);
			urlParams.set('orden', res[1]);
			window.location.search = urlParams.toString();

		}, error => {
			//loader false
			var errorF;
			(error.error.message) != null ?
				errorF = error.error.message :
				errorF = 'Hubo un error al consultar las Ordenes!';
			Swal.fire({
				icon: 'error',
				title: 'Error...',
				text: errorF,
				footer: 'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.'
			});/* .then((result) => {
				if (result.isConfirmed) {
					window.history.back();
				}
			}) */
		})
	}
	
	cuvChange(event) {
		this.cuvsSelected = event;
		if(event !== null && event !== '' && event.length > 0) {
			this.allowButton = true;
		}else{
			this.allowButton = false;
		}
	}

	getCuvsDtu(){
		this.cuvsService.getCuvsDtu(this.idOrden).subscribe(res => {
			this.cuvs = res;
		}, error => {
			//loader false
			var errorF;
			(error.error.message) != null ?
				errorF = error.error.message :
				errorF = 'Hubo un error al consultar las Ordenes!';
			Swal.fire({
				icon: 'error',
				title: 'Error...',
				text: errorF,
				footer: 'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.'
			}).then((result) => {
				if (result.isConfirmed) {
					window.history.back();
				}
			});
		})
	}

	indexDtu(){
		let datos = {};
		datos['id_orden'] = this.idOrden;

		this.dtuService.indexDtu(datos).subscribe(res => {
			this.cuvs = res[0].dtus;
			const ELEMENT_DATA: Dtu[] = this.cuvs;
			this.dataSource = new MatTableDataSource(ELEMENT_DATA);
		}, error => {
			//loader false
			var errorF;
			(error.error.message) != null ?
				errorF = error.error.message :
				errorF = 'Hubo un error al consultar los DTU!';
			Swal.fire({
				icon: 'error',
				title: 'Error...',
				text: errorF,
				footer: 'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.'
			}).then((result) => {
				if (result.isConfirmed) {
					window.history.back();
				}
			});
		})
	}

	showDtu(){
		let datos = {};
		datos['id_orden'] = this.idOrden;
		datos['dtu_id'] = this.idDtu;

		this.dtuService.showDtu(datos).subscribe(res => {
			this.orden = res[0];
			this.datosObra = res[0].datos_obra;
			this.verificador = this.datosObra.verificador;
			
			this.conceptosDictaminados = res[1]['1'];
			this.ecotecnologias = res[1]['2'];
			this.conceptosPorEjecutar = res[1]['3'];
			
			this.conceptos = Object.keys(this.orden.dtus[0].conceptos).map((k) => ({'concepto_id':this.orden.dtus[0].conceptos[k]['id'], 'opcion':this.orden.dtus[0].conceptos[k]['pivot']['opcion']}));
			this.dtu = res[0].dtus[0];
			this.cuvs = this.dtu.cuvs;
			this.cuvsAnteriores = res[2]
			this.cuvs.forEach(element => {
				if(element.sembrado != null){
					element.ecotecnias.length > 5 ? this.cuvsECO++ : this.cuvsECO+0;
					
					var rectangle = new Konva.Group({
						x: element.sembrado.pos_x,
						y: element.sembrado.pos_y,
						width: (element.sembrado.ancho_inicial * element.sembrado.escala_x),
						height: (element.sembrado.alto_inicial * element.sembrado.escala_y),
						rotation: element.sembrado.inclinacion,
						draggable: false,
					}); 
					rectangle.add(new Konva.Rect({
						width: (element.sembrado.ancho_inicial * element.sembrado.escala_x),
						height: (element.sembrado.alto_inicial * element.sembrado.escala_y),
						fill: '#ffff00',
						opacity: 0.9,
						stroke: element.ecotecnias.length > 5 ? '#00ec00' : '#000000',
					}));
				
					rectangle.add(new Konva.Text({
						text: element.id_vivienda,
						fontSize: 15,
						fontFamily: 'Calibri',
						fill: '#000000',
						width: (element.sembrado.ancho_inicial * element.sembrado.escala_x),
						height: (element.sembrado.alto_inicial * element.sembrado.escala_y),
						padding: 10,
						align: 'center',
						verticalAlign: 'middle'
					}));
					this.layer.add(rectangle);
					this.layer.draw();

					this.rect_width = element.sembrado.ancho_inicial * element.sembrado.escala_x;
					this.rect_height = element.sembrado.alto_inicial * element.sembrado.escala_y;
					this.rect_angle = element.sembrado.inclinacion;
				}else{
					this.cuvSinSembrado = true;
				}
			});

			this.cuvsAnteriores.forEach(element => {
				if(element.sembrado != null){
					
					var rectangle = new Konva.Group({
						x: element.sembrado.pos_x,
						y: element.sembrado.pos_y,
						width: (element.sembrado.ancho_inicial * element.sembrado.escala_x),
						height: (element.sembrado.alto_inicial * element.sembrado.escala_y),
						rotation: element.sembrado.inclinacion,
						draggable: false,
					}); 
					rectangle.add(new Konva.Rect({
						width: (element.sembrado.ancho_inicial * element.sembrado.escala_x),
						height: (element.sembrado.alto_inicial * element.sembrado.escala_y),
						fill: '#ffc000',
						opacity: 0.9,
						stroke: element.ecotecnias.length > 5 ? '#00ec00' : '#000000',
					}));
				
					rectangle.add(new Konva.Text({
						text: element.lote,
						fontSize: 15,
						fontFamily: 'Calibri',
						fill: '#000000',
						width: (element.sembrado.ancho_inicial * element.sembrado.escala_x),
						height: (element.sembrado.alto_inicial * element.sembrado.escala_y),
						padding: 10,
						align: 'center',
						verticalAlign: 'middle'
					}));
					this.layer.add(rectangle);
					this.layer.draw();

					this.rect_width = element.sembrado.ancho_inicial * element.sembrado.escala_x;
					this.rect_height = element.sembrado.alto_inicial * element.sembrado.escala_y;
					this.rect_angle = element.sembrado.inclinacion;
				}else{
					this.cuvSinSembrado = true;
				}
			});
		}, error => {
			//loader false
			var errorF;
			(error.error.message) != null ?
				errorF = error.error.message :
				errorF = 'Hubo un error al consultar el DTU!';
			Swal.fire({
				icon: 'error',
				title: 'Error...',
				text: errorF,
				footer: 'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.'
			}).then((result) => {
				if (result.isConfirmed) {
					window.history.back();
				}
			});
		})

	}

	setDtu(){

		const swalWithBootstrapButtons = Swal.mixin({
			customClass: {
				confirmButton: 'btn btn-primary',
				cancelButton: 'btn btn-danger'
			},
			buttonsStyling: true
		})

		if(this.allowButton){
			let datos = {};
			datos['cuvs'] =  Object.keys(this.cuvsSelected).map(k => this.cuvsSelected[k].cuv);
			datos['id_orden'] =  this.idOrden;

			this.dtuService.storeDtu(datos).subscribe(res => {
				swalWithBootstrapButtons.fire({
					icon: 'success',
					title: 'DTU Creado con exito!',
					text: '¿Desea ver el DTU creado?',
					showCancelButton: true,
					confirmButtonText: 'Si, ver DTU',
					cancelButtonText: 'No, recargar',
					reverseButtons: true
				}).then((result) => {
					if (result.isConfirmed) {
						window.location.href ='/show-dtu?method=show&orden='+this.idOrden+'&dtu='+res.id;
					}/*  else {
						window.location.reload();
					} */

				});
			}, error => {
				//loader false
				var errorF;
				(error.error.message) != null ?
					errorF = error.error.message :
					errorF = 'Hubo un error al crear el DTU!';
				Swal.fire({
					icon: 'error',
					title: 'Error...',
					text: errorF,
					footer: 'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.'
				}).then((result) => {
					if (result.isConfirmed) {
						window.history.back();
					}
				});
			})

		}
	}

	conceptoChange(concepto_id, opcion){
		this.conceptos[concepto_id-1].opcion = parseInt(opcion.target.value)
		this.updateDtu();
	}

	updateDtu(){
		let datos = {};
		datos['id'] = this.dtu.id;
		datos['conceptos'] = this.conceptos;

		this.dtuService.updateDtu(datos).subscribe(res => {
			
		}, error => {
			//loader false
			var errorF;
			(error.error.message) != null ?
				errorF = error.error.message :
				errorF = 'Hubo un error al actualizar el DTU!';
			Swal.fire({
				icon: 'error',
				title: 'Error...',
				text: errorF,
				footer: 'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.'
			}).then((result) => {
				if (result.isConfirmed) {
					window.history.back();
				}
			});
		})
	}

}
