import { Component, OnInit } from '@angular/core';
import { UsuariosService } from 'src/app/services/usuarios.service';
import { RolesService } from 'src/app/services/roles.service';
import { UntypedFormBuilder, UntypedFormControl, FormControlName, UntypedFormGroup } from '@angular/forms';
import Swal from 'sweetalert2';
import { Subject } from 'rxjs';

@Component({
	selector: 'app-usuario',
	templateUrl: './usuario.component.html',
	styleUrls: ['./usuario.component.scss']
})
export class UsuarioComponent implements OnInit {

	public user: any;
	public roles: any;
	public isLoading: boolean = false;
	public isAdmin: boolean = false;
	public usuarioActivo: number = 0;
	public dataSource: any;
	public userForm: UntypedFormGroup;
	public allowButton: boolean = false;
	public isUpdate: boolean = false;
	public usuario: any = {};
	public selectedRole: number;
	subUse = new Subject<any>();

	constructor(
		private usuariosService: UsuariosService,
		private rolesService: RolesService,
		private formBuilder: UntypedFormBuilder,
	) {
		this.userForm = this.formBuilder.group({
			id: new UntypedFormControl(""),
			nombre: new UntypedFormControl(""),
			primer_apellido: new UntypedFormControl(""),
			segundo_apellido: new UntypedFormControl(""),
			email: new UntypedFormControl(""),
			telefono: new UntypedFormControl(""),
			id_rol: new UntypedFormControl(""),
			activo: new UntypedFormControl(""),
			cedula: new UntypedFormControl(""),
			password: new UntypedFormControl(""),
			password_confirmation: new UntypedFormControl(""),
		});

		this.subUse.subscribe(upUse => {
			this.userForm.patchValue(upUse);
		});
	}
	
	userChange(event){
		this.usuario = event;
		if (this.usuario != null)
			this.allowButton = true;
	}

	ngOnInit(): void {
		let user = localStorage.getItem('SESSION_USER_ID');
		this.getUser(user);
		this.getRoles();
	}

	getUser(user){
		let datos = {};
		datos['user_id'] = user;
		this.usuariosService.show(datos).subscribe(res => {
			this.usuario = res;
			this.isAdmin = res.id_rol == 1 ? true : false;
			this.selectedRole = res.id_rol;
			this.userForm.patchValue(this.usuario);
		}, error => {
			this.isLoading = false;
			var errorF;
			(error.error.message) != null ?
				errorF = error.error.message :
				errorF = 'Hubo un error al consultar usuario!';
			this.isLoading = false;
			Swal.fire({
				icon: 'error',
				title: 'Error...',
				text: errorF,
				footer: 'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.'
			});
		});
	}
	
	updateUser(event){
		let datos = event.value;
		this.isLoading = true;
		this.usuariosService.update(datos).subscribe(res => {
			this.isLoading = false;
			Swal.fire(
				'Guardado!',
				'Usuario actualizado correctamente',
				'success'
			);

			this.subUse.next(res);
		}, error => {
			//loader false
			var errorF;
			var rows = error.error.errors ? Object.keys(error.error.errors) : null;
			(error.error.message) != null ?
				errorF = error.error.message+' ('+rows+')' :
				errorF = 'Hubo un error al actualizar la ecotecnia!';
			this.isLoading = false;
			Swal.fire({
				icon: 'error',
				title: 'Error...',
				text: errorF,
				footer: 'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.'
			});
		});
	}
	
	getRoles(){
		this.rolesService.index().subscribe(res => {
			this.roles = res;
		}, error => {
			this.isLoading = false;
			var errorF;
			(error.error.message) != null ?
				errorF = error.error.message :
				errorF = 'Hubo un error al crear la ecotecnia!';
			this.isLoading = false;
			Swal.fire({
				icon: 'error',
				title: 'Error...',
				text: errorF,
				footer: 'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.'
			});
		});
	}

}
