import { Component, ViewChild, ElementRef, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormArray } from '@angular/forms';
import { UploadFileService } from 'src/app/services/fileUpload.service';
import { ProjectsService } from 'src/app/services/projects.service';
import { MatStepper } from '@angular/material/stepper';
import { Cuv } from 'src/app/entities/cuv';
import { Usuario } from 'src/app/entities/Usuario';
import { UsuariosService } from 'src/app/services/usuarios.service';
import Swal from 'sweetalert2';

@Component({
	selector: 'app-new-project',
	templateUrl: './new-project.component.html',
	styleUrls: ['./new-project.component.scss']
})
export class NewProjectComponent implements OnInit {

	fileUploadForm: UntypedFormGroup;
	public sumaTotal: any;
	public isLoading: boolean;
	datosOferente: UntypedFormGroup;
	datosPonderaciones: UntypedFormGroup;
	datosCuvs: UntypedFormGroup;
	verificadoresForm: UntypedFormGroup;
	datosPonderacionesCampos: Array<any> = [{ concepto: "", ponderacion: "" }];
	datosCuvsCampos: Array<Cuv> = [];
	cuvNameColumns: Array<string> = [];
	cuvNameColumnsDefault: Array<string> = ["CUV", 'ID', "Orden", "Calle", "# Ext.", "# Int.", "C.P.", "Lote", "Manzana", "S. Manzana", "Condominio", "Entrada", "Edificio", "Nivel", "Entre Calle", "Y Calle", "Otra Col.", "Costo Vivienda", "Costo Orden/Vivienda", "# Estacionamiento", "Departamento", "Casa"];
	public isFileReady: boolean = false;
	public isFileUploaded: boolean = false;
	public ordenVerificacion: Array<object> = [
		{ name: "ID Orden", key: 'id_orden' },
		{ name: "ID Oferente", key: "id_oferente" },
		{ name: "RFC", key: "rfc" },
		{ name: "Nombre", key: "nombre" },
		{ name: "Calle", key: "calle" },
		{ name: "Colonia", key: "colonia" },
		{ name: "Numero Exterior", key: "numero_exterior" },
		{ name: "Codigo Postal", key: "codigo_postal" },
		{ name: "Telefono", key: "telefono" },
		{ name: "E-Mail", key: "email" },
		{ name: "Nombre del Referente", key: "nombre_referente" },
		{ name: "Registro Patronal", key: "registro_patronal" }
	];

	@ViewChild('stepper') private myStepper: MatStepper;
	@ViewChild("fileDropRef", { static: false }) fileDropEl: ElementRef;
	fileInputLabel: string;
	files: any[] = [];
	public newProjectData: any = {}

	//verificador
	isVerificadorSelected: boolean = false;
	public allVerificadores: Usuario[];

	public isFormReady: boolean = false;

	constructor(
		private _formBuilder: UntypedFormBuilder,
		private projects: ProjectsService,
		private uploadFileService: UploadFileService,
		private formBuilder: UntypedFormBuilder,
		private usuariosService: UsuariosService
	) { }

	ngOnInit(): void {
		this.getAllVerificadores();

		this.fileUploadForm = this.formBuilder.group({
			import_file: new UntypedFormControl("")
		});
		this.datosOferente = this._formBuilder.group({
			id_orden: new UntypedFormControl(""),
			id_oferente: new UntypedFormControl(""),
			rfc: new UntypedFormControl(""),
			nombre: new UntypedFormControl(""),
			calle: new UntypedFormControl(""),
			colonia: new UntypedFormControl(""),
			numero_exterior: new UntypedFormControl(""),
			codigo_postal: new UntypedFormControl(""),
			telefono: new UntypedFormControl(""),
			email: new UntypedFormControl(""),
			nombre_referente: new UntypedFormControl(""),
			registro_patronal: new UntypedFormControl(""),
		});
		this.datosPonderaciones = this.formBuilder.group({
			control1: this.formBuilder.array([])
		});
		this.datosCuvs = this.formBuilder.group({
			control1: new UntypedFormControl("")
		});
		this.verificadoresForm = new UntypedFormGroup({
			userVerificador: new UntypedFormControl("")
		});
	}

	onFileDropped($event) {
		this.isFileReady = true;
		this.prepareFilesList($event);
	}

	onFileSelect(event: any) {
		this.isFileReady = true;
		this.prepareFilesList(event);
	}

	deleteFile() {
		let index = 0;
		if (this.files[index].progress < 100) {
			return;
		}
		this.isFileReady = false;
		this.isFileUploaded = false;
		this.files.splice(index, 1);
	}

	uploadFilesSimulator(index: number) {
		setTimeout(() => {
			if (index === this.files.length) {
				return;
			} else {
				const progressInterval = setInterval(() => {
					if (this.files[index].progress === 100) {
						this.isFileUploaded = true;
						clearInterval(progressInterval);
						this.uploadFilesSimulator(index + 1);
					} else {
						this.files[index].progress += 5;
					}
				}, 50);
			}
		}, 1000);
	}

	prepareFilesList(files: Array<any>) {
		for (const item of files) {
			item.progress = 0;
			this.files.push(item);
		}
		this.fileDropEl.nativeElement.value = "";
		this.uploadFilesSimulator(0);
	}

	formatBytes(bytes, decimals = 2) {
		if (bytes === 0) {
			return "0 Bytes";
		}
		const k = 1024;
		const dm = decimals <= 0 ? 0 : decimals;
		const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
		const i = Math.floor(Math.log(bytes) / Math.log(k));
		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
	}

	onFormSubmit() {
		let formData = new FormData();
		formData.append('import_file', this.files[0]);
		//loader true;
		this.isLoading = true;
		this.uploadFileService.uploadFile(formData).subscribe(res => {
			//loader false
			this.isLoading = false;
			this.myStepper.next();
			this.newProjectData = res;
			this.fillDatosOferente();
			Swal.fire(
				'Guardado!',
				'Avance guardado correctamente',
				'success'
			)

		}, error => {
			this.isLoading = false;
			//loader false
			var errorF;
			(error.error.message) != null ?
				errorF = error.error.message :
				errorF = 'Hubo un error al cargar el Archivo!';
			console.error(error);
			Swal.fire({
				icon: 'error',
				title: 'Error...',
				text: errorF,
				footer: 'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.'
			}).then((result) => {
				
			})
		});
	}

	public fillDatosOferente() {
		this.uploadFileService.getProjectById(this.newProjectData.id_orden).subscribe(res => {
			this.newProjectData = res[0];
			this.datosOferente.patchValue({
				id_orden: this.newProjectData.id_orden,
				id_oferente: this.newProjectData.id_oferente,
				rfc: this.newProjectData.rfc,
				nombre: this.newProjectData.nombre,
				calle: this.newProjectData.calle,
				colonia: this.newProjectData.colonia,
				numero_exterior: this.newProjectData.numero_ext,
				codigo_postal: this.newProjectData.codigo_postal,
				telefono: this.newProjectData.telefono,
				email: this.newProjectData.e_mail,
				nombre_referente: this.newProjectData.nombre_referente,
			});
			this.fillDatosPonderaciones();
			this.fillDatosCuvs();
			window.location.href ='/info-obra?orden='+this.newProjectData.id_orden;
		}, error => {
			console.error(error);
		});
	}

	fillDatosPonderaciones() {
		this.datosPonderacionesCampos = this.newProjectData.ponderaciones_partida;
		let sumaTotal = 0;
		const partidas = this.datosPonderaciones.get('control1') as UntypedFormArray;
		this.datosPonderacionesCampos.forEach((element) => {
			partidas.push(
				this.formBuilder.group({
					ponderacion: (element.ponderacion*100).toFixed(2)+'%',
					id_partida: element.id_partida
				})
			)
			sumaTotal += element.ponderacion*1;
		})
		this.sumaTotal = (sumaTotal*100).toFixed(0);
	}

	fillDatosCuvs() {
		this.cuvNameColumns = Object.keys(this.newProjectData.cuvs[0]);
		this.cuvNameColumns.splice(-1, 1);
		this.cuvNameColumns.splice(-1, 1);
		this.datosCuvsCampos = this.newProjectData.cuvs;
	}

	public controlConcepto(nombre) {
		return nombre.replace('/\s/g', '_');
	}

	public getAllVerificadores() {
		this.usuariosService.getVerificadoresService().subscribe(res => {
			this.allVerificadores = res;
		}, error => {

		});
	}

	public verificadorChange(event) {
		this.isVerificadorSelected = true;
	}

	public asignarVerificador() {
		let user = {
			id_orden: this.newProjectData.id_orden,
			id_verificador: this.verificadoresForm.value.userVerificador.id
		};

		if (user.id_orden != undefined) {
			this.usuariosService.assignVerificadorObra(user).subscribe(res => {
				res == 1 ? this.isFormReady = true : this.isFormReady = false;
				Swal.fire(
					'Guardado!',
					'Verificador asignado correctamente',
					'success'
				)
			}, error => {
				Swal.fire({
					icon: 'error',
					title: 'Error...',
					text: 'Hubo un error al asignar un Verificador!',
					footer: 'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.'
				})
			})
		} else {
			Swal.fire(
				'¿Ha cargado un archivo?',
				'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.',
				'question'
			)
		}
	}

	public guardarCambiosOferente(form: any) {
		let dirtyValues = {};

		Object.keys(form.controls)
			.forEach(key => {
				let currentControl = form.controls[key];

				if (currentControl.dirty) {
					if (currentControl.controls)
						dirtyValues[key] = this.guardarCambiosOferente(currentControl);
					else
						dirtyValues[key] = currentControl.value;
				}
			});

		//service to update Oferentes
		if (Object.keys(dirtyValues).length > 0) {

			dirtyValues['id_orden'] = this.newProjectData.id_orden;
			this.uploadFileService.updateOferente(dirtyValues).subscribe(res => {

			}, error => {
				console.error(error);
			})

		}

	}

	public guardarCambiosPonderaciones(form: any) {
		let data = form;
		let datos = {};
		let partidas = [];

		//let datos = this.getPonderaciones(data);
		Object.keys(data.controls).forEach((element) => {
			data.controls.control1.controls.forEach(element => {
				if (element.dirty) {
					element.value.ponderacion = element.value.ponderacion.replace("%", "");
					partidas.push(element.value);
				}
			});
		});

		datos['id_orden'] = this.newProjectData.id_orden;
		if (Object.keys(partidas).length > 0) {
			datos['partidas'] = partidas;
			this.uploadFileService.updatePonderaciones(datos).subscribe(res => {
				Swal.fire(
					'Guardado!',
					'Verificador asignado correctamente',
					'success'
				);/* .then((result) => {
					if (result.isConfirmed) {
						window.location.reload();
					}
				}) */
			}, error => {
				var errorF;
				(error.error.message) != null ?
					errorF = error.error.message :
					errorF = 'Hubo un error al guardar el conepto!';
				Swal.fire({
					icon: 'error',
					title: 'Error...',
					text: errorF,
					footer: 'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.'
				});/* .then((result) => {
					if (result.isConfirmed) {
						window.location.reload();
					}
				}) */
			})

		} else {
			Swal.fire(
				'¿Ha hecho algún cambio?',
				'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.',
				'question'
			)
		}
	}

	public guardarCambiosCuvs(form: any) {
		//pending to review
	}

}
