import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { CdkTableModule } from '@angular/cdk/table';

import es from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
registerLocaleData(es, 'es');

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AdminPanelComponent } from './components/shared/admin-panel/admin-panel.component';
import { MainViewComponent } from './components/shared/main-view/main-view.component';
import { HomeComponent } from './components/home/home.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module';
import { NewProjectComponent } from './components/new-project/new-project.component';
import { ProjectsService } from './services/projects.service';
import { UploadExcelFileDirective } from './directives/upload-excel-file.directive';
import { ProgressBarComponent } from './components/functional/progress-bar/progress-bar.component';
import { UploadFileService } from './services/fileUpload.service';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import { UsuariosService } from './services/usuarios.service';
import { UsersComponent } from './components/users/users.component';
import { ObrasComponent } from './components/obras/obras.component';
import { RevisionDocumentalComponent } from './components/revision-documental/revision-documental';
import { SembradoComponent } from './components/sembrado/sembrado.component';
import { SembradoService } from './services/sembrado.service';
import { LoginComponent } from './components/shared/login/login.component';
import { LoaderComponent } from './components/shared/loader/loader.component';
import { VerificacionesComponent } from './components/verificaciones/verificaciones.component';
import { SelectProcessComponent } from './components/select-process/select-process.component';
import { VerificacionesService } from './services/verificaciones.service';
import { PrototiposService } from './services/prototipos.service';
import { InfoObraComponent } from './components/info-obra/info-obra.component';
import { ReportesDisponiblesComponent } from './components/reportes-disponibles/reportes-disponibles.component';
import { ReporteComponent } from './components/reporte/reporte.component';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { ModalCuvComponent } from './components/modal-cuv/modal-cuv.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ModalConceptoComponent } from './components/modal-concepto/modal-concepto.component';
import { HistorialReportesComponent } from './components/historial-reportes/historial-reportes.component';
import { DescargarReportesComponent } from './components/descargar-reportes/descargar-reportes.component';
import { EcotecniasService } from './services/ecotecniasService.service';
import { EcotecniasComponent } from './components/ecotecnias/ecotecnias.component';
import { RolesService } from './services/roles.service';
import { DTUService } from './services/dtuService.service';
import { UsuarioComponent } from './components/usuario/usuario.component';
import { DtuComponent } from './components/dtu/dtu.component';
import { NotasBitacoraComponent } from './components/notas-bitacora/notas-bitacora.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { tiposReportesService } from './services/tiposReportes.service';
import { PruebasLaboratorioService } from './services/pruebasLaboratorio.service';
import { InegiService } from './services/inegiService.service';
import { PruebasLaboratorioComponent } from './components/pruebas-laboratorio/pruebas-laboratorio.component';
import { SelectorObraComponent } from './components/shared/selector-obra/selector-obra.component';

import { NgSelectModule } from '@ng-select/ng-select';
import { ShortNumberPipe } from './pipes/short-number.pipe';
import { DialogoUserComponent } from './components/users/dialogo-user/dialogo-user.component';
import { DialogoEcotecniasComponent } from './components/ecotecnias/dialogo-ecotecnias/dialogo-ecotecnias.component';
import { DialogoNotaBitacoraComponent } from './components/notas-bitacora/dialogo-nota-bitacora/dialogo-nota-bitacora.component';

@NgModule({
	declarations: [
		AppComponent,
		AdminPanelComponent,
		MainViewComponent,
		HomeComponent,
		NewProjectComponent,
		UploadExcelFileDirective,
		ProgressBarComponent,
		UsersComponent,
		UsuarioComponent,
		ObrasComponent,
		RevisionDocumentalComponent,
		SembradoComponent,
		LoginComponent,
		LoaderComponent,
		VerificacionesComponent,
		SelectProcessComponent,
		InfoObraComponent,
		ReportesDisponiblesComponent,
		ReporteComponent,
		ModalCuvComponent,
		ModalConceptoComponent,
		HistorialReportesComponent,
		DescargarReportesComponent,
		ShortNumberPipe,
		EcotecniasComponent,
		DtuComponent,
		NotasBitacoraComponent,
		PruebasLaboratorioComponent,
		DialogoUserComponent,
		DialogoEcotecniasComponent,
		DialogoNotaBitacoraComponent,
		SelectorObraComponent
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		MaterialModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		CdkTableModule,
		MatCardModule,
		MatButtonModule,
		MatTabsModule,
		MatDialogModule,
		NgSelectModule,
		MatCheckboxModule
	],
	providers: [
		ProjectsService,
		UploadFileService,
		UsuariosService,
		SembradoService,
		tiposReportesService,
		VerificacionesService,
		PrototiposService,
		EcotecniasService,
		RolesService,
		DTUService,
		InegiService,
		PruebasLaboratorioService,
		MatStepper,
		{ provide: LOCALE_ID, useValue: 'es' }
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
