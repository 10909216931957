<section class="animate__animated animate__fadeIn d-flex align-items-center">
    <div id="container" #containerReportes>
        <div id="wraper_tabla_reporte" class="table-responsive">
            <br>
            <button class="btn btn-lg btn-success btn-reporte-avances" title="Descargar todos los reportes." #success id="boton_descargar" (click)="download($event)">
                DESCARGAR REPORTES
            </button>
            <div *ngIf="isLoaded" class="container-reportes">
                    <table id="reporteGraficoAvances" #reporteGraficoAvances class="table table-bordered table-sm reporteGraficoAvances"> <!--29 COLUMNAS-->
                        <tbody> 
                            <tr>
                                <td id="logo_ISO" class="contenido" rowspan="5" colspan="5">
                                    <img class="logo" src="../../../assets/img/iso.png" alt="Logo Calidad ISO" />
                                </td>
                                <td id="titulo_ruv" rowspan="5" colspan="24">REGISTRO ÚNICO DE VIVIENDA</td>
                            </tr>
                            <!--vvROWSPANSvv-->
                            <tr></tr>
                            <tr></tr>
                            <tr></tr>
                            <tr></tr>
                            <!--^^ROWSPANS^^-->
                            <tr><td id="titulo_reporte" colspan="29">REPORTE GRÁFICO DE AVANCES DE OBRA</td></tr>
                            <tr>
                                <td id="titulo_no_oferta" class="titulo_left" colspan="5">NO. OFERTA</td>
                                <td id="no_oferta" class="contenido" colspan="4">
                                    <strong>
                                        {{reporte.id_oferta}}
                                    </strong>
                                </td>
                                <td id="titulo_orden_verif" class="titulo_left" colspan="4">ORDEN DE VERIF.</td>
                                <td id="ordern_verif" class="contenido" colspan="4">
                                    <strong>
                                        {{reporte.id_orden}}
                                    </strong>
                                </td>
                                <td id="titulo_no_viviendas" class="titulo_left" colspan="4">NO. DE VIVIENDAS</td>
                                <td id="no_viviendas" class="contenido" colspan="2">
                                    <strong>
                                        {{reporte.cuvs_count}}
                                    </strong>
                                </td>
                                <td id="titulo_viv_ecologica" class="titulo_left" colspan="4">VIV. ECOLÓGICA</td>
                                <td id="viv_ecologica" class="contenido" colspan="2"></td>
                            </tr>
                            <!--SEPARADOR--><tr><td colspan="29" class="separador"></td></tr><!--SEPARADOR-->
                            <tr>
                                <td id="titulo_estado" class="titulo_left" colspan="5">ESTADO</td>
                                <td id="estado" class="contenido" colspan="10">
                                    <strong>
                                        {{obra.estado.nombre}}
                                    </strong>
                                </td>
                                <td id="titulo_localidad" class="titulo_left" colspan="3">LOCALIDAD</td>
                                <td id="localidad" class="contenido" colspan="11">
                                    <strong>
                                        {{obra.municipio.nombre}}
                                    </strong>
                                </td>
                            </tr>
                            <tr>
                                <td id="titulo_nombre_del_frente" class="titulo_left" colspan="5">NOMBRE DEL FRENTE</td>
                                <td id="nombre_del_frente" class="contenido" colspan="24"><strong>{{reporte.nombre_del_frente}}</strong></td>
                            </tr>
                            <tr>
                                <td id="titulo_domicilio" class="titulo_left" colspan="5">DOMICILIO</td>
                                <td id="domicilio" class="contenido" colspan="24">
                                    <strong>
                                        Calle {{reporte.calle}}, Colonia {{reporte.colonia}}, CP: {{reporte.codigo_postal}}
                                    </strong>
                                </td>
                            </tr>
                            <!--SEPARADOR--><tr><td colspan="29" class="separador"></td></tr><!--SEPARADOR-->
                            <tr>
                                <td id="titulo_fecha_inicio_programado" class="titulo_top" colspan="5">FECHA INICIO PROGRAMADO</td>
                                <td id="titulo_fecha_terminacion_prog" class="titulo_top" colspan="5">FECHA TERMINACIÓN PROG.</td>
                                <td id="titulo_inicio_real" class="titulo_top" colspan="4">INICIO REAL</td>
                                <td id="titulo_plazo_ejecucion" class="titulo_top" colspan="4">PLAZO EJECUCIÓN</td>
                                <td id="titulo_periodo_de_reporte" class="titulo_top" colspan="8">PERIODO DE REPORTE</td>
                                <td id="titulo_no_de_reporte" class="titulo_top" colspan="2">NO. DE REPORTE</td>
                            </tr>
                            <tr>
                                <td id="fecha_inicio_programado" class="contenido" colspan="5">
                                    <strong>
                                        [Pendiente]
                                    </strong>
                                </td>
                                <td id="fecha_terminacion_prog" class="contenido" colspan="5">
                                    <strong>
                                        [Pendiente]
                                    </strong>
                                </td>
                                <td id="inicio_real" class="contenido" colspan="4">
                                    <strong>
                                        {{obra.inicio_real}}
                                    </strong>
                                </td>
                                <td id="plazo_ejecucion" class="contenido" colspan="4">
                                    <strong>
                                        {{obra.plazo_ejecucion}} meses
                                    </strong>
                                </td>
                                <td id="periodo_de_reporte_inicio" class="contenido" colspan="4">
                                    <strong>
                                        {{first}}
                                    </strong>
                                </td>
                                <td id="periodo_de_reporte_fin" class="contenido" colspan="4">
                                    <strong>
                                        {{last}}
                                    </strong>
                                </td>
                                <td id="no_de_reporte" class="contenido" colspan="2">
                                    <strong>
                                        {{historialReportes[historialReportes.length-1].numero_reporte}}
                                    </strong>
                                </td>
                            </tr>
                            <!--SEPARADOR--><tr><td colspan="29" class="separador"></td></tr><!--SEPARADOR-->
                            <tr>
                                <td id="titulo_constructora" class="titulo_left" colspan="5">CONSTRUCTORA</td>
                                <td id="constructora" class="contenido" colspan="10"><strong>{{obra.constructora}}</strong></td>
                                <td id="titulo_no_registro_constructora" class="titulo_left" colspan="3">NO. REGISTRO</td>
                                <td id="no_registro_constructora" class="contenido" colspan="11"><strong>{{obra.numero_registro}}</strong></td>
                            </tr>
                            <tr>
                                <td id="titulo_supervisor_verificador" class="titulo_left" colspan="5">SUPERVISOR / VERIFICADOR</td>
                                <td id="supervisor_verificador" class="contenido" colspan="10">
                                    <strong *ngIf="verificadorAsignado != null;else elseCedula">
                                        {{verificadorAsignado.nombre}} {{verificadorAsignado.primer_apellido}} {{verificadorAsignado.segundo_apellido}}
                                    </strong>
                                    <ng-template #elseCedula>
                                        <strong>Sin Asignar</strong>
                                    </ng-template>
                                </td>
                                <td id="titulo_no_registro_supervisor" class="titulo_left" colspan="3">NO. REGISTRO</td>
                                <td id="no_registro_supervisor" class="contenido" colspan="11">
                                    <strong *ngIf="verificadorAsignado != null;else elseCedula">
                                        {{verificadorAsignado.cedula}}
                                    </strong>
                                    <ng-template #elseCedula>
                                        <strong>Sin Asignar</strong>
                                    </ng-template>
                                </td>
                            </tr>
                            <!--SEPARADOR--><tr><td colspan="29" class="separador"></td></tr><!--SEPARADOR-->
                            <tr>
                                <td id="grafica" colspan="29">	
                                    <div class="chart-container">	 	    		
                                        <canvas #control style="max-width: -webkit-fill-available;"></canvas>
                                    </div>
                                </td>
                            </tr>
                            <!--SEPARADOR--><tr><td colspan="29" class="separador"></td></tr><!--SEPARADOR-->
                            <tr>
                                <td id="titulo_no_de_reporte_bis" class="titulo_left" colspan="5">NÚMERO DE REPORTE</td>
                                <td *ngFor="let avance of chart_labels; let i = index" id="no_de_reporte_bis_{{i+1}}" class="contenido">{{i+1}}</td>
                            </tr>
                            <tr>
                                <td id="titulo_avance_programado" class="titulo_left" colspan="5">AVANCE PROGRAMADO</td>
                                <td *ngFor="let avance of chart_labels; let i = index" id="avance_programado_1" class="contenido_small">{{avanceProgramado[i]}}%</td>
                            </tr>
                            <tr>
                                <td id="titulo_avance_real" class="titulo_left" colspan="5">AVANCE REAL</td>
                                <td *ngFor="let reporte of historialReportes; let i = index" id="avance_real_1" class="contenido">
                                        {{reporte.avance}}%
                                </td>
                            </tr>
                            <tr>
                                <td id="titulo_acumulado_viv" class="titulo_left" colspan="5">ACUMULADO VIV. >80%</td>
                                <td *ngFor="let reporte of historialReportes; let i = index" id="acumulado_viv_1" class="contenido">{{ reporte.avance_acumulado_viv }}</td>
                            </tr>
                            <tr>
                                <td id="titulo_acumulado_DTU" class="titulo_left" colspan="5">ACUMULADO DTU'S</td>
                                <td *ngFor="let reporte of historialReportes; let i = index" id="acumulado_DTU_1" class="contenido">{{ reporte.avance_acumulado_DTU }}</td>
                                
                            </tr>
                            <!--SEPARADOR--><tr><td colspan="29" class="separador"></td></tr><!--SEPARADOR-->
                            <tr>
                                <td id="titulo_importe_programado" class="titulo_left" colspan="5">IMPORTE PROGRAMADO</td>
                                <td *ngFor="let avance of chart_labels; let i = index" id="importe_programado_1" class="contenido_small">{{ (totalObra * (avanceProgramado[i] / 100)) | shortNumber }}</td>
                            </tr>
                            <tr>
                                <td id="titulo_importe_ejecutado" class="titulo_left" colspan="5">IMPORTE EJECUTADO</td>
                                <td *ngFor="let reporte of historialReportes; let i = index"  id="importe_ejecutado_1" class="contenido_small">{{ (totalObra * (reporte.avance / 100)) | shortNumber }}</td>
                            </tr>		    
                            <!--SEPARADOR--><tr><td colspan="29" class="separador"></td></tr><!--SEPARADOR-->
                            <tr>
                                <td id="titulo_lugar_y_fecha" class="titulo_left" colspan="5">LUGAR Y FECHA</td>
                                <td id="lugar_y_fecha" class="contenido" colspan="24"></td>
                            </tr>
                            <!--SEPARADOR--><tr><td colspan="29" class="separador"></td></tr><!--SEPARADOR-->
                            <tr>
                                <td id="titulo_nombre_y_firma" class="titulo_left" colspan="5">NOMBRE Y FIRMA DEL VERIFICADOR</td>
                                <td id="nombre_y_firma" class="contenido" colspan="24">
                                    <div *ngIf="verificadorAsignado == null; else verificador">
                                        <strong>Verificador sin asignar.</strong>
                                    </div>
                                    <ng-template #verificador>
                                        <div>
                                            <strong> {{verificadorAsignado.nombre}} {{verificadorAsignado.primer_apellido}} {{verificadorAsignado.segundo_apellido}}. </strong>
                                        </div>
                                        <hr>
                                        <div>
                                            <strong> Firma no disponible. </strong>
                                        </div>
                                    </ng-template>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                <!--***********-->
                <!--EDIFICACIÓN-->
                <!--***********-->
                    <table id="tabla_reporte" class="table table-bordered table-sm reporteEdificacion"> <!--29 COLUMNAS-->
                        <tbody> 
                            <tr>
                                <td id="logo_ISO" class="contenido" rowspan="5" colspan="5">
                                    <img class="logo" src="../../../assets/img/iso.png" alt="Logo Calidad ISO" />
                                </td>
                                <td id="titulo_ruv" rowspan="5" colspan="24">REGISTRO ÚNICO DE VIVIENDA</td>
                            </tr>
                            <!--vvROWSPANSvv-->
                            <tr></tr>
                            <tr></tr>
                            <tr></tr>
                            <tr></tr>
                            <!--^^ROWSPANS^^-->
                            <tr><td id="titulo_reporte" colspan="29">REPORTE DE EDIFICACION</td></tr>
                            <tr>
                                <td id="titulo_no_oferta" class="titulo_left" colspan="5">NO. OFERTA</td>
                                <td id="no_oferta" class="contenido" colspan="4">
                                    <strong>
                                        {{reporte.id_oferta}}
                                    </strong>
                                </td>
                                <td id="titulo_orden_verif" class="titulo_left" colspan="4">ORDEN DE VERIF.</td>
                                <td id="ordern_verif" class="contenido" colspan="4">
                                    <strong>
                                        {{reporte.id_orden}}
                                    </strong>
                                </td>
                                <td id="titulo_no_viviendas" class="titulo_left" colspan="4">NO. DE VIVIENDAS</td>
                                <td id="no_viviendas" class="contenido" colspan="2">
                                    <strong>
                                        {{reporte.cuvs_count}}
                                    </strong>
                                </td>
                                <td id="titulo_viv_ecologica" class="titulo_left" colspan="4">VIV. ECOLÓGICA</td>
                                <td id="viv_ecologica" class="contenido" colspan="2"></td>
                            </tr>
                            <!--SEPARADOR--><tr><td colspan="29" class="separador"></td></tr><!--SEPARADOR-->
                            <tr>
                                <td id="titulo_estado" class="titulo_left" colspan="5">ESTADO</td>
                                <td id="estado" class="contenido" colspan="10">
                                    <strong>
                                        {{obra.estado.nombre}}
                                    </strong>
                                </td>
                                <td id="titulo_localidad" class="titulo_left" colspan="3">LOCALIDAD</td>
                                <td id="localidad" class="contenido" colspan="11">
                                    <strong>
                                        {{obra.municipio.nombre}}
                                    </strong>
                                </td>
                            </tr>
                            <tr>
                                <td id="titulo_nombre_del_frente" class="titulo_left" colspan="5">NOMBRE DEL FRENTE</td>
                                <td id="nombre_del_frente" class="contenido" colspan="24"><strong>{{reporte.nombre_del_frente}}</strong></td>
                            </tr>
                            <tr>
                                <td id="titulo_domicilio" class="titulo_left" colspan="5">DOMICILIO</td>
                                <td id="domicilio" class="contenido" colspan="24">
                                    <strong>
                                        Calle {{reporte.calle}}, Colonia {{reporte.colonia}}, CP: {{reporte.codigo_postal}}
                                    </strong>
                                </td>
                            </tr>
                            <!--SEPARADOR--><tr><td colspan="29" class="separador"></td></tr><!--SEPARADOR-->
                            <tr>
                                <td id="titulo_fecha_inicio_programado" class="titulo_top" colspan="5">FECHA INICIO PROGRAMADO</td>
                                <td id="titulo_fecha_terminacion_prog" class="titulo_top" colspan="5">FECHA TERMINACIÓN PROG.</td>
                                <td id="titulo_inicio_real" class="titulo_top" colspan="4">INICIO REAL</td>
                                <td id="titulo_plazo_ejecucion" class="titulo_top" colspan="4">PLAZO EJECUCIÓN</td>
                                <td id="titulo_periodo_de_reporte" class="titulo_top" colspan="8">PERIODO DE REPORTE</td>
                                <td id="titulo_no_de_reporte" class="titulo_top" colspan="3">NO. DE REPORTE</td>
                            </tr>
                            <tr>
                                <td id="fecha_inicio_programado" class="contenido" colspan="5">
                                    <strong>
                                        [Pendiente]
                                    </strong>
                                </td>
                                <td id="fecha_terminacion_prog" class="contenido" colspan="5">
                                    <strong>
                                        [Pendiente]
                                    </strong>
                                </td>
                                <td id="inicio_real" class="contenido" colspan="4">
                                    <strong>
                                        {{obra.inicio_real}}
                                    </strong>
                                </td>
                                <td id="plazo_ejecucion" class="contenido" colspan="4">
                                    <strong>
                                        {{obra.plazo_ejecucion}} meses
                                    </strong>
                                </td>
                                <td id="periodo_de_reporte_inicio" class="contenido" colspan="4">
                                    <strong>
                                        {{first}}
                                    </strong>
                                </td>
                                <td id="periodo_de_reporte_fin" class="contenido" colspan="4">
                                    <strong>
                                        {{last}}
                                    </strong>
                                </td>
                                <td id="no_de_reporte" class="contenido" colspan="3">
                                    <strong>
                                        {{historialReportes[historialReportes.length-1].numero_reporte}}
                                    </strong>
                                </td>
                            </tr>
                            <!--SEPARADOR--><tr><td colspan="29" class="separador"></td></tr><!--SEPARADOR-->
                            <tr>
                                <td id="titulo_constructora" class="titulo_left" colspan="5">CONSTRUCTORA</td>
                                <td id="constructora" class="contenido" colspan="10"><strong>{{obra.constructora}}</strong></td>
                                <td id="titulo_no_registro_constructora" class="titulo_left" colspan="3">NO. REGISTRO</td>
                                <td id="no_registro_constructora" class="contenido" colspan="11"><strong>{{obra.numero_registro}}</strong></td>
                            </tr>
                            <tr>
                                <td id="titulo_supervisor_verificador" class="titulo_left" colspan="5">SUPERVISOR / VERIFICADOR</td>
                                <td id="supervisor_verificador" class="contenido" colspan="10">
                                    <strong *ngIf="verificadorAsignado != null;else elseCedula">
                                        {{verificadorAsignado.cedula}}
                                    </strong>
                                    <ng-template #elseCedula>
                                        <strong>Sin Asignar</strong>
                                    </ng-template>
                                </td>
                                <td id="titulo_no_registro_supervisor" class="titulo_left" colspan="3">NO. REGISTRO</td>
                                <td id="no_registro_supervisor" class="contenido" colspan="11">
                                    <strong *ngIf="obra.verificador != null;else elseCedula">
                                        {{obra.verificador.cedula}}
                                    </strong>
                                    <ng-template #elseCedula>
                                        <strong>Sin Asignar</strong>
                                    </ng-template>

                                </td>
                            </tr>
                            <!--SEPARADOR--><tr><td colspan="29" class="separador"></td></tr><!--SEPARADOR-->
                            <tr>
                                <td id="evidencias" colspan="29">
                                    <ng-container *ngIf="evidenciasArchivos[2];else elseEvidencias">
                                        <div class="row">
                                            <div class="col-6" *ngFor="let evidencia of evidenciasArchivos[2]">
                                                <img src="/storage/evidencias/{{evidencia.evidencia.imgName}}" style="max-width: inherit; margin: auto;" class="mx-auto d-block"/>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <ng-template #elseEvidencias>
                                        <strong>
                                            No hay evidencias relacionadas.
                                        </strong>
                                    </ng-template>
                                </td>
                            </tr>
                            <!--SEPARADOR--><tr><td colspan="29" class="separador"></td></tr><!--SEPARADOR-->
                            <tr>
                                <td id="titulo_descripcion" class="titulo_left" colspan="5">DESCRIPCIÓN</td>
                                <td id="descripcion" class="contenido" colspan="24">
                                    <strong>{{reporte.reportes[1].descripcion}}</strong>
                                </td>
                            </tr>
                            <!--SEPARADOR--><tr><td colspan="29" class="separador"></td></tr><!--SEPARADOR-->
                            <tr>
                                <td id="titulo_lugar_y_fecha" class="titulo_left" colspan="5">LUGAR Y FECHA</td>
                                <td id="lugar_y_fecha" class="contenido" colspan="24"></td>
                            </tr>
                            <!--SEPARADOR--><tr><td colspan="29" class="separador"></td></tr><!--SEPARADOR-->
                            <tr>
                                <td id="titulo_nombre_firma_cedula" class="titulo_left" colspan="5">NOMBRE, FIRMA Y CÉDULA PROFESIONAL VERIFICADOR</td>
                                <td id="nombre_y_firma" class="contenido" colspan="24">
                                    <div *ngIf="obra.verificador == null; else verificador">
                                        <strong>Verificador sin asignar.</strong>
                                    </div>
                                    <ng-template #verificador>
                                        <div>
                                            <strong> {{obra.verificador.nombre}} {{obra.verificador.primer_apellido}} {{obra.verificador.segundo_apellido}}. </strong>
                                        </div>
                                        <hr>
                                        <div>
                                            <strong> Firma no disponible. </strong>
                                        </div>
                                        <hr>
                                        <div>
                                            <strong> Cedula no disponible. </strong>
                                        </div>
                                    </ng-template>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                <!--************-->
                <!--URBANIZACIÓN-->
                <!--************-->
                    <table id="tabla_reporte" class="table table-bordered table-sm reporteUrbanizacion"> <!--29 COLUMNAS-->
                        <tbody> 
                            <tr>
                                <td id="logo_ISO" class="contenido" rowspan="5" colspan="5">
                                    <img class="logo" src="../../../assets/img/iso.png" alt="Logo Calidad ISO" />
                                </td>
                                <td id="titulo_ruv" rowspan="5" colspan="24">REGISTRO ÚNICO DE VIVIENDA</td>
                            </tr>
                            <!--vvROWSPANSvv-->
                            <tr></tr>
                            <tr></tr>
                            <tr></tr>
                            <tr></tr>
                            <!--^^ROWSPANS^^-->
                            <tr><td id="titulo_reporte" colspan="29">REPORTE DE URBANIZACION</td></tr>
                            <tr>
                                <td id="titulo_no_oferta" class="titulo_left" colspan="5">NO. OFERTA</td>
                                <td id="no_oferta" class="contenido" colspan="4">
                                    <strong>
                                        {{reporte.id_oferta}}
                                    </strong>
                                </td>
                                <td id="titulo_orden_verif" class="titulo_left" colspan="4">ORDEN DE VERIF.</td>
                                <td id="ordern_verif" class="contenido" colspan="4">
                                    <strong>
                                        {{reporte.id_orden}}
                                    </strong>
                                </td>
                                <td id="titulo_no_viviendas" class="titulo_left" colspan="4">NO. DE VIVIENDAS</td>
                                <td id="no_viviendas" class="contenido" colspan="2">
                                    <strong>
                                        {{reporte.cuvs_count}}
                                    </strong>
                                </td>
                                <td id="titulo_viv_ecologica" class="titulo_left" colspan="4">VIV. ECOLÓGICA</td>
                                <td id="viv_ecologica" class="contenido" colspan="2"></td>
                            </tr>
                            <!--SEPARADOR--><tr><td colspan="29" class="separador"></td></tr><!--SEPARADOR-->
                            <tr>
                                <td id="titulo_estado" class="titulo_left" colspan="5">ESTADO</td>
                                <td id="estado" class="contenido" colspan="10">
                                    <strong>
                                        {{obra.estado.nombre}}
                                    </strong>
                                </td>
                                <td id="titulo_localidad" class="titulo_left" colspan="3">LOCALIDAD</td>
                                <td id="localidad" class="contenido" colspan="11">
                                    <strong>
                                        {{obra.municipio.nombre}}
                                    </strong>
                                </td>
                            </tr>
                            <tr>
                                <td id="titulo_nombre_del_frente" class="titulo_left" colspan="5">NOMBRE DEL FRENTE</td>
                                <td id="nombre_del_frente" class="contenido" colspan="24"><strong>{{reporte.nombre_del_frente}}</strong></td>
                            </tr>
                            <tr>
                                <td id="titulo_domicilio" class="titulo_left" colspan="5">DOMICILIO</td>
                                <td id="domicilio" class="contenido" colspan="24">
                                    <strong>
                                        Calle {{reporte.calle}}, Colonia {{reporte.colonia}}, CP: {{reporte.codigo_postal}}
                                    </strong>
                                </td>
                            </tr>
                            <!--SEPARADOR--><tr><td colspan="29" class="separador"></td></tr><!--SEPARADOR-->
                            <tr>
                                <td id="titulo_fecha_inicio_programado" class="titulo_top" colspan="5">FECHA INICIO PROGRAMADO</td>
                                <td id="titulo_fecha_terminacion_prog" class="titulo_top" colspan="5">FECHA TERMINACIÓN PROG.</td>
                                <td id="titulo_inicio_real" class="titulo_top" colspan="4">INICIO REAL</td>
                                <td id="titulo_plazo_ejecucion" class="titulo_top" colspan="4">PLAZO EJECUCIÓN</td>
                                <td id="titulo_periodo_de_reporte" class="titulo_top" colspan="8">PERIODO DE REPORTE</td>
                                <td id="titulo_no_de_reporte" class="titulo_top" colspan="3">NO. DE REPORTE</td>
                            </tr>
                            <tr>
                                <td id="fecha_inicio_programado" class="contenido" colspan="5">
                                    <strong>
                                        [Pendiente]
                                    </strong>
                                </td>
                                <td id="fecha_terminacion_prog" class="contenido" colspan="5">
                                    <strong>
                                        [Pendiente]
                                    </strong>
                                </td>
                                <td id="inicio_real" class="contenido" colspan="4">
                                    <strong>
                                        {{obra.inicio_real}}
                                    </strong>
                                </td>
                                <td id="plazo_ejecucion" class="contenido" colspan="4">
                                    <strong>
                                        {{obra.plazo_ejecucion}} meses
                                    </strong>
                                </td>
                                <td id="periodo_de_reporte_inicio" class="contenido" colspan="4">
                                    <strong>
                                        {{first}}
                                    </strong>
                                </td>
                                <td id="periodo_de_reporte_fin" class="contenido" colspan="4">
                                    <strong>
                                        {{last}}
                                    </strong>
                                </td>
                                <td id="no_de_reporte" class="contenido" colspan="3">
                                    <strong>
                                        {{historialReportes[historialReportes.length-1].numero_reporte}}
                                    </strong>
                                </td>
                            </tr>
                            <!--SEPARADOR--><tr><td colspan="29" class="separador"></td></tr><!--SEPARADOR-->
                            <tr>
                                <td id="titulo_constructora" class="titulo_left" colspan="5">CONSTRUCTORA</td>
                                <td id="constructora" class="contenido" colspan="10"><strong>{{obra.constructora}}</strong></td>
                                <td id="titulo_no_registro_constructora" class="titulo_left" colspan="3">NO. REGISTRO</td>
                                <td id="no_registro_constructora" class="contenido" colspan="11"><strong>{{obra.numero_registro}}</strong></td>
                            </tr>
                            <tr>
                                <td id="titulo_supervisor_verificador" class="titulo_left" colspan="5">SUPERVISOR / VERIFICADOR</td>
                                <td id="supervisor_verificador" class="contenido" colspan="10">
                                    <strong *ngIf="verificadorAsignado != null;else elseCedula">
                                        {{verificadorAsignado.cedula}}
                                    </strong>
                                    <ng-template #elseCedula>
                                        <strong>Sin Asignar</strong>
                                    </ng-template>
                                </td>
                                <td id="titulo_no_registro_supervisor" class="titulo_left" colspan="3">NO. REGISTRO</td>
                                <td id="no_registro_supervisor" class="contenido" colspan="11">
                                    <strong *ngIf="obra.verificador != null;else elseCedula">
                                        {{obra.verificador.cedula}}
                                    </strong>
                                    <ng-template #elseCedula>
                                        <strong>Sin Asignar</strong>
                                    </ng-template>

                                </td>
                            </tr>
                            <!--SEPARADOR--><tr><td colspan="29" class="separador"></td></tr><!--SEPARADOR-->
                            <tr>
                                <td id="evidencias" colspan="29">
                                    <ng-container *ngIf="evidenciasArchivos[3];else elseEvidencias">
                                        <div class="row">
                                            <div class="col-6" *ngFor="let evidencia of evidenciasArchivos[3]">
                                                <img src="/storage/evidencias/{{evidencia.evidencia.imgName}}" style="max-width: inherit; margin: auto;" class="mx-auto d-block"/>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <ng-template #elseEvidencias>
                                        <strong>
                                            No hay evidencias relacionadas.
                                        </strong>
                                    </ng-template>
                                </td>
                            </tr>
                            <!--SEPARADOR--><tr><td colspan="29" class="separador"></td></tr><!--SEPARADOR-->
                            <tr>
                                <td id="titulo_descripcion" class="titulo_left" colspan="5">DESCRIPCIÓN</td>
                                <td id="descripcion" class="contenido" colspan="24">
                                    <strong>{{reporte.reportes[2].descripcion}}</strong>
                                </td>
                            </tr>
                            <!--SEPARADOR--><tr><td colspan="29" class="separador"></td></tr><!--SEPARADOR-->
                            <tr>
                                <td id="titulo_lugar_y_fecha" class="titulo_left" colspan="5">LUGAR Y FECHA</td>
                                <td id="lugar_y_fecha" class="contenido" colspan="24"></td>
                            </tr>
                            <!--SEPARADOR--><tr><td colspan="29" class="separador"></td></tr><!--SEPARADOR-->
                            <tr>
                                <td id="titulo_nombre_firma_cedula" class="titulo_left" colspan="5">NOMBRE, FIRMA Y CÉDULA PROFESIONAL VERIFICADOR</td>
                                <td id="nombre_y_firma" class="contenido" colspan="24">
                                    <div *ngIf="obra.verificador == null; else verificador">
                                        <strong>Verificador sin asignar.</strong>
                                    </div>
                                    <ng-template #verificador>
                                        <div>
                                            <strong> {{obra.verificador.nombre}} {{obra.verificador.primer_apellido}} {{obra.verificador.segundo_apellido}}. </strong>
                                        </div>
                                        <hr>
                                        <div>
                                            <strong> Firma no disponible. </strong>
                                        </div>
                                        <hr>
                                        <div>
                                            <strong> Cedula no disponible. </strong>
                                        </div>
                                    </ng-template>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                <!--***************-->
                <!--INFRAESTRUCTURA-->
                <!--***************-->
                    <table id="tabla_reporte" class="table table-bordered table-sm reporteInfraestructura"> <!--29 COLUMNAS-->
                        <tbody> 
                            <tr>
                                <td id="logo_ISO" class="contenido" rowspan="5" colspan="5">
                                    <img class="logo" src="../../../assets/img/iso.png" alt="Logo Calidad ISO" />
                                </td>
                                <td id="titulo_ruv" rowspan="5" colspan="24">REGISTRO ÚNICO DE VIVIENDA</td>
                            </tr>
                            <!--vvROWSPANSvv-->
                            <tr></tr>
                            <tr></tr>
                            <tr></tr>
                            <tr></tr>
                            <!--^^ROWSPANS^^-->
                            <tr><td id="titulo_reporte" colspan="29">REPORTE DE INFRAESTRUCTURA</td></tr>
                            <tr>
                                <td id="titulo_no_oferta" class="titulo_left" colspan="5">NO. OFERTA</td>
                                <td id="no_oferta" class="contenido" colspan="4">
                                    <strong>
                                        {{reporte.id_oferta}}
                                    </strong>
                                </td>
                                <td id="titulo_orden_verif" class="titulo_left" colspan="4">ORDEN DE VERIF.</td>
                                <td id="ordern_verif" class="contenido" colspan="4">
                                    <strong>
                                        {{reporte.id_orden}}
                                    </strong>
                                </td>
                                <td id="titulo_no_viviendas" class="titulo_left" colspan="4">NO. DE VIVIENDAS</td>
                                <td id="no_viviendas" class="contenido" colspan="2">
                                    <strong>
                                        {{reporte.cuvs_count}}
                                    </strong>
                                </td>
                                <td id="titulo_viv_ecologica" class="titulo_left" colspan="4">VIV. ECOLÓGICA</td>
                                <td id="viv_ecologica" class="contenido" colspan="2"></td>
                            </tr>
                            <!--SEPARADOR--><tr><td colspan="29" class="separador"></td></tr><!--SEPARADOR-->
                            <tr>
                                <td id="titulo_estado" class="titulo_left" colspan="5">ESTADO</td>
                                <td id="estado" class="contenido" colspan="10">
                                    <strong>
                                        {{obra.estado.nombre}}
                                    </strong>
                                </td>
                                <td id="titulo_localidad" class="titulo_left" colspan="3">LOCALIDAD</td>
                                <td id="localidad" class="contenido" colspan="11">
                                    <strong>
                                        {{obra.municipio.nombre}}
                                    </strong>
                                </td>
                            </tr>
                            <tr>
                                <td id="titulo_nombre_del_frente" class="titulo_left" colspan="5">NOMBRE DEL FRENTE</td>
                                <td id="nombre_del_frente" class="contenido" colspan="24"><strong>{{reporte.nombre_del_frente}}</strong></td>
                            </tr>
                            <tr>
                                <td id="titulo_domicilio" class="titulo_left" colspan="5">DOMICILIO</td>
                                <td id="domicilio" class="contenido" colspan="24">
                                    <strong>
                                        Calle {{reporte.calle}}, Colonia {{reporte.colonia}}, CP: {{reporte.codigo_postal}}
                                    </strong>
                                </td>
                            </tr>
                            <!--SEPARADOR--><tr><td colspan="29" class="separador"></td></tr><!--SEPARADOR-->
                            <tr>
                                <td id="titulo_fecha_inicio_programado" class="titulo_top" colspan="5">FECHA INICIO PROGRAMADO</td>
                                <td id="titulo_fecha_terminacion_prog" class="titulo_top" colspan="5">FECHA TERMINACIÓN PROG.</td>
                                <td id="titulo_inicio_real" class="titulo_top" colspan="4">INICIO REAL</td>
                                <td id="titulo_plazo_ejecucion" class="titulo_top" colspan="4">PLAZO EJECUCIÓN</td>
                                <td id="titulo_periodo_de_reporte" class="titulo_top" colspan="8">PERIODO DE REPORTE</td>
                                <td id="titulo_no_de_reporte" class="titulo_top" colspan="3">NO. DE REPORTE</td>
                            </tr>
                            <tr>
                                <td id="fecha_inicio_programado" class="contenido" colspan="5">
                                    <strong>
                                        [Pendiente]
                                    </strong>
                                </td>
                                <td id="fecha_terminacion_prog" class="contenido" colspan="5">
                                    <strong>
                                        [Pendiente]
                                    </strong>
                                </td>
                                <td id="inicio_real" class="contenido" colspan="4">
                                    <strong>
                                        {{obra.inicio_real}}
                                    </strong>
                                </td>
                                <td id="plazo_ejecucion" class="contenido" colspan="4">
                                    <strong>
                                        {{obra.plazo_ejecucion}} meses
                                    </strong>
                                </td>
                                <td id="periodo_de_reporte_inicio" class="contenido" colspan="4">
                                    <strong>
                                        {{first}}
                                    </strong>
                                </td>
                                <td id="periodo_de_reporte_fin" class="contenido" colspan="4">
                                    <strong>
                                        {{last}}
                                    </strong>
                                </td>
                                <td id="no_de_reporte" class="contenido" colspan="3">
                                    <strong>
                                        {{historialReportes[historialReportes.length-1].numero_reporte}}
                                    </strong>
                                </td>
                            </tr>
                            <!--SEPARADOR--><tr><td colspan="29" class="separador"></td></tr><!--SEPARADOR-->
                            <tr>
                                <td id="titulo_constructora" class="titulo_left" colspan="5">CONSTRUCTORA</td>
                                <td id="constructora" class="contenido" colspan="10"><strong>{{obra.constructora}}</strong></td>
                                <td id="titulo_no_registro_constructora" class="titulo_left" colspan="3">NO. REGISTRO</td>
                                <td id="no_registro_constructora" class="contenido" colspan="11"><strong>{{obra.numero_registro}}</strong></td>
                            </tr>
                            <tr>
                                <td id="titulo_supervisor_verificador" class="titulo_left" colspan="5">SUPERVISOR / VERIFICADOR</td>
                                <td id="supervisor_verificador" class="contenido" colspan="10">
                                    <strong *ngIf="verificadorAsignado != null;else elseCedula">
                                        {{verificadorAsignado.cedula}}
                                    </strong>
                                    <ng-template #elseCedula>
                                        <strong>Sin Asignar</strong>
                                    </ng-template>
                                </td>
                                <td id="titulo_no_registro_supervisor" class="titulo_left" colspan="3">NO. REGISTRO</td>
                                <td id="no_registro_supervisor" class="contenido" colspan="11">
                                    <strong *ngIf="obra.verificador != null;else elseCedula">
                                        {{obra.verificador.cedula}}
                                    </strong>
                                    <ng-template #elseCedula>
                                        <strong>Sin Asignar</strong>
                                    </ng-template>

                                </td>
                            </tr>
                            <!--SEPARADOR--><tr><td colspan="29" class="separador"></td></tr><!--SEPARADOR-->
                            <tr>
                                <td id="evidencias" colspan="29">
                                    <ng-container *ngIf="evidenciasArchivos[4];else elseEvidencias">
                                        <div class="row">
                                            <div class="col-6" *ngFor="let evidencia of evidenciasArchivos[4]">
                                                <img src="/storage/evidencias/{{evidencia.evidencia.imgName}}" style="max-width: inherit; margin: auto;" class="mx-auto d-block"/>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <ng-template #elseEvidencias>
                                        <strong>
                                            No hay evidencias relacionadas.
                                        </strong>
                                    </ng-template>
                                </td>
                            </tr>
                            <!--SEPARADOR--><tr><td colspan="29" class="separador"></td></tr><!--SEPARADOR-->
                            <tr>
                                <td id="titulo_descripcion" class="titulo_left" colspan="5">DESCRIPCIÓN</td>
                                <td id="descripcion" class="contenido" colspan="24">
                                    <strong>{{reporte.reportes[3].descripcion}}</strong>
                                </td>
                            </tr>
                            <!--SEPARADOR--><tr><td colspan="29" class="separador"></td></tr><!--SEPARADOR-->
                            <tr>
                                <td id="titulo_lugar_y_fecha" class="titulo_left" colspan="5">LUGAR Y FECHA</td>
                                <td id="lugar_y_fecha" class="contenido" colspan="24"></td>
                            </tr>
                            <!--SEPARADOR--><tr><td colspan="29" class="separador"></td></tr><!--SEPARADOR-->
                            <tr>
                                <td id="titulo_nombre_firma_cedula" class="titulo_left" colspan="5">NOMBRE, FIRMA Y CÉDULA PROFESIONAL VERIFICADOR</td>
                                <td id="nombre_y_firma" class="contenido" colspan="24">
                                    <div *ngIf="obra.verificador == null; else verificador">
                                        <strong>Verificador sin asignar.</strong>
                                    </div>
                                    <ng-template #verificador>
                                        <div>
                                            <strong> {{obra.verificador.nombre}} {{obra.verificador.primer_apellido}} {{obra.verificador.segundo_apellido}}. </strong>
                                        </div>
                                        <hr>
                                        <div>
                                            <strong> Firma no disponible. </strong>
                                        </div>
                                        <hr>
                                        <div>
                                            <strong> Cedula no disponible. </strong>
                                        </div>
                                    </ng-template>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            <hr>
        </div>
    </div>
</section>
<app-loader [isLoading]="isLoading"></app-loader>
