import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-main-view',
	templateUrl: './main-view.component.html',
	styleUrls: ['./main-view.component.scss']
})

export class MainViewComponent implements OnInit {
	public
	public isLoading: boolean = false;
	constructor() { }

	ngOnInit(): void {
		window.localStorage.setItem('orden', '');
		
	}
	sidebarToggle(event){
		var sidebar = document.getElementById("sidebar");
		sidebar.classList.toggle("active");
		var contenedor = document.getElementById("content");
		if(sidebar.classList.value == 'active'){
			contenedor.style.width = 'calc(100% - 80px )';
		}else{
			contenedor.style.width = '100%';
		}
	}
	showLoader() {
		window.scrollTo({ 
			top: 0, 
			left: 0, 
			behavior: 'smooth' 
		});
		document.body.style.overflowY = "hidden";
		this.isLoading = true;
	}
	hideLoader() {
		this.isLoading = false;
		document.body.style.overflowY = "auto";
	}
}
