import { Component, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormControl, FormControlName, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalConceptoService } from 'src/app/services/modalConcepto.service';
import Swal from 'sweetalert2';
import { NgSelectConfig } from '@ng-select/ng-select';
import { EventManager } from '@angular/platform-browser';


@Component({
  selector: 'app-modal-concepto',
  templateUrl: './modal-concepto.component.html',
  styleUrls: ['./modal-concepto.component.scss']
})
export class ModalConceptoComponent {
  idOrden: any;
  conceptos: Array<any>
  conceptoForm: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<ModalConceptoComponent>,
    private ModalConceptoService: ModalConceptoService,
    ) {
      this.idOrden = data.id_orden;
      this.conceptoForm = this.formBuilder.group({
        id_concepto: new UntypedFormControl(""),
        ponderacion: new UntypedFormControl(""),
        id_orden: new UntypedFormControl(this.idOrden),
        id_partida: new UntypedFormControl("")
      });
      this.getAllConceptos();
    }

    onChange(event) {
      this.conceptoForm.controls['id_partida'].patchValue(event.id_partida);
    }
  
    onConfirmClick(form: any): void {
      this.ModalConceptoService.setConcepto(form.value).subscribe(res=> {
        Swal.fire(
          'Guardado!',
          'Concepto asignado correctamente',
          'success'
        );

        this.dialogRef.close(true);
      }, error => {
        var errorF;
        (error.error.message) != null ? 
          errorF =  error.error.message : 
          errorF = 'Hubo un error al guardar el conepto!';
        Swal.fire({
          icon: 'error',
          title: 'Error...',
          text: errorF,
          footer: 'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.'
        });
      });
    }
    
  getAllConceptos(){
    this.ModalConceptoService.getConceptos().subscribe(res=> {
      this.conceptos = res;
    }, error => {
      Swal.fire({
        icon: 'error',
        title: 'Error...',
        text: 'Hubo un error al cargar los conceptos!',
        footer: 'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.'
      });
    });
  }

}
