<div class="row">
    <div class="wrapper">
        <!-- Sidebar  -->
        <app-admin-panel></app-admin-panel>
        <!-- Page Content  -->
        <div id="content" class="container-fluid content-container fix-container">

            <nav class="navbar navbar-expand-lg navbar-light bg-light">
                <div class="container-fluid">

                    <button id="sidebarCollapse" (click)="sidebarToggle($event)" class="toggle-button">
                        <mat-icon class="white-icon">menu</mat-icon>
                    </button>

                </div>
            </nav>
                <router-outlet></router-outlet>
        </div>
    </div>
</div>