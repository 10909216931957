import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/AuthService.service';

@Component({
  selector: 'app-admin-panel',
  templateUrl: './admin-panel.component.html',
  styleUrls: ['./admin-panel.component.scss']
})
export class AdminPanelComponent implements OnInit {

  public authUserName: any;
  public authUserApellido: any;
  public authUserApellido2: any;
  public authUserRole: any;

  constructor(
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.authUserName = localStorage.getItem('SESSION_USER');
    this.authUserRole = localStorage.getItem('SESSION_ROLE');
    this.authUserApellido =localStorage.getItem('SESSION_USER_APELLIDO');
    this.authUserApellido2 =localStorage.getItem('SESSION_USER_APELLIDO2');
    
  }

  public cerrarSesion(){
    this.authService.logout();
  }

}
