import { Component, OnInit, ElementRef, ViewChildren, AfterViewInit, QueryList, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, UntypedFormGroup, Validators, FormsModule,ReactiveFormsModule, UntypedFormControl, FormArray} from '@angular/forms';
import { ReporteService } from 'src/app/services/reporte.service';
import { UsuariosService } from 'src/app/services/usuarios.service';
import { Chart } from "chart.js";
import jspdf from 'jspdf';
import Swal from 'sweetalert2';
import html2canvas from 'html2canvas';
import { Usuario } from 'src/app/entities/Usuario';
import { MatTableDataSource } from '@angular/material/table';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MainViewComponent } from '../shared/main-view/main-view.component';


export interface Comentario {
	nombre: any;
	si: boolean;
	no1: boolean;
	no2: boolean;
	comentario: any;
}

@Component({
  providers:[MainViewComponent ],
  selector: 'app-reporte',
  templateUrl: './reporte.component.html',
  styleUrls: ['./reporte.component.scss'],
})
export class ReporteComponent implements AfterViewInit, OnInit {
	public range: 0;
	public selectedVerif: any;
	@ViewChildren('control') userSelect: QueryList<ElementRef>;
	public isLoading: boolean = false;
	public idOrden: Array<any> = [
		this.activatedRoute.snapshot.queryParams["orden"],
		localStorage.getItem('SESSION_USER_ID')
	];
	

	
	displayedColumns: string[] = ['nombre', 'si', 'no1', 'no2', 'comentario', 'vivienda'];
	public dataSource: any;

	public reporteRecibido: any;
	public idReporteRecibido: any;
	public reporteGraficoAvances: boolean = false;
	public reporteEdificacion: boolean = false;
	public reporteUrbanizacion: boolean = false;
	public reporteInfraestructura: boolean = false;
	public reporteAvancePonderado: boolean = false;
	public reporteCaratula: boolean = false;
	public evaluacionCalidad: boolean = false;
	public cumplimientoCalidad: boolean = false;

	public totalMonto: number = 0;
	public totalFactor: number = 0;
	public totalEdifEcos: number = 0;
	public porcentajeEco: number = 0;
	public totalMontoEdifEcos: number = 0;
	public numeroQuincena: number = 0;
	public existeReporte: boolean = false;
	public isReporteSetted: boolean = false;
	public reporteAvances : any;
	public avancesPrototipo: any;
	public totalPrototipos: any = 0;
	public ponderacionesEqUrbano: any;
	public totalPonderacionEqUrbano: any = 0;
	public avanceEqUrbano: any = 0;
	public iniciadas = 0;

	public fechaActual: Date;
	
	public eqUrbano: any;
	public prototipos: any;
	public totalExp: any = 0;
	public totalPorcentaje: any = 0;
	public conCumplimiento: Array<any>;
	public totalNotasSinInicio: any;
	public totalNotasEco: any;
	public totalNotas: any;
	public NCNotasSinInicio: any;
	public NCNotasEco: any;
	public notasBitacora: any;
	public notasBitacoraAsociadas: any;

	public allowEvidencias: boolean = true;
	public isVerificadorSelected: boolean = false;
	public totalReportes: boolean = false;
	public datos: Array<any> = [];
	public reporte: any;
	public obra: any;
	public evidencias: any;
	public evidenciasArchivos: any;
	public pdf: any;
	public imgData: any;
	public totalObra: number;
	public verificadoresForm: UntypedFormGroup;
	public isFormReady: boolean = false;
	public first: any;
	public last: any;
	public historialReportes: any;
	public ultimoReporte: any;
	public chart: any;
	public chart_labels: Array<any>;
	public avanceProgramado: Array<any>;
	public avances: Array<any>;
	public isLoaded: boolean = false;
	public omitir: Number = 0;
	public reportes: Array<any> = [
		{
			'id_tipo_reporte': 1,
			'nombre': 'Reporte Grafico De Avance De Obra',
			'link': 'reporteGraficoAvances'
		},
		{
			'id_tipo_reporte': 2 ,
			'nombre': 'Reporte Fotografico (Edificación)',
			'link': 'reporteEdificacion',
		},
		{
			'id_tipo_reporte': 3 ,
			'nombre': 'Reporte Fotografico (Urbanización)',
			'link': 'reporteUrbanizacion'
		},
		{
			'id_tipo_reporte': 4 ,
			'nombre': 'Reporte Fotografico (Infraestructura)',
			'link': 'reporteInfraestructura'
		},
		{
			'id_tipo_reporte': 5 ,
			'nombre': 'Calculo de Avance Ponderado',
			'link': 'null'
		},
		{
			'id_tipo_reporte': 6 ,
			'nombre': 'Todos los Reportes',
			'link': 'totalReportes'
		},
		{
			'id_tipo_reporte': 7 ,
			'nombre': 'Reporte Caratula de Obra',
			'link': 'reporteCaratula'
		},
		{
			'id_tipo_reporte': 8 ,
			'nombre': 'Evaluación de Calidad',
			'link': 'evaluacionCalidad'
		},
		{
			'id_tipo_reporte': 9 ,
			'nombre': 'Cumplimiento de Calidad',
			'link': 'cumplimientoCalidad'
		},
	];
	public allVerificadores: Usuario[];
	public verificadorSelected: any;
	public cuvsDtu: number;
	public cuvsAvancePeriodo: number;
	public host = environment.api;
	public columnNotas: Array<number> = [0,1,2,3];
	public comentariosAgrupados = [];

	public download(event)
	{
		html2canvas(document.querySelector("#container"), {scrollY: -window.scrollY}).then(canvas => {

			var pdf = new jspdf('p', 'pt', [canvas.width, canvas.height]);

			this.imgData  = canvas.toDataURL("image/png", 1.0);
			pdf.addImage(this.imgData, 0, 0, canvas.width, canvas.height);
			pdf.save(this.reporteRecibido+'.pdf');
			
			let datos = {};
			datos['id_orden'] = this.activatedRoute.snapshot.queryParams["orden"];
			datos['reporte'] = this.imgData;
			datos['id_reporte'] = this.reportes.indexOf(this.reportes.find(report => report.link === this.reporteRecibido))+1;
			this.reporteService.saveReporte(datos).subscribe(res => {
				Swal.fire(
					'Guardado!',
					'Reporte almacenado correctamente',
					'success'
				);/* .then((result) => {
					if (result.isConfirmed) {
						window.location.reload();
					}
				}) */
				this.isLoading = false;
			}, error => {
				//loader false
				var errorF;
				(error.error.message) != null ?
					errorF = error.error.message :
					errorF = 'Hubo un error al guardar el Reporte!';
				Swal.fire({
					icon: 'error',
					title: 'Error...',
					text: errorF,
					footer: 'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.'
				});/* .then((result) => {
					if (result.isConfirmed) {
						window.history.back();
					}
				}) */
				this.isLoading = false;
			});
		});
	}

	constructor(
		private reporteService: ReporteService,
		private activatedRoute: ActivatedRoute,
		private usuariosService: UsuariosService,
		private http: HttpClient,
		private cdr: ChangeDetectorRef,
		private main: MainViewComponent
	) {
		this.sidebarToggle();
		this.omitir = 0;
	}
    
	showLoader(){
		this.main.showLoader();
		this.isLoading = true;
	}
	hideLoader(){
		this.main.hideLoader();
		this.isLoading = false;
	}

	applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dataSource.filter = filterValue.trim().toLowerCase();
	}

	sidebarToggle(){
		var sidebar = document.getElementById("sidebar");
		sidebar.classList.add('active');
		var contenedor = document.getElementById("content");
		if(sidebar.classList.value == 'active'){
			contenedor.style.width = 'calc(100% - 80px )';
		}
	}

	ngAfterViewInit(): void {
		this.idReporteRecibido = this.reportes.indexOf(this.reportes.find(report => report.link === this.reporteRecibido))+1;
		if(this.idReporteRecibido == 1){
			this.userSelect.changes.subscribe(item => {
				if (this.userSelect.length) {
					var chart = this.userSelect.first.nativeElement;
				}

				this.chart = new Chart(chart, {
					type: 'line',
					data: {
					labels: this.chart_labels,
					datasets: [{
						data: this.avanceProgramado,
						label: "PROGRAMADO",
						borderColor: "#0000ff",
						pointRadius: 5,
						pointHoverRadius: 9,
						pointBackgroundColor: "#0000ff",
						fill: false
					},{
						data: this.avances,
						label: "REAL",
						borderColor: "#ff0000",
						pointRadius: 8,
						pointHoverRadius: 12,
						pointBackgroundColor: "#ff0000",
						fill: false
					}]
					},
					options: {
					title: {
						display: true,
						text: 'CONTROL GRÁFICO DE AVANCE DE OBRA',
					},
					scales: {
							yAxes: [{
							ticks: {
								suggestedMin: 0,
								suggestedMax: 100.00,
								stepSize: 10.00
							}
							}],
						}
					},
				});
			})
		}
  	}

  	sub;
	ngOnInit(): void {
		this.sub=this.activatedRoute.paramMap.subscribe(params => {
			this.reporteRecibido = params.get('id');
		});
		this.reporteRecibido == this.reportes[0].link ? this.reporteGraficoAvances = true : this.reporteGraficoAvances = false;
		this.reporteRecibido == this.reportes[1].link ? this.reporteEdificacion = true : this.reporteEdificacion = false;
		this.reporteRecibido == this.reportes[2].link ? this.reporteUrbanizacion = true : this.reporteUrbanizacion = false;
		this.reporteRecibido == this.reportes[3].link ? this.reporteInfraestructura = true : this.reporteInfraestructura = false;

		this.reporteRecibido == this.reportes[6].link ? this.reporteCaratula = true : this.reporteCaratula = false;
		this.reporteRecibido == this.reportes[7].link ? this.evaluacionCalidad = true : this.evaluacionCalidad = false;
		this.reporteRecibido == this.reportes[8].link ? this.cumplimientoCalidad = true : this.cumplimientoCalidad = false;

		if(this.reporteGraficoAvances || this.reporteCaratula || this.evaluacionCalidad || this.cumplimientoCalidad){
			this.allowEvidencias = false;
		}
		this.verificadoresForm = new UntypedFormGroup({
			userVerificador: new UntypedFormControl("")
		});
		
		if(this.reporteRecibido == "null"){
			Swal.fire(
				'Reporte No disponible',
				'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.',
				'question'
			)
			history.back()
		}
		this.getReporteData();
	}

	generarAvanceService(reporte){
		let datos = {};
		datos['id_orden'] = this.idOrden[0];
		datos['id_reporte'] = reporte != null ? reporte.id_reporte : null;
		this.reporteService.generarReporteAvance(datos).subscribe(res => {
			Swal.fire(
				'Guardado!',
				'Reporte generado correctamente',
				'success'
			);/* .then((result) => {
				if (result.isConfirmed) {
					window.location.reload();
				}
			}) */
			this.isLoading = false;
		}, error => {
			//loader false
			var errorF;
			(error.error.message) != null ?
				errorF = error.error.message :
				errorF = 'Hubo un error al crear el Reporte de Avances!';
			Swal.fire({
				icon: 'error',
				title: 'Error...',
				text: errorF,
				footer: 'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.'
			}).then((result) => {
				if (result.isConfirmed) {
					window.history.back();
				}
			});

			this.isLoading = false;
		});
	}

	public generarAvance(event)
	{
		this.isLoading = true;
		this.reporteService.isReporteSetted(this.idOrden).subscribe(res => {
			this.isReporteSetted = res[0];
			this.reporteAvances = res[1];
			if(this.isReporteSetted){
				Swal.fire({
					icon: 'warning',
					title: 'Ya se ha generado un reporte en la quincena actual.',
					text: 'Si desea continuar el reporte será actualizado con la información de avances actual.',
					showDenyButton: true,
					confirmButtonText: `Actualizar`,
					denyButtonText: `Cancelar`,
				}).then((result) => {
					if (result.isConfirmed) {
						this.generarAvanceService(this.reporteAvances);
					}
				})
			}else{
				this.generarAvanceService(null);
			}	
			this.isLoading = false;

		}, error => {
			//loader false
			var errorF;
			(error.error.message) != null ?
				errorF = error.error.message :
				errorF = 'Hubo un error al crear el Reporte de Avances!';
			Swal.fire({
				icon: 'error',
				title: 'Error...',
				text: errorF,
				footer: 'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.'
			}).then((result) => {
				if (result.isConfirmed) {
					window.history.back();
				}
			});

			this.isLoading = false;
		});
	}

	public getReporteData() {

		this.showLoader();
		this.idOrden.push(this.reportes.indexOf(this.reportes.find(report => report.link === this.reporteRecibido)) + 1);

		this.reporteService.getReporteData(this.idOrden).subscribe(res => {
			this.reporte = res[0];
			this.obra = this.reporte.datos_obra;
			this.historialReportes = this.reporte.historial_reportes;
			this.range = this.historialReportes[0].avance;
			this.ultimoReporte = this.historialReportes[this.historialReportes.length - 1];
			this.first = res[1];
			this.last = res[2];
			this.totalObra = this.reporte.cuvs_sum_costo_vivienda;
			this.evidenciasArchivos = res[3];
			this.totalEdifEcos = res[4];
			this.totalMontoEdifEcos = res[5];
			//
			this.numeroQuincena = res[6];
			this.existeReporte = res[7];
			this.avancesPrototipo = res[8];
			//
			this.ponderacionesEqUrbano = Object.values(res[9]);
			//
			this.prototipos = res[10];
			this.eqUrbano = res[11];
			this.porcentajeEco = res[12];
			this.iniciadas = res[13];
			this.conCumplimiento = res[14];

			this.totalNotasSinInicio = res[15];
			this.totalNotasEco = res[16];
			//Nocumplen
			this.NCNotasSinInicio = res[18].filter(x => !this.totalNotasSinInicio.some(y => x.id === y.id));
			this.NCNotasEco = res[18].filter(x => !this.totalNotasEco.some(y => x.id === y.id));

			this.totalNotas = res[18];
			this.cuvsDtu = res[19];

			this.fechaActual = res[20];
			this.cuvsAvancePeriodo = res[21];
			this.notasBitacora = res[22];
			this.notasBitacoraAsociadas = Object.keys(res[23]).map((key) => [key, res[23][key]]);

			// Crear un objeto para almacenar los resultados
			// console.log(res[18]);
			// Recorrer el arreglo de comentarios
			res[18].forEach(comentario => {
				let avance = comentario.comentarios_verificaciones.avance;
				const conceptoId = comentario.comentarios_verificaciones.concepto.id_concepto;
				const conceptoTexto = comentario.comentarios_verificaciones.concepto.nombre;
				const cuv = comentario.comentarios_verificaciones.cuv;
				const comentarioTexto = comentario.comentario != null ? comentario.comentario.comentario + ' - Avance: ' + avance : 'Sin comentario establecido' + ' - Avance: ' + avance;
				const si = comentario.si;
				const no1 = comentario.no1;
				const no2 = comentario.no2;
				// Verificar si ya existe un resultado con la misma combinación de agrupamiento
				let resultadoExistente = null;
				this.comentariosAgrupados.forEach(item => {
					if (
						item.conceptoId === conceptoId &&
						item.comentario.comentario === comentarioTexto &&
						item.comentario.si === si &&
						item.comentario.no1 === no1 &&
						item.comentario.no2 === no2
					) {
						// console.log('compara', item.conceptoId);
						resultadoExistente = item;
					}
				});

				// Verificar si la clave ya existe en el resultado
				if (resultadoExistente != null) {
					resultadoExistente.cuvs.push(cuv);
				} else {
					// Si el resultado no existe, crear un nuevo elemento en el arreglo
					this.comentariosAgrupados.push({
						conceptoId,
						comentario: {
							comentario: comentarioTexto,
							concepto: conceptoTexto,
							si,
							no1,
							no2
						},
						cuvs: [cuv]
					});
				}

				this.comentariosAgrupados.forEach(item => {
					item.cuvs.sort((a, b) => (a.id_vivienda - b.id_vivienda));
				});
			});
			this.comentariosAgrupados.forEach(item => {
				item.cuvs.forEach((cuv, index) => {
					let actual = item.cuvs[index].id_vivienda;
					let ant = typeof item.cuvs[index - 1] != 'undefined' ? item.cuvs[index - 1].id_vivienda : 0;
					let sig = typeof item.cuvs[index + 1] != 'undefined' ? item.cuvs[index + 1].id_vivienda : 0;
					// if(index >= 1 && this.suma(actual,1) === this.toInt(sig)){
					// 	item.cuvs[index].omitir = true;
					// }else{
					// 	item.cuvs[index].omitir = false;
					// }
					if (index >= 1 && this.resta(actual, 1) === this.toInt(ant) && this.suma(actual, 1) === this.toInt(sig)) {
						item.cuvs[index].omitir = true;
					} else {
						item.cuvs[index].omitir = false;
					}
				});
			});

			// Mostrar el resultado
			console.log(this.comentariosAgrupados);
			// this.comentariosAgrupados.forEach(item => {
			//     //console.log(item.cuvs.length);
			//     let omitir = false;
			//     item.cuvs.forEach((cuv, index) => {
			//         console.log(cuv.id_vivienda, cuv.omitir)
			//     })
			// })

			const ELEMENT_DATA: Comentario[] = this.comentariosAgrupados;
			this.dataSource = new MatTableDataSource(ELEMENT_DATA);
			this.dataSource.filterPredicate = (data, filter: string) => {
				const accumulator = (currentTerm, key) => {
					return this.nestedFilterCheck(currentTerm, data, key);
				};
				const dataStr = Object.keys(data).reduce(accumulator, '').toLowerCase();

				// Transform the filter by converting it to lowercase and removing whitespace.
				const transformedFilter = filter.trim().toLowerCase();
				return dataStr.indexOf(transformedFilter) !== -1;
			};

			this.datos = res;
			this.idReporteRecibido == 1 || this.idReporteRecibido == 7 ? this.setDataGrafica() : null;
			this.getAllVerificadores();
			console.log(this.obra);
			this.selectedVerif = this.obra.id_verificador != null ? this.obra.id_verificador : null;
			this.isLoaded = true;
			this.isLoading = false;

			if (this.reporteCaratula == true) {
				this.reporte.prototipos.forEach((element, i) => {
					this.totalFactor += (element.cuvs_sum_costo_vivienda / this.reporte.cuvs_sum_costo_vivienda);
					this.totalMonto += Number(element.cuvs_sum_costo_vivienda);
					this.totalPrototipos += (this.avancesPrototipo[i] / 100) * element.cuvs_sum_costo_vivienda;
				});

				this.ponderacionesEqUrbano.forEach((element, i) => {
					this.totalPonderacionEqUrbano += parseFloat(element.ponderacion) * 100;
				});
				this.totalFactor = this.totalFactor - (this.totalPonderacionEqUrbano / 100);

				this.reporte.prototipos.forEach((element, index) => {
					this.totalExp += ((100 - this.totalPonderacionEqUrbano) * (element.cuvs_sum_costo_vivienda / this.reporte.cuvs_sum_costo_vivienda) / 100 * (this.totalMonto)) * this.prototipos[index] / 100;
				});
				this.ponderacionesEqUrbano.forEach((element, index) => {
					this.totalExp += ((element.ponderacion) * this.totalMonto) * this.eqUrbano[index] / 100;
				});
				this.totalPorcentaje = this.totalExp * 100 / this.totalMonto;
			}
			if (this.reporte == null) {
				Swal.fire(
					'Reporte No disponible',
					'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.',
					'question'
				)
			}
			this.hideLoader();
		}, error => {
			//loader false
			var errorF;
			(error.error.message) != null ?
				errorF = error.error.message :
				errorF = 'Hubo un error al cargar el Reporte!';
			Swal.fire({
				icon: 'error',
				title: 'Error...',
				text: errorF,
				footer: 'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.'
			}).then((result) => {
				if (result.isConfirmed) {
					window.history.back();
				}
			});
			this.hideLoader();
		});

	}

	resta(minuendo, sustraendo){
		return parseInt(minuendo) - parseInt(sustraendo);
	}
	suma(minuendo, sustraendo){
		return parseInt(minuendo) + parseInt(sustraendo);
	}
	toInt(number){
		return parseInt(number);
	}
	
	nestedFilterCheck(search, data, key) {
		console.log(search, data, key)
		if (typeof data[key] === 'object' && key != 'cuvs') {
		  for (const k in data[key]) {
			if (data[key][k] !== null) {
			  search = this.nestedFilterCheck(search, data[key], k);
			}
		  }
		} else {
		  search += data[key];
		}
		return search;
	}

	setDataGrafica(){
		this.chart_labels = Array();
		this.avanceProgramado = Array();
		this.avances = Array();

		//3 casos para barra azul y creación de grafica
		//hay más reportes generados que avances programados
		//hay más avances programados que reportes generados
		//No hay avances programados
		if(this.reporte.avances_programados.length == 0){
			//Si no hay avances programados, se crea la grafica dinamicamente con el numero de reportes generados
			let avanceIndice = this.historialReportes.length;
			let avanceIndice0 = avanceIndice-1;
			for(let i=1; i<=avanceIndice; i++){
				this.chart_labels.push(i);
			}
			let avance = 0;
			for(let j=0; j<avanceIndice; j++){
				this.avanceProgramado.push(avance.toFixed(2));
				avance = avance + (100/avanceIndice0);
			}
		}else{
			if(this.historialReportes.length >= this.reporte.avances_programados.length){
				//Si hay más reportes generados, reportes generados establece los labels de la grafica
				this.historialReportes.forEach((element) => {
					this.chart_labels.push(element.numero_reporte);
				});
				this.reporte.avances_programados.forEach((element) => {
					this.avanceProgramado.push(element.avance_programado)
				});
			}else if(this.reporte.avances_programados.length > this.historialReportes.length){
				//Si hay más avances programados, avances prrogramados establece los lables de la grafica
				this.reporte.avances_programados.forEach((element) => {
					this.chart_labels.push(element.numero_reporte);
					this.avanceProgramado.push(element.avance_programado)
				});
			}
		}
		this.historialReportes.forEach(element => {
			this.avances.push(element.avance);
		});
	}

	updateInitialReport(event){
		let datos = {};
		datos['id_orden'] = this.activatedRoute.snapshot.queryParams["orden"],
		datos['avance'] = event;
		this.reporteService.updateFirstReport(datos).subscribe(res => {
			this.chart.data.datasets[1].data[0] = event;
			this.chart.update();
		}, error => {
			//loader false
			var errorF;
			(error.error.message) != null ?
				errorF = error.error.message :
				errorF = 'Hubo un error al actualizar el reporte!';
			Swal.fire({
				icon: 'error',
				title: 'Error...',
				text: errorF,
				footer: 'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.'
			}).then((result) => {
				if (result.isConfirmed) {
					window.history.back();
				}
			});
		});
	}

	getAllVerificadores(){
		this.isLoading = true;
		this.usuariosService.getVerificadoresService().subscribe(res=> {
			this.isLoading = false;
			this.allVerificadores = res;
		}, error => {
			this.isLoading = false;
			//loader false
			var errorF;
			(error.error.message) != null ?
				errorF = error.error.message :
				errorF = 'Hubo un error al cargar verificadores!';
			Swal.fire({
				icon: 'error',
				title: 'Error...',
				text: errorF,
				footer: 'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.'
			}).then((result) => {
				if (result.isConfirmed) {
					window.history.back();
				}
			});
		});
	}

	verificadorChange(event) {
		this.showLoader();
		let id_orden = this.activatedRoute.snapshot.queryParams["orden"];
		let user = {
			id_orden: id_orden,
		  	id_verificador: event.value
		};
		this.usuariosService.assignVerificadorObra(user).subscribe(res=> {
		  	res == 1 ? this.isFormReady = true : this.isFormReady = false;
			  Swal.fire(
				  'Guardado!',
				  'Verificador asignado correctamente',
				  'success'
			  )
			  this.verificadorSelected = event.value;
			this.hideLoader();
		}, error => {
			this.hideLoader();
			//loader false
			var errorF;
			(error.error.message) != null ?
				errorF = error.error.message :
				errorF = 'Hubo un error al asignar al verificador!';
			Swal.fire({
				icon: 'error',
				title: 'Error...',
				text: errorF,
				footer: 'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.'
			}).then((result) => {
				if (result.isConfirmed) {
					window.history.back();
				}
			});
		})
	}

	getEvidencias(event){
		let datos = {};
		datos['id_orden'] = this.activatedRoute.snapshot.queryParams["orden"];
		datos['id_reporte_recibido'] = this.idReporteRecibido;
		this.isLoading = true;
		this.reporteService.getEvidencias(datos).subscribe(res=> {
			this.evidencias = res;
			this.isLoading = false;
		}, error => {
			this.isLoading = false;
			//loader false
			var errorF;
			(error.error.message) != null ?
				errorF = error.error.message :
				errorF = 'Hubo un error al cargar las evidencias!';
			Swal.fire({
				icon: 'error',
				title: 'Error...',
				text: errorF,
				footer: 'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.'
			}).then((result) => {
				if (result.isConfirmed) {
					window.history.back();
				}
			});
		});
	}

	setEvidencias(event){
		let datos = {};
		datos['id_tipo_reporte'] = this.idReporteRecibido;
		datos['id_evidencia'] = event;
		this.isLoading = true;
		this.reporteService.setEvidencias(datos).subscribe(res=> {
			Swal.fire(
				'Guardado!',
				'Evidencia seleccionada correctamente',
				'success'
			);/* .then((result) => {
				if (result.isConfirmed) {
					window.location.reload();
				}
			}) */
			
			this.isLoading = false;
		}, error => {
			this.isLoading = false;
			//loader false
			var errorF;
			(error.error.message) != null ?
				errorF = error.error.message :
				errorF = 'Hubo un error al guardar los datos!';
			Swal.fire({
				icon: 'error',
				title: 'Error...',
				text: errorF,
				footer: 'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.'
			}).then((result) => {
				if (result.isConfirmed) {
					window.history.back();
				}
			});
		});
	}

	updateEvidencias(event){
		let datos = {};
		datos['id_reporte_evidencia'] = event;
		this.isLoading = true;
		this.reporteService.updateEvidencias(datos).subscribe(res=> {
			Swal.fire(
				'Actualizado!',
				'Evidencia deseleccionada correctamente',
				'success'
			);/* .then((result) => {
				if (result.isConfirmed) {
					window.location.reload();
				}
			}) */
			this.isLoading = false;
		}, error => {
			this.isLoading = false;
			//loader false
			var errorF;
			(error.error.message) != null ?
				errorF = error.error.message :
				errorF = 'Hubo un error al guardar los cambios!';
			Swal.fire({
				icon: 'error',
				title: 'Error...',
				text: errorF,
				footer: 'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.'
			}).then((result) => {
				if (result.isConfirmed) {
					window.history.back();
				}
			});
		});
	}

	descripcionChange(event){
		let datos = {};
		datos['descripcion'] = event.target.value;
		datos['id'] = this.reporte.reportes[0].id;
		this.reporteService.updateReporteDescripcion(datos).subscribe(res=> {
			Swal.fire(
				'Actualizada!',
				'Descripción actualizada correctamente',
				'success'
			)
			this.isLoading = false;
		}, error => {
			this.isLoading = false;
			var errorF;
			(error.error.message) != null ?
				errorF = error.error.message :
				errorF = 'Hubo un error al guardar los cambios!';
			Swal.fire({
				icon: 'error',
				title: 'Error...',
				text: errorF,
				footer: 'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.'
			}).then((result) => {
				if (result.isConfirmed) {
					window.history.back();
				}
			});
		});
	}

	descargarReportes(event){
		window.location.href ='/descargar-reportes?orden='+this.idOrden[0];
	}

}
