<nav id="sidebar">
    <div class="sidebar-header">
        <div class="no-collapse-header text-center">
            <span class="material-icons userIcon">
                person
            </span>
            <h3>
                <p>
                    {{authUserName}}
                </p>
                <p>
                    <ng-container *ngIf="authUserApellido != null">
                        {{authUserApellido}}
                    </ng-container>
                    <ng-container *ngIf="authUserApellido2 != null">
                        {{authUserApellido2}}
                    </ng-container>
                </p>
            </h3>
        </div>
        <div class="collapse-header">
            <mat-icon aria-hidden="false" class="userIcon" title="{{authUserName}} {{authUserApellido2}} {{authUserApellido}}">
                person
            </mat-icon>
        </div>
    </div>

    <ul class="list-unstyled components">
        <li>
            <a [routerLink]="['/']"><mat-icon aria-hidden="false">home</mat-icon> Inicio</a>
        </li>
        
        <li>
            <a href="#subMenuReportes" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">
                <mat-icon aria-hidden="false">bookmarks</mat-icon> Reportes
            </a>
            <ul class="collapse list-unstyled" id="subMenuReportes">
                <li class="nav-item"><a [routerLink]="['/historial-reportes']">Historico de Reportes</a></li> 
                <!-- <li class="nav-item"><a [routerLink]="['/reportes-disponibles']">Generar Reportes</a></li> -->
                <li class="nav-item">
                    <a [routerLink]="['/selector-obra']"
                        [queryParams]="{route: 'reportes-disponibles'}">
                        Generar Reportes
                    </a>
                </li>
            </ul>
        </li>

        <li>
            <a href="#subMenuObras" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">
                <mat-icon aria-hidden="false">assignment</mat-icon> Obras
            </a>
            <ul class="collapse list-unstyled" id="subMenuObras">
                <li class="nav-item"><a [routerLink]="['/listado-obras']">Listado de Obras</a></li>
                <li class="nav-item" *ngIf="authUserRole == 1">
                    <a [routerLink]="['/crear-obra']">Crear Obra</a>
                </li>
            </ul>
        </li>
        <li *ngIf="authUserRole == 1">
            <a href="#subMenuEcotecnias" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">
                <mat-icon aria-hidden="false">eco</mat-icon> Ecotecnias
            </a>
            <ul class="collapse list-unstyled" id="subMenuEcotecnias">
                <li class="nav-item"><a [routerLink]="['/listado-ecotecnias']">Listado de Ecotecnías</a></li>
            </ul>
        </li>

        <li>
            <a href="#subMenuUsuarios" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">
                <mat-icon aria-hidden="false">people</mat-icon> Usuarios
            </a>
            <ul class="collapse list-unstyled" id="subMenuUsuarios">
                <li class="nav-item">
                    <a [routerLink]="['/usuario']">
                        <mat-icon class="material-icons">person</mat-icon>
                        Mi Perfil
                    </a>
                </li>
                <li class="nav-item" *ngIf="authUserRole == 1">
                    <a [routerLink]="['/usuarios']">
                        <mat-icon aria-hidden="false">people</mat-icon>
                        Listado de Usuarios
                    </a>
                </li>
            </ul>
        </li>

        <li *ngIf="authUserRole == 1">
            <a href="#subMenuDTU" data-toggle="collapse" aria-expanded="false" title="DICTAMEN TÉCNICO ÚNICO" class="dropdown-toggle">
                <mat-icon class="material-icons">assignment_turned_in</mat-icon> DTU
            </a>
            <ul class="collapse list-unstyled collapsing" id="subMenuDTU">
                <li class="nav-item" *ngIf="authUserRole == 1">
                    <!-- <a [routerLink]="['/set-dtu']"
                        [queryParams]="{method: 'form'}">
                        <mat-icon class="material-icons">insert_drive_file</mat-icon>
                        Generar DTU
                    </a> -->
                    <a [routerLink]="['/selector-obra']"
                        [queryParams]="{method: 'form', route: 'set-dtu'}">
                        <mat-icon class="material-icons">insert_drive_file</mat-icon>
                        Generar DTU
                    </a>
                </li>
                <li class="nav-item" *ngIf="authUserRole == 1">
                    <!-- <a [routerLink]="['/index-dtu']"
                    [queryParams]="{method: 'index'}">
                        <mat-icon aria-hidden="false">file_copy</mat-icon>
                        Listado de DTU
                    </a> -->
                    <a [routerLink]="['/selector-obra']"
                        [queryParams]="{method: 'index', route: 'index-dtu'}">
                        <mat-icon aria-hidden="false">file_copy</mat-icon>
                        Listado de DTU
                    </a>
                </li>
            </ul>
        </li>

        <li *ngIf="authUserRole == 1">
            <a href="#subMenuNotas" data-toggle="collapse" aria-expanded="false" title="Notas Bitacora" class="dropdown-toggle">
                <mat-icon class="material-icons">note</mat-icon> Bitacora
            </a>
            <ul class="collapse list-unstyled collapsing" id="subMenuNotas">
                <li class="nav-item" *ngIf="authUserRole == 1">
                    <a [routerLink]="['/index-notas']" [queryParams]="{method: 'notas'}">
                        <mat-icon class="material-icons">notes</mat-icon>
                        Listado de Notas
                    </a>
                </li>
            </ul>
        </li>

        <!-- <li *ngIf="authUserRole == 1">
            <a href="#subMenuPruebasLaboratorio" data-toggle="collapse" aria-expanded="false" title="Pruebas de Laboratorio" class="dropdown-toggle short-text">
                <mat-icon class="material-icons">biotech</mat-icon>
                Pruebas de Laboratorio
            </a>
            <ul class="collapse list-unstyled collapsing" id="subMenuPruebasLaboratorio">
                <li class="nav-item" *ngIf="authUserRole == 1">
                    <a [routerLink]="['/set-prueba']" [queryParams]="{method: 'form'}">
                        <mat-icon class="material-icons">note_add</mat-icon>
                        Nueva Prueba
                    </a>
                </li>
                <li class="nav-item" *ngIf="authUserRole == 1">
                    <a [routerLink]="['/index-pruebas']" [queryParams]="{method: 'show'}">
                        <mat-icon class="material-icons">file_copy</mat-icon>
                        Consultar Pruebas
                    </a>
                </li>
            </ul>
        </li> -->

        <li>
            <a (click)="cerrarSesion()"><mat-icon aria-hidden="false">exit_to_app</mat-icon> Cerrar Sesión</a>
        </li>
    </ul>
</nav>
