import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PruebasLaboratorioService } from 'src/app/services/pruebasLaboratorio.service'
import { DTUService } from 'src/app/services/dtuService.service'
import { cuvsService } from 'src/app/services/cuvsService.service';
import { ProjectsService } from 'src/app/services/projects.service';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { MatTableDataSource } from '@angular/material/table';
import { UntypedFormBuilder, UntypedFormControl, FormControlName, UntypedFormGroup, Validator, Validators } from '@angular/forms';
import Swal from 'sweetalert2';

export interface Prueba {
	id: number;
	reporte_id: any;
	dias: any;
	resistencia: any;
	humedad: any;
	material: any;
	cuv: any;
	created_at: string;
}

@Component({
  selector: 'app-pruebas-laboratorio',
  templateUrl: './pruebas-laboratorio.component.html',
  styleUrls: ['./pruebas-laboratorio.component.scss']
})
export class PruebasLaboratorioComponent implements OnInit {

	@ViewChild('selectManzanas') selectManzanas: MatSelect;
	@ViewChild('selectLotes') selectLotes: MatSelect;
	@ViewChild('selectCuvs') selectCuvs: MatSelect;
	@ViewChild('selectPrueba') selectPrueba: MatSelect;
	@ViewChild('selectMaterial') selectMaterial: MatSelect;

	public idOrden: any = this.activatedRoute.snapshot.queryParams["orden"];
	public userId: any;
	public method: any = this.activatedRoute.snapshot.queryParams["method"];
	public cuvs: any;
	public pruebas: any;
	public materiales: any;
	public response: any;
	public lotes: any;
	public manzanas: any;
	public tipo_prueba: any;
	public isLoteSelected: boolean = false;
	public isMaterialSelected: boolean = false;
	public isManzanaSelected: boolean = false;
	public isAvanceSelected: boolean = false;
	public allManzanasSelected: boolean = false;
	public allLotesSelected: boolean = false;
	public isTipoSelected: boolean = false;
	public isLoading: boolean = false;
	displayedColumns: string[] = ['id', 'material', 'prueba_id', 'cuv', 'acciones'];
	public dataSource: any;
	
	public pruebaForm: UntypedFormGroup;
	public isCuvSelected: boolean = false;
	public selectedCuv: any;
	
	validation_messages = {
		'dias': [
		  { type: 'required', message: 'El campo Dias es necesario.' },
		  { type: 'pattern', message: 'El campo Dias no debe estar vacío' },
		  { type: 'min', message: 'El campo Dias debe ser númerico' },
		],
		'reporte': [
		  { type: 'required', message: 'El campo Reporte es necesario.' },
		  { type: 'pattern', message: 'El campo Reporte no debe estar vacío' },
		  { type: 'min', message: 'El campo Reporte debe ser númerico' },
		],
		'resistencia': [
		  { type: 'required', message: 'El campo Resistencia es necesario.' },
		  { type: 'pattern', message: 'El campo Resistencia no debe estar vacío' },
		  { type: 'min', message: 'El campo Resistencia debe ser mayor a 0' },
		],
		'tipo_prueba': [
		  { type: 'required', message: 'El campo tipo_prueba es necesario.' },
		],
		'material': [
		  { type: 'required', message: 'El campo material es necesario.' },
		],
		'humedad': [
		  { type: 'required', message: 'El campo humedad es necesario.' },
		],
	}

	constructor(
			private pruebasLabotarorioService: PruebasLaboratorioService,
			private dtuService: DTUService,
			private cuvsService: cuvsService,
			private projectsService: ProjectsService,
			private formBuilder: UntypedFormBuilder,
			private activatedRoute: ActivatedRoute,
	) { }

	ngOnInit(): void {
		
		this.pruebaForm = this.formBuilder.group({
			cuv: new UntypedFormControl(""),
			lote: new UntypedFormControl(""),
			manzana: new UntypedFormControl(""),
			tipo_prueba: new UntypedFormControl({value:""}, Validators.compose([
				Validators.required
			])),
			dias: new UntypedFormControl({number:"", disabled: true}, Validators.compose([
				Validators.required, Validators.min(0), Validators.pattern("^[0-9]*$")
			])),
			reporte: new UntypedFormControl({number:"", disabled: true}, Validators.compose([
				Validators.required, Validators.min(0), Validators.pattern("^[0-9]*$")
			])),
			resistencia: new UntypedFormControl({number:"", disabled: true}, Validators.compose([
				Validators.required, Validators.min(0), Validators.pattern("^[+-]?([0-9]*[.])?[0-9]+$")
			])),
			humedad: new UntypedFormControl({number:"", disabled: true}, Validators.compose([
				Validators.required, Validators.min(0), Validators.pattern("^[+-]?([0-9]*[.])?[0-9]+$")
			])),
			material: new UntypedFormControl({value:""}, Validators.compose([
				Validators.required
			])),
		});
		
		this.pruebaForm.get('dias').disable()
		this.pruebaForm.get('reporte').disable()
		this.pruebaForm.get('resistencia').disable()
		this.pruebaForm.get('humedad').disable()

		this.userId = localStorage.getItem('SESSION_USER_ID');

		if (this.idOrden == null) {
			this.hasObra();
		}
		if(this.method == 'form' && this.idOrden != null){
			this.getCuvs();
			this.getMateriales();
		}
		if(this.method == 'show' && this.idOrden != null){
			this.getObraCuvs();
		}
	}

	getObraCuvs(){
		let idOrden: object = {};
		idOrden['id_orden'] = this.activatedRoute.snapshot.queryParams["orden"];
		this.cuvsService.getCuvs(idOrden).subscribe(res => {
			this.cuvs = res;
		}, error => {
			//loader false
			var errorF;
			(error.error.message) != null ?
				errorF = error.error.message :
				errorF = 'Hubo un error al consultar las viviendas!';
			Swal.fire({
				icon: 'error',
				title: 'Error...',
				text: errorF,
				footer: 'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.'
			}).then((result) => {
				if (result.isConfirmed) {
					window.history.back();
				}
			});
		})
	}

	getCuvs(){
		let idOrden: object = {};
		idOrden['id_orden'] = this.activatedRoute.snapshot.queryParams["orden"];
		this.projectsService.getAllCuvs(idOrden).subscribe(res => {
			this.response = res;
			this.manzanas = Object.keys(res);
		}, error => {
			//loader false
			var errorF;
			(error.error.message) != null ?
				errorF = error.error.message :
				errorF = 'Hubo un error al consultar las Ordenes!';
			Swal.fire({
				icon: 'error',
				title: 'Error...',
				text: errorF,
				footer: 'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.'
			}).then((result) => {
				if (result.isConfirmed) {
					window.history.back();
				}
			});
		})
	}

	getMateriales(){
		
		this.pruebasLabotarorioService.getMateriales().subscribe(res => {
			this.materiales = res;
		}, error => {
			//loader false
			var errorF;
			(error.error.message) != null ?
				errorF = error.error.message :
				errorF = 'Hubo un error al consultar los Materiales!';
			Swal.fire({
				icon: 'error',
				title: 'Error...',
				text: errorF,
				footer: 'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.'
			}).then((result) => {
				if (result.isConfirmed) {
					window.history.back();
				}
			});
		})
	}
	
	cuvSeleccionada(event) {
		this.selectedCuv = event.value;
		this.selectedCuv.length > 0 ? this.isCuvSelected = true: this.isCuvSelected = true;
		
		if(this.method == 'show' && this.idOrden != null && this.isCuvSelected){
			this.getPruebas(this.selectedCuv);
		}
	}

	manzanaSeleccionada(event) {
		var manzanas = event.value;
		var lotes = [];
		var indexManzanas = [];

		for (var p in manzanas) {
			if (manzanas[p] != 0)
				indexManzanas.push(manzanas[p].value);
		}

		indexManzanas.forEach(manzana => {
			for (var p in this.response[manzana]) {
				lotes.push(this.response[manzana][p])
			}
		});
		this.lotes = lotes;
		if(manzanas.length > 0 ){
			this.isManzanaSelected = true 
		}else{
			this.isManzanaSelected = false
			this.isLoteSelected = false
			this.isCuvSelected = false
			delete this.cuvs;
			this.selectCuvs.options.forEach((item: MatOption) => item.deselect());
			this.selectLotes.options.forEach((item: MatOption) => item.deselect());
			this.selectPrueba.options.forEach((item: MatOption) => item.deselect());
			delete this.lotes;
			this.pruebaForm.get('dias').disable()
			this.pruebaForm.get('reporte').disable()
			this.pruebaForm.get('resistencia').disable()
			this.pruebaForm.get('humedad').disable()
		}
	}

	loteSeleccionado(event) {
		var lotes = event.value;
		var indexLotes = [];

		for (var p in lotes) {
			if (lotes[p] != 0) {
				lotes[p].value.forEach(element => {
					indexLotes.push(element);
				});
			}
		}
		this.cuvs = indexLotes;
		if(lotes.length > 0){
			this.isLoteSelected = true;
		}else{
			this.isLoteSelected = false;
			this.isCuvSelected = false;
			delete this.cuvs;
			this.selectCuvs.options.forEach((item: MatOption) => item.deselect());
			this.selectPrueba.options.forEach((item: MatOption) => item.deselect());
			this.pruebaForm.get('dias').disable()
			this.pruebaForm.get('reporte').disable()
			this.pruebaForm.get('resistencia').disable()
			this.pruebaForm.get('humedad').disable()
		}
	}

	tipoSeleccionado(event) {
		var tipo_prueba = event.value;
		this.tipo_prueba = tipo_prueba;
		if(event.value != null){
			this.isTipoSelected = true;
			this.pruebaForm.get('dias').enable()
			this.pruebaForm.get('reporte').enable()
			this.pruebaForm.get('resistencia').enable()
			this.pruebaForm.get('humedad').enable()
		}else{
			this.isTipoSelected = false;
			this.pruebaForm.get('dias').disable()
			this.pruebaForm.get('reporte').disable()
			this.pruebaForm.get('resistencia').disable()
			this.pruebaForm.get('humedad').disable()
		}
	}

	
	materialSeleccionado(event){
		var material = event.value.id;
		material != null ?
			this.isMaterialSelected = true
			:
			this.isMaterialSelected = false;

	}

	selectAllManzanas() {
		if (this.allManzanasSelected == false) {
			this.allManzanasSelected = true;
			this.selectManzanas.options.forEach((item: MatOption) => item.select());
		} else {
			this.allManzanasSelected = false;
			this.selectManzanas.options.forEach((item: MatOption) => item.deselect());
		}
	}

	selectAllLotes() {
		if (this.allLotesSelected == false) {
			this.allLotesSelected = true;
			this.selectLotes.options.forEach((item: MatOption) => item.select());
		} else {
			this.allLotesSelected = false;
			this.selectLotes.options.forEach((item: MatOption) => item.deselect());
		}
	}
	
	optionManzanaClick() {
		let newStatus = true;
		this.selectManzanas.options.forEach((item: MatOption) => {
			if (!item.selected) {
				newStatus = false;
				this.selectLotes.options.forEach((item: MatOption) => item.deselect());
				this.selectCuvs.options.forEach((item: MatOption) => item.deselect());
			}
		});
	}

	optionLoteClick() {
		let newStatus = true;
		this.selectLotes.options.forEach((item: MatOption) => {
			if (!item.selected) {
				newStatus = false;
				this.selectCuvs.options.forEach((item: MatOption) => item.deselect());
			}
		});
	}

	optionCuvClick() {
		let newStatus = true;
		this.selectCuvs.options.forEach((item: MatOption) => {
			if (!item.selected) {
				newStatus = false;
			}
		});
	}

	hasObra(){
		this.dtuService.hasObra(this.userId).subscribe(res => {
			const urlParams = new URLSearchParams(window.location.search);
			urlParams.set('orden', res[1]);
			window.location.search = urlParams.toString();

		}, error => {
			//loader false
			var errorF;
			(error.error.message) != null ?
				errorF = error.error.message :
				errorF = 'Hubo un error al consultar las Ordenes!';
			Swal.fire({
				icon: 'error',
				title: 'Error...',
				text: errorF,
				footer: 'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.'
			}).then((result) => {
				if (result.isConfirmed) {
					window.history.back();
				}
			});
		})
	}

	guardarPrueba(event){
		this.isLoading = true;
		this.pruebasLabotarorioService.store(event.value).subscribe(res => {
			Swal.fire(
				'Guardado!',
				'Prueba almacenada correctamente',
				'success'
			);/* .then((result) => {
				if (result.isConfirmed) {
					window.location.reload();
				}
			}) */
			this.isLoading = false;
		}, error => {
			//loader false
			var errorF;
			(error.error.message) != null ?
				errorF = error.error.message :
				errorF = 'Hubo un error al guardar la Prueba!';
			Swal.fire({
				icon: 'error',
				title: 'Error...',
				text: errorF,
				footer: 'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.'
			}).then((result) => {
				if (result.isConfirmed) {
					window.history.back();
				}
			});
		})
	}

	applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dataSource.filter = filterValue.trim().toLowerCase();
	}

	getPruebas(cuv){
		let datos = {};
		datos['cuv'] = cuv;
		this.isLoading = true;
		this.pruebasLabotarorioService.show(datos).subscribe(res => {
			this.pruebas = res.pruebas_laboratorio;
			const ELEMENT_DATA: Prueba[] = this.pruebas;
			this.dataSource = new MatTableDataSource(ELEMENT_DATA);
			this.isLoading = false;
		}, error => {
			//loader false
			var errorF;
			(error.error.message) != null ?
				errorF = error.error.message :
				errorF = 'Hubo un error al consultar las Pruebas!';
			Swal.fire({
				icon: 'error',
				title: 'Error...',
				text: errorF,
				footer: 'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.'
			}).then((result) => {
				if (result.isConfirmed) {
					window.history.back();
				}
			});
		});
	}

}
