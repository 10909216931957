import { Component, OnInit, ViewChild } from '@angular/core';
import { ProjectsService } from 'src/app/services/projects.service';
import { ActivatedRoute, Router, NavigationEnd  } from '@angular/router';
import Swal from 'sweetalert2';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'app-selector-obra',
  templateUrl: './selector-obra.component.html',
  styleUrls: ['./selector-obra.component.scss']
})
export class SelectorObraComponent implements OnInit {

  // @ViewChild('select') ngSelectComponent: select;
  @ViewChild('select', { static: false }) public select: NgSelectComponent;
  
	public route: string = this.activatedRoute.snapshot.queryParams["route"];
	public method: string = this.activatedRoute.snapshot.queryParams["method"];
	public isLoading: boolean = false;
	public userId: any;
	public userRole: any;
	public listadoObras: Array<any> = [];
	public allowButton: boolean = false;
	public ordenSelected: {};

	constructor(
    private projectsService: ProjectsService,
		private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
		this.userId = localStorage.getItem('SESSION_USER_ID');
		this.userRole = localStorage.getItem('SESSION_ROLE');
	}

  ngOnInit(): void {
		this.getListadoObras();
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
         this.route = this.activatedRoute.snapshot.queryParams["route"];
         this.method = this.activatedRoute.snapshot.queryParams["method"];
         this.allowButton = false;
         this.ordenSelected = null;
         this.select.clearModel();
      }
    });
  }
	btnChange(event) {
		this.ordenSelected = event.id_orden;
		if(event !== null) {
			this.allowButton = true;
		}else{
			this.allowButton = false;
		}
	}

	getListadoObras() {
		let datos = {};
		datos['id_verificador'] = this.userId;
		datos['user_role'] = this.userRole;
		this.isLoading = true;

		this.projectsService.getAllProjects(datos).subscribe(res=> {
			this.listadoObras = res;
			this.isLoading = false;
		}, error => {
			this.isLoading = false;
			var errorF;
			(error.error.message) != null ?
				errorF = error.error.message :
				errorF = 'Hubo un error al cargar los reportes!';
			Swal.fire({
				icon: 'error',
				title: 'Error...',
				text: errorF,
				footer: 'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.'
			});
		});
	}
}
