<section class="animate__animated animate__fadeIn h-90 d-flex align-items-center py-3">
    <div class="container">
        <div class="section-title row">
            <div class="col-6">
                <h2>Sembrado</h2>
            </div>
            <div class="col-6 ml-auto text-right" *ngIf="!isMobile">
                <button class="btn btn-primary" aria-label="Subir Archivo" data-toggle="modal" data-target="#sembrado">Cargar Sembrado</button>
            </div>
        </div>
        <div class="section-content row">
            <div class="col-12">
                <div id="canvas"></div>
                <div id="menu">
                    <div>
                        <button id="lock-button">Bloquear</button>
                        <button *ngIf="isNewFigure" data-toggle="modal" data-target="#cambioCuv">
                            Cuv - {{ idFigura }} Ext. {{ ext }} Manzana: {{ manzana }} Lote: {{ lote }}
                        </button>
                        <button id="delete-button">Borrar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="modal fade" id="sembrado" tabindex="-1" role="dialog" aria-labelledby="sembradoTitle" aria-hidden="false">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="sembradoTitle">Sembrado</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <input id="import_file" #fileDropRef type="file" name="import_file" (change)="prepararArchivo($event.target.files)">
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-primary" (click)="cargarMapa($event)">Confirmar</button>
                <button type="button" class="btn btn-secondary" (click)="cancelarSubida()" data-dismiss="modal">Cancelar</button>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="cambioCuv" tabindex="-1" role="dialog" aria-labelledby="cambioCuvTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">Seleccion de CUV</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-12">
                        <p>CUV actual: - <strong>{{ idFigura }}</strong></p>
                    </div>
                </div>
                <mat-form-field appearance="fill" class="w-100">
                    <mat-label>Lista de CUVs disponibles</mat-label>
                    <mat-select [(ngModel)]="selectedCuv" name="cuvs" class="w-100">
                        <mat-option *ngFor="let cuv of availableCuvs; let i = index" [value]="cuv">
                           {{cuv.cuv}} - # Ext. {{ cuv.numero_exterior }}, Lote: {{ cuv.lote }}, Manzana: {{ cuv.manzana }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                <button type="button" class="btn btn-primary" (click)="cambiarCuvFigura($event, selectedCuv)" [disabled]="selectedCuv.length == 0">Guardar Cambios</button>
            </div>
        </div>
    </div>
</div>
<app-loader [isLoading]="isLoading"></app-loader>