import { Component, OnInit, ViewChild } from '@angular/core';
import { NotasService } from 'src/app/services/notasService.service';
import { DTUService } from 'src/app/services/dtuService.service';
import { cuvsService } from 'src/app/services/cuvsService.service';
import { VerificacionesService } from 'src/app/services/verificaciones.service';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormControl, FormControlName, UntypedFormGroup, Validator, Validators } from '@angular/forms';
import { animate, state, style, transition, trigger } from '@angular/animations';
import Swal from 'sweetalert2';
import { DialogoNotaBitacoraComponent } from './dialogo-nota-bitacora/dialogo-nota-bitacora.component';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';

export interface Nota {
	id: number;
	nota: string;
	created_at: string;
	updated: string;
	id_orden: string;
}

@Component({
  selector: 'app-notas-bitacora',
  templateUrl: './notas-bitacora.component.html',
  styleUrls: ['./notas-bitacora.component.scss'],
  animations: [
	trigger('detailExpand', [
	  state('collapsed', style({height: '0px', minHeight: '0'})),
	  state('expanded', style({height: '*'})),
	  transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
	]),
  ]
})
export class NotasBitacoraComponent implements OnInit {
	public isLoading: boolean = false;
	public isUpdate: boolean = false;
	public allowButton: boolean = false;
	public notas: any;
	public nota: any;
	public checkDisabled: boolean;
	public notaForm: UntypedFormGroup;
	
	public cuvs: any;
	public cuvsSelected: any;
	public conceptos: any;

	public userId: any;

	public idOrden: any = this.activatedRoute.snapshot.queryParams["orden"];

	dataSource = Array<any>();
	dataSourceBack = Array<any>();
	displayedColumns: string[] = ['id', 'nota', 'created_at', 'acciones'];
	
	public method: any = this.activatedRoute.snapshot.queryParams["method"];

	
	subColumnsToDisplay: string[] = ['cuv', 'id_vivienda', 'lote', 'manzana'];
	public expandedElement: any | null;

	subUse = new Subject<any>();
	@ViewChild(MatTable) table: MatTable<any>;

	constructor(
		private formBuilder: UntypedFormBuilder,
		private notasService: NotasService,
		private dtuService: DTUService,
		private cuvsService: cuvsService,
		private activatedRoute: ActivatedRoute,
		private verificacionesService: VerificacionesService,
		private dialog: MatDialog
	) {
		this.notaForm = this.formBuilder.group({
			id: new UntypedFormControl(""),
			id_orden: new UntypedFormControl(""),
			nota: new UntypedFormControl("", Validators.compose([
				Validators.required,
			])),
			cuvs: new UntypedFormControl(""),
			concepto: new UntypedFormControl(""),
			solDTU: new UntypedFormControl(""),
		});
		this.userId = localStorage.getItem('SESSION_USER_ID');
		this.checkDisabled = true;
		if(typeof this.idOrden == 'undefined' || this.idOrden == null){
			this.idOrden = String(window.localStorage.getItem('orden'));
			var url = new URL(window.location.href);
			url.searchParams.append('orden', window.localStorage.getItem('orden'));
		}
	}

	ngOnInit(): void {
		this.subUse.subscribe(nota => {
			this.recargaDatos(nota);
		});
		if (this.idOrden == null || typeof this.idOrden == 'undefined') {
			this.hasObra();
		}
		if(this.method == 'notas' && this.idOrden != null){
			this.getNotas();
			this.getCuvs();
			this.getConceptos();
		}
	}

	onCloseModal(){
		this.notaForm.reset();
	}

	toggleModal(bool, element) {
		const dialogRef = this.dialog.open(DialogoNotaBitacoraComponent, {
			data: {
				element,
				bool,
				subUse: this.subUse,
				conceptos: this.conceptos,
				cuvs: this.cuvs
			},
			width: '510px'
		});

		dialogRef.afterClosed().subscribe(result => {
			if (result === 'guardar') {
				Swal.fire(
					'Guardado!',
					'Nota guardada correctamente',
					'success'
				);
				this.isLoading = false;
			} else if (result === 'actalizar') {
				Swal.fire(
					'Actualizo!',
					'Nota actualizada correctamente',
					'success'
				);
				this.isLoading = false;
			}
		});
	}

	applyFilter(event: KeyboardEvent) {
		const datSourArray = new Array<any>();
		let filterValue = (event.target as HTMLInputElement).value.toLowerCase();
	
		if (this.dataSourceBack.length === 0) {
			this.dataSourceBack = this.dataSource;
		} else {
			this.dataSource = this.dataSourceBack;
		}
	
		for (let i = 0; i < this.dataSource.length; i++) {
			let busqueda = this.dataSource[i];
			let dato = busqueda.nota.toLowerCase();
			if (dato.indexOf(filterValue) >= 0) {
				datSourArray.push(busqueda);
			}
		}
	
		if (filterValue.length === 0) {
			this.dataSource = this.dataSourceBack;
		} else {
			this.dataSource = datSourArray;
		}
	
		this.table.renderRows();
	}

	hasObra(){
		this.dtuService.hasObra(this.userId).subscribe(res => {
			const urlParams = new URLSearchParams(window.location.search);
			urlParams.set('orden', res[1]);
			window.location.search = urlParams.toString();
		}, error => {
			//loader false
			var errorF;
			(error.error.message) != null ?
				errorF = error.error.message :
				errorF = 'Hubo un error al consultar las Ordenes!';
			Swal.fire({
				icon: 'error',
				title: 'Error...',
				text: errorF,
				footer: 'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.'
			});
		})
	}

	getNotas() {
		let datos = {};
		
		datos['id_orden'] = this.idOrden;
		this.notasService.getNotas(datos).subscribe(res => {
			this.isLoading = true;
			for (const index in res) {
				if (Object.prototype.hasOwnProperty.call(res, index)) {
					const element = res[index];
					element['orden'] = Number(index) + 1;
				}
			}
			this.notas = res;
			this.dataSource = this.notas;
			this.isLoading = false;
		}, error => {
			//loader false
			var errorF;
			(error.error.message) != null ?
				errorF = error.error.message :
				errorF = 'Hubo un error al cargar las notas!';
			this.isLoading = false;
			Swal.fire({
				icon: 'error',
				title: 'Error...',
				text: errorF,
				footer: 'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.'
			}).then((result) => {
				if (result.isConfirmed) {
					window.history.back();
				}
			});
		})
	}

	getCuvs() {
		let datos = {};
		datos['id_orden'] = this.idOrden;
		this.cuvsService.getCuvs(datos).subscribe(res => {
			this.isLoading = true;
			this.cuvs = res;
			this.isLoading = false;
		}, error => {
			//loader false
			var errorF;
			(error.error.message) != null ?
				errorF = error.error.message :
				errorF = 'Hubo un error al cargar los cuvs!';
			this.isLoading = false;
			Swal.fire({
				icon: 'error',
				title: 'Error...',
				text: errorF,
				footer: 'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.'
			}).then((result) => {
				if (result.isConfirmed) {
					window.history.back();
				}
			});
		})
	}

	getConceptos() {
		let datos = {};
		datos['id_orden'] = this.idOrden;
		this.verificacionesService.getConceptosRelacionados(datos).subscribe(res => {
			this.isLoading = true;
			this.conceptos = res;
			this.isLoading = false;
		}, error => {
			Swal.fire({
				icon: 'error',
				title: 'Error...',
				text: 'Hubo un error al cargar los conceptos!',
				footer: 'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.'
			})
		})
	}

	deleteNota(nota) {
		this.nota = nota;
		const swalWithBootstrapButtons = Swal.mixin({
			customClass: {
				confirmButton: 'btn btn-primary',
				cancelButton: 'btn btn-danger'
			},
			buttonsStyling: true
		})
		swalWithBootstrapButtons.fire({
			icon: 'warning',
			title: '¿Está Seguro?',
			text: 'Al borrar este elemento se eliminará permanentemente de cada Reporte que lo contenga.',
			showCancelButton: true,
			confirmButtonText: 'Si, Borrar',
			cancelButtonText: 'No, Cancelar',
			reverseButtons: true
		}).then((result) => {
			if (result.isConfirmed) {
				this.isLoading = true;
				this.notasService.deleteNota(this.nota).subscribe(res => {
					this.isLoading = false;

					this.dataSource = this.dataSource.filter(notUser => notUser.id !== this.nota.id);
					this.table.renderRows();

					Swal.fire(
						'Guardado!',
						'Servicio eliminado correctamente',
						'success'
					);
				}, error => {
					//loader false
					var errorF;
					(error.error.message) != null ?
						errorF = error.error.message :
						errorF = 'Hubo un error al borrar la nota!';
					this.isLoading = false;
					Swal.fire({
						icon: 'error',
						title: 'Error...',
						text: errorF,
						footer: 'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.'
					});
				})
			}
		});
	}

	recargaDatos(nota: any) {
		if (this.dataSource.find(datUser => datUser.id === nota.id)) {
    		this.dataSource.find(datUser => datUser.id === nota.id).nota = nota.nota;
    		this.dataSource.find(datUser => datUser.id === nota.id).cuvs = nota.cuvs;
    		this.dataSource.find(datUser => datUser.id === nota.id).concepto = nota.concepto;
		} else {
			this.dataSource.push(nota);
		}
		this.table.renderRows();
	}
}
