import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, UntypedFormArray, FormArray} from '@angular/forms';
import { UploadFileService } from 'src/app/services/fileUpload.service';
import { ProjectsService } from 'src/app/services/projects.service';
import { InegiService } from 'src/app/services/inegiService.service';
import { MatStepper } from '@angular/material/stepper';
import { Cuv } from 'src/app/entities/cuv';
import { Usuario } from 'src/app/entities/Usuario';
import { UsuariosService } from 'src/app/services/usuarios.service';
import { cuvsService } from 'src/app/services/cuvsService.service';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ModalCuvComponent } from '../modal-cuv/modal-cuv.component';
import { ModalConceptoComponent } from '../modal-concepto/modal-concepto.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-info-obra',
  templateUrl: './info-obra.component.html',
  styleUrls: ['./info-obra.component.scss']
})
export class InfoObraComponent implements OnInit {
	idOrden: any = this.activatedRoute.snapshot.queryParams["orden"];
	sumaTotal: any;
	selectedVerif: any;
	estados: any;
	municipios: any;
	localidades: any;
	estadoSelected: any;
	isEstadoSelected: boolean = false;
	isMunicipioSelected: boolean = false;
	isLocalidadSelected: boolean = false;
	isLocationDataSetted: boolean = false;
	allowButton: boolean = false;
	allowDataButton: boolean = false;
	municipioSelected: any;
	localidadSelected: any;
	cuvSeleccionado: any;
	isUpdate: boolean = false;
	isLoading: boolean = false;
	fileUploadForm: UntypedFormGroup;
	datosOferente: UntypedFormGroup;
	datosPonderaciones: UntypedFormGroup;
	datosCuvs: UntypedFormGroup;
	verificadoresForm: UntypedFormGroup;
	datosPonderacionesCampos = new Array<any>();
	datosCuvsCampos: Array<Cuv> = [];
	datosConceptos: Array<any> = [];
	verificador: Array<any> = [];
	verificadorAsignado: any;
	cuvNameColumns: Array<string> = [];
	datosConceptosColumns: Array<any> = [];
	cuvNameColumnsDefault: Array<string> = [
		"CUV", 'ID', "Orden", "Calle", "# Ext.", "# Int.",
		'Colonia', "C.P.", "Lote", "Manzana", "S. Manzana",
		"Condominio", "Entrada", "Edificio", "Nivel", "Entre Calle",
		"Y Calle", "Otra Col.", "Costo Vivienda", "Costo Orden/Vivienda",
		"# Estacionamiento", "Departamento", "Casa", "Geolocalización", "Prototipo"
	];
	isFileReady: boolean = false;
	isFileUploaded: boolean = false;
	ordenVerificacion: Array<object> = [
		{ name: "ID Orden", key: 'id_orden' },
		{ name: "ID Oferente", key: "id_oferente" },
		{ name: "RFC", key: "rfc" },
		{ name: "Nombre", key: "nombre" },
		{ name: "Calle", key: "calle" },
		{ name: "Colonia", key: "colonia" },
		{ name: "Numero Exterior", key: "numero_ext" },
		{ name: "Codigo Postal", key: "codigo_postal" },
		{ name: "Telefono", key: "telefono" },
		{ name: "E-Mail", key: "e_mail" },
		{ name: "Nombre del Referente", key: "nombre_referente" },
		{ name: "Registro Patronal", key: "registro_patronal" }
	];

	@ViewChild('stepper') private myStepper: MatStepper;
	@ViewChild("fileDropRef", { static: false }) fileDropEl: ElementRef;
	@ViewChild('accordion') accordion: ElementRef;
	fileInputLabel: string;
	files: any[] = [];
	newProjectData: any = {}

	//verificador
	isVerificadorSelected: boolean = false;
	allVerificadores: Usuario[];

	isFormReady: boolean = false;

	constructor(
		private _formBuilder: UntypedFormBuilder,
		private projects: ProjectsService,
		private uploadFileService: UploadFileService,
		private formBuilder: UntypedFormBuilder,
		private usuariosService: UsuariosService,
		private cuvsService: cuvsService,
		private inegiService: InegiService,
		private activatedRoute: ActivatedRoute,
		public dialog: MatDialog
	) { }

	sidebarToggle(){
		var sidebar = document.getElementById("sidebar");
		sidebar.classList.add('active');
		var contenedor = document.getElementById("content");
		if(sidebar.classList.value == 'active'){
			contenedor.style.width = 'calc(100% - 80px )';
		}
	}

	openDialog() {
		const dialogRef = this.dialog.open(ModalCuvComponent,{
			data:{
				id_orden: this.activatedRoute.snapshot.queryParams["orden"],
				cuv: this.isUpdate == true ? this.cuvSeleccionado : null
			},
		});
		dialogRef.afterClosed().subscribe(result => {
			if (result) {
				this.myStepper.selectedIndex = 3;
				this.isUpdate = false;
				this.fillDatosOferente();
			}
		});
	}

	nuevoConcepto() {
		const dialogRef = this.dialog.open(ModalConceptoComponent,{
			data:{
				id_orden: this.activatedRoute.snapshot.queryParams["orden"]
			},
			width:'50vh'
		});
		dialogRef.afterClosed().subscribe(result => {
			if (result) {
				this.datos();
				this.myStepper.selectedIndex = 2;
			}
		});
	}

	ngOnInit(): void {
		this.datos();
	}

	datos() {
		this.fillDatosOferente();
		this.datosOferente = this._formBuilder.group({
			id_orden: new UntypedFormControl({value:"", disabled:true}),
			id_oferente: new UntypedFormControl(""),
			rfc: new UntypedFormControl(""),
			nombre: new UntypedFormControl(""),
			calle: new UntypedFormControl(""),
			colonia: new UntypedFormControl(""),
			numero_ext: new UntypedFormControl(""),
			codigo_postal: new UntypedFormControl(""),
			telefono: new UntypedFormControl(""),
			e_mail: new UntypedFormControl(""),
			nombre_referente: new UntypedFormControl(""),
			registro_patronal: new UntypedFormControl(""),
		});

		this.datosPonderaciones = this.formBuilder.group({
			control: this.formBuilder.array([])
		});

		this.datosCuvs = this.formBuilder.group({
			control1: new UntypedFormControl("")
		});

		this.verificadoresForm = new UntypedFormGroup({
			userVerificador: new UntypedFormControl("")
		});

		this.getLocalidades();
	}

	onFormSubmit(){
		let formData = new FormData();
		formData.append('import_file', this.files[0]);
		//loader true;
		this.uploadFileService.uploadFile(formData).subscribe(res => {
		//loader false
		this.myStepper.next();
		this.newProjectData = res;
		this.fillDatosOferente();
		},
		error => {
		//loader false
		});
	}

	fillDatosOferente(){
		this.idOrden = this.activatedRoute.snapshot.queryParams["orden"];
		this.uploadFileService.getProjectById(this.idOrden).subscribe(res => {
			this.isLoading = true;
			this.newProjectData = res[0];
			this.selectedVerif = res[0].datos_obra.id_verificador;
			this.datosOferente.patchValue({
				id_orden: this.newProjectData.id_orden,
				id_oferente: this.newProjectData.id_oferente,
				rfc: this.newProjectData.rfc,
				nombre: this.newProjectData.nombre_del_frente,
				calle: this.newProjectData.calle,
				colonia: this.newProjectData.colonia,
				numero_ext: this.newProjectData.numero_ext,
				codigo_postal: this.newProjectData.codigo_postal,
				telefono: this.newProjectData.telefono,
				e_mail: this.newProjectData.e_mail,
				nombre_referente: this.newProjectData.nombre_referente,
				registro_patronal: this.newProjectData.registro_patronal,
			});
			this.fillDatosPonderaciones();
			this.fillDatosCuvs();
			this.fillDatosConceptos();
			this.getAllVerificadores();
			this.isLoading = false;
			this.isLocationDataSetted = res[0].datos_obra.localidad === null ? true : false;
			if(this.isLocationDataSetted){
				Swal.fire({
					icon: 'warning',
					title: 'Datos ambiguos',
					text: 'Parece que la ubicación de la obra no se encontró, será necesario actualizarlos manualmente.',
					footer: 'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.'
				}).then((result) => {
					/* Read more about isConfirmed, isDenied below */
					if (result.isConfirmed) {
						this.allowDataButton = false;
						this.allowButton = false;
						this.sidebarToggle();
						this.accordion.nativeElement.scrollIntoView({
							behavior: 'auto',
							block: 'center',
							inline: 'center'});
						this.accordion.nativeElement.classList.add('focus');
					}
				})
			}else{
				this.estadoSelected = res[0].datos_obra.estado;
				this.isEstadoSelected = true;
				this.municipioSelected = res[0].datos_obra.municipio;
				this.isMunicipioSelected = true;
				this.localidadSelected = res[0].datos_obra.localidad;
				this.isLocalidadSelected = true;
				this.allowButton = true;
			}
		},
		error => {
			this.isLoading = false;
			console.error(error);
		}); 
	}
	
	fillDatosPonderaciones() {
		this.datosPonderacionesCampos = this.newProjectData.ponderaciones_partida;
		let sumaTotal = 0;
		const partidas = this.datosPonderaciones.get('control') as UntypedFormArray;
		this.datosPonderacionesCampos.forEach((element) => {
			partidas.push(
				this.formBuilder.group({
					ponderacion: (element.ponderacion * 100).toFixed(2) + '%',
					id_partida: element.id_partida
				})
			)
			sumaTotal += element.ponderacion * 1;
		});
		this.sumaTotal = (sumaTotal*100).toFixed(2);
	}

	fillDatosCuvs(){
		this.cuvNameColumns = Object.keys(this.newProjectData.cuvs[0]);
		this.cuvNameColumns.splice(-1,1);
		this.cuvNameColumns.splice(-1,1);
		this.datosCuvsCampos = this.newProjectData.cuvs;
	}

	fillDatosConceptos(){
		this.datosConceptos = this.newProjectData.ponderaciones;
	}

	controlConcepto(nombre){
		return nombre.replace('/\s/g', '_');
	}

	getAllVerificadores(){
		this.usuariosService.getVerificadoresService().subscribe(res=> {
		this.allVerificadores = res;
		},
		error => {
			Swal.fire({
				icon: 'error',
				title: 'Error...',
				text: 'Hubo un error al consultar verificadores!',
				footer: 'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.'
			})
		})
	}

	verificadorChange(event) {
		this.isVerificadorSelected = true;
		this.verificadorAsignado = event.value;
	}

	asignarVerificador(){
		let user = {
			id_orden: this.newProjectData.id_orden,
			id_verificador: this.verificadorAsignado
		};
		this.usuariosService.assignVerificadorObra(user).subscribe(res=> {
			res == 1 ? this.isFormReady = true : this.isFormReady = false;
			Swal.fire({
				icon: 'success',
				title: 'Guardado!',
				html:'¡Verificador asignado correctamente!<br/><br/>'+
				'<small>Se ha enviado un correo al verificador.</small>',
				footer: '<small>En caso de no ver el correo, verificar en la carpeta de <strong>Spam o Correo no deseado</strong></small>'
			});
		},
		error => {
			Swal.fire({
				icon: 'error',
				title: 'Error...',
				text: 'Hubo un error al asignar un Verificador!',
				footer: 'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.'
			})
		})
	}

	guardarCambiosOferente(form: any){
		let dirtyValues = {};

		Object.keys(form.controls).forEach(key => {
			let currentControl = form.controls[key];

			if (currentControl.dirty) {
				if (currentControl.controls)
					dirtyValues[key] = this.guardarCambiosOferente(currentControl);
				else
					dirtyValues[key] = currentControl.value;
			}
		});

		//service to update Oferentes
		if (Object.keys(dirtyValues).length > 0) {
			dirtyValues['id_orden'] = this.newProjectData.id_orden;
			this.uploadFileService.updateOferente(dirtyValues).subscribe(res => {
				Swal.fire(
				'Guardado!',
				'Verificador asignado correctamente',
				'success'
				);
			}, error =>{
				Swal.fire({
				icon: 'error',
				title: 'Error...',
				text: 'Error de comunicación!: ' + error,
				footer: 'Si el error persiste, favor de comunicarse con soporte'
				})
			});
		} else {
			Swal.fire(
				'¿Ha hecho algún cambio?',
				'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.',
				'question'
			);
		}
	}

	guardarCambiosPonderaciones(form: any) {
		let data = form;
		let datos = {};
		let partidas = [];

		Object.keys(data.controls).forEach(element => {
			data.controls.control.controls.forEach(element => {
				if (element.dirty) {
					element.value.ponderacion = element.value.ponderacion.replace("%", "");
					partidas.push(element.value);
				}
			});
		});
		
		datos['id_orden'] = this.newProjectData.id_orden;
		if (Object.keys(partidas).length > 0) {
			datos['partidas'] = partidas;
			this.uploadFileService.updatePonderaciones(datos).subscribe(res => {
				this.datos();
				Swal.fire(
					'Guardado!',
					'Verificador asignado correctamente',
					'success'
				);
			},
			error => {
				var errorF;
				(error.error.message) != null ? 
				errorF =  error.error.message : 
				errorF = 'Hubo un error al guardar el conepto!';
				Swal.fire({
					icon: 'error',
					title: 'Error...',
					text: errorF,
					footer: 'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.'
				});
			});
		} else {
			Swal.fire(
				'¿Ha hecho algún cambio?',
				'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.',
				'question'
			);
		}
	}

	guardarCambiosCuvs(form: any) {
		//pending to review
	}

	editCuv(cuv) {
		let Cuv = {};
		Cuv['cuv'] = cuv;
		this.cuvsService.editCuv(Cuv).subscribe(res => {
			this.cuvSeleccionado = res;
			this.isUpdate = true;
			this.openDialog();
		},
		error => {
			var errorF;
			(error.error.message) != null ? 
			errorF =  error.error.message : 
			errorF = 'Hubo un error al acceder al CUV';
			Swal.fire({
				icon: 'error',
				title: 'Error...',
				text: errorF,
				footer: 'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.'
			}).then((result) => {
				if (result.isConfirmed) {
				} 
			})
		});
	}
	
	deleteCuv(cuv){
		let Cuv = {};
		Cuv['cuv'] = cuv;
		Swal.fire({
			title: '¿Está seguro que desea eliminar este elemento?',
			html: 'Será eliminado permanentemente.',
			icon: 'question',
			showCancelButton: true,
			focusConfirm: false,
			confirmButtonText:
			  'Continuar',
			cancelButtonText:
			  'Cancelar',
		}).then((result) => {
			if (result.isConfirmed) {
				this.cuvsService.deleteCuv(Cuv).subscribe(res => {
					Swal.fire({
						icon: 'success',
						title: '¡Eliminado!',
						text: 'Cuv eliminado correctamente.'
					});/* .then((result) => {
						window.location.reload();
					}) */
				}, error => {
					var errorF;
					(error.error.message) != null ? 
					errorF =  error.error.message : 
					errorF = 'Hubo un error al acceder al CUV';
					Swal.fire({
						icon: 'error',
						title: 'Error...',
						text: errorF,
						footer: 'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.'
					});/* .then((result) => {
						if (result.isConfirmed) {
							window.location.reload();
						} 
					}) */
				});
			}
		});
	}

	getLocalidades(){
		this.inegiService.getEstados().subscribe(res => {
			this.estados = res;
		},
		error => {
			var errorF;
			(error.error.message) != null ? 
			errorF =  error.error.message : 
			errorF = 'Hubo un error al cargar los datos!';
			Swal.fire({
				icon: 'error',
				title: 'Error...',
				text: errorF,
				footer: 'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.'
			}).then((result) => {
				if (result.isConfirmed) {
					window.location.reload();
				} 
			})
		})
	}

	selectEstado(event){
		this.estadoSelected = event;
		this.municipios = null;
		this.isEstadoSelected = true;
		let datos = {};
		datos['estado'] = event;
		this.inegiService.getMunicipios(datos).subscribe(res => {
			this.municipios = res;
		},
		error => {
			var errorF;
			(error.error.message) != null ? 
			errorF =  error.error.message : 
			errorF = 'Hubo un error al cargar los datos!';
			Swal.fire({
				icon: 'error',
				title: 'Error...',
				text: errorF,
				footer: 'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.'
			}).then((result) => {
				if (result.isConfirmed) {
					window.location.reload();
				} 
			})
		})
	}

	selectMunicipio(event){
		this.municipioSelected = event;
		this.isMunicipioSelected = true;
		this.localidades = null;
		let datos = {};
		datos['municipio'] = event;
		this.inegiService.getLocalidades(datos).subscribe(res => {
			this.localidades = res;
		},
		error => {
			var errorF;
			(error.error.message) != null ? 
			errorF =  error.error.message : 
			errorF = 'Hubo un error al cargar los datos!';
			Swal.fire({
				icon: 'error',
				title: 'Error...',
				text: errorF,
				footer: 'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.'
			}).then((result) => {
				if (result.isConfirmed) {
					window.location.reload();
				} 
			})
		})
	}
	selectLocalidad(event){
		this.localidadSelected = event;
		this.isLocalidadSelected = true;
		if(this.isLocationDataSetted == false && this.isMunicipioSelected == true){
			this.allowDataButton = true;
		}else if(this.isLocationDataSetted == true && this.isMunicipioSelected == true){
			this.allowDataButton = true;
		}else{
			 this.allowDataButton = false;
		}
	}

	guardarDatosObra(){
		this.idOrden = this.activatedRoute.snapshot.queryParams["orden"];
		let datos = {};
		datos['id_orden'] = this.idOrden;
		datos['estado'] = this.estadoSelected.id;
		datos['municipio'] = this.municipioSelected.id;
		datos['localidad'] = this.localidadSelected.id;
		
		this.uploadFileService.updateDatosObra(datos).subscribe(res => {
			Swal.fire(
				'Actualizado!',
				'Orden actualizada correctamente',
				'success'
			);
		},
		error => {
			var errorF;
			(error.error.message) != null ? 
			errorF =  error.error.message : 
			errorF = 'Hubo un error al cargar los datos!';
			Swal.fire({
				icon: 'error',
				title: 'Error...',
				text: errorF,
				footer: 'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.'
			}).then((result) => {
				if (result.isConfirmed) {
					window.location.reload();
				} 
			})
		})
	}
}