<div class="container">
    <div class="section-title">
        <h2>Verificaciones</h2>
    </div>
    <div class="d-none"> {{idOrden}} </div>
    <div class="section-content">
        <form [formGroup]="verificacionesForm" enctype="multipart/form-data">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12 py-2">
                            <div class="row">
                                <div class="col-sm-12 col-md-6 col-lg-6">
                                    <mat-label for="manzana">Seleccionar Manzana</mat-label>
                                    <mat-select formControlName="manzana" (selectionChange)="manzanaSeleccionada($event)" #selectManzanas multiple>
                                        <mat-option [value]="0" (click)="selectAllManzanas()">Seleccionar Todas</mat-option>
                                        <mat-option (click)="optionManzanaClick()" *ngFor="let manzana of manzanas | keyvalue" [value]="manzana">
                                            Manzana: {{ manzana.value[0] }}
                                        </mat-option>
                                    </mat-select>
                                </div>
                                <div class="col-sm-12 col-md-6 col-lg-6">
                                    <mat-label for="lote">Seleccionar Lote</mat-label>
                                    <mat-select formControlName="lote" (selectionChange)="loteSeleccionado($event)" #selectLotes multiple>
                                        <mat-option [value]="0" (click)="selectAllLotes()">Seleccionar Todos</mat-option>
                                        <mat-option (click)="optionLoteClick()" *ngFor="let lote of lotes | keyvalue " [value]="lote">
                                            Manzana {{ lote.value[0].manzana }} Lote: {{ lote.value[0].lote }}
                                        </mat-option>
                                    </mat-select>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 py-2" style="border-bottom: 1px solid rgba(213,213,213,1);">
                            <mat-label for="cuv">Seleccionar CUV a verificar</mat-label>
                            <mat-select formControlName="cuv" (selectionChange)="cuvSeleccionada($event)" #selectCuvs multiple>
                                <mat-option [value]="0" (click)="selectAllCuvs()">Seleccionar Todos</mat-option>
                                <mat-option (click)="optionCuvClick()" *ngFor="let cuv of cuvs" [value]="cuv">
                                    <div *ngIf="cuv.numero_interior == '' || cuv.numero_interior == null ;else elseTwo">
                                        ID Vivienda: <strong>{{ cuv.id_vivienda }} </strong>
                                        - No. Ext: <strong>{{cuv.numero_exterior}}</strong>
                                        - <strong>{{cuv.prototipo.nombre}}</strong>
                                    </div>
                                    <ng-template #elseTwo>
                                        ID Vivienda: <strong>{{ cuv.id_vivienda }} </strong>
                                        - No. Int: <strong>{{cuv.numero_interior}}</strong>
                                        - No. Ext: <strong>{{cuv.numero_exterior}}</strong>
                                        - <strong>{{cuv.prototipo.nombre}}</strong>
                                    </ng-template>
                                    
                                </mat-option>
                            </mat-select>
                        </div>
                        <div class="col-12 py-2">
                            <mat-label for="concepto">Concepto</mat-label>
                            <mat-select [(ngModel)]="selectedConcept" formControlName="concepto" (selectionChange)="conceptoSeleccionado($event)">
                                <mat-optgroup *ngFor="let partida of conceptos" [label]="partida.partida.nombre"
                                    [disabled]="partida.disabled">
                                    <mat-option *ngFor="let concepto of partida.partida.conceptos" [value]="concepto">
                                        {{concepto.nombre}}
                                    </mat-option>
                                </mat-optgroup>
                            </mat-select>
                        </div>
                        <div class="col-12 py-2">
                            <mat-label for="concepto">Avance</mat-label>
                            <mat-select [(ngModel)]="selectedAvance" formControlName="avance" (selectionChange)="avanceSeleccionado($event)">
                                <mat-option *ngFor="let avance of avances" [value]="avance">
                                    {{avance}}
                                </mat-option>
                            </mat-select>
                        </div>

                        <div class="col-12 py-2" *ngIf="message != ''">
                            <div class="alert alert-warning" role="alert">
                                {{message}}
                            </div>
                        </div>
                        <div class="col-12 py-2">
                            <hr>
                            <div *ngIf="allowEmptyComment == true">
                                <div class="row">
                                    <div class="col">
                                        <div class="row">
                                            <div class="col"> 
                                                <mat-label for="concepto">
                                                    <h3>
                                                        Observaciones
                                                    </h3>
                                                </mat-label>
                                            </div>
                                            
                                            <div *ngIf="allowHistorial">
                                                <div class="col text-right"> 
                                                    <button class="btn btn-primary btn-sm" data-toggle="modal" data-target=".historialComentarios">
                                                        Historial de Comentarios
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <textarea
                                            class="form-control"
                                            matInput formControlName="comentario"
                                            placeholder="Comentario" [value]="comentario"
                                            (input)="settedComment($event.target.value)"
                                        ></textarea>
                                    </div>
                                </div>
                                <hr>
                                <div class="row">
                                    <div class="col">
                                        <div class="row">
                                            <div class="col"> 
                                                <mat-label for="cumplimiento">
                                                    <h3>
                                                        Cumplimiento (II) E ó C(*)
                                                    </h3>
                                                </mat-label>
                                            </div>
                                            <div class="col text-right">
                                                <button mat-icon-button data-toggle="modal" data-target="#cumplimientoInfo" aria-hidden="true">
                                                    <mat-icon>info</mat-icon>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-4 col-md-4 col-lg-4 py-2">

                                                <mat-select [(value)]="si" formControlName="si" class="form-control" placeholder="Cumple" 
                                                (selectionChange)="cumplimientoSi($event)">
                                                <mat-option (click)="resetSi()" [value]="">Sin Seleccionar</mat-option>
                                                    <mat-option *ngFor="let option of cumplimientoOpts" [value]="option">
                                                        {{option}}
                                                    </mat-option>
                                                </mat-select>
                                            </div>
                                            <div class="col-sm-4 col-md-4 col-lg-4 py-2">
                                                <mat-select [(value)]="no1" formControlName="no1" class="form-control" placeholder="No (1)"
                                                (selectionChange)="cumplimientoNo1($event)" [value]="">
                                                <mat-option (click)="resetNo1()">Sin Seleccionar</mat-option>
                                                    <mat-option *ngFor="let option of cumplimientoOpts" [value]="option">
                                                        {{option}}
                                                    </mat-option>
                                                </mat-select>
                                            </div>
                                            <div class="col-sm-4 col-md-4 col-lg-4 py-2">
                                                <mat-select [(value)]="no2" formControlName="no2" class="form-control" placeholder="No (2)"
                                                (selectionChange)="cumplimientoNo2($event)" [value]="">
                                                <mat-option (click)="resetNo2()">Sin Seleccionar</mat-option>
                                                    <mat-option *ngFor="let option of cumplimientoOpts" [value]="option">
                                                        {{option}}
                                                    </mat-option>
                                                </mat-select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ng-container *ngIf="allowEcos">
                            <div class="col-12 py-2">
                                <hr>
                                <div class="card">
                                    <div class="card-header" id="headingTwo">
                                        <h5 class="mb-0">
                                            <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                Ecotecnologías 
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                                        <div class="card-body">
                                            <div class="row">
                                                
                                                <div class="col-sm-12 col-md-9 col-lg-9 py-2">
                                                    <ng-select [items]="ecotecnias"
                                                        [(ngModel)]="ecotecniasSelected"
                                                        formControlName="ecotecnias"
                                                        bindLabel="ecotecnia"
                                                        bindValue="id"
                                                        multiple="true"
                                                        clearAllText="Deseleccionar todas"
                                                        [selectableGroupAsModel]="true"
                                                        #select
                                                    >
                                                    <ng-template ng-header-tmp>

                                                        <div>
                                                            <button class="btn btn-outline-dark btn-sm btn-block" (click)="onSelectAll();">{{ecotecniasToggle}}</button>
                                                        </div>
                                                    
                                                      </ng-template>
                                                        <ng-template ng-option-tmp let-item="item">
                                                            {{item.ecotecnia}}
                                                        </ng-template>
                                                    </ng-select>
                                                </div>
                                                <div class="col-sm-12 col-md-3 col-lg-3 py-2">
                                                    <ng-container *ngIf="userRole==1">
                                                        <button class="btn btn-primary btn-sm" data-toggle="modal"
                                                            data-target="#crearEcotecniaModal">
                                                            Crear Ecotecnia
                                                        </button>
                                                    </ng-container>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr>
                            </div>
                        </ng-container>
                        <div class="col-12 py-2">
                            <mat-label for="concepto">
                                <h3>
                                    Evidencias
                                </h3>
                            </mat-label>
                            <div>
                                <div id="accordion">
                                    <div class="card" *ngFor="let evidencia of evidencias; let i = index">
                                        <div class="card-header" [id]="'headings'+i">
                                            <h5 class="mb-0">
                                            <button class="btn btn-link" data-toggle="collapse" [attr.data-target]="'#collapses'+i" aria-expanded="false" aria-controls="collapses">
                                                Evidencia {{i+1}}
                                            </button>
                                            </h5>
                                        </div>
                                    
                                        <div [id]="'collapses'+i" class="collapse collapsed" [attr.aria-labelledby]="'headings'+i" data-parent="#accordion">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-12 py-2">
                                                        <img class="img-fluid" [src]="evidencias[i]" alt="">
                                                    </div>
                                                </div>
                                                <label for="Imagen"></label>
                                                <button class="btn btn-primary" (click)="focus()" data-toggle="modal" (click)="startCamera(i)" data-target="#takePhoto">Tomar Evidencia</button>
                                            </div>
                                        </div>
                                    </div>
                                    </div>                                    
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="submit" class="btn btn-primary" (click)="guardarVerificacion($event)" [disabled]="!(isConceptSelected && isCuvSelected && isAvanceSelected)">Confirmar</button>
                    <button type="button" class="btn btn-secondary" (click)="cancelarSubida()" data-dismiss="modal">Cancelar</button>
                </div>
            </div>
        </form>
    </div>
</div>
<div class="modal fade" id="cumplimientoInfo" tabindex="-1" role="dialog" aria-labelledby="cumplimientoInfoLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="modal-title" id="cumplimientoInfoLabel">
                    Información de Cumplimiento
                </h3>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                Utilizar la letra <strong>E</strong> para calificar la especificación del proyecto.<br><br>
                La letra <strong>P</strong> para calificar los documentos de calidad de los materiales y de los trabajos.<br><br>
                La letra <strong>C</strong> para calificar la calidad, con base a los criterios para la  evaluación de la calidad de la vivienda.<br>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade historialComentarios" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
            
            <div class="modal-header">
                <h3 class="modal-title" id="historialComentarios">
                    Historial de Comentarios
                </h3>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div *ngIf="avanceIndividual != null">
                    <table class="table">
                        <thead>
                          <tr>
                            <th scope="col">ID</th>
                            <th scope="col">Comentario</th>
                            <th scope="col">Cumple</th>
                            <th scope="col">No (1)</th>
                            <th scope="col">No (2)</th>
                          </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngIf="allowHistorial">
                                <ng-container *ngFor="let comentario of avanceIndividual[0].comentarios_verificaciones; let i = index">
                                    <tr>
                                        <td scope="row">
                                            <div class="col" *ngIf="comentario.comentario != null;else isNullID">
                                                {{ comentario.comentario.id }}
                                            </div>
                                            <ng-template #isNullID>
                                            </ng-template>
                                        </td>
                                        <td>
                                            <div class="col" *ngIf="comentario.comentario != null;else isNullC">
                                                {{comentario.comentario.comentario}}
                                            </div>
                                            <ng-template #isNullC>
                                            </ng-template>
                                        </td>
                                        <td>
                                            <div class="col" *ngIf='comentario.si != null;else isNullS'>
                                                {{ comentario.si }}
                                            </div>
                                            <ng-template #isNullS>
                                            </ng-template>
                                        </td>
                                        <td>
                                            <div class="col" *ngIf="comentario.no1 != null;else isNullN1">
                                                {{ comentario.no1 }}
                                            </div>
                                            <ng-template #isNullN1>
                                            </ng-template>
                                        </td>
                                        <td>
                                            <div class="col" *ngIf="comentario.no2 != null;else isNullN2">
                                                {{ comentario.no2 }}
                                            </div>
                                            <ng-template #isNullN2>
                                            </ng-template>
                                        </td>
                                    </tr>
                                </ng-container>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="crearEcotecniaModal" tabindex="-1" role="dialog" aria-labelledby="crearEcotecniaModal" data-backdrop="false"
	aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title" id="exampleModalLongTitle">
					Crear Ecotecnia
				</h5>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<form [formGroup]="ecoForm">
                    <div class="row">
                        <div class="col col-12">
                            <mat-form-field class="w-100">
                                <mat-label>Ecotecnia</mat-label>
                                <input required type="text" matInput (ngModelChange)="ecotecniaChange($event)" formControlName="ecotecnia">
                            </mat-form-field>
                        </div>
                    </div>
                </form>
			</div>
			<div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="storeEco(ecoForm)" [disabled]="!(allowButton)">
                    Guardar
                </button>
			</div>
		</div>
	</div>
</div>
  
<div class="modal fade align-items-center justify-content-center" id="takePhoto" tabindex="-1" role="dialog" aria-labelledby="takePhotoTitle" aria-hidden="false" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-dialog-centered" role="document">        
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" #closeBtn>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            <div class="modal-body">
                <div class="p-1 justify-content-center text-center">
                    <video #video class="vid img-fluid" autoplay playsinline></video>
                </div>
                <div class="pb-2 text-center d-none">
                    <canvas #canvas class="vid img-fluid d-none"></canvas>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-primary btn-sm align-center text-center" (click)="switchCamera()"><i class="material-icons">cameraswitch</i></button>
                <button class="btn btn-primary" (click)="capture()" data-dismiss="modal">Tomar foto</button>
                <button type="button" class="btn btn-secondary" (click)="cancelarSubida()" data-dismiss="modal">Cancelar</button>
            </div>
        </div>
    </div>
</div>
<app-loader [isLoading]="isLoading"></app-loader>