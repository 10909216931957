import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
    providedIn: 'root'
})
export class PruebasLaboratorioService {

    public headers: Headers;
    public requestOptions;

    constructor(private http: HttpClient) {
        httpOptions.headers.append("Authorization", "Bearer " + localStorage.getItem("SESSION"));
        this.requestOptions = {
          headers: {"Authorization": "Bearer " + localStorage.getItem("SESSION_TOKEN")}
        }
    }

    show(datos): Observable<any>{
        return this.http.post<any>(environment.api + "get-prueba", datos, this.requestOptions);
    }
    index(): Observable<any>{
        return this.http.get<any>(environment.api + "get-pruebas", this.requestOptions);
    }
    getMateriales(): Observable<any>{
        return this.http.get<any>(environment.api + "get-materiales", this.requestOptions);
    }
    store(datos): Observable<any>{
        return this.http.post<any>(environment.api + "store-prueba", datos, this.requestOptions);
    }
    update(): Observable<any>{
        return this.http.get<any>(environment.api + "update-prueba", this.requestOptions);
    }
    delete(): Observable<any>{
        return this.http.get<any>(environment.api + "delete-prueba", this.requestOptions);
    }

}