<h2 mat-dialog-title>Agregar Concepto</h2>
Orden: {{ idOrden }}
<hr>
<mat-dialog-content >
    <form [formGroup]="conceptoForm">
        <div class="form-group">
            <ng-select [items]="conceptos"
                bindLabel="nombre"
                bindValue="id_concepto"
                formControlName="id_concepto"
                groupBy="conceptos"
                #select (change)="onChange($event)"
            >
                <ng-template ng-optgroup-tmp let-item="item">
                    {{item.nombre}}
                </ng-template>
                <ng-template ng-option-tmp let-item="item">
                    {{item.nombre}}
                </ng-template>
            </ng-select>
        </div>
        <div class="row">
            <div class="col col-12">
                <mat-form-field class="w-100">
                    <mat-label>Ponderación</mat-label>
                    <input type="number" max="100" min="1" step="1" value="1" matInput formControlName="ponderacion">
                </mat-form-field>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col center boton">
                <button class="btn btn-primary col-6" mat-raised-button (click)="onConfirmClick(conceptoForm)" tabindex="1">Guardar</button>
                <button class="btn btn-danger active col-6" mat-raised-button mat-dialog-close tabindex="-1">Cancelar</button>
            </div>
        </div>
    </form>
</mat-dialog-content>