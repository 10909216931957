import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { UploadFileService } from 'src/app/services/fileUpload.service';
import { ProjectsService } from 'src/app/services/projects.service';
import { ReporteService } from 'src/app/services/reporte.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';

interface TipoDocumento {
	value: string;
	viewValue: string;
}

@Component({
	selector: 'app-revision-documental',
	templateUrl: './revision-documental.component.html',
	styleUrls: ['./revision-documental.component.scss'],
	animations: [
		trigger('detailExpand', [
			state('collapsed', style({ height: '0px', minHeight: '0' })),
			state('expanded', style({ height: '*' })),
			transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
		]),
	]
})

export class RevisionDocumentalComponent implements OnInit {

	public catDocumentos: Array<any> = [];
	public displayedColumns = ['nombre', 'indicaciones', 'upload'];
	public displayedInternalColumns: Array<any> = ["comentarios"];
	public expandedElement: any | null;
	public files: any[] = [];
	fileUploadForm: UntypedFormGroup;
	public idOrden: any = "";
	public categoryToUploadSelected: any = {};
	public listaIndicaciones: any;
	public isLoading: boolean = false;

	constructor(
		private projectsService: ProjectsService,
		private reporteService: ReporteService,
		private activatedRoute: ActivatedRoute,
		private changeDetectorRefs: ChangeDetectorRef,
		private formBuilder: UntypedFormBuilder
	) { }

	ngOnInit(): void {
		this.fileUploadForm = this.formBuilder.group({
			import_file: new UntypedFormControl("")
		});
		this.getCatDocumentos();
	}

	getCatDocumentos() {
		this.projectsService.getCategoriasRevisionDocumental().subscribe(res => {
			this.isLoading = true;
			this.catDocumentos = res;
			this.catDocumentos.some(i => {
				i['docs'] = [];
				i['indicaciones'] = [];
			});
			this.getAllDocsByOrdenId();
			this.getAllIndicaciones();
			this.isLoading = false;
		}, error => {
			this.isLoading = false;
			console.error(error);
		});
	}

	getAllDocsByOrdenId() {
		let idOrden: object = {};
		idOrden['id_orden'] = this.activatedRoute.snapshot.queryParams["orden"];
		this.projectsService.getDocsByOrdenId(idOrden).subscribe(res => {
			this.isLoading = true;
			for (let elem of res) {
				this.catDocumentos.some(i => {
					if (i.id_tipo == elem.id_tipo) {
						i['docs'].push(elem);
					}
				});
			}
			this.changeDetectorRefs.detectChanges();
			this.isLoading = false;
		}, error => {
			this.isLoading = false;
			console.error(error);
		});
	}

	prepararArchivo(files: Array<any>) {
		for (const item of files) {
			item.progress = 0;
			this.files.push(item);
		}
	}

	currentCategory(category: any) {
		this.categoryToUploadSelected = category;
	}

	subirArchivo(event) {
		//get id_orden value param
		this.idOrden = this.activatedRoute.snapshot.queryParams["orden"];
		let formData = new FormData();
		event.currentTarget.closest('.modal').classList.remove('show');
		const elem = event;
		if (this.files.length > 0) {
			formData.append('import_file', this.files[0]);
			formData.append('id_orden', this.idOrden);
			formData.append('id_tipo', this.categoryToUploadSelected.id_tipo);
			formData.append('tipo_documento', this.categoryToUploadSelected.nombre.normalize('NFD').replace(/[\u0300-\u036f]/g,"").toLowerCase().replace(/\s/g, '-'));
			let filename = this.files[0].name.split('.');
			formData.append('nombre_archivo', (Math.random() + 1).toString(36).substring(2)+"."+filename[filename.length-1]);
			if(filename[filename.length-1] == "png" || filename[filename.length-1] == "jpg" || filename[filename.length-1] == "pdf"){
				this.projectsService.uploadDocRevision(formData).subscribe(res => {
					this.isLoading = true;
					this.ngOnInit();
					Swal.fire(
						'Guardado!',
						'Archivo guardado correctamente',
						'success'
					);/* .then((result) => {
						if (result.isConfirmed) {
							window.location.reload();
						}
					}) */
				}, (error: any) => {
					this.isLoading = false;
					console.error(error);
				});
			}else{
				Swal.fire({
					title: 'Tipo de archivo incorrecto',
					text: "Los tipos de archivos admitidos son .jpg, .png o pdf",
					icon: 'warning',
					confirmButtonColor: '#3085d6',
					confirmButtonText: 'Ok'
				});/* .then((result) => {
					window.location.reload();
				}) */
			}
		}
	}

	cancelarSubida() {

	}

	cambioEstado(element, event) {
		let status: any = element;
		event.checked ? status.status = 1 : status.status = 0;
		this.projectsService.changeStatusDocRev(status).subscribe(res => {
			this.changeDetectorRefs.detectChanges();

			Swal.fire(
				'Validado',
				'Archivo validado correctamente',
				'success'
			)
		}, error => {
			console.error(error);

			Swal.fire({
				icon: 'error',
				title: 'Error...',
				text: 'Hubo un error al validar el archivo!',
				footer: 'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.'
			})
		});
	}

	public getAllIndicaciones() {
		let idOrden: object = {};
		this.projectsService.getAllIndicaciones().subscribe(res => {
			for (let elem of res) {
				this.catDocumentos.some(i => {
					if (i.id_tipo == elem.id_tipo) {
						i['indicaciones'].push(elem);
					}
				});
			}
			this.changeDetectorRefs.detectChanges();
		}, error => {
			console.error(error);
		});
	}

	public mostrarIndicaciones(element: any) {
		this.listaIndicaciones = element.indicaciones;
	}

	guardarComentarios(element, event) {
		element.comentarios = event.currentTarget.previousElementSibling.value;
		this.projectsService.changeCommentDocRev(element).subscribe(res => {
			this.changeDetectorRefs.detectChanges();
			Swal.fire(
				'Guardado!',
				'Comentario guardado correctamente',
				'success'
			)
		}, error => {
			console.error(error);
			Swal.fire({
				icon: 'error',
				title: 'Error...',
				text: 'Hubo un error al guardar el comentario!',
				footer: 'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.'
			})
		});
	}

	descargarDocumento(path) {
		let datos = {};
		datos['url'] = path.replace('/storage/','');
		let nombre = path.split("/");
		let nombreArchivo = nombre[nombre.length-1];
		let mime = nombre[nombre.length-1].split(".");
		let mimeArchivo = mime[mime.length-1];
		datos['nombre'] = nombreArchivo;
		this.reporteService.descargarArchivoReporte(datos).subscribe(res => {
			if (res[1] == null) {
				Swal.fire(
					'Reporte No disponible',
					'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.',
					'question'
				)
			} else {
				let mime = res[0].substr(res[0].indexOf('.'));
				var a = document.createElement("a"); //Create <a>
				if (mime == '.png') {
					a.href = "data:image/png;base64," + res[1]; //Image Base64 Goes here
				}
				if(mime == '.jpg'){
					a.href = "data:image/jpg;base64," + res[1]; //Image Base64 Goes here
				}
				if (mime == '.pdf') {
					a.href = "data:application/pdf;base64," + res[1]; //Image Base64 Goes here
				}
				a.download = res[0]; //File name Here
				a.click(); //Downloaded file
			}
		}, error => {
			//loader false
			var errorF;
			(error.error.message) != null ?
				errorF = error.error.message :
				errorF = 'Hubo un error al descargar el reporte!';
			Swal.fire({
				icon: 'error',
				title: 'Error...',
				text: errorF,
				footer: 'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.'
			}).then((result) => {
				if (result.isConfirmed) {
					window.history.back();
				}
			});
		});
	}
}
