import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/AuthService.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public loginForm: UntypedFormGroup;
  public loading: boolean = false;
  public submitted: boolean = false;
  public returnUrl: string;
  public error: string = '';
  public authValid: boolean = true;
  public isLoading: boolean = false;
  public roleAs: string;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: '',
      password:'',
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  onSubmit(){
    let formData: any = {};
    this.isLoading = true;
    formData.email = this.loginForm.value.email;
    formData.password = this.loginForm.value.password;
    formData.id_usuario = this.loginForm.value.id_usuario;
    formData.remember_me = true;
    this.authService.login(formData).subscribe(res=>{
      localStorage.setItem('STATE', 'true');
      localStorage.setItem('SESSION_TOKEN', res.access_token);
      localStorage.setItem('SESSION_EXPIRES', res.expires_at);
      localStorage.setItem('SESSION_ROLE', res.id_rol);
      localStorage.setItem('SESSION_USER_ID', res.id_usuario);
      localStorage.setItem('SESSION_USER', res.nombre);
      localStorage.setItem('SESSION_USER_APELLIDO', res.primer_apellido);
      localStorage.setItem('SESSION_USER_APELLIDO2', res.segundo_apellido);
      this.router.navigate(['/']);
      this.isLoading = false;
    }, error => {
      this.isLoading = false;
      console.error(error);
    });
  }

}
