import { Component, OnInit } from '@angular/core';
// import { report } from 'process';
import Swal from 'sweetalert2';
import { ActivatedRoute } from '@angular/router';
import { ReporteService } from 'src/app/services/reporte.service';

@Component({
	selector: 'app-reportes-disponibles',
	templateUrl: './reportes-disponibles.component.html',
	styleUrls: ['./reportes-disponibles.component.scss']
})
export class ReportesDisponiblesComponent implements OnInit {
	public isLoading: boolean = false;
	public idOrden: any = this.activatedRoute.snapshot.queryParams["orden"];
	public reporteSeleccionado: any;
	public userId: any;
	public userRole: any;
	public reportes: Array<any> = [
		{
			'reporte': 'Reporte Grafico De Avance De Obra',
			'link': 'reporteGraficoAvances'
		},
		{
			'reporte': 'Reporte Fotografico (Edificación)',
			'link': 'reporteEdificacion'
		},
		{
			'reporte': 'Reporte Fotografico (Urbanización)',
			'link': 'reporteUrbanizacion'
		},
		{
			'reporte': 'Reporte Fotografico (Infraestructura)',
			'link': 'reporteInfraestructura'
		},
		{
			'reporte': 'Reporte Caratula de Obra',
			'link': 'reporteCaratula'
		},
		{
			'reporte': 'Evaluación de Calidad',
			'link': 'evaluacionCalidad'
		},
		{
			'reporte': 'Cumplimiento de Calidad',
			'link': 'cumplimientoCalidad'
		},
		{
			'reporte': 'Calculo de Avance Ponderado',
			'link': 'null'
		},
	];

	constructor(
		private activatedRoute: ActivatedRoute,
		private reporteService: ReporteService
	) { }

	ngOnInit(): void {
		this.getReportesDisponibles();
		this.userId = localStorage.getItem('SESSION_USER_ID');
		this.userRole = localStorage.getItem('SESSION_ROLE');
		if (this.idOrden == null) {
			this.getOrdenRelacionada();
		}
	}

	getReportesDisponibles() {
		this.isLoading = false;
	}

	getOrdenRelacionada() {
		let datos = {};
		datos['id_user'] = this.userId;
		datos['role_user'] = this.userId;

		this.reporteService.getOrdenRelacionada(datos).subscribe(res => {
			this.idOrden = res;
			const urlParams = new URLSearchParams(window.location.search);
			urlParams.set('orden', res.id_orden);
			window.location.search = urlParams.toString();
		}, error => {
			//loader false
			var errorF;
			(error.error.message) != null ?
				errorF = error.error.message :
				errorF = 'Hubo un error al cargar la Orden!';
			Swal.fire({
				icon: 'error',
				title: 'Error...',
				text: errorF,
				footer: 'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.'
			}).then((result) => {
				if (result.isConfirmed) {
					window.history.back();
				}
			});
		});
	}
}
