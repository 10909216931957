<section class="animate__animated animate__fadeIn d-flex align-items-center">
    <div class="container">
        <ng-container *ngIf="idOrden != null">

            <ng-container *ngIf="method === 'form'">
                <h2  class="ng-tns-c193-0">Orden Actual: {{ idOrden }}</h2>
                <div class="card">
                    <div class="card-body">
                        <h3 class="card-title">Generar DTU</h3>
                        <p class="card-text">CUV's con avance al 100%.</p>
                        <div class="row">
                            <div class="col">
                                <ng-select [items]="cuvs"
                                    (change)="cuvChange($event)"
                                    bindLabel="cuv"
                                    bindValue="id_vivienda"
                                    multiple="true"
                                    clearAllText="Deseleccionar todas"
                                    [selectableGroupAsModel]="true"
                                    #select
                                >
                                    <ng-template ng-option-tmp let-item="item">
                                        ID Vivienda: <strong>{{ item.id_vivienda }} </strong>
                                        - CUV: <strong>{{item.cuv}}</strong>
                                        - Lote: <strong>{{item.lote}}</strong>
                                        - Manzana: <strong>{{item.manzana}}</strong>
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col">
                                <button class="btn btn-sm btn-primary" (click)="setDtu()" [disabled]="!(allowButton)">Generar DTU</button>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            
            <ng-container *ngIf="method === 'index'">
                <h2  class="ng-tns-c193-0">Orden Actual: {{ idOrden }}</h2>
                <div class="card">
                    <div class="card-body">
                        <h3 class="card-title">Listado DTU's</h3>
                        <div class="row">
                            <div class="col">
                                <mat-form-field>
                                    <mat-label>Filtro</mat-label>
                                    <input matInput (keyup)="applyFilter($event)" placeholder="ej. Regadera Ahorradora	" #input>
                                </mat-form-field>
                            </div>
                        </div>
                        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" multiTemplateDataRows>
            
                            <!-- Position Column -->
                            <ng-container matColumnDef="id">
                                <th mat-header-cell *matHeaderCellDef> ID </th>
                                <td mat-cell *matCellDef="let element"> {{element.id}} </td>
                            </ng-container>
            
                            <!-- Name Column -->
                            <ng-container matColumnDef="id_orden">
                                <th mat-header-cell *matHeaderCellDef> Orden </th>
                                <td mat-cell *matCellDef="let element"> {{element.id_orden}} </td>
                            </ng-container>
            
                            <!-- Name Column -->
                            <ng-container matColumnDef="created_at">
                                <th mat-header-cell *matHeaderCellDef> Fecha de Creación </th>
                                <td mat-cell *matCellDef="let element"> {{element.created_at | date: 'dd/MM/yyyy hh:mm a'}} </td>
                            </ng-container>
            
                            <!-- Name Column -->
                            <ng-container matColumnDef="updated_at">
                                <th mat-header-cell *matHeaderCellDef> Fecha de Modificación </th>
                                <td mat-cell *matCellDef="let element"> {{element.updated_at | date: 'dd/MM/yyyy hh:mm a'}} </td>
                            </ng-container>

                            <!-- Name Column -->
                            <ng-container matColumnDef="acciones">
                                <th mat-header-cell *matHeaderCellDef> Acciones </th>
                                <td mat-cell *matCellDef="let element">
                                    <a [routerLink]="['/show-dtu']" 
                                        [queryParams]="{method: 'show', orden: idOrden, dtu: element.id}">
                                        <mat-icon class="pointer" title="Abrir DTU">open_in_new</mat-icon> 
                                    </a>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="expandedDetail">
                                <td mat-cell *matCellDef="let element" [attr.colspan]="subColumnsToDisplay.length+1">
                                    <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                                        <div class="container" *ngIf="element.cuvs">
                                            <div class="row">
                                                <div class="col">
                                                    <strong>ID Vivienda</strong>
                                                </div>
                                                <div class="col">
                                                    <strong>Cuv</strong>
                                                </div>
                                                <div class="col">
                                                    <strong>Lote</strong>
                                                </div>
                                                <div class="col">
                                                    <strong>Manzana</strong>
                                                </div>
                                            </div>
                                            <div class="example-element-description row underline py-3" *ngFor="let cuv of element.cuvs">
                                                <div class="col">{{ cuv.id_vivienda }}</div>
                                                <div class="col">{{ cuv.cuv }}</div>
                                                <div class="col">{{ cuv.lote }}</div>
                                                <div class="col">{{ cuv.manzana }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </ng-container>
                            
                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let element; columns: displayedColumns;"
                                class="example-element-row"
                                [class.example-expanded-row]="expandedElement === element"
                                (click)="expandedElement = expandedElement === element ? null : element">
                            </tr>
                            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
            
                            <!-- Row shown when there is no matching data. -->
                            <tr class="mat-row" *matNoDataRow>
                                <td class="mat-cell" colspan="4">No hay resultados para: "{{input.value}}"</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </ng-container>
            
            <ng-container *ngIf="method === 'show'">
                <div id="wraper_tabla_reporte" class="table-responsive">
                    <mat-tab-group>
                        <!--***********-->
                        <!--DTU ANVERSO-->
                        <!--***********-->
                        <mat-tab label="DICTAMEN TECNICO UNICO ANVERSO">
                            <table id="tabla_reporte_p" class="table table-bordered table-sm"> <!--29 COLUMNAS-->
                                <tbody>
                                    <!--SEPARADOR--><tr><td colspan="8" class="separador"></td></tr><!--SEPARADOR-->
                                    <tr>
                                        <td id="logo_ISO" class="contenido" colspan="1">
                                            <img class="logo" src="../../../assets/img/iso.png" alt="Logo Calidad ISO" />
                                        </td>
                                        <td id="titulo_ruv" colspan="7">
                                            <table>
                                                <tr>
                                                    REGISTRO ÚNICO DE VIVIENDA
                                                </tr>
                                                <tr><br></tr>
                                                <tr><br></tr>
                                                <tr><br></tr>
                                                <tr><br></tr>
                                                <tr id="titulo_reporte" class="">
                                                    DICTAMEN TECNICO ÚNICO ANVERSO
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                    <!--SEPARADOR--><tr><td colspan="8" class="separador"></td></tr><!--SEPARADOR-->
                                    <tr>
                                        <td class="contenido" colspan="3">INFORMACIÓN DE LA OFERTA</td>
                                        <td class="contenido" colspan="1"></td>
                                        <td class="contenido" colspan="1"></td>
                                        <td class="contenido" colspan="1">Dictamen Núm.</td>
                                        <td class="contenido" colspan="1">
                                            <strong>
                                                # {{dtu.id}}
                                            </strong>
                                        </td>
                                        <td class="contenido" colspan="1">Nombre?</td>
                                    </tr>
                                    <!--SEPARADOR--><tr><td colspan="8" class="separador"></td></tr><!--SEPARADOR-->
                                    <tr>
                                        <td class="contenido" colspan="1">DELEGACIÓN</td>
                                        <td class="contenido" colspan="3">
                                            <strong>
                                                {{datosObra.municipio.nombre}}
                                            </strong>
                                        </td>
                                        <td class="contenido" colspan="2">N° VIVS. DE LA OFERTA</td>
                                        <td class="contenido" colspan="2">
                                            <strong>
                                                {{orden.cuvs_count}}
                                            </strong>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="contenido" colspan="2">N° DE REGISTRO</td>
                                        <td class="contenido" colspan="2">
                                            <strong>
                                                {{orden.registro_patronal}}
                                            </strong>
                                        </td>
                                        <td class="contenido" colspan="1">NOMBRE</td>
                                        <td class="contenido" colspan="3">
                                            <strong>
                                                {{orden.nombre}}
                                            </strong>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="contenido" colspan="1">UBICACIÓN</td>
                                        <td class="contenido" colspan="7">
                                            <strong>
                                                Calle: {{orden.calle}}, Colonia: {{orden.colonia}}
                                            </strong>
                                        </td>
                                    </tr>
                                    <tr>
                                        <!-- <td colspan="8">
                                            <table>
                                                <tbody>
                                                    <tr> -->
                                                        <td class="contenido" colspan="1">C.P.</td>
                                                        <td class="contenido" colspan="1">
                                                            <strong>{{orden.codigo_postal}}</strong>
                                                        </td>
                                                        <td class="contenido" colspan="1">LOCALIDAD</td>
                                                        <td class="contenido" colspan="2">
                                                            <strong>
                                                                {{datosObra.localidad.nombre}}
                                                            </strong>
                                                        </td>
                                                        <td class="contenido" colspan="1">ESTADO</td>
                                                        <td class="contenido" colspan="2">
                                                            <strong>
                                                                {{datosObra.estado.nombre}}
                                                            </strong>
                                                        </td>
                                                    <!-- </tr>
                                                </tbody>
                                            </table>
                                        </td> -->
                                    </tr>
                                    <!--SEPARADOR--><tr><td colspan="8" class="separador"></td></tr><!--SEPARADOR-->
                                    <tr>
                                        <td class="contenido" colspan="2">OFERENTE CONSTRUCTOR</td>
                                        <td class="contenido" colspan="6">
                                            <strong>
                                                {{orden.nombre_del_frente}}
                                            </strong>
                                        </td>
                                    </tr>
                                    <!-- ************ -->
                                    <!-- FIN CABEZERA -->
                                    <!-- ************ -->
                                    <!--SEPARADOR--><tr><td colspan="8" class="separador"></td></tr><!--SEPARADOR-->
                                    <tr>
                                        <td colspan="8">INFORMACIÓN DE LA DICTAMINACIÓN</td>
                                    </tr>
                                    <tr>
                                        <td class="contenido" colspan="1">FECHA DE NOTA DE BITACORA</td>
                                        <td class="contenido" colspan="1">NUM. NOTA DE BITACORA</td>
                                        <td class="contenido" colspan="1">TOTAL VIVS. DICTAMINADAS</td>
                                        <td class="contenido" colspan="1">NUM VIVS. DICT. A SOL. OFNTE.</td>
                                        <td class="contenido b-green" colspan="1">NUM VIVS. ECOLOGICAS</td>
                                        <td class="contenido" colspan="1"></td>
                                        <td class="small" colspan="1">ACUMULADOS</td>
                                    </tr>
                                    <tr>
                                        <td class="small" colspan="1">N° DE REGISTRO</td>
                                        <td class="small" colspan="1">#NUMERO</td>
                                        <td class="small" colspan="1">{{dtu.cuvs.length}}</td>
                                        <td class="small" colspan="1">NOMBRE</td>
                                        <td class="small b-green" colspan="1">{{cuvsECO}}</td>
                                        <td class="small" colspan="1"></td>
                                        <td class="small" colspan="1"></td>
                                        <td class="small" colspan="1"></td>
                                    </tr>
                                    <!--SEPARADOR--><tr><td colspan="8" class="separador"></td></tr><!--SEPARADOR-->
                                    <!-- <form [formGroup]="conceptosForm" enctype="multipart/form-data"> -->
                                        <tr>
                                            <td colspan="8">I. CONCEPTOS DICTAMINADOS</td>
                                        </tr>
                                        <ng-container *ngFor="let concepto of conceptosDictaminados">
                                            <tr>
                                                <td class="small" colspan="7" [innerHTML]="'<strong>'+concepto.index+'</strong>'+'.  '+concepto.concepto"></td>
                                                <td class="small" colspan="1">
                                                    
                                                    <select style="width:100%;" [value]="concepto.pivot.opcion" (change)="conceptoChange(concepto.id, $event)">
                                                        <option value="1">EJECUTADO</option>
                                                        <option value="0">NO EJECUTADO</option>
                                                        <option value="2">NO APLICA</option>
                                                    </select>
                                                </td>
                                            </tr>
                                        </ng-container>
                                        <!--SEPARADOR--><tr><td colspan="8" class="separador"></td></tr><!--SEPARADOR-->
                                        <tr>
                                            <td colspan="8">
                                                <div class="alert alert-warning l-red text-center margin-0" role="alert">
                                                    <h3>
                                                        PARA VIVIENDAS EN PAQUETES DE LINEA III Y LINEA III INDIVIDUAL, TODOS LOS CONCEPTOS DEBEN ESTAR EJECUTADOS Y LA VIVIENDA TERMINADA AL 100%.
                                                    </h3>
                                                </div>
                                            </td>
                                        </tr>
                                        <!--SEPARADOR--><tr><td colspan="8" class="separador"></td></tr><!--SEPARADOR-->
                                        <tr>
                                            <td class="b-green" colspan="8">
                                                II. ECOTECNOLOGIAS  DICTAMINADAS
                                            </td>
                                        </tr>
                                        <ng-container *ngIf="ecotecnologias.length > 0">

                                            <tr>
                                                <td colspan="6">
                                                    
                                                </td>
                                                <td class="text-center small" colspan="1">
                                                    <strong>POR EJECUTAR</strong>
                                                </td>
                                                <td class="text-center small" colspan="1">
                                                    <strong>NUM VIVS</strong>
                                                </td>
                                            </tr>
                                            <ng-container *ngFor="let ecotecnia of ecotecnologias">
                                                <tr>
                                                    <td class="small" colspan="6" [innerHTML]="'<strong>'+ecotecnia.index+'</strong>'+'.  '+ecotecnia.concepto"></td>
                                                    <td class="small red-border" colspan="1">
                                                        <select style="width:100%;" [value]="ecotecnia.pivot.opcion" (change)="conceptoChange(ecotecnia.id, $event)">
                                                            <option value="1">EJECUTADO</option>
                                                            <option value="0">NO EJECUTADO</option>
                                                            <option value="2">NO APLICA</option>
                                                        </select>
                                                    </td>
                                                    <td class="small text-center yellow-border" colspan="1">
                                                        {{cuvs.length}}
                                                    </td>
                                                </tr>
                                            </ng-container>
                                        </ng-container>
                                        <!--SEPARADOR--><tr><td colspan="8" class="separador"></td></tr><!--SEPARADOR-->
                                        <tr>
                                            <td class="" colspan="6">
                                                III. CONCEPTOS POR  EJECUTAR (Nota 8)
                                            </td>
                                            <td class="text-center small" colspan="1">
                                                <strong>SI</strong>
                                            </td>
                                            <td class="text-center small" colspan="1">
                                                <strong>NO</strong>
                                            </td>
                                        </tr>
                                        <ng-container *ngFor="let conceptoPE of conceptosPorEjecutar">
                                            <tr>
                                                <td class="small" colspan="6" [innerHTML]="'<strong>'+conceptoPE.index+'</strong>'+'.  '+conceptoPE.concepto"></td>
                                                <td class="small red-border text-center" colspan="1">
                                                    <select style="width:100%;" [value]="conceptoPE.pivot.opcion" (change)="conceptoChange(conceptoPE.id, $event)">
                                                        <option value="0">X</option>
                                                        <option value="2">NO APLICA</option>
                                                    </select>
                                                </td>
                                                <td class="small text-center" colspan="1">
                                                    <select style="width:100%;" [value]="conceptoPE.pivot.opcion" (change)="conceptoChange(conceptoPE.id, $event)">
                                                        <option value="1">X</option>
                                                        <option value="2">NO APLICA</option>
                                                    </select>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    <!-- </form> -->
                                    <!--SEPARADOR--><tr><td colspan="8" class="separador"></td></tr><!--SEPARADOR-->
                                    <tr>
                                        <td class="l-blue" colspan="8">
                                            (**) ES COMPROMISO DEL OFERENTE QUE LOS CONCEPTOS MARCADOS "SI" SEAN EJECUTADOS ANTES DE LA ENTREGA Y PAGO DE LA VIVIENDA.
                                        </td>
                                    </tr>
                                    <!--SEPARADOR--><tr><td colspan="8" class="separador"></td></tr><!--SEPARADOR-->
                                    <tr>
                                        <td colspan="8">
                                            NOTAS:
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="small notas-size-content" colspan="8">
                                            (1) En los casos que el dictamen no ampare viviendas con ecotecnologías la sección II será cancelada cruzandola con una diagonal.<br>
                                            (2) Se consideran viviendas terminadas, aquellas que cuentan con urbanización y los servicios de agua potable, drenaje y energía eléctrica conectados y en operación y pendiente o no la colocación o aplicación de los conceptos indicados en la sección III. ES COMPROMISO DEL OFERENTE QUE LOS CONCEPTOS MARCADOS "SI" SEAN EJECUTADOS ANTES DE LA ENTREGA Y PAGO DE LA VIVIENDA.<br>
                                            (3) EN SU CASO, LAS VARIACIONES EN LA RECEPCIÓN Y/O MUNICIPALIZACIÓN DE LOS SERVI CIOS ES CONFORME SE DETALLA EN LAS POLITICAS Y ESPECIFICACIONES DEL PROCEDIMIENTO.<br>
                                            (4) PARA VALIDAR EL CUMPLIMIENTO DE ESTAS ECOTECNOLOCIAS SE DEBEN APLICAR LAS NORMAS Y/O DISPOCIONES ESTABLECIDAS EN EL MANUAL EXPLICATIVO DEL PROGRAMA DE HIPOTECAS VERDES  SUBSIDIO 2009.<br>
                                            (5) Las eco tecnólogias de la 1 a la 7 y la 8.a, son sujetas a robo o vandalismo, por lo cual se verificará que los equipos o insumos para su instalación se encuentren en bodega en número igual al de viviendas que ampara el dictamen. El Desarrollador o Constructor, entregará el original de la carta responsiva que le compromete a ejecutarlas o instalarlas a la entrega de la vivienda.<br>
                                            (7) Este concepto  no es sujeto de verificación.<br>
                                            (8) Estos conceptos se consideran sujetos a robo o vandalismo, puede quedar o no,  pendiente su colocación o aplicación. ES COMPROMISO DEL OFERENTE QUE LOS CONCEPTOS MARCADOS "SI" SEAN EJECUTADOS ANTES DE LA ENTREGA Y PAGO DE LA VIVIENDA. Estos conceptos son aunados a los que corresponden a las viviendas con eco tecnólogías.<br>
                                        </td>
                                    </tr>
                                    <!-- ************* -->
                                    <!-- FIN CONTENIDO -->
                                    <!-- ************* -->
                                    <tr>
                                        <td class="text-center" colspan="8">
                                            <h3>
                                                SE CERTIFICA QUE LAS VIVIENDAS DESCRITAS EN ESTE DOCUMENTO, CUENTAN CON TODOS LOS SERVICIOS, ESTAN TERMINADAS Y EN CONDICIONES DE SER HABITADAS.
                                            </h3>
                                            <h4 class="mb-0">
                                                EMPRESA DE VERIFICACIÓN
                                            </h4>
                                            <h2>
                                                {{datosObra.constructora}}
                                            </h2>
                                            <br>
                                            <br>
                                            <div>
                                                <div class="comb z-999">
                                                    {{verificador.nombre}} {{verificador.primer_apellido}} {{verificador.segundo_apellido}}
                                                </div>
                                                <div class="comb" style="margin-top: 4px;">
                                                    ________________________________________________________________________________________________
                                                </div>
                                            </div>
                                            <br>
                                            <br>
                                            Administrador Único
                                            <br>
                                            CED. PROF. <strong>{{verificador.cedula}}</strong>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </mat-tab>
                        <!--***********-->
                        <!--DTU REVERSO-->
                        <!--***********-->
                        <mat-tab label="DICTAMEN TECNICO UNICO REVERSO">
                            <table id="tabla_reporte_p" class="table table-bordered table-sm"> <!--29 COLUMNAS-->
                                <tbody>
                                    <!--SEPARADOR--><tr><td colspan="8" class="separador"></td></tr><!--SEPARADOR-->
                                    <tr>
                                        <td id="logo_ISO" class="contenido" colspan="1">
                                            <img class="logo" src="../../../assets/img/iso.png" alt="Logo Calidad ISO" />
                                        </td>
                                        <td id="titulo_ruv" colspan="7">
                                            <table>
                                                <tr>
                                                    REGISTRO ÚNICO DE VIVIENDA
                                                </tr>
                                                <tr><br></tr>
                                                <tr><br></tr>
                                                <tr><br></tr>
                                                <tr><br></tr>
                                                <tr id="titulo_reporte" class="">
                                                    DICTAMEN TECNICO ÚNICO REVERSO
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                    <!--SEPARADOR--><tr><td colspan="8" class="separador"></td></tr><!--SEPARADOR-->
                                    <tr>
                                        <td class="contenido" colspan="3">INFORMACIÓN DE LA OFERTA</td>
                                        <td class="contenido" colspan="1"></td>
                                        <td class="contenido" colspan="1"></td>
                                        <td class="contenido" colspan="1">Dictamen Núm.</td>
                                        <td class="contenido" colspan="1">
                                            <strong>
                                                # {{dtu.id}}
                                            </strong>
                                        </td>
                                        <td class="contenido" colspan="1">Nombre?</td>
                                    </tr>
                                    <!--SEPARADOR--><tr><td colspan="8" class="separador"></td></tr><!--SEPARADOR-->
                                    <tr>
                                        <td class="contenido" colspan="1">DELEGACIÓN</td>
                                        <td class="contenido" colspan="3">
                                            <strong>
                                                {{datosObra.municipio.nombre}}
                                            </strong>
                                        </td>
                                        <td class="contenido" colspan="2">N° VIVS. DE LA OFERTA</td>
                                        <td class="contenido" colspan="2">
                                            <strong>
                                                {{orden.cuvs_count}}
                                            </strong>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="contenido" colspan="2">N° DE REGISTRO</td>
                                        <td class="contenido" colspan="2">
                                            <strong>
                                                {{orden.registro_patronal}}
                                            </strong>
                                        </td>
                                        <td class="contenido" colspan="1">NOMBRE</td>
                                        <td class="contenido" colspan="3">
                                            <strong>
                                                {{orden.nombre}}
                                            </strong>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="contenido" colspan="1">UBICACIÓN</td>
                                        <td class="contenido" colspan="7">
                                            <strong>
                                                Calle: {{orden.calle}}, Colonia: {{orden.colonia}}
                                            </strong>
                                        </td>
                                    </tr>
                                    <tr>
                                        <!-- <td colspan="8">
                                            <table>
                                                <tbody>
                                                    <tr> -->
                                                        <td class="contenido" colspan="1">C.P.</td>
                                                        <td class="contenido" colspan="1">
                                                            <strong>{{orden.codigo_postal}}</strong>
                                                        </td>
                                                        <td class="contenido" colspan="1">LOCALIDAD</td>
                                                        <td class="contenido" colspan="2">
                                                            <strong>
                                                                {{datosObra.localidad.nombre}}
                                                            </strong>
                                                        </td>
                                                        <td class="contenido" colspan="1">ESTADO</td>
                                                        <td class="contenido" colspan="2">
                                                            <strong>
                                                                {{datosObra.estado.nombre}}
                                                            </strong>
                                                        </td>
                                                    <!-- </tr>
                                                </tbody>
                                            </table>
                                        </td> -->
                                    </tr>
                                    <!--SEPARADOR--><tr><td colspan="8" class="separador"></td></tr><!--SEPARADOR-->
                                    <tr>
                                        <td class="contenido" colspan="2">OFERENTE CONSTRUCTOR</td>
                                        <td class="contenido" colspan="6">
                                            <strong>
                                                {{orden.nombre_del_frente}}
                                            </strong>
                                        </td>
                                    </tr>
                                    <!--SEPARADOR--><tr><td colspan="8" class="separador"></td></tr><!--SEPARADOR-->
                                    <!-- ************ -->
                                    <!-- FIN CABEZERA -->
                                    <!-- ************ -->
                                    <tr>
                                        <td colspan="8">INFORMACIÓN DE LA DICTAMINACIÓN</td>
                                    </tr>
                                    <tr>
                                        <td class="contenido" colspan="1">FECHA DE NOTA DE BITACORA</td>
                                        <td class="contenido" colspan="1">NUM. NOTA DE BITACORA</td>
                                        <td class="contenido" colspan="1">TOTAL VIVS. DICTAMINADAS</td>
                                        <td class="contenido" colspan="1">NUM VIVS. DICT. A SOL. OFNTE.</td>
                                        <td class="contenido b-green" colspan="1">NUM VIVS. ECOLOGICAS</td>
                                        <td class="contenido" colspan="1"></td>
                                        <td class="small" colspan="1">ACUMULADOS</td>
                                    </tr>
                                    <tr>
                                        <td class="small" colspan="1">N° DE REGISTRO</td>
                                        <td class="small" colspan="1">#NUMERO</td>
                                        <td class="small" colspan="1">{{dtu.cuvs.length}}</td>
                                        <td class="small" colspan="1">NOMBRE</td>
                                        <td class="small b-green" colspan="1">{{cuvsECO}}</td>
                                        <td class="small" colspan="1"></td>
                                        <td class="small" colspan="1"></td>
                                        <td class="small" colspan="1"></td>
                                    </tr>
                                    <!--SEPARADOR--><tr><td colspan="8" class="separador"></td></tr><!--SEPARADOR-->
                                    <!-- FIN INFORMACIÓN DE LA DICTAMINACIÓN -->
                                    <tr>
                                        <td class="contenido text-center small" colspan="1">VIV. NO.</td>
                                        <td class="contenido text-center small" colspan="3">MANZANA Y/O CALLE</td>
                                        <td class="contenido text-center small" colspan="1">LOTE Y/O NUMERO</td>
                                        <td class="contenido text-center small" colspan="1">CUV</td>
                                        <td class="contenido text-center small b-green" colspan="1">VIV. ECOLOGICA</td>
                                        <td class="contenido text-center small" colspan="1">DICTAMEN A SOL. DEL OFERENTE</td>
                                    </tr>
                                    <ng-container *ngFor="let cuv of cuvs; let i = index">
                                        <tr>
                                            <td class="contenido text-center small" colspan="1">{{i+1}}</td>
                                            <td class="contenido text-center small" colspan="3">{{cuv.manzana}} / {{cuv.calle}}</td>
                                            <td class="contenido text-center small" colspan="1">{{cuv.lote}} / {{cuv.numero_exterior}}</td>
                                            <td class="contenido text-center small" colspan="1"><strong>{{cuv.cuv}}</strong></td>
                                            <td class="contenido text-center small b-green" colspan="1">
                                                <ng-container *ngIf="cuv.ecotecnias.length > 5;else noECO">
                                                    SI
                                                </ng-container>
                                                <ng-template #noECO>
                                                    NO
                                                </ng-template>
                                            </td>
                                            <td class="contenido text-center" colspan="1">?</td>
                                        </tr>
                                    </ng-container>
                                    <!-- ************* -->
                                    <!-- FIN CONTENIDO -->
                                    <!-- ************* -->
                                    <!--SEPARADOR--><tr><td colspan="8" class="separador"></td></tr><!--SEPARADOR-->
                                    <tr>
                                        <td class="text-center" colspan="8">
                                            <h3>
                                                SE CERTIFICA QUE LAS VIVIENDAS DESCRITAS EN ESTE DOCUMENTO, CUENTAN CON TODOS LOS SERVICIOS, ESTAN TERMINADAS Y EN CONDICIONES DE SER HABITADAS.
                                            </h3>
                                            <h4 class="mb-0">
                                                EMPRESA DE VERIFICACIÓN
                                            </h4>
                                            <h2>
                                                {{datosObra.constructora}}
                                            </h2>
                                            <br>
                                            <br>
                                            <div>
                                                <div class="comb z-999">
                                                    {{verificador.nombre}} {{verificador.primer_apellido}} {{verificador.segundo_apellido}}
                                                </div>
                                                <div class="comb" style="margin-top: 4px;">
                                                    ________________________________________________________________________________________________
                                                </div>
                                            </div>
                                            <br>
                                            <br>
                                            Administrador Único
                                            <br>
                                            CED. PROF. <strong>{{verificador.cedula}}</strong>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </mat-tab>
                        <!--***********-->
                        <!--DTU SEMBRADO-->
                        <!--***********-->
                        <mat-tab label="SEMBRADO">
                            <table id="tabla_reporte_p" class="table table-bordered table-sm"> <!--29 COLUMNAS-->
                                <tbody>
                                    <!--SEPARADOR--><tr><td colspan="8" class="separador"></td></tr><!--SEPARADOR-->
                                    <tr>
                                        <td id="logo_ISO" class="contenido" colspan="1">
                                            <img class="logo" src="../../../assets/img/iso.png" alt="Logo Calidad ISO" />
                                        </td>
                                        <td id="titulo_ruv" colspan="7">
                                            <table>
                                                <tr>
                                                    REGISTRO ÚNICO DE VIVIENDA
                                                </tr>
                                                <tr><br></tr>
                                                <tr><br></tr>
                                                <tr><br></tr>
                                                <tr><br></tr>
                                                <tr id="titulo_reporte" class="">
                                                    DICTAMEN TECNICO ÚNICO REVERSO
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="contenido" colspan="3"></td>
                                        <td class="contenido" colspan="1"></td>
                                        <td class="contenido" colspan="1"></td>
                                        <td class="contenido" colspan="1"></td>
                                        <td class="contenido" colspan="1"></td>
                                        <td class="contenido" colspan="1"></td>
                                    </tr>
                                    <!--SEPARADOR--><tr>
                                        <ng-container *ngIf="cuvSinSembrado">
                                            <td colspan="8" class="separador">
                                                <div class="alert alert-warning alert-dismissible fade show" role="alert">
                                                    Alguna de las viviendas asignadas al DTU no fue agregada al Sembrado.
                                                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                            </td>
                                        </ng-container>
                                    </tr><!--SEPARADOR-->
                                    <tr>
                                        <td colspan="8">
                                            <div #canvas id="canvas"></div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </mat-tab>
                        <!--******************-->
                        <!--CARATULA DTU ACUSE-->
                        <!--******************-->
                        <mat-tab label="CARATULA DTU ACUSE">
                            <table id="tabla_reporte_p" class="table table-sm"> <!--29 COLUMNAS-->
                                <tbody>
                                    <!--SEPARADOR--><tr><td colspan="8" class="separador"></td></tr><!--SEPARADOR-->
                                    <tr>
                                        <td id="logo_ISO" class="contenido" colspan="1">
                                            <img class="logo" src="../../../assets/img/iso.png" alt="Logo Calidad ISO" />
                                        </td>
                                        <td id="titulo_ruv" colspan="7">
                                            <table>
                                                <tr>
                                                    REGISTRO ÚNICO DE VIVIENDA
                                                </tr>
                                                <tr><br></tr>
                                                <tr><br></tr>
                                                <tr><br></tr>
                                                <tr><br></tr>
                                                <tr id="titulo_reporte" class="">
                                                    CARÁTULA DTU ACUSE
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="contenido" colspan="3"></td>
                                        <td class="contenido" colspan="1"></td>
                                        <td class="contenido" colspan="1"></td>
                                        <td class="contenido" colspan="1"></td>
                                        <td class="contenido" colspan="1"></td>
                                        <td class="contenido" colspan="1"></td>
                                    </tr>
                                    <!--SEPARADOR--><tr><td colspan="8" class="separador"></td></tr><!--SEPARADOR-->
                                    <br>
                                    <br>
                                    <br>
                                    <br>
                                    <br>
                                    <br>
                                    <tr>
                                        <td class="contenido" colspan="5"></td>
                                        <td class="contenido" colspan="2">
                                            <mat-form-field class="w-100">
                                                <mat-label> Lugar </mat-label>
                                                <input required type="text" value="{{orden.datos_obra.localidad.nombre}}" matInput>
                                            </mat-form-field>
                                        </td>
                                        <td class="contenido" colspan="1">
                                            <mat-form-field class="w-100">
                                                <mat-label> Fecha </mat-label>
                                                <input required type="date" [ngModel]="currentDate | date:'yyyy-MM-dd'"  matInput>
                                            </mat-form-field>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="contenido" colspan="5"></td>
                                        <td class="contenido" colspan="3">
                                            <mat-form-field class="w-100">
                                                <mat-label> Número de Oficio o Borrar </mat-label>
                                                <input required type="text" matInput>
                                            </mat-form-field>
                                        </td>
                                    </tr>
                                    <!--SEPARADOR--><tr><td colspan="8" class="separador"></td></tr><!--SEPARADOR-->
                                    <!--SEPARADOR--><tr><td colspan="8" class="separador"></td></tr><!--SEPARADOR-->
                                    <tr>
                                        <td colspan="5">
                                            <mat-form-field class="w-100">
                                                <mat-label> Nombre </mat-label>
                                                <strong>
                                                    <input required type="text" matInput>
                                                </strong>
                                            </mat-form-field>
                                        </td>
                                        <td class="contenido" colspan="3"></td>
                                    </tr>
                                    <tr>
                                        <td colspan="5">
                                            <strong>ÁREA TÉCNICA</strong>
                                        </td>
                                        <td class="contenido" colspan="3"></td>
                                    </tr>
                                    <tr>
                                        <td colspan="5">
                                            <strong>REGISTRO ÚNICO DE VIVIENDA</strong>
                                        </td>
                                        <td class="contenido" colspan="3"></td>
                                    </tr>
                                    <tr>
                                        <td colspan="5">
                                            <strong>P R E S E N T E:</strong>
                                        </td>
                                        <td class="contenido" colspan="3"></td>
                                    </tr>
                                    <tr>
                                        <td class="contenido" colspan="8">
                                            <div style="margin: 10% 10% 10% 10%;line-height: 26px;">
                                                Adjunto a la presente entrego a Usted, el reporte el Dictamen Técnico único N 33, del paquete de Mercado Abierto, 
                                                N {{ orden.id_orden }}, del frente denominado <strong> {{ orden.nombre_del_frente }} </strong>, a cargo de la constructora
                                                de Vivienda <strong>{{orden.nombre}}</strong>, con ubicación en calle {{ orden.calle }}, Colonia {{ orden.colonia }},
                                                Estado {{ orden.datos_obra.estado.nombre }}
                                            </div>
                                        </td>
                                    </tr>
                                    <br>
                                    <tr>
                                        <td class="contenido" colspan="8">
                                            <div style="margin: 0 10% 0 10%;line-height: 26px;">
                                                Agradeciendo de antemano la atención que sirva prestar quedo de usted
                                            </div>
                                        </td>
                                    </tr>
                                    <br>
                                    <br>
                                    <tr>
                                        <td class="contenido" colspan="8">
                                            <div class="text-center">
                                                Atentamente
                                            </div>
                                        </td>
                                    </tr>
                                    <br>
                                    <br>
                                    <br>
                                    <tr>
                                        <td class="contenido text-center" colspan="8">
                                            <div>
                                                <div class="comb z-999">
                                                    {{verificador.nombre}} {{verificador.primer_apellido}} {{verificador.segundo_apellido}}
                                                </div>
                                                <div class="comb" style="margin-top: 4px;">
                                                    ________________________________________________________________________________________________
                                                </div>
                                            </div>
                                        <br>
                                        <br>
                                        Administrador Único
                                        <br>
                                        CED. PROF. <strong>{{verificador.cedula}}</strong>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </mat-tab>
                    </mat-tab-group>
                </div>
            </ng-container>
        </ng-container>
    </div>
</section>
<script src="https://cdn.jsdelivr.net/gh/linways/table-to-excel@v1.0.4/dist/tableToExcel.js"></script>
