<div class="card">
    <div class="card-body">
        <h3 class="card-title">Listado de Obras</h3>
        <p class="card-text">Seleccionar Obra:</p>
        <div class="row">
            <div class="col">
                <ng-select [items]="listadoObras"
                (change)="btnChange($event)"
                    bindLabel="id_orden"
                    bindValue="id_orden"
                    clearAllText="Deseleccionar todas"
                    #select
                >
                <ng-template ng-label-tmp let-item="item">
                    <strong>
                        <span>{{ item.id_orden }}</span>
                    </strong>
                        <span> {{ ' - ' + item.nombre_del_frente != null ? item.nombre_del_frente : ''}} </span>
                </ng-template>
                    <ng-template ng-option-tmp let-item="item">
                        ID Orden: <strong>{{ item.id_orden}} </strong>
                            <ng-container *ngIf="item.nombre_del_frente != null; else sinNombre">
                                Nombre: 
                                <strong>
                                    {{ item.nombre_del_frente }}
                                </strong>
                            </ng-container>
                            <ng-template #sinNombre>
                                -
                            </ng-template>
                    </ng-template>
                </ng-select>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col">
                <button class="btn btn-sm btn-primary" [routerLink]="'/'+route" [queryParams]="{method: method, orden: ordenSelected }" [disabled]="!(allowButton)">Seleccionar</button>
            </div>
        </div> 
   
    </div>
</div>