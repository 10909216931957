<section class="animate__animated animate__fadeIn d-flex align-items-center">
	<div class="container">
		<div class="section-title">
			<h2>Listado de Usuarios</h2>
		</div>
		<div class="section-content">
			<div class="row">
				<div class="col-sm-12 col-md-8 col-lg-8 py-2">
					<mat-form-field>
						<mat-label>Filtro</mat-label>
						<input matInput (keyup)="applyFilter($event)" placeholder="ej. Manuel Rodriguez	" #input>
					</mat-form-field>
				</div>
				<div class="col-sm-12 col-md-4 col-lg-4 py-2">
					<button type="button" class="btn btn-primary btn-sm" data-toggle="modal"
						data-target="#exampleModalCenter" (click)="toggleModal(0, null)">
						Nuevo Usuario
					</button>
				</div>
			</div>
            
			<table mat-table [dataSource]="dataSource">
                
				<ng-container matColumnDef="id">
					<th mat-header-cell *matHeaderCellDef> ID </th>
					<td mat-cell *matCellDef="let element; let idx = index"> {{ idx + 1 }} </td>
				</ng-container>

				<ng-container matColumnDef="nombre">
					<th mat-header-cell *matHeaderCellDef> Nombre(s) </th>
					<td mat-cell *matCellDef="let element"> {{element.nombre}} </td>
				</ng-container>
				<ng-container matColumnDef="primer_apellido">
					<th mat-header-cell *matHeaderCellDef> Primer Apellido </th>
					<td mat-cell *matCellDef="let element"> {{element.primer_apellido}} </td>
				</ng-container>
				<ng-container matColumnDef="segundo_apellido">
					<th mat-header-cell *matHeaderCellDef> Segundo Apellido </th>
					<td mat-cell *matCellDef="let element"> {{element.segundo_apellido}} </td>
				</ng-container>

				<ng-container matColumnDef="email">
					<th mat-header-cell *matHeaderCellDef> Correo </th>
					<td mat-cell *matCellDef="let element"> {{element.email}} </td>
				</ng-container>

				<ng-container matColumnDef="id_rol">
					<th mat-header-cell *matHeaderCellDef> Rol </th>
					<td mat-cell *matCellDef="let element"> {{element.role.nombre}} </td>
				</ng-container>

				<ng-container matColumnDef="activo">
					<th mat-header-cell *matHeaderCellDef> Activo </th>
					<td mat-cell *matCellDef="let element">
                        <ng-container *ngIf="element.activo > 0;else unchecked">
                            <i class="material-icons">check_box</i>
                        </ng-container>
                        <ng-template #unchecked>
                            <i class="material-icons">check_box_outline_blank</i>
                        </ng-template>
                    </td>
				</ng-container>

				<ng-container matColumnDef="acciones">
					<th mat-header-cell *matHeaderCellDef> Acciones </th>
					<td mat-cell *matCellDef="let element">
						<button mat-icon-button color="primary" title='Modificar' data-toggle="modal"
							data-target="#exampleModalCenter" (click)="toggleModal(1, element)">
							<mat-icon>update</mat-icon>
						</button>
						<button mat-icon-button color="warn" title='Eliminar' (click)="deleteUser(element)">
							<mat-icon>delete</mat-icon>
						</button>
					</td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
				<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

				<tr class="mat-row" *matNoDataRow>
					<td class="mat-cell" colspan="4">No hay resultados para: "{{input.value}}"</td>
				</tr>
			</table>
		</div>
	</div>
</section>
<app-loader [isLoading]="isLoading"></app-loader>
