import { Component, OnInit } from '@angular/core';
import { ProjectsService } from 'src/app/services/projects.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-obras',
  templateUrl: './obras.component.html',
  styleUrls: ['./obras.component.scss']
})
export class ObrasComponent implements OnInit {
	public listadoObras: Array<any> = [];
	public displayedColumns: Array<any> = ['id_orden', 'id_oferta', 'id_oferente', 'nombre', 'colonia', 'acciones']
	public isLoading: boolean = false;
	public userId: any;
	public userRole: any;
	public orden: string;

	constructor(private projectsService: ProjectsService) { 
		this.userId = localStorage.getItem('SESSION_USER_ID');
		this.userRole = localStorage.getItem('SESSION_ROLE');
	}

	ngOnInit(): void {
		this.getListadoObras();
	}
	switchOrden(orden){
		console.log(orden);
		let lastOrder = window.localStorage.getItem('orden');
		if(!lastOrder && lastOrder !== orden){
			window.localStorage.setItem('orden', String(orden));
		}
	}

	getListadoObras() {
		let datos = {};
		datos['id_verificador'] = this.userId;
		datos['user_role'] = this.userRole;
		this.isLoading = true;
		this.projectsService.getAllProjects(datos).subscribe(res=> {
			this.listadoObras = res;
			this.isLoading = false;
		}, error => {
			this.isLoading = false;
			var errorF;
			(error.error.message) != null ?
				errorF = error.error.message :
				errorF = 'Hubo un error al cargar los reportes!';
			Swal.fire({
				icon: 'error',
				title: 'Error...',
				text: errorF,
				footer: 'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.'
			});
		});
	}
}
