<section class="animate__animated animate__fadeIn h-90 d-flex align-items-center">
    <div class="container">
        <div class="section-title">
            <h2>Crear Nueva Obra</h2>
        </div>
        <div class="section-content">
            <div class="row">
                <div class="col">
                    <div class="alert alert-warning alert-dismissible fade show" role="alert">
                        - Al cargar el archivo las celdas deben tener el contenido, no una referencia a otra celda. <br/>
                        - Para cargar prototipos, agregar los nombres al lado del select separados por comas.<br/>
                        - En la localidad, no agregar estado, solo la localidad. <br/>
                        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                </div>
            </div>
            <mat-horizontal-stepper linear #stepper>
                <mat-step [stepControl]="fileUploadForm">
                    <form [formGroup]="fileUploadForm" (ngSubmit)="onFormSubmit()" enctype="multipart/form-data">
                        <div class="section-title">
                            <h3>Carga de archivo de obra</h3>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="drop-zone" appUploadExcelFile (fileDropped)="onFileDropped($event)" *ngIf="!isFileReady">
                                    <input type="file" #fileDropRef id="fileDropRef" name="import_file" (change)="onFileSelect($event.target.files)">
                                    <mat-icon aria-hidden="false" aria-label="Subir Archivo">file_upload</mat-icon>
                                    <h3>Arrastrar y soltar el archivo aquí</h3>
                                    <p class="bold">ó</p>
                                    <label for="fileDropRef">Click para seleccionar archivo</label>
                                </div>
                                <div class="files-list" *ngIf="isFileReady">
                                    <div class="single-file" *ngFor="let file of files; let i = index">
                                        <div class="row">
                                            <div class="col-1">
                                                <mat-icon aria-hidden="false" aria-label="Archivo">insert_drive_file</mat-icon>
                                            </div>
                                            <div class="col-10">
                                                <div class="info">
                                                    <h4 class="name">
                                                        {{ file?.name }} - {{ formatBytes(file?.size) }}
                                                    </h4>
                                                    <app-progress-bar [progress]="file?.progress"></app-progress-bar>
                                                </div>
                                            </div>
                                            <div class="col-1">
                                                <mat-icon aria-hidden="false" aria-label="Eliminar" (click)="deleteFile()">delete_forever</mat-icon>
                                            </div>
                                        </div>
                                        <div class="row" *ngIf="isFileUploaded">
                                            <div class="col-12 text-center">
                                                <button type="submit" class="btn btn-primary">Confirmar</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </mat-step>
                <mat-step [stepControl]="datosOferente">
                    <form [formGroup]="datosOferente">
                        <div class="section-title">
                            <h3>Datos del Oferente</h3>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <mat-form-field class="w-50" *ngFor="let datosVerificacion of ordenVerificacion; let i = index">
                                <mat-label>{{datosVerificacion.name}}</mat-label>
                                    <input matInput attr.placeholder="{{datosVerificacion.name}}" [formControlName]="datosVerificacion.key">
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <button class="btn btn-primary" mat-button (click)="guardarCambiosOferente(datosOferente)">Guardar Cambios</button>
                            </div>
                            <div class="col text-right">
                                <button class="btn btn-primary" mat-button matStepperNext>Continuar</button>
                            </div>
                        </div>
                    </form>
                </mat-step>
                <mat-step [stepControl]="datosPonderaciones">
                    <form [formGroup]="datosPonderaciones">

                        <div class="row">
                            <div class="col col-sm-6">
                                <div class="section-title">
                                    <h3>
                                        Ponderaciones
                                    </h3>
                                </div>
                            </div>
                            <div class="col col-sm-6">
                                <h4 style="text-align: right;">
                                    Suma:
                                    <strong> {{sumaTotal}}%</strong>
                                </h4>

                            </div>
                        </div>

                        <div [formArrayName]="'control1'">
                            <div *ngIf="datosPonderacionesCampos.length > 0; else vacio">
                                <div class="row">
                                    <div class="col-12">
                                        <mat-form-field class="w-50" *ngFor="let campo of datosPonderacionesCampos; let i = index">
                                            <mat-label>{{campo.partida?.nombre}}</mat-label>
                                            <div [formGroupName]="i">
                                                <input formControlName="ponderacion" [id]="campo.id_partida" matInput attr.placeholder="{{campo.concepto}}" attr.value="{{(campo.ponderacion * 100)}}%">
                                            </div>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ng-template #vacio>
                            <div class="row">
                                <div class="col">
                                    <div class="alert alert-warning" role="alert">
                                        Obra sin <strong>Partidas</strong> asignadas
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                        <div class="row">
                            <div class="col">
                                <button class="btn btn-primary" mat-button (click)="guardarCambiosPonderaciones(datosPonderaciones)">Guardar Cambios</button>
                            </div>
                            <div class="col text-sm-left text-md-right text-lg-right">
                                <button class="btn btn-primary mr-2" mat-button matStepperPrevious>Anterior</button>
                                <button class="btn btn-primary" mat-button matStepperNext>Continuar</button>
                            </div>
                        </div>
                    </form>
                </mat-step>
                <mat-step>
                    <form [formGroup]="datosCuvs">
                        <div class="section-title">
                            <h3>Cuvs</h3>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="table-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th *ngFor="let name of cuvNameColumnsDefault;">{{name}}</th>
                                                <th>Geolocalización</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let rows of datosCuvsCampos; let i = index;">
                                                <td *ngFor="let col of cuvNameColumns | keyvalue; let y = index">{{datosCuvsCampos[i][cuvNameColumns[y]]}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col">
                                <button class="btn btn-primary" mat-button (click)="guardarCambiosCuvs($event)">Guardar Cambios</button>
                            </div>
                            <div class="col text-right">
                                <button class="btn btn-primary mr-2" mat-button matStepperPrevious>Anterior</button>
                                <button class="btn btn-primary" mat-button matStepperNext>Continuar</button>
                            </div>
                        </div>
                    </form>
                </mat-step>
                <mat-step>
                    <form [formGroup]="verificadoresForm">
                        <form [formGroup]="verificadoresForm">
                            <div class="section-title">
                                <h3>Elegir Verificador para obra</h3>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="d-flex align-items-center">
                                        <mat-form-field appearance="fill">
                                            <mat-label>Seleccionar Verificador</mat-label>
                                            <mat-select formControlName="userVerificador" (selectionChange)="verificadorChange($event)">
                                                <mat-option *ngFor="let verif of allVerificadores" [value]="verif">
                                                    {{verif.nombre}} {{verif.primer_apellido}} {{verif.segundo_apellido}} 
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <div class="button-container ml-2">
                                            <button *ngIf="isVerificadorSelected" class="btn btn-primary" (click)="asignarVerificador()">Asignar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div *ngIf="isFormReady">
                            <a [routerLink]="['/']">
                                <button class="btn btn-primary" mat-button>Terminar</button>
                            </a>
                        </div>
                    </form>
                </mat-step>
            </mat-horizontal-stepper>
        </div>
    </div>
</section>
<app-loader [isLoading]="isLoading"></app-loader>