<section class="animate__animated animate__fadeIn h-90 d-flex align-items-center py-3">
    <div class="container">
        <div class="section-title">
            <h2>Historial de reportes generados</h2>
        </div>
        <div class="section-content">
            <table mat-table [dataSource]="reportes" multiTemplateDataRows class="mat-elevation-z8">
                <ng-container matColumnDef="{{column}}" *ngFor="let column of columnsToDisplay">
                    <th mat-header-cell *matHeaderCellDef> {{column}} </th>
                    <td mat-cell *matCellDef="let element"> {{element[0]}} </td>
                </ng-container>
                
                <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                <ng-container matColumnDef="expandedDetail">
                    <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
                        <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                        <div class="container" *ngIf="element[1]">
                                <div class="row">
                                    <div class="col-5 underline">
                                        <strong>Fecha</strong>
                                    </div>
                                    <div class="col-5 underline">
                                        <strong>Tipo Reporte</strong>
                                    </div>
                                    <div class="col-2 text-right underline">
                                        <strong>Descargar</strong>
                                    </div>
                                </div>

                                <div class="example-element-description row underline py-3" *ngFor="let reporte of element[1]">
                                    <div class="col-5">{{ reporte.updated_at | date: 'dd/MM/yyyy hh:mm a' }}</div>
                                    <div class="col-5">{{ reporte.tipos_reportes.nombre }}</div>
                                    <div class="col-2 text-center"> 
                                        <mat-icon class="pointer" (click)="descargarReporte(reporte, $event)">save</mat-icon> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                </ng-container>
                
                <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"
                    class="example-element-row"
                    [class.example-expanded-row]="expandedElement === element"
                    (click)="expandedElement = expandedElement === element ? null : element">
                </tr>
                <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
            </table>
        </div>
    </div>
</section>