import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common'
import { UsuariosService } from 'src/app/services/usuarios.service';
import { RolesService } from 'src/app/services/roles.service';
import { MatTable } from '@angular/material/table';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import Swal from 'sweetalert2';
import { MatDialog } from '@angular/material/dialog';
import { DialogoUserComponent } from './dialogo-user/dialogo-user.component';
import { Subject } from 'rxjs';

export interface Ecotecnia {
	id: number;
	nombre: string;
	primer_apellido: string;
	segundo_apellido: string;
	email: string;
	telefono: number;
	cedula: string;
	id_rol: number;
	activo: number;
	created_at: string;
	updated_at: string;
}

const ELEMENT_DATA: Ecotecnia[] = [];

@Component({
	selector: 'app-users',
	templateUrl: './users.component.html',
	styleUrls: ['./users.component.scss']
})

export class UsersComponent implements OnInit {
	public users: any;
	public roles: any;
	public isLoading = false;
	public usuarioActivo = 0;
	dataSource = ELEMENT_DATA;
	dataSourceBack = ELEMENT_DATA;
	public userForm: UntypedFormGroup;
	public allowButton = false;
	public isUpdate = false;
	public usuario = {};
	public selectedRole: number;
	subUse = new Subject<any>();
	@ViewChild(MatTable) table: MatTable<Ecotecnia>;

	displayedColumns: string[] = [
		'id',
		'nombre',
		'primer_apellido',
		'segundo_apellido',
		'email',
		'id_rol',
		'activo',
		'acciones'
	];

	constructor(
		private usuariosService: UsuariosService,
		private rolesService: RolesService,
		private formBuilder: UntypedFormBuilder,
		public dialog: MatDialog,
		private location: Location
	) { 
		this.userForm = this.formBuilder.group({
			id: new UntypedFormControl(""),
			nombre: new UntypedFormControl(""),
			primer_apellido: new UntypedFormControl(""),
			segundo_apellido: new UntypedFormControl(""),
			email: new UntypedFormControl(""),
			telefono: new UntypedFormControl(""),
			id_rol: new UntypedFormControl(""),
			activo: new UntypedFormControl(""),
			cedula: new UntypedFormControl(""),
		});
		this.sidebarToggle();
		this.getRoles();

		this.subUse.subscribe(datoGuardado => {
			this.recargaDatos(datoGuardado);
		});
	}

	ngOnInit(): void {
		this.getUsers();
	}

	getRoles(){
		this.rolesService.index().subscribe(res => {
			this.roles = res;
		}, error => {
			this.isLoading = false;
			var errorF;
			(error.error.message) != null ?
				errorF = error.error.message :
				errorF = 'Hubo un error al crear la ecotecnia!';
			this.isLoading = false;
			Swal.fire({
				icon: 'error',
				title: 'Error...',
				text: errorF,
				footer: 'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.'
			}).then((result) => {
				if (result.isConfirmed) {
					this.location.back();
				}
			})

		});
	}
	
	sidebarToggle() {
		var sidebar = document.getElementById("sidebar");
		sidebar.classList.add('active');
		var contenedor = document.getElementById("content");
		if(sidebar.classList.value == 'active'){
			contenedor.style.width = 'calc(100% - 80px )';
		}
	}

	applyFilter(event: KeyboardEvent) {
		const datSourArray = new Array<any>();
		let filterValue = (event.target as HTMLInputElement).value.toLowerCase();
	
		if (this.dataSourceBack.length === 0) {
			this.dataSourceBack = this.dataSource;
		} else {
			this.dataSource = this.dataSourceBack;
		}
	

		for (let i = 0; i < this.dataSource.length; i++) {
			let busqueda = this.dataSource[i];
			let dato = busqueda.nombre ? busqueda.nombre.toLowerCase() : undefined;
			if (dato && dato.indexOf(filterValue) >= 0) {
				if (!datSourArray.includes(busqueda)) {
					datSourArray.push(busqueda);
				}
			}

			dato = busqueda.primer_apellido ? busqueda.primer_apellido.toLowerCase() : undefined;
			if (dato && dato.indexOf(filterValue) >= 0) {
				if (!datSourArray.includes(busqueda)) {
					datSourArray.push(busqueda);
				}
			}

			dato = busqueda.segundo_apellido ? busqueda.segundo_apellido.toLowerCase() : undefined;
			if (dato && dato.indexOf(filterValue) >= 0) {
				if (!datSourArray.includes(busqueda)) {
					datSourArray.push(busqueda);
				}
			}

			dato = busqueda.email ? busqueda.email.toLowerCase() : undefined;
			if (dato && dato.indexOf(filterValue) >= 0) {
				if (!datSourArray.includes(busqueda)) {
					datSourArray.push(busqueda);
				}
			}

			dato = busqueda['role'] ? busqueda['role'].nombre.toLowerCase() : undefined;
			if (dato && dato.indexOf(filterValue) >= 0) {
				if (!datSourArray.includes(busqueda)) {
					datSourArray.push(busqueda);
				}
			}
		}
	
		if (filterValue.length === 0) {
			this.dataSource = this.dataSourceBack;
		} else {
			this.dataSource = datSourArray;
		}

		this.table.renderRows();
	}

	toggleModal(bool, element) {
		const dialogRef = this.dialog.open(DialogoUserComponent, {
			data: {
				element,
				roles: this.roles,
				userForm: this.userForm,
				bool,
				subUse: this.subUse
			},
			width: '800px',
		});
	
		dialogRef.afterClosed().subscribe(result => {
			if (result === 'guardar') {
				Swal.fire(
					'Guardado!',
					'Datos registrado correctamente',
					'success'
				);
			} else if (result === 'actalizar') {
				Swal.fire(
					'Actualizo!',
					'Datos actualizado correctamente',
					'success'
				);
			}
		});
	}

	userChange(event){
		this.usuario = event;
		if (this.usuario != null)
			this.allowButton = true;
	}

	recargaDatos(usuarioDato: Ecotecnia) {
		if (this.dataSource.find(datUser => datUser.id === usuarioDato.id)) {
			this.dataSource.find(datUser => datUser.id === usuarioDato.id).nombre = usuarioDato.nombre;
			this.dataSource.find(datUser => datUser.id === usuarioDato.id).primer_apellido = usuarioDato.primer_apellido;
			this.dataSource.find(datUser => datUser.id === usuarioDato.id).segundo_apellido = usuarioDato.segundo_apellido;
			this.dataSource.find(datUser => datUser.id === usuarioDato.id).email = usuarioDato.email;
			this.dataSource.find(datUser => datUser.id === usuarioDato.id).telefono = usuarioDato.telefono;
			this.dataSource.find(datUser => datUser.id === usuarioDato.id).id_rol = usuarioDato.id_rol;
			this.dataSource.find(datUser => datUser.id === usuarioDato.id).activo = usuarioDato.activo;
			this.dataSource.find(datUser => datUser.id === usuarioDato.id).cedula = usuarioDato.cedula;
		} else {
			this.getUsers();
		}
		this.table.renderRows();
	}
	
	deleteUser(usuario) {
		const swalWithBootstrapButtons = Swal.mixin({
			customClass: {
				confirmButton: 'btn btn-primary',
				cancelButton: 'btn btn-danger'
			},
			buttonsStyling: true
		})
		swalWithBootstrapButtons.fire({
			icon: 'warning',
			title: '¿Está Seguro?',
			text: 'Al borrar este elemento se eliminará permanentemente.',
			showCancelButton: true,
			confirmButtonText: 'Si, Borrar',
			cancelButtonText: 'No, Cancelar',
			reverseButtons: true
		}).then((result) => {
			if (result.isConfirmed) {
				this.isLoading = true;
				this.usuariosService.delete(usuario).subscribe(res => {
					this.isLoading = false;

					this.dataSource = this.dataSource.filter(notUser => notUser.id !== usuario.id);
					this.table.renderRows();

					Swal.fire(
						'Eliminado!',
						'Usuario eliminado correctamente',
						'success'
					);
					// this.subUse.next(res);
				}, error => {
					//loader false
					var errorF;
					(error.error.message) != null ?
						errorF = error.error.message :
						errorF = 'Hubo un error al borrar el usuario!';
					this.isLoading = false;
					Swal.fire({
						icon: 'error',
						title: 'Error...',
						text: errorF,
						footer: 'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.'
					}).then((result) => {
						if (result.isConfirmed) {
							this.location.back();
						}
					})
				});
			} else {

			}
		})

	}

	getUsers(){
		this.usuariosService.index().subscribe(res => {
			this.users = res;
			this.dataSource = this.users;
		}, error => {
			this.isLoading = false;
			var errorF;
			(error.error.message) != null ?
				errorF = error.error.message :
				errorF = 'Hubo un error al crear la ecotecnia!';
			this.isLoading = false;
			Swal.fire({
				icon: 'error',
				title: 'Error...',
				text: errorF,
				footer: 'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.'
			}).then((result) => {
				if (result.isConfirmed) {
					this.location.back();
				}
			})

		});
	}
}
