<div class="container">
    <div class="section-title">
        <h2>Información de Obra</h2>
    </div>
    <div class="section-content">
        <mat-horizontal-stepper linear #stepper>
            <mat-step [stepControl]="datosOferente">
                <form [formGroup]="datosOferente">
                    <div class="section-title">
                        <h3>Datos del Oferente</h3>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <mat-form-field class="w-50" *ngFor="let datosVerificacion of ordenVerificacion; let i = index">
                            <mat-label>{{datosVerificacion.name}}</mat-label>
                                <input matInput attr.placeholder="{{datosVerificacion.name}}" [formControlName]="datosVerificacion.key">
                            </mat-form-field>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col">
                            <button class="btn btn-primary" mat-button (click)="guardarCambiosOferente(datosOferente)" [disabled]="!(allowButton)">Guardar Cambios</button>
                        </div>
                        <div class="col text-right">
                            <button class="btn btn-primary" mat-button matStepperNext>Continuar</button>
                        </div>
                    </div>
                </form>
            </mat-step>
            <mat-step [stepControl]="datosPonderaciones">
                <form [formGroup]="datosPonderaciones">
                    <div class="row">
                        <div class="col col-sm-6">
                            <div class="section-title">
                                <h3>
                                    Ponderaciones
                                </h3>
                            </div>
                        </div>
                        <div class="col col-sm-6">
                            <h4 style="text-align: right;">
                                Suma:
                                <strong> {{sumaTotal}}%</strong>
                            </h4>
                        </div>
                    </div>

                    <div [formArrayName]="'control'" class="scroll">
                        <div *ngIf="datosPonderacionesCampos.length > 0; else vacio">
                            <div class="row">
                                <div class="col-12">
                                    <mat-form-field class="w-50" *ngFor="let campo of datosPonderacionesCampos; let i = index">
                                        <mat-label>{{ campo.partida?.nombre | uppercase }}</mat-label>
                                        <div [formGroupName]="i">
                                            <input formControlName="ponderacion" [id]="campo.id_partida" matInput attr.placeholder="{{campo.concepto}}" attr.value="{{(campo.ponderacion * 100)}}%">
                                        </div>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ng-template #vacio>
                        <div class="row">
                            <div class="col">
                                <div class="alert alert-warning" role="alert">
                                    Obra sin <strong>Partidas</strong> asignadas
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <div class="row">
                        <div class="col">
                            <button class="btn btn-primary" mat-button (click)="guardarCambiosPonderaciones(datosPonderaciones)">Guardar Cambios</button>
                        </div>
                        <div class="col text-sm-left text-md-right text-lg-right">
                            <button class="btn btn-primary mr-2" mat-button matStepperPrevious>Anterior</button>
                            <button class="btn btn-primary" mat-button matStepperNext>Continuar</button>
                        </div>
                    </div>
                </form>
            </mat-step>
            <mat-step [stepControl]="datosConceptos">
                <div class="row">
                    <div class="col col-sm-6">
                        <div class="section-title">
                            <h3>Conceptos por Partida</h3>
                        </div>
                    </div>
                    <div class="col col-sm-6 text-right">
                        <button class="btn btn-primary" mat-button (click)="nuevoConcepto()">Agregar Concepto</button>
                    </div>
                </div>
                <div *ngIf="datosConceptos.length > 0; else datosConceptosVacios" class="scroll">
                    <div class="row espacio">
                        <div class="col-12">
                            <div class="table-responsive scrolInfo">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th>Concepto</th>
                                            <th>Ponderación</th>
                                            <th>Partida Oirgen</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let datos of datosConceptos; let i = index;">
                                            <td>{{ datos.concepto.nombre }}</td>
                                            <td>{{(datos.ponderacion * 100) | number:'1.2-2'}}%</td>
                                            <td>{{ datos.concepto.partidas.nombre }} </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col text-sm-left text-md-right text-lg-right">
                            <button class="btn btn-primary mr-2" mat-button matStepperPrevious>Anterior</button>
                            <button class="btn btn-primary" mat-button matStepperNext>Continuar</button>
                        </div>
                    </div>
                </div>
                <ng-template #datosConceptosVacios>
                    <div class="row">
                        <div class="col">
                            <div class="alert alert-warning" role="alert">
                                Obra sin <strong>Conceptos</strong> asignados
                            </div>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col text-right">
                            <button class="btn btn-primary mr-2" mat-button matStepperPrevious>Anterior</button>
                            <button class="btn btn-primary" mat-button matStepperNext>Continuar</button>
                        </div>
                    </div>
                </ng-template>
            </mat-step>
            <mat-step [stepControl]="datosCuvs">
                <div class="row">
                    <div class="col col-sm-6">
                        <div class="section-title">
                            <h3>Cuvs</h3>
                        </div>
                    </div>
                    <div class="col col-sm-6 text-right">
                        <button class="btn btn-primary" mat-button (click)="openDialog()">Nuevo Cuv</button>
                    </div>
                </div>

                <form [formGroup]="datosCuvs">
                    <div *ngIf="datosCuvsCampos.length > 0; else vacioCuvs">
                        <div class="row scroll">
                            <div class="col-12">
                                <div class="table-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>Acciones</th>
                                                <th *ngFor="let name of cuvNameColumnsDefault;">{{name}}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let rows of datosCuvsCampos; let i = index;">
                                                
                                                <td>
                                                    <button mat-icon-button color="primary" title='Modificar' aria-label="Example icon button with a home icon"
                                                    (click)="editCuv( datosCuvsCampos[i]['cuv'] )">
                                                        <mat-icon>update</mat-icon>
                                                    </button>
                                                    <button mat-icon-button color="warn" title='Eliminar' aria-label="Example icon button with a home icon"
                                                    (click)="deleteCuv( datosCuvsCampos[i]['cuv'] )">
                                                        <mat-icon>delete</mat-icon>
                                                    </button>
                                                </td>

                                                <td *ngFor="let col of cuvNameColumns | keyvalue; let y = index">
                                                    <div *ngIf="y == 24;else noPrototipo">
                                                        {{datosCuvsCampos[i][cuvNameColumns[y]].nombre}}
                                                    </div>
                                                    <ng-template #noPrototipo>
                                                        {{datosCuvsCampos[i][cuvNameColumns[y]]}}
                                                    </ng-template>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col">
                                <button class="btn btn-primary" mat-button (click)="guardarCambiosCuvs($event)">Guardar Cambios</button>
                            </div>
                            <div class="col text-sm-left text-md-right text-lg-right">
                                <button class="btn btn-primary mr-2" mat-button matStepperPrevious>Anterior</button>
                                <button class="btn btn-primary" mat-button matStepperNext>Continuar</button>
                            </div>
                        </div>
                    </div>
                    <ng-template #vacioCuvs>
                        <div class="row">
                            <div class="col">
                                <div class="alert alert-warning" role="alert">
                                    Obra sin <strong>Cuvs</strong> asignados
                                </div>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col text-right">
                                <button class="btn btn-primary mr-2" mat-button matStepperPrevious>Anterior</button>
                                <button class="btn btn-primary" mat-button matStepperNext>Continuar</button>
                            </div>
                        </div>
                    </ng-template>
                </form>
            </mat-step>
            <mat-step>
                <form [formGroup]="verificadoresForm">
                    <form [formGroup]="verificadoresForm">
                        <div class="section-title">
                            <h3>Elegir Verificador para obra</h3>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="d-flex align-items-center">
                                    <mat-form-field appearance="fill">
                                        <mat-label>Seleccionar Verificador</mat-label>
                                        <mat-select [(ngModel)]="selectedVerif" formControlName="userVerificador" (selectionChange)="verificadorChange($event)">
                                            <mat-option *ngFor="let verif of allVerificadores" [value]="verif.id">
                                                {{verif.nombre}} {{verif.primer_apellido}} {{verif.segundo_apellido}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <div class="button-container ml-2">
                                        <button *ngIf="isVerificadorSelected" class="btn btn-primary" (click)="asignarVerificador()">Asignar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div *ngIf="isFormReady">
                        <a [routerLink]="['/']">
                            <button class="btn btn-primary" mat-button>Terminar</button>
                        </a>
                    </div>
                </form>
            </mat-step>
        </mat-horizontal-stepper>
        <br>
        <div id="accordion" #accordion>
            <div class="card">
                <div class="card-header" id="headingOne">
                    <h5 class="mb-0">
                        <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true"
                            aria-controls="collapseOne">
                            Datos de Obra
                        </button>
                    </h5>
                </div>
        
                <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                    <div class="card-body">
                        <div class="row">
                            <div class="col">
                                <ng-select 
                                    [items]="estados"
                                    (change)="selectEstado($event)"
                                    [(ngModel)]="estadoSelected"
                                    bindLabel="nombre"
                                    placeholder="Seleccionar Estado"
                                    [clearable]="false"
                                    #select
                                >
                                    <ng-template ng-option-tmp let-item="item">
                                        {{item.nombre}}
                                    </ng-template>
                                </ng-select>
                            </div>
                            <div class="col">
                                <ng-container *ngIf="isEstadoSelected">
                                    <ng-select 
                                        [items]="municipios"
                                        (change)="selectMunicipio($event)"
                                        [(ngModel)]="municipioSelected"
                                        bindLabel="nombre"
                                        placeholder="Seleccionar Municipio"
                                        [clearable]="false"
                                        #select
                                    >
                                        <ng-template ng-option-tmp let-item="item">
                                            {{item.nombre}}
                                        </ng-template>
                                    </ng-select>
                                </ng-container>
                            </div>
                            <div class="col">
                                <ng-container *ngIf="isMunicipioSelected">
                                    <ng-select 
                                        [items]="localidades"
                                        (change)="selectLocalidad($event)"
                                        [(ngModel)]="localidadSelected"
                                        bindLabel="nombre"
                                        placeholder="Seleccionar Localidad"
                                        [clearable]="false"
                                        #select
                                    >
                                        <ng-template ng-option-tmp let-item="item">
                                            {{item.nombre}}
                                        </ng-template>
                                    </ng-select>
                                </ng-container>
                            </div>
                        </div>
                        <button class="btn btn-primary" mat-button (click)="guardarDatosObra()" [disabled]="!(allowDataButton)">Guardar Datos Obra</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loader [isLoading]="isLoading"></app-loader>