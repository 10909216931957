import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormControl, FormControlName, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalCuvService } from 'src/app/services/modalCuv.service';
import { cuvsService } from 'src/app/services/cuvsService.service';
import { PrototiposService } from 'src/app/services/prototipos.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modal-cuv',
  templateUrl: './modal-cuv.component.html',
  styleUrls: ['./modal-cuv.component.scss']
})
export class ModalCuvComponent{
  idOrden: any;
  cuv: Array<any>;
  prototipos: Array<any>;
  originalCuv: number;
  cuvSeleccionado: any;
  cuvForm: UntypedFormGroup;
  isUpdate: boolean = false;
  prototipoSeleccionado: any;

	constructor(
		private formBuilder: UntypedFormBuilder,
		@Inject(MAT_DIALOG_DATA) private data: any,
		private dialogRef: MatDialogRef<ModalCuvComponent>,
		private ModalCuvService: ModalCuvService,
		private cuvsService: cuvsService,
		private prototiposService: PrototiposService
	) {
		this.idOrden = data.id_orden;
		this.cuvForm = this.formBuilder.group({
			cuv: new UntypedFormControl(""),
			id_orden: new UntypedFormControl(this.idOrden),
			id_vivienda: new UntypedFormControl(0),
			calle: new UntypedFormControl(""),
			numero_exterior: new UntypedFormControl(""),
			numero_interior: new UntypedFormControl(""),
			colonia: new UntypedFormControl(""),
			codigo_postal: new UntypedFormControl(""),
			lote: new UntypedFormControl(""),
			manzana: new UntypedFormControl(""),
			super_manzana: new UntypedFormControl(""),
			condominio: new UntypedFormControl(""),
			entrada: new UntypedFormControl(""),
			edificio: new UntypedFormControl(""),
			nivel: new UntypedFormControl(""),
			entre_calle: new UntypedFormControl(""),
			y_calle: new UntypedFormControl(""),
			otra_colonia: new UntypedFormControl(""),
			costo_vivienda: new UntypedFormControl(""),
			costo_vivienda_orden: new UntypedFormControl(""),
			numero_estacionamientos: new UntypedFormControl(""),
			departamento: new UntypedFormControl(""),
			casa: new UntypedFormControl(""),
			geolocalizacion: new UntypedFormControl(""),
			prototipo: new UntypedFormControl("")
		});
		this.cuvSeleccionado = data.cuv;
		// Si existe cuv, update
		if(this.cuvSeleccionado != null){
			this.cuvForm.patchValue(this.cuvSeleccionado);
			this.originalCuv = this.cuvSeleccionado.cuv;
			this.isUpdate = true;
			this.prototipoSeleccionado = parseInt(this.cuvSeleccionado.prototipo);
		}
		this.getPrototipos(this.idOrden);
	}

	getPrototipos(orden){
		let datos = {};
		datos['id_orden'] = orden;
		this.prototiposService.getPrototipos(datos).subscribe(res=>{
			this.prototipos = res;
		}, error => {
			var errorF;
			(error.error.message) != null ? 
			errorF =  error.error.message : 
			errorF = 'Hubo un error al consultar Prototipos';
			Swal.fire({
				icon: 'error',
				title: 'Error...',
				text: errorF,
				footer: 'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.'
			});
		});
	}

    onConfirmClick(form: any): void {
        this.cuv = form.value;
		if(this.cuvSeleccionado != null){
			this.cuv['original_cuv'] = this.originalCuv;
			this.cuvsService.updateCuv(this.cuv).subscribe(res=>{
				Swal.fire(
					'Actualizado!',
					'Cuv actualizado correctamente',
					'success'
				);
				this.dialogRef.close(res);
			}, error => {
				//loader false
				var errorF;
				(error.error.message) != null ?
					errorF = error.error.message :
					errorF = 'Hubo un error al actualizar el CUV!';
				Swal.fire({
					icon: 'error',
					title: 'Error...',
					text: errorF,
					footer: 'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.'
				});
			})
		} else {
			this.ModalCuvService.setCuv(this.cuv).subscribe(res=>{
				Swal.fire(
					'Guardado!',
					'Verificador asignado correctamente',
					'success'
				);
				this.dialogRef.close(res);
			}, error=> {
				//loader false
				var errorF;
				(error.error.message) != null ?
					errorF = error.error.message :
					errorF = 'Hubo un error al crear el CUV!';
				Swal.fire({
					icon: 'error',
					title: 'Error...',
					text: errorF,
					footer: 'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.'
				});
			});
		}
    }

}
