import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
    providedIn: 'root'
})
export class ProjectsService {

  public headers: Headers;
  public requestOptions;

  constructor(private http: HttpClient) {
    httpOptions.headers.append("Authorization", "Bearer " + localStorage.getItem("SESSION"));
    this.requestOptions = {
      headers: {"Authorization": "Bearer " + localStorage.getItem("SESSION_TOKEN")}
    }
  }

  createNewProject(data): Observable<any>{
      return this.http.post<any>(environment.api + "crear_orden", data, this.requestOptions);
  }

  getAllProjects(datos): Observable<any>{
    return this.http.post<any>(environment.api + "listado-obras", datos, this.requestOptions);
  }

  getAllCuvs(orden: any): Observable<any>{
    return this.http.post<any>(environment.api + "getCUV", orden, this.requestOptions);
  }

  getCategoriasRevisionDocumental(): Observable<any>{
    return this.http.get<any>(environment.api + "getTipoDocumento", this.requestOptions);
  }

  getDocsByOrdenId(orden: object): Observable<any>{
    return this.http.post<any>(environment.api + "get-datos-documentos", orden, this.requestOptions);
  }

  uploadDocRevision(file: any): Observable<any>{
    return this.http.post<any>(environment.api + "subir-archivo-revision", file, this.requestOptions);
  }

  changeStatusDocRev(data: any): Observable<any>{
    return this.http.post<any>(environment.api + "actualizar-estado-revision", data, this.requestOptions);
  }

  changeCommentDocRev(data: any): Observable<any>{
    return this.http.post<any>(environment.api + "actualizar-comentario-revision", data, this.requestOptions);
  }

  getAllIndicaciones(): Observable<any>{
    return this.http.get<any>(environment.api + "get-indicaciones", this.requestOptions);
  }
}