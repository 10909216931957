import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
  export class AuthService {
    isLogin = false;
  
    roleAs: string;
  
    constructor(
      private http: HttpClient,
      private router: Router
    ) { }
  
    login(value: FormData): Observable<any>{

      return this.http.post<any>(environment.api + "login", value);
    }

    getToken(){
      return this.http.get<any>(environment.api + "get-csrf");
    }
  
    logout() {
      this.isLogin = false;
      this.roleAs = '';
      localStorage.clear();
      localStorage.setItem('STATE', 'false');
      this.router.navigate(['/login']);
      return of({ success: this.isLogin, role: '' });
    }
  
    isLoggedIn() {
      const loggedIn = localStorage.getItem('STATE');
      if (loggedIn == 'true')
        this.isLogin = true;
      else
        this.isLogin = false;
      return this.isLogin;
    }
  
    getRole() {
      this.roleAs = localStorage.getItem('SESSION_ROLE');
      return this.roleAs;
    }
  
}