import { Component, OnInit, ElementRef, ViewChildren, ViewChild, AfterViewInit, QueryList, AfterViewChecked } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormGroup } from '@angular/forms';
import { ReporteService } from 'src/app/services/reporte.service';
import { Chart } from "chart.js";
import jspdf from 'jspdf';
import Swal from 'sweetalert2';
import html2canvas from 'html2canvas';
import { Usuario } from 'src/app/entities/Usuario';
import { image } from 'html2canvas/dist/types/css/types/image';

@Component({
	selector: 'app-descargar-reportes',
	templateUrl: './descargar-reportes.component.html',
	styleUrls: ['./descargar-reportes.component.scss']
})

export class DescargarReportesComponent implements AfterViewInit, OnInit {
	public range: 0;
	@ViewChildren('control') userSelect: QueryList<ElementRef>;
	@ViewChildren('containerReportes') containerReportes: QueryList<ElementRef>;
	@ViewChildren('success') success: QueryList<ElementRef>;
	public isLoading: boolean = false;
	public idOrden: Array<any> = [
		this.activatedRoute.snapshot.queryParams["orden"],
		localStorage.getItem('SESSION_USER_ID')
	];
	public imgReporteGraficoAvances: any;
	public imgreporteEdificacion: any;
	public reporteUrbanizacion: any;
	public reporteInfraestructura: any;
	public reporteAvancePonderado: any;
	public datos: Array<any> = [];
	public reporte: any;
	public reportesExtraidos: Array<any>;
	public pdf: jspdf;
	public obra: any;
	public verificadorAsignado: any;
	public evidencias: any;
	public evidenciasArchivos: any;
	public imgData: any;
	public totalObra: number;
	public verificadoresForm: UntypedFormGroup;
	public isFormReady: boolean = false;
	public first: any;
	public last: any;
	public historialReportes: any;
	public chart: any;
	public chart_labels: Array<any>;
	public avanceProgramado: Array<any>;
	public avances: Array<any>;
	public isLoaded: boolean = false;
	public reportes: Array<any> = [
		{'link' : 'reporteGraficoAvances'},
		{'link' : 'reporteEdificacion'},
		{'link' : 'reporteUrbanizacion'},
		{'link' : 'reporteInfraestructura'},
		{'link' : 'null'},
	];
	public allVerificadores: Usuario[];

	async enviarDatos(reportes){
		
		var pdf = btoa(reportes.output()); 

		let formData = new FormData();
		formData.append('id_orden', this.activatedRoute.snapshot.queryParams["orden"]);
		formData.append('reporte', pdf);
		formData.append('id_reporte', '6');
		formData.append('mime', '.pdf');
		
		this.reporteService.saveReporte(formData).subscribe(res => {

		}, error => {
			console.error('Hubo un error al guardar los Reportes!')
		});
	}

	public download(event)
	{
		let reportes = [".reporteGraficoAvances", ".reporteEdificacion", ".reporteUrbanizacion", ".reporteInfraestructura"];

		const reportesImg = reportes.map(element => html2canvas(document.querySelector(element), {scrollY: -window.scrollY}))
		this.isLoading = true;
		
		let imagenes =["","","",""];

		const images = Promise.all(reportesImg).then(canvases => {
			canvases.forEach((element, index) => {
				const img = element.toDataURL('image/png');
				if(index == 0){
					this.pdf = new jspdf('p', 'pt', 
						[
							element.width,
							element.height
						]
					);
					this.pdf.addImage(img, 0, 0, element.width, element.height, undefined, 'FAST');
					imagenes[index] = img;
				}
				if(index > 0){
					this.pdf.addPage();
					this.pdf.addImage(img, 0, 0, element.width, element.height, undefined, 'FAST');
					imagenes[index] = img;
				}
				if(this.pdf.getNumberOfPages() == 4){
					this.pdf.save('Reportes.pdf');
					this.isLoading = false;
					this.enviarDatos(this.pdf);
				}
			})
			return imagenes;	
		});
	}
	
	constructor(
		private reporteService: ReporteService,
		private activatedRoute: ActivatedRoute,
		public element: ElementRef
	) {
		this.element.nativeElement
		this.sidebarToggle();
	}

	sidebarToggle(){
		
		var sidebar = document.getElementById("sidebar");
		sidebar.classList.add('active');
		var contenedor = document.getElementById("content");
		if(sidebar.classList.value == 'active'){
			contenedor.style.width = 'calc(100% - 80px )';
		}
	}

	ngAfterViewInit(): void {
		
		this.userSelect.changes.subscribe(item => {
			if (this.userSelect.length) {
				var chart = this.userSelect.first.nativeElement;
			}

			this.chart = new Chart(chart, {
				type: 'line',
				data: {
				labels: this.chart_labels,
				datasets: [{
					data: this.avanceProgramado,
					label: "PROGRAMADO",
					borderColor: "#0000ff",
					pointRadius: 5,
					pointHoverRadius: 9,
					pointBackgroundColor: "#0000ff",
					fill: false
				},{
					data: this.avances,
					label: "REAL",
					borderColor: "#ff0000",
					pointRadius: 8,
					pointHoverRadius: 12,
					pointBackgroundColor: "#ff0000",
					fill: false
				}]
				},
				options: {
				title: {
					display: true,
					text: 'CONTROL GRÁFICO DE AVANCE DE OBRA',
				},
				scales: {
						yAxes: [{
						ticks: {
							suggestedMin: 0,
							suggestedMax: 100.00,
							stepSize: 10.00
						}
						}],
					}
				},
			});
    	})
		
  	}
  	sub;
	ngOnInit(): void {
		this.getReporteData();
	}

	public getReporteData() {

		this.isLoading = true;

		this.reporteService.descargarReportes(this.idOrden).subscribe(res => {
			this.reporte = res[0];
			this.obra = this.reporte.datos_obra;
			this.verificadorAsignado = this.obra.verificador;
			this.historialReportes = this.reporte.historial_reportes;
			this.range = this.historialReportes[0].avance;
			this.first = res[1];
			this.last = res[2];
			this.totalObra = this.reporte.cuvs_sum_costo_vivienda;
			this.evidenciasArchivos = res[3]
			this.datos = res;
			this.setDataGrafica();
			this.isLoaded = true;
			this.isLoading= false;
			if (this.reporte == null) {
				Swal.fire(
					'Reporte No disponible',
					'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.',
					'question'
				)
			}
		}, error => {
			//loader false
			var errorF;
			(error.error.message) != null ?
				errorF = error.error.message :
				errorF = 'Hubo un error al cargar el Reporte!';
			Swal.fire({
				icon: 'error',
				title: 'Error...',
				text: errorF,
				footer: 'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.'
			}).then((result) => {
				if (result.isConfirmed) {
					window.history.back();
				}
			});
			this.isLoading= false;
		});

	}

	setDataGrafica(){
		this.chart_labels = Array();
		this.avanceProgramado = Array();
		this.avances = Array();

		//3 casos para barra azul y creación de grafica
		//hay más reportes generados que avances programados
		//hay más avances programados que reportes generados
		//No hay avances programados
		if(this.reporte.avances_programados.length == 0){
			//Si no hay avances programados, se crea la grafica dinamicamente con el numero de reportes generados
			let avanceIndice = this.historialReportes.length;
			let avanceIndice0 = avanceIndice-1;
			for(let i=1; i<=avanceIndice; i++){
				this.chart_labels.push(i);
			}
			let avance = 0;
			for(let j=0; j<avanceIndice; j++){
				this.avanceProgramado.push(avance.toFixed(2));
				avance = avance + (100/avanceIndice0);
			}
		}else{
			if(this.historialReportes.length >= this.reporte.avances_programados.length){
				//Si hay más reportes generados, reportes generados establece los labels de la grafica
				this.historialReportes.forEach((element) => {
					this.chart_labels.push(element.numero_reporte);
				});
				this.reporte.avances_programados.forEach((element) => {
					this.avanceProgramado.push(element.avance_programado)
				});
			}else if(this.reporte.avances_programados.length > this.historialReportes.length){
				//Si hay más avances programados, avances prrogramados establece los lables de la grafica
				this.reporte.avances_programados.forEach((element) => {
					this.chart_labels.push(element.numero_reporte);
					this.avanceProgramado.push(element.avance_programado)
				});
			}
		}
		this.historialReportes.forEach(element => {
			this.avances.push(element.avance);
		});
		
	}


}

