<app-loader [isLoading]="isLoading"></app-loader>
<section class="animate__animated animate__fadeIn d-flex align-items-center">
    <div class="container">
        <ng-container *ngIf="method === 'notas'">
            <div class="row">
                <div class="col-sm-12 col-md-8 col-lg-8 py-2">
                    <mat-form-field>
                        <mat-label>Filtro</mat-label>
                        <input matInput (keyup)="applyFilter($event)" placeholder="ej. Regadera Ahorradora	" #input>
                    </mat-form-field>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-4 py-2">
                    <button type="button" class="btn btn-primary btn-sm" data-toggle="modal"
                        data-target="#exampleModalCenter" (click)="toggleModal(0, null)">
                        Nueva Nota
                    </button>
                </div>
            </div>
    
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" multiTemplateDataRows>
    
                <!-- Position Column -->
                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef> ID. </th>
                    <td mat-cell *matCellDef="let element;"> {{ element.orden }} </td>
                </ng-container>
    
                <!-- Name Column -->
                <ng-container matColumnDef="nota">
                    <th mat-header-cell *matHeaderCellDef> Nota </th>
                    <td mat-cell *matCellDef="let element"> {{element.nota}} </td>
                </ng-container>
    
                <!-- Name Column -->
                <ng-container matColumnDef="created_at">
                    <th mat-header-cell *matHeaderCellDef> Fecha de Creación </th>
                    <td mat-cell *matCellDef="let element"> {{element.created_at | date: 'dd/MM/yyyy hh:mm a'}} </td>
                </ng-container>
    
                <!-- Position Column -->
                <ng-container matColumnDef="acciones">
                    <th mat-header-cell *matHeaderCellDef> Acciones </th>
                    <td mat-cell *matCellDef="let element">
                        <button mat-icon-button color="primary" title='Modificar' data-toggle="modal"
                            data-target="#exampleModalCenter" (click)="toggleModal(1, element)">
                            <mat-icon>update</mat-icon>
                        </button>
                        <button mat-icon-button color="warn" title='Eliminar' (click)="deleteNota(element)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <ng-container matColumnDef="expandedDetail">
                    <td mat-cell *matCellDef="let element" [attr.colspan]="subColumnsToDisplay.length+1">
                        <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                            <div class="container" *ngIf="element.cuvs.length > 0">
                                <div class="row">
                                    <div class="col">
                                        <strong>ID Vivienda</strong>
                                    </div>
                                    <div class="col">
                                        <strong>Cuv</strong>
                                    </div>
                                    <div class="col">
                                        <strong>Lote</strong>
                                    </div>
                                    <div class="col">
                                        <strong>Manzana</strong>
                                    </div>
                                </div>
                                <div class="example-element-description row underline py-3" *ngFor="let cuv of element.cuvs">
                                    <div class="col">{{ cuv.id_vivienda }}</div>
                                    <div class="col">{{ cuv.cuv }}</div>
                                    <div class="col">{{ cuv.lote }}</div>
                                    <div class="col">{{ cuv.manzana }}</div>
                                </div>
                                <ng-container *ngIf="element.concepto.length > 0">
                                    <hr>
                                    <div class="row">
                                        <div class="col">
                                            <strong>Concepto</strong>
                                        </div>
                                    </div>
                                    <div class="example-element-description row underline py-3" *ngFor="let concepto of element.concepto">
                                        <div class="col">{{ concepto.nombre }}</div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let element; columns: displayedColumns;"
                    class="example-element-row"
                    [class.example-expanded-row]="expandedElement === element"
                    (click)="expandedElement = expandedElement === element ? null : element">
                </tr>
                <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>

                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="4">No hay resultados para: "{{input.value}}"</td>
                </tr>
            </table>
        </ng-container>
    </div>
</section>
