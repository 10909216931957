<div *ngIf="isUpdate == true;else createCuv">
    <h2 mat-dialog-title>Actualizar CUV</h2>
</div>
<ng-template #createCuv>
    <h2 mat-dialog-title>Nuevo CUV</h2>
</ng-template>
Orden: {{ idOrden }}
<hr>
<mat-dialog-content>
    <form [formGroup]="cuvForm">
        <mat-form-field class="w-100">
            <mat-label>CUV</mat-label>
            <div *ngIf="isUpdate == true;else createCuvInput">
                <input required type="text" matInput formControlName="cuv" readonly>
            </div>
            <ng-template #createCuvInput>
                <input required type="text" matInput formControlName="cuv">
            </ng-template>
        </mat-form-field>
        <div class="row">
            <div class="col col-sm-12 col-md-4 col-xs-4">
                <mat-form-field class="w-100">
                    <mat-label>Calle</mat-label>
                    <input required type="text" matInput formControlName="calle">
                </mat-form-field>
            </div>
            <div class="col col-sm-12 col-md-4 col-xs-4">
                <mat-form-field class="w-100">
                    <mat-label>Colonia</mat-label>
                    <input required type="text" matInput formControlName="colonia">
                </mat-form-field>
            </div>
            <div class="col col-sm-12 col-md-4 col-xs-4">
                <mat-form-field class="w-100">
                    <mat-label>Otra Colonia</mat-label>
                    <input type="text" matInput formControlName="otra_colonia">
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col col-sm-12 col-md-6 col-xs-6">
                <mat-form-field class="w-100">
                    <mat-label>Entre Calle</mat-label>
                    <input required type="text" matInput formControlName="entre_calle">
                </mat-form-field>
            </div>
            <div class="col col-sm-12 col-md-6 col-xs-6">
                <mat-form-field class="w-100">
                    <mat-label>Y Calle</mat-label>
                    <input required type="text" matInput formControlName="y_calle">
                </mat-form-field>
            </div>
        </div>
        
        <div class="row">
            <div class="col">
                <mat-form-field class="w-100">
                    <mat-label>Numero Ext</mat-label>
                    <input required type="number" matInput formControlName="numero_exterior">
                </mat-form-field>
            </div>
            <div class="col">
                <mat-form-field class="w-100">
                    <mat-label>Numero Int.</mat-label>
                    <input type="number" matInput formControlName="numero_interior">
                </mat-form-field>
            </div>
            <div class="col">
                <mat-form-field class="w-100">
                    <mat-label>C.P.</mat-label>
                    <input required type="number" matInput formControlName="codigo_postal">
                </mat-form-field>
            </div>
        </div>
        
        <div class="row">
            <div class="col">
                <mat-form-field class="w-100">
                    <mat-label>Lote</mat-label>
                    <input required type="text" matInput formControlName="lote">
                </mat-form-field>
            </div>
            <div class="col">
                <mat-form-field class="w-100">
                    <mat-label>Manzana</mat-label>
                    <input required type="text" matInput formControlName="manzana">
                </mat-form-field>
            </div>
            <div class="col">
                <mat-form-field class="w-100">
                    <mat-label>S. Manzana</mat-label>
                    <input type="text" matInput formControlName="super_manzana">
                </mat-form-field>
            </div>
        </div>
        
        <div class="row">
            <div class="col col-sm-12 col-md-3 col-xs-3">
                <mat-form-field class="w-100">
                    <mat-label>Condominio</mat-label>
                    <input type="text" matInput formControlName="condominio">
                </mat-form-field>
            </div>
            <div class="col col-sm-12 col-md-3 col-xs-3">
                <mat-form-field class="w-100">
                    <mat-label>Entrada</mat-label>
                    <input type="text" matInput formControlName="entrada">
                </mat-form-field>
            </div>
            <div class="col col-sm-12 col-md-3 col-xs-3">
                <mat-form-field class="w-100">
                    <mat-label>Edificio</mat-label>
                    <input type="text" matInput formControlName="edificio">
                </mat-form-field>
            </div>
            <div class="col col-sm-12 col-md-3 col-xs-3">
                <mat-form-field class="w-100">
                    <mat-label>Nivel</mat-label>
                    <input type="text" matInput formControlName="nivel">
                </mat-form-field>
            </div>
        </div>
        
        <div class="row">
            <div class="col col-sm-12 col-md-3 col-xs-3">
                <mat-form-field class="w-100">
                    <mat-label>Costo Orden/Vivienda</mat-label>
                    <input required type="number" matInput formControlName="costo_vivienda">
                </mat-form-field>
            </div>
            <div class="col col-sm-12 col-md-3 col-xs-3">
                <mat-form-field class="w-100">
                    <mat-label>No. Estacionamientos</mat-label>
                    <input type="number" matInput formControlName="numero_estacionamientos">
                </mat-form-field>
            </div>
            <div class="col col-sm-12 col-md-3 col-xs-3">
                <mat-form-field class="w-100">
                    <mat-label>Departamento</mat-label>
                    <input type="number" matInput formControlName="departamento">
                </mat-form-field>
            </div>
            <div class="col col-sm-12 col-md-3 col-xs-3">
                <mat-form-field class="w-100">
                    <mat-label>Casa</mat-label>
                    <input type="number" matInput formControlName="casa">
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col col-sm-12 col-md-6 col-xs-6">
                <mat-form-field class="w-100">
                    <mat-label>Geolocalización</mat-label>
                    <input type="text" matInput formControlName="geolocalizacion">
                </mat-form-field>
            </div>
            <div class="col col-sm-12 col-md-6 col-xs-6">
                <mat-form-field appearance="fill" style="display: inherit;" class="selectPrototipo">
                    <mat-select [(ngModel)]="prototipoSeleccionado" required formControlName="prototipo">
                        <mat-option *ngFor="let verif of prototipos" [value]="verif.numero_prototipo">
                            {{verif.nombre}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <hr>
    </form>
</mat-dialog-content>

<div class="row mt-2">
    <div class="col center boton">
        <button class="btn btn-primary" mat-raised-button (click)="onConfirmClick(cuvForm)" tabindex="1">Guardar</button>
        <button class="btn btn-danger active" mat-raised-button mat-dialog-close tabindex="-1">Cancelar</button>
    </div>
</div>