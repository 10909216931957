import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-select-process',
  templateUrl: './select-process.component.html',
  styleUrls: ['./select-process.component.scss']
})
export class SelectProcessComponent implements OnInit {
  public isLoading: boolean = false;
  public idOrden: any = "";
  constructor(
    private activatedRoute: ActivatedRoute
  ) { 
    
  }

  ngOnInit(): void {
    this.idOrden = this.activatedRoute.snapshot.queryParams["orden"] ? this.activatedRoute.snapshot.queryParams["orden"] : window.localStorage.getItem('orden');
  }

}
