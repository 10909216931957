import { Injectable  } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})

export class UploadFileService {

  public headers: Headers;
  public requestOptions;

  constructor(private http: HttpClient) {
    httpOptions.headers.append("Authorization", "Bearer " + localStorage.getItem("SESSION"));
    this.requestOptions = {
      headers: {"Authorization": "Bearer " + localStorage.getItem("SESSION_TOKEN")}
    }
  }

  uploadFile(file: any): Observable<any>{
    return this.http.post<any>(environment.api + "leer_archivo", file, this.requestOptions);
  }

  getProjectById(id: string): Observable<any>{
    return this.http.get<any>(environment.api + "consultar_orden/" + id, this.requestOptions);
  }

  updateOferente(data: object): Observable<any> {
    return this.http.post<any>(environment.api + "editOferente", data, this.requestOptions);
  }

  updatePonderaciones(data): Observable<any> {
    return this.http.post<any>(environment.api + "editPonderaciones", data, this.requestOptions);
  }

  updateDatosObra(data): Observable<any> {
    return this.http.post<any>(environment.api + "update-datos-obra", data, this.requestOptions);
  }
}