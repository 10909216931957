<section class="animate__animated animate__fadeIn h-90 d-flex align-items-center">
    <div class="container">
        <div class="section-title">
            <h2>Seleccionar</h2>
        </div>
        <div class="section-content">
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12 margin">
                    <div class="card">
                        <div class="card-body text-center">
                            <div class="card-title">
                                <mat-icon aria-hidden="false">class</mat-icon>
                            </div>
                            <p>Generar una nueva verificación.</p>
                            <div class="card-button">
                                <a 
                                    [routerLink]="['/verificaciones']"
                                    [queryParams]="{ orden: idOrden }"
                                >
                                    <button class="btn btn-primary">Continuar</button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="col-lg-6 col-md-6 col-sm-12 margin">
                    <div class="card">
                        <div class="card-body text-center">
                            <div class="card-title">
                                <mat-icon aria-hidden="false">assignment</mat-icon>
                            </div>
                            <p>Generar Reportes.</p>
                            <div class="card-button">
                                <a [routerLink]="['/reportes-disponibles']" [queryParams]="{orden: idOrden }">
                                    <button class="btn btn-primary">Continuar</button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<app-loader [isLoading]="isLoading"></app-loader>