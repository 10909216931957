<div class="modal-content">
	<div class="modal-header">
		<h5 class="modal-title" id="exampleModalLongTitle">
			<ng-container *ngIf="isUpdate == true;else isStoreTitle">
				Actualizar Ecotecnia
			</ng-container>
			<ng-template #isStoreTitle>
				Crear Usuario
			</ng-template>
		</h5>
		<button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<form [formGroup]="userForm">
			<div class="row">
				<ng-container *ngIf="isUpdate == true; else isStoreForm">
					<div class="col col-sm-12 col-md-12 col-lg-12">
						<mat-form-field class="w-100">
							<mat-label>Nombre (s)</mat-label>
							<input required type="text" matInput (ngModelChange)="userChange($event)"
								formControlName="nombre">
						</mat-form-field>
					</div>
					<div class="col col-sm-6 col-md-6 col-lg-6">
						<mat-form-field class="w-100">
							<mat-label>Primer Apellido</mat-label>
							<input type="text" matInput (ngModelChange)="userChange($event)"
								formControlName="primer_apellido">
						</mat-form-field>
					</div>
					<div class="col col-sm-6 col-md-6 col-lg-6">
						<mat-form-field class="w-100">
							<mat-label>Segundo Apellido</mat-label>
							<input type="text" matInput (ngModelChange)="userChange($event)"
								formControlName="segundo_apellido">
						</mat-form-field>
					</div>
					<div class="col col-sm-6 col-md-6 col-lg-6">
						<mat-form-field class="w-100">
							<mat-label>Correo</mat-label>
							<input required type="email" matInput (ngModelChange)="userChange($event)"
								formControlName="email">
						</mat-form-field>
					</div>
					<div class="col col-sm-6 col-md-6 col-lg-6">
						<mat-form-field class="w-100">
							<mat-label>Telefono</mat-label>
							<input required type="text" matInput (ngModelChange)="userChange($event)"
								formControlName="telefono" maxlength="10">
						</mat-form-field>
					</div>
					<div class="col col-sm-6 col-md-6 col-lg-6">
						<mat-form-field appearance="fill" style="display: inherit;">
							<mat-label>Rol</mat-label>
							<mat-select [(ngModel)]="selectedRole" required formControlName="id_rol" (selectionChange)="userChange($event)">
								<mat-option *ngFor="let rol of roles" [value]="rol.id_rol">
									{{rol.nombre}}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
					<div class="col col-sm-6 col-md-6 col-lg-6">
						<mat-form-field appearance="fill" style="display: inherit;">
							<mat-label>Estado</mat-label>
							<mat-select [(ngModel)]="usuarioActivo" required formControlName="activo" (selectionChange)="userChange($event)">
								<mat-option [value]="1">Activo</mat-option>
								<mat-option [value]="0">Inactivo</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
					<div class="col col-sm-12 col-md-12 col-lg-12">
						<mat-form-field class="w-100">
							<mat-label>Cedula</mat-label>
							<input type="text" matInput (ngModelChange)="userChange($event)"
								formControlName="cedula">
						</mat-form-field>
					</div>
				</ng-container>
				<ng-template #isStoreForm>
					<div class="col col-sm-12 col-md-12 col-lg-12">
						<mat-form-field class="w-100">
							<mat-label>Nombre(s) Usuario</mat-label>
							<input required type="text" matInput (ngModelChange)="userChange($event)"
								formControlName="nombre">
						</mat-form-field>
					</div>
					<div class="col col-sm-6 col-md-6 col-lg-6">
						<mat-form-field class="w-100">
							<mat-label>Primer Apellido</mat-label>
							<input type="text" matInput (ngModelChange)="userChange($event)"
								formControlName="primer_apellido">
						</mat-form-field>
					</div>
					<div class="col col-sm-6 col-md-6 col-lg-6">
						<mat-form-field class="w-100">
							<mat-label>Segundo Apellido</mat-label>
							<input type="text" matInput (ngModelChange)="userChange($event)"
								formControlName="segundo_apellido">
						</mat-form-field>
					</div>
					<div class="col col-sm-6 col-md-6 col-lg-6">
						<mat-form-field class="w-100">
							<mat-label>Correo</mat-label>
							<input required type="email" matInput (ngModelChange)="userChange($event)"
								formControlName="email">
						</mat-form-field>
					</div>
					<div class="col col-sm-6 col-md-6 col-lg-6">
						<mat-form-field class="w-100">
							<mat-label>Telefono</mat-label>
							<input required type="text" matInput (ngModelChange)="userChange($event)"
								formControlName="telefono" maxlength="10">
						</mat-form-field>
					</div>
					<div class="col col-sm-6 col-md-6 col-lg-6">
						<mat-form-field appearance="fill" style="display: inherit;">
							<mat-label>Rol</mat-label>
							<mat-select [(ngModel)]="selectedRole" required formControlName="id_rol" (selectionChange)="userChange($event)">
								<mat-option *ngFor="let rol of roles" [value]="rol.id_rol">
									{{rol.nombre}}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
					<div class="col col-sm-6 col-md-6 col-lg-6">
						<mat-form-field appearance="fill" style="display: inherit;">
							<mat-label>Estado</mat-label>
							<mat-select [(ngModel)]="usuarioActivo" required formControlName="activo" (selectionChange)="userChange($event)">
								<mat-option [value]="1">Activo</mat-option>
								<mat-option [value]="0">Inactivo</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
					<div class="col col-sm-12 col-md-12 col-lg-12">
						<mat-form-field class="w-100">
							<mat-label>Cedula</mat-label>
							<input type="text" matInput (ngModelChange)="userChange($event)"
								formControlName="cedula">
						</mat-form-field>
					</div>
				</ng-template>
			</div>
		</form>
		<div class="alert alert-warning alert-dismissible fade show" role="alert">
			Los campos con asterisco (*) son obligatorios.	
			<button type="button" class="close" data-dismiss="alert" aria-label="Close">
				<span aria-hidden="true">&times;</span>
			</button>
			</div>
	</div>
	<div class="modal-footer">
		<ng-container *ngIf="isUpdate == true; else isStoreFooter">
			<button type="button" class="btn btn-primary" (click)="updateUser(userForm)"
				[disabled]="!(allowButton)">
				Actualizar
			</button>
		</ng-container>
		<ng-template #isStoreFooter>
			<button type="button" class="btn btn-primary" (click)="storeUser(userForm)"
				[disabled]="!(allowButton)">
				Guardar
			</button>
		</ng-template>
	</div>
</div>