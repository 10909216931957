import { Component, OnInit, ViewChild } from '@angular/core';
import { EcotecniasService } from 'src/app/services/ecotecniasService.service';
import { MatTable } from '@angular/material/table';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import Swal from 'sweetalert2';
import { MatDialog } from '@angular/material/dialog';
import { DialogoEcotecniasComponent } from './dialogo-ecotecnias/dialogo-ecotecnias.component';
import { Subject } from 'rxjs';

export interface Ecotecnia {
	id: number;
	ecotecnia: string;
	created_at: string;
	acciones: any;
}

const ELEMENT_DATA: Ecotecnia[] = [];

@Component({
	selector: 'app-ecotecnias',
	templateUrl: './ecotecnias.component.html',
	styleUrls: ['./ecotecnias.component.scss']
})
export class EcotecniasComponent implements OnInit {
	isLoading: boolean = false;
	isUpdate: boolean = false;
	allowButton: boolean = false;
	ecotecnias: any;
	ecotecnia: any = null;
	dataSource = ELEMENT_DATA;
	dataSourceBack = ELEMENT_DATA;
	ecoForm: UntypedFormGroup;
	displayedColumns: string[] = ['id', 'ecotecnia', 'created_at', 'acciones'];
	subUse = new Subject<any>();
	@ViewChild(MatTable) table: MatTable<any>;

	constructor(
		private ecotecniasService: EcotecniasService,
		private formBuilder: UntypedFormBuilder,
		public dialog: MatDialog
	) {
		this.ecoForm = this.formBuilder.group({
			id: new UntypedFormControl(""),
			ecotecnia: new UntypedFormControl("")
		});

		this.subUse.subscribe(ecotec => {
			this.recargaDatos(ecotec);
		});
	}

	deleteEco(ecotecnologia) {
		const swalWithBootstrapButtons = Swal.mixin({
			customClass: {
				confirmButton: 'btn btn-primary',
				cancelButton: 'btn btn-danger'
			},
			buttonsStyling: true
		})
		swalWithBootstrapButtons.fire({
			icon: 'warning',
			title: '¿Está Seguro?',
			text: 'Al borrar este elemento se eliminará permanentemente de cada CUV que lo contenga.',
			showCancelButton: true,
			confirmButtonText: 'Si, Borrar',
			cancelButtonText: 'No, Cancelar',
			reverseButtons: true
		}).then((result) => {
			if (result.isConfirmed) {
				this.isLoading = true;
				this.ecotecniasService.deleteEcotecnia(ecotecnologia).subscribe(res => {
					this.isLoading = false;
					Swal.fire(
						'Borrada!',
						'Ecotecnia eliminada correctamente',
						'success'
					)
				}, error => {
					var errorF;
					(error.error.message) != null ?
						errorF = error.error.message :
						errorF = 'Hubo un error al borrar la ecotecnia!';
					this.isLoading = false;
					Swal.fire({
						icon: 'error',
						title: 'Error...',
						text: errorF,
						footer: 'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.'
					});
				})
			} else {

			}
		})

	}

	toggleModal(bool, element, viewId) {
		const dialogRef = this.dialog.open(DialogoEcotecniasComponent, {
			data: {
				element,
				bool,
				subUse: this.subUse,
				viewId
			}
		});

		dialogRef.afterClosed().subscribe(result => {
			if (result === 'guardar') {
				Swal.fire(
					'Guardado!',
					'Ecotecnia guardada correctamente',
					'success'
				)
			} else if (result === 'actalizar') {
				Swal.fire(
					'Actualizo!',
					'Ecotecnia actualizada correctamente',
					'success'
				);
			}
		});
	}

	ecotecniaChange(event) {
		this.ecotecnia = event;
		if (this.ecotecnia != null)
			this.allowButton = true;
	}

	applyFilter(event: KeyboardEvent) {
		const datSourArray = new Array<any>();
		let filterValue = (event.target as HTMLInputElement).value.toLowerCase();

		if (this.dataSourceBack.length === 0) {
			this.dataSourceBack = this.dataSource;
		} else {
			this.dataSource = this.dataSourceBack;
		}

		for (let i = 0; i < this.dataSource.length; i++) {
			let busqueda = this.dataSource[i];
			let dato = busqueda.ecotecnia.toLowerCase();
			if (dato.indexOf(filterValue) >= 0) {
				datSourArray.push(busqueda);
			}
		}

		if (filterValue.length === 0) {
			this.dataSource = this.dataSourceBack;
		} else {
			this.dataSource = datSourArray;
		}

		this.table.renderRows();
	}

	ngOnInit(): void {
		this.getEcotecnias();
	}

	getEcotecnias() {
		this.ecotecniasService.getEcotecnias().subscribe(res => {
			this.isLoading = true;
			this.ecotecnias = res;
			this.dataSource = res;
			this.isLoading = false;
		}, error => {
			var errorF;
			(error.error.message) != null ?
				errorF = error.error.message :
				errorF = 'Hubo un error al cargar las ecotecnias!';
			this.isLoading = false;
			Swal.fire({
				icon: 'error',
				title: 'Error...',
				text: errorF,
				footer: 'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.'
			}).then((result) => {
				if (result.isConfirmed) {

				}
			})
		})
	}

	recargaDatos(usuarioDato: any) {
		if (this.dataSource.find(datUser => datUser.id === usuarioDato.id)) {
    		this.dataSource.find(datUser => datUser.id === usuarioDato.id).ecotecnia = usuarioDato.ecotecnia;
		} else {
			this.dataSource.push(usuarioDato);
		}
		this.table.renderRows();
	}
}
