<section class="animate__animated animate__fadeIn d-flex align-items-center">
	<div class="container">
    <div class="section-title">
        <h2>Revisión Documental</h2>
    </div>
    <div class="section-content">
        <table mat-table [dataSource]="catDocumentos" multiTemplateDataRows class="mat-elevation-z8">
            <ng-container matColumnDef="nombre">
                <th mat-header-cell *matHeaderCellDef> Categoria </th>
                <td mat-cell *matCellDef="let element"> {{element.nombre}} </td>
            </ng-container>
            
            <ng-container matColumnDef="indicaciones">
                <th mat-header-cell *matHeaderCellDef> Indicaciones </th>
                <td mat-cell *matCellDef="let element"> 
                    <mat-icon class="pointer" title="Indicaciones" aria-hidden="false" aria-label="Indicaciones" data-toggle="modal" data-target="#indicaciones" (click)="mostrarIndicaciones(element)">feedback</mat-icon> 
                </td>
            </ng-container>
            
            <!-- Weight Column -->
            <ng-container matColumnDef="upload">
                <th mat-header-cell *matHeaderCellDef> Subir Archivo </th>
                <td mat-cell *matCellDef="let element" class="text-center">
                    <mat-icon class="pointer" title="Subir Archivo" aria-hidden="false" aria-label="Subir Archivo" data-toggle="modal" data-target="#uploadFileModal" (click)="currentCategory(element)">file_upload</mat-icon>
                </td>
            </ng-container>

            <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                    <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                        <div class="container" *ngIf="element.docs.length > 0">
                            <div class="row">
                                <div class="col-4 underline">Nombre</div>
                                <div class="col-6 underline">Comentarios</div>
                                <div class="col-2 text-right underline">Validación</div>
                            </div>
                            <div class="example-element-description row underline py-3" *ngFor="let doc of element.docs">
                                <div class="col-4">
                                    <a class="btn btn-link btn-sm" (click)="descargarDocumento(doc.url)">
                                        {{ doc.nombre }}
                                    </a>
                                </div>
                                <div class="col-6">
                                    <textarea [value]="doc.comentarios"></textarea>
                                    <mat-icon class="pointer" title="Comentarios" aria-label="Comentarios" (click)="guardarComentarios(doc, $event)">save</mat-icon> 
                                </div>
                                <div class="col-2 text-right">
                                    <mat-slide-toggle [checked]="doc.status == 1 ? true : false" (change)="cambioEstado(doc, $event)"></mat-slide-toggle>
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let element; columns: displayedColumns;"
                class="example-element-row"
                [class.example-expanded-row]="expandedElement === element"
                (click)="expandedElement = expandedElement === element ? null : element">
            </tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
        </table>
    </div>
</div>
<div class="modal fade" id="uploadFileModal" tabindex="-1" role="dialog" aria-labelledby="uploadFileModalTitle" aria-hidden="false">
    <div class="modal-dialog" role="document">
        <form [formGroup]="fileUploadForm" (ngSubmit)="subirArchivo($event)" enctype="multipart/form-data">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="uploadFileModalTitle">Subir Archivo</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <input #fileDropRef type="file" name="import_file" (change)="prepararArchivo($event.target.files)">
                </div>
                <div class="modal-footer">
                    <button type="submit" class="btn btn-primary">Confirmar</button>
                    <button type="button" class="btn btn-secondary" (click)="cancelarSubida()" data-dismiss="modal">Cancelar</button>
                </div>
            </div>
        </form>
    </div>
</div>
<div class="modal fade" id="indicaciones" tabindex="-1" role="dialog" aria-labelledby="indicacionesTitle" aria-hidden="false">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="indicacionesTitle">Indicaciones</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <table class="table">
                    <tr *ngFor="let indicaciones of listaIndicaciones">
                        <td>{{indicaciones.descripcion}}</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</div>
</section>
<app-loader [isLoading]="isLoading"></app-loader>