import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { NotasService } from 'src/app/services/notasService.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-dialogo-nota-bitacora',
  templateUrl: './dialogo-nota-bitacora.component.html',
  styleUrls: ['./dialogo-nota-bitacora.component.scss']
})
export class DialogoNotaBitacoraComponent implements OnInit {
  isLoading = false;
  isUpdate = false;
  allowButton = false;
  notaForm: UntypedFormGroup;
  conceptos: any;
  validationMessages = {
		'nota': [
		  { type: 'required', message: 'El campo Nota es necesario.' },
		  { type: 'pattern', message: 'El campo Nota no debe estar vacío.' },
		]
	};
  checkDisabled = true;
  cuvs: any;
  nota: any;
  idOrden = window.localStorage.getItem('orden');
  cuvsSelected: any;
  subUse = new Subject<any>();
  datosNotas: any;
  idConEdi: number;

  constructor(private notasService: NotasService,
              public dialogRef: MatDialogRef<DialogoNotaBitacoraComponent>,
              // private formBuilder: UntypedFormBuilder,
              private activatedRoute: ActivatedRoute,
              private formBuilder: UntypedFormBuilder,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.notaForm = this.formBuilder.group({
      id: new UntypedFormControl(""),
      id_orden: new UntypedFormControl(""),
      nota: new UntypedFormControl("", Validators.compose([
        Validators.required,
      ])),
      cuvs: new UntypedFormControl(""),
      concepto: new UntypedFormControl(""),
      solDTU: new UntypedFormControl(""),
    });
  }

  ngOnInit(): void {
    this.conceptos = this.data.conceptos;
    this.cuvs = this.data.cuvs;
    this.subUse = this.data.subUse;
	this.datosNotas = Object.assign([], this.data.element);
	console.log(this.idOrden);

	if (this.data.bool === 1) {
		const datoCuvs = [];

		this.datosNotas.concepto.forEach(datCon => {
			this.idConEdi = datCon.id_concepto;
			this.datosNotas.concepto = this.idConEdi;
		});

		this.datosNotas.cuvs.forEach(datCuv => {
			datoCuvs.push(datCuv.cuv);
		});
		if (datoCuvs.length > 0) {
			this.cuvsSelected = datoCuvs;
			this.datosNotas.cuvs = datoCuvs;
			this.checkDisabled = false;
		}

		this.isUpdate = true;
		this.notaForm.patchValue(this.datosNotas);
	} else {
		this.isUpdate = false;
	}
  }

  onCloseModal(){
		this.dialogRef.close();
	}

  storeNota(nota) {
		this.notaForm.controls['id_orden'].setValue(this.idOrden);
		this.nota = nota.value;
    
		this.notasService.storeNota(this.nota).subscribe(res => {
			this.dialogRef.close('guardar');

			const cuvsNew = [];
			const concNew = [];
			this.cuvsSelected.forEach(element => {
				const dato = this.cuvs.find(cuv => cuv.cuv === element);
				if (dato) {
					cuvsNew.push(dato);
				}
			});
			res.cuvs = cuvsNew;

			this.conceptos.forEach(element => {
				element.partida.conceptos.forEach(idConc => {
					if (idConc.id_concepto === this.nota.concepto) {
						concNew.push(idConc);
					}
				});
			});
			res.concepto = concNew;
			this.subUse.next(res);
		}, error => {
			var errorF;
			(error.error.message) != null ?
				errorF = error.error.message :
				errorF = 'Hubo un error al crear la nota!';
			Swal.fire({
				icon: 'error',
				title: 'Error...',
				text: errorF,
				footer: 'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.'
			});
		})
	}

	updateNota(nota) {
		this.nota = nota.value;
		delete this.nota.orden;

		this.notasService.updateNota(this.nota).subscribe(res => {
			this.dialogRef.close('actalizar');
			const cuvsNew = [];
			const concNew = [];
			this.cuvsSelected.forEach(element => {
				const dato = this.cuvs.find(cuv => cuv.cuv === element);
				if (dato) {
					cuvsNew.push(dato);
				}
			});
			res.cuvs = cuvsNew;

			this.conceptos.forEach(element => {
				element.partida.conceptos.forEach(idConc => {
					if (idConc.id_concepto === this.nota.concepto) {
						concNew.push(idConc);
					}
				});
			});
			res.concepto = concNew;

			this.subUse.next(res);
		}, error => {
			//loader false
			var errorF;
			(error.error.message) != null ?
				errorF = error.error.message :
				errorF = 'Hubo un error al actualizar la nota!';
			Swal.fire({
				icon: 'error',
				title: 'Error...',
				text: errorF,
				footer: 'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.'
			});
		});
	}

  	notaChange() {
		this.cuvsSelected = this.notaForm.value['cuvs'];

		if (this.isUpdate) {
			if (this.notaForm.get('nota').value != null) {
				this.allowButton = true;
			}

			if (this.notaForm.get('nota').value == "" && this.notaForm.get('cuvs').value.length > 0) {
				this.allowButton = false;
      		}
		} else {
			if (this.notaForm.get('nota').value != null && this.notaForm.get('nota').value != "") {
				this.allowButton = true;
	      	} else {
				this.allowButton = false;
	      	}
		}
		
		if (this.cuvsSelected.length > 0) {
			this.checkDisabled = false;
		}
	}
}
