import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainViewComponent } from './components/shared/main-view/main-view.component';
import { NewProjectComponent } from './components/new-project/new-project.component';
import { UsersComponent } from './components/users/users.component';
import { UsuarioComponent } from './components/usuario/usuario.component';
import { ObrasComponent } from './components/obras/obras.component';
import { RevisionDocumentalComponent } from './components/revision-documental/revision-documental';
import { SembradoComponent } from './components/sembrado/sembrado.component';
import { LoginComponent } from './components/shared/login/login.component';
import { VerificacionesComponent } from './components/verificaciones/verificaciones.component';
import { SelectProcessComponent } from './components/select-process/select-process.component';
import { InfoObraComponent } from './components/info-obra/info-obra.component';
import { ReportesDisponiblesComponent } from './components/reportes-disponibles/reportes-disponibles.component'
import { HistorialReportesComponent } from './components/historial-reportes/historial-reportes.component'
import { DescargarReportesComponent } from './components/descargar-reportes/descargar-reportes.component'
import { EcotecniasComponent } from './components/ecotecnias/ecotecnias.component'
import { AuthGuard } from './auth.guard';
import { ReporteComponent } from './components/reporte/reporte.component';
import { DtuComponent } from './components/dtu/dtu.component';
import { NotasBitacoraComponent } from './components/notas-bitacora/notas-bitacora.component';
import { PruebasLaboratorioComponent } from './components/pruebas-laboratorio/pruebas-laboratorio.component';
import { SelectorObraComponent } from './components/shared/selector-obra/selector-obra.component';

const role = localStorage.getItem('SESSION_ROLE');
const user = localStorage.getItem('SESSION_USER_ID');

const routes: Routes = [
	{ path: 'login', component: LoginComponent },
	{
		path: '', component: MainViewComponent, children: [
			{
				path: '', component: ObrasComponent, canActivate: [AuthGuard], data: {
					role: role
				}
			},
			{
				path: 'crear-obra', component: NewProjectComponent, canActivate: [AuthGuard], data: {
					role: role
				}
			},
			{
				path: 'listado-obras', component: ObrasComponent, canActivate: [AuthGuard], data: {
					role: role
				}
			},
			{
				path: 'usuarios', component: UsersComponent, canActivate: [AuthGuard], data: {
					role: role
				}
			},
			{
				path: 'usuario', component: UsuarioComponent, canActivate: [AuthGuard], data: {
					role: role
				}
			},
			{
				path: 'revision-documental', component: RevisionDocumentalComponent, canActivate: [AuthGuard], data: {
					role: role
				}
			},
			{
				path: 'sembrado', component: SembradoComponent, canActivate: [AuthGuard], data: {
					role: role
				}
			},
			{
				path: 'verificaciones', component: VerificacionesComponent, canActivate: [AuthGuard], data: {
					role: role
				}
			},
			{
				path: 'seleccionar-proceso', component: SelectProcessComponent, canActivate: [AuthGuard], data: {
					role: role
				}
			},
			{
				path: 'reportes-disponibles', component: ReportesDisponiblesComponent, canActivate: [AuthGuard], data: {
					role: role
				}
			},
			{
				path: 'info-obra', component: InfoObraComponent, canActivate: [AuthGuard], data: {
					role: role
				}
			},
			{
				path: 'reporte/:id', component: ReporteComponent, canActivate: [AuthGuard], data: {
					role: role
				}
			},
			{
				path: 'descargar-reportes', component: DescargarReportesComponent, canActivate: [AuthGuard], data: {
					role: role
				}
			},
			{
				path: 'historial-reportes', component: HistorialReportesComponent, canActivate: [AuthGuard], data: {
					role: role
				}
			},
			{
				path: 'listado-ecotecnias', component: EcotecniasComponent, canActivate: [AuthGuard], data: {
					role: role
				}
			},
			{
				path: 'index-dtu', component: DtuComponent, canActivate: [AuthGuard], data: {
					role: role
				}
			},
			{
				path: 'set-dtu', component: DtuComponent, canActivate: [AuthGuard], data: {
					role: role
				}
			},
			{
				path: 'show-dtu', component: DtuComponent, canActivate: [AuthGuard], data: {
					role: role
				}
			},
			//NotasBitacora
			{
				path: 'index-notas', component: NotasBitacoraComponent, canActivate: [AuthGuard], data: {
					role: role
				}
			},
			{
				path: 'set-nota', component: NotasBitacoraComponent, canActivate: [AuthGuard], data: {
					role: role
				}
			},
			{
				path: 'show-nota', component: NotasBitacoraComponent, canActivate: [AuthGuard], data: {
					role: role
				}
			},
			//Pruebas de Laboratorio
			{
				path: 'index-pruebas', component: PruebasLaboratorioComponent, canActivate: [AuthGuard], data: {
					role: role
				}
			},
			{
				path: 'set-prueba', component: PruebasLaboratorioComponent, canActivate: [AuthGuard], data: {
					role: role
				}
			},
			{
				path: 'show-prueba', component: PruebasLaboratorioComponent, canActivate: [AuthGuard], data: {
					role: role
				}
			},
			{
				path: 'selector-obra', component: SelectorObraComponent, canActivate: [AuthGuard], data: {
					role: role
				}
			}
		]
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
	exports: [RouterModule]
})
export class AppRoutingModule { }
