<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">
            <ng-container *ngIf="isUpdate == true;else isStoreTitle">
                Actualizar Ecotecnia
            </ng-container>
            <ng-template #isStoreTitle>
                Crear Ecotecnia
            </ng-template>
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="ecoForm">
            <div class="row">
                <ng-container *ngIf="isUpdate == true;else isStoreForm">
                    <div class="col col-sm-3 col-md-3 col-lg-3">
                        <mat-form-field class="w-100">
                            <mat-label>ID</mat-label>
                            <!-- <input type="text" matInput formControlName="id" readonly> -->
                            <input type="text" matInput [value]="viewId" readonly>
                        </mat-form-field>
                    </div>
                    <div class="col col-sm-9 col-md-9 col-lg-9">
                        <mat-form-field class="w-100">
                            <mat-label>Ecotecnia</mat-label>
                            <input required type="text" matInput (ngModelChange)="ecotecniaChange($event)"
                                formControlName="ecotecnia">
                        </mat-form-field>
                    </div>
                </ng-container>
                <ng-template #isStoreForm>
                    <div class="col col-12">
                        <mat-form-field class="w-100">
                            <mat-label>Ecotecnia</mat-label>
                            <input required type="text" matInput (ngModelChange)="ecotecniaChange($event)"
                                formControlName="ecotecnia">
                        </mat-form-field>
                    </div>
                </ng-template>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <ng-container *ngIf="isUpdate == true;else isStoreFooter">
            <button type="button" class="btn btn-primary" (click)="updateEco(ecoForm)"
                [disabled]="!(allowButton)">
                Actualizar
            </button>
        </ng-container>
        <ng-template #isStoreFooter>
            <button type="button" class="btn btn-primary" (click)="storeEco(ecoForm)"
                [disabled]="!(allowButton)">
                Guardar
            </button>
        </ng-template>
    </div>
</div>