import { Component, ElementRef, OnInit, ViewChild, Renderer2, HostListener } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, FormControlName, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Cuv } from 'src/app/entities/cuv';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { ProjectsService } from 'src/app/services/projects.service';
import { EcotecniasService } from 'src/app/services/ecotecniasService.service';
import { VerificacionesService } from 'src/app/services/verificaciones.service';
import Swal from 'sweetalert2';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { pipe } from 'rxjs';

export interface Ecotecnia {
	id: number;
	ecotecnia: string;
}
declare const navigator: any;
@Component({
	selector: 'app-verificaciones',
	templateUrl: './verificaciones.component.html',
	styleUrls: ['./verificaciones.component.scss']
})

export class VerificacionesComponent implements OnInit {
	//camera and screenshot containers
	@ViewChild('video', { static: true }) videoElement: ElementRef;
	@ViewChild('canvas', { static: true }) canvas: ElementRef;
	@ViewChild('selectManzanas') selectManzanas: MatSelect;
	@ViewChild('selectLotes') selectLotes: MatSelect;
	@ViewChild('selectCuvs') selectCuvs: MatSelect;
    @ViewChild('closeBtn', { static: true }) closeBtn: ElementRef;
	@ViewChild('tomarEvidencia') searchElement: ElementRef;

	public userRole: any;

	public idOrden: string = this.activatedRoute.snapshot.queryParams["orden"]
	public isLoading: boolean = false;
	public verificacionesForm: UntypedFormGroup;
	public ecoForm: UntypedFormGroup;
	public lotes: Array<any> = [];
	public manzanas: Array<{}>;
	public allManzanasSelected: boolean = false;
	public allLotesSelected: boolean = false;
	public allCuvsSelected: boolean = false;
	public allowComment: boolean = false;
	public allowHistorial: boolean = false;
	public allowEcos: boolean = false;
	public allowEmptyComment: boolean = false;
	public cuvs: Array<any> = [];
	public response: Array<any> = [];
	public selectedCuv: any;
	public selectedLote: any;
	public selectedManzana: any;
	public selectedConcept: any;
	public selectedAvance: any;
	public conceptos: Array<any> = [];
	public avances: Array<number> = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
	public avanceIndividual: any;
	public videoWidth = 0;
	public videoHeight = 0;
	public selected: number = 0;
	
	public defaultsOpts: any = { audio: false, video: true };
	public shouldFaceUser: boolean = true;
	public supports = navigator.mediaDevices.getSupportedConstraints();
	public switchCameraEnable: boolean = this.supports['facingMode'] == true ? true : false;
	public stream: any;
	public constraints = {
		video: true
	};
	public comentario: string = "";
	public si: string = "";
	public no1: string = "";
	public no2: string = "";
	public cumplimientoOpts: Array<any> = ["C", "E", "P", "C y E", "C y P", "E y P", "C; E y P"]

	public message: string = "";
	public evidencias: Array<any> = ["", "", "", ""];
	public isConceptSelected: boolean = false;
	public isCuvSelected: boolean = false;
	public isLoteSelected: boolean = false;
	public isManzanaSelected: boolean = false;
	public isAvanceSelected: boolean = false;

	public ecotecniasSelected: any = null;
	public ecotecniasToggle: string;
	public ecotecnias: any;
	public allowButton: boolean = false;
	public ecotecnia: any = null;

	constructor(
		private formBuilder: UntypedFormBuilder,
		private activatedRoute: ActivatedRoute,
		private projectsService: ProjectsService,
		private verificacionesService: VerificacionesService,
		private ecotecniasService: EcotecniasService,
		private renderer: Renderer2,
	) {
	}

	@HostListener('window:beforeunload', ['$event'])
	onBeforeUnload(event: Event): void {
		this.closeModal();
		this.cancelarSubida();
	}

	ngOnInit(): void {
		this.userRole = localStorage.getItem('SESSION_ROLE');
		this.verificacionesForm = this.formBuilder.group({
			cuv: new UntypedFormControl(""),
			lote: new UntypedFormControl(""),
			manzana: new UntypedFormControl(""),
			concepto: new UntypedFormControl(""),
			avance: new UntypedFormControl(""),
			comentario: new UntypedFormControl(""),
			ecotecnias: new UntypedFormControl(""),
			si: new UntypedFormControl(""),
			no1: new UntypedFormControl(""),
			no2: new UntypedFormControl(""),
			fotos: new UntypedFormControl("")
		});

		this.ecoForm = this.formBuilder.group({
			ecotecnia: new UntypedFormControl("")
		});

		navigator.permissions.query({ name: 'camera' }).then(
			function (permissionStatus) {
				permissionStatus.onchange = function () {

				}
			}
		);

		this.getAllCuvs();
		this.getAllConceptos();
		this.getEcotecnias();
	}

	getAllCuvs() {
		let idOrden: object = {};
		idOrden['id_orden'] = this.activatedRoute.snapshot.queryParams["orden"];
		this.projectsService.getAllCuvs(idOrden).subscribe(res => {
			this.isLoading = true;
			this.response = res;
			this.manzanas = Object.keys(res);
			this.isLoading = false;
		}, error => {
			this.isLoading = false;
		});
	}

	onSelectAll(): any {
		if (this.ecotecnias.length == this.ecotecniasSelected.length) {
			this.ecotecniasSelected = [];
		} else if (this.ecotecnias.length > this.ecotecniasSelected.length) {
			this.ecotecniasSelected = this.ecotecnias.map(item => item.id);
		}
		this.ecotecnias.length === this.ecotecniasSelected.length ? this.ecotecniasToggle = "Deseleccionar todas" : this.ecotecniasToggle = "Seleccionar todas";
	}

	getEcotecnias() {
		this.ecotecniasService.getEcotecnias().subscribe(res => {
			this.isLoading = true;
			this.ecotecnias = res;
			this.isLoading = false;
		}, error => {
			//loader false
			var errorF;
			(error.error.message) != null ?
				errorF = error.error.message :
				errorF = 'Hubo un error al cargar las ecotecnias!';
			this.isLoading = false;
			Swal.fire({
				icon: 'error',
				title: 'Error...',
				text: errorF,
				footer: 'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.'
			}).then((result) => {
				if (result.isConfirmed) {
					window.history.back();
				}
			});
		});
	}

	getEcotecnias1(): Observable<Array<Ecotecnia>> {
		const result: BehaviorSubject<Array<any>> = new BehaviorSubject([]);
		this.ecotecniasService.getEcotecnias().subscribe((data: any) => {
		  // Your parsing code...
		  result.next(data);
		  result.complete();
		});
		return result;
	  }
	
	getAllConceptos() {
		let datos = {};
		datos['id_orden'] = this.activatedRoute.snapshot.queryParams["orden"];
		this.verificacionesService.getConceptosRelacionados(datos).subscribe(res => {
			this.conceptos = res;
		}, error => {
			Swal.fire({
				icon: 'error',
				title: 'Error...',
				text: 'Hubo un error al cargar los conceptos!',
				footer: 'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.'
			})
		})
	}

	conceptoSeleccionado(event) {
		this.isConceptSelected = true;
		this.message = '';
		this.selectedConcept = event.value;
		let formData = {};
		let cuvs = Array();

		formData["id_orden"] = this.idOrden;
		this.selectedCuv.forEach(cuv => {
			if (cuv != null) {
				cuvs.push(cuv['cuv']);
			}
		});
		formData['cuvs'] = cuvs;
		formData['id_concepto'] = this.selectedConcept.id_concepto;
		this.verificacionesService.getAvances(formData).subscribe(res => {
			this.avanceIndividual = res;

			if (cuvs.length === 1 && this.avanceIndividual.length === 1) {
				this.allowEmptyComment = true;
				if (this.avanceIndividual[0].comentarios_verificaciones.length > 0) {
					this.allowHistorial = true;
				}
				this.ecotecniasSelected = this.avanceIndividual[0].cuv.ecotecnias.map(element => element.id_ecotecnia);
				this.ecotecnias.length === this.ecotecniasSelected.length ? this.ecotecniasToggle = "Deseleccionar todas" : this.ecotecniasToggle = "Seleccionar todas";

				this.allowEcos = true;
				this.isAvanceSelected = true;
				this.selectedAvance = res[0].avance;
			} else if (cuvs.length === 1 && this.avanceIndividual.length === 0) {
				//this.allowEcos = true;
				this.allowEmptyComment = true;
				this.message = 'No hay verificaciones anteriores para el CUV seleccionado en el concepto actual.';
			} else if (cuvs.length > 1) {
				this.message = "Para ver el historial de comentarios seleccionar el CUV individualmente. Al crear una verificación con varios CUV seleccionados, estos compartiran el mismo comentario.";
				this.allowEmptyComment = true;
			}
		}, error => {
			Swal.fire({
				icon: 'error',
				title: 'Error...',
				text: 'Hubo un error al cargar el avance del concepto!',
				footer: 'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.'
			});
		});
	}

	cuvSeleccionada(event) {
		this.isCuvSelected = true;
		this.selectedCuv = event.value;
		this.isConceptSelected = false;
		this.selectedConcept = null;
		this.isAvanceSelected = false;
		this.selectedAvance = null;
		this.avanceIndividual = null;
		this.allowComment = false;
		this.allowEmptyComment = false;
		this.allowHistorial = false;
		this.allowEcos = false;
		this.message = '';
		this.verificacionesForm.controls['ecotecnias'].setValue(null);
	}

	selectAllManzanas() {
		if (this.allManzanasSelected == false) {
			this.allManzanasSelected = true;
			this.selectManzanas.options.forEach((item: MatOption) => item.select());
		} else {
			this.allManzanasSelected = false;
			this.selectManzanas.options.forEach((item: MatOption) => item.deselect());
		}
	}

	selectAllLotes() {
		if (this.allLotesSelected == false) {
			this.allLotesSelected = true;
			this.selectLotes.options.forEach((item: MatOption) => item.select());
		} else {
			this.allLotesSelected = false;
			this.selectLotes.options.forEach((item: MatOption) => item.deselect());
		}
	}

	selectAllCuvs() {
		if (this.allCuvsSelected == false) {
			this.allCuvsSelected = true;
			this.selectCuvs.options.forEach((item: MatOption) => item.select());
		} else {
			this.allCuvsSelected = false;
			this.selectCuvs.options.forEach((item: MatOption) => item.deselect());
		}
	}

	optionManzanaClick() {
		let newStatus = true;
		this.selectManzanas.options.forEach((item: MatOption) => {
			if (!item.selected) {
				newStatus = false;
				this.selectLotes.options.forEach((item: MatOption) => item.deselect());
				this.selectCuvs.options.forEach((item: MatOption) => item.deselect());
			}
		});
	}

	optionLoteClick() {
		let newStatus = true;
		this.selectLotes.options.forEach((item: MatOption) => {
			if (!item.selected) {
				newStatus = false;
				this.selectCuvs.options.forEach((item: MatOption) => item.deselect());
			}
		});
	}

	optionCuvClick() {
		let newStatus = true;
		this.selectCuvs.options.forEach((item: MatOption) => {
			if (!item.selected) {
				newStatus = false;
			}
		});
	}

	manzanaSeleccionada(event) {
		var manzanas = event.value;
		var lotes = [];
		var indexManzanas = [];

		for (var p in manzanas) {
			if (manzanas[p] != 0)
				indexManzanas.push(manzanas[p].value);
		}

		indexManzanas.forEach(manzana => {
			for (var p in this.response[manzana]) {
				lotes.push(this.response[manzana][p])
			}
		});
		this.lotes = lotes;
	}

	loteSeleccionado(event) {
		var lotes = event.value;
		var indexLotes = [];

		for (var p in lotes) {
			if (lotes[p] != 0) {
				lotes[p].value.forEach(element => {
					indexLotes.push(element);
				});
			}
		}
		this.cuvs = indexLotes;
	}

	avanceSeleccionado(event) {
		this.isAvanceSelected = true;
		this.selectedAvance = event.value;
	}

	settedComment(searchValue: string): void {
		this.comentario = searchValue;
	}

	cumplimientoSi(event) {
		this.si = event.value;
	}
	cumplimientoNo1(event) {
		this.no1 = event.value;
	}
	cumplimientoNo2(event) {
		this.no2 = event.value;
	}
	resetSi() {
		this.si = null
	}
	resetNo1() {
		this.no1 = null;
	}
	resetNo2() {
		this.no2 = null;
	}

	subirFoto(event) {

	}

	guardarVerificacion(event) {
		let formData = new FormData();
		if (this.selectedCuv[0] == 0) {
			this.selectedCuv.splice(0, 1);
		}
		if (this.isConceptSelected && this.isCuvSelected && this.isAvanceSelected && (this.selectedCuv.length > 0)) {
			formData.append('id_orden', this.idOrden);
			formData.append('cuv', JSON.stringify(this.selectedCuv));
			formData.append('id_concepto', this.selectedConcept.id_concepto);
			formData.append('avance', this.selectedAvance);
			formData.append('comentario', this.comentario ? this.comentario : 'Sin comentario');
			formData.append('si', this.si);
			formData.append('no1', this.no1);
			formData.append('no2', this.no2);
			formData.append('evidencias', JSON.stringify(this.evidencias));
			formData.append('ecotecnias', JSON.stringify(this.ecotecniasSelected));

			this.showLoader();
			this.verificacionesService.setAvances(formData).subscribe(res => {
				Swal.fire(
					'Guardado!',
					'Avance guardado correctamente',
					'success'
				).then((result) => {
					this.hideLoader();
				})
			}, error => {
				//loader false
				var errorF;
				(error.error.message) != null ?
					errorF = error.error.message :
					errorF = 'Hubo un error al crear las verificaciones!';
				this.isLoading = false;
				Swal.fire({
					icon: 'error',
					title: 'Error...',
					text: errorF,
					footer: 'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.'
				}).then((result) => {
					if (result.isConfirmed) {
						window.history.back();
					}
				});
			});
		} else {
			Swal.fire({
				icon: 'error',
				title: 'Error...',
				text: 'No hay CUVS seleccionados!',
				footer: 'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.'
			})
		}
	}
	showLoader() {
		window.scrollTo({ 
			top: 0, 
			left: 0, 
			behavior: 'smooth' 
		});
		document.body.style.overflowY = "hidden";
		this.isLoading = true;
	}
	hideLoader() {
		this.isLoading = false;
		document.body.style.overflowY = "auto";
	}
	switchCamera() {
		if( this.stream == null ) return
		// we need to flip, stop everything
		this.stream.getTracks().forEach(t => {
		  t.stop();
		});
		// toggle / flip
		this.shouldFaceUser = !this.shouldFaceUser;
		this.startCamera(this.selected);
	}

	startCamera(number) {
		this.selected = number;
		this.defaultsOpts = {
			audio: false,
			video: {
				facingMode: this.shouldFaceUser ? 'environment' : 'user',
			}
		};
		if (!!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)) {
			navigator.mediaDevices.getUserMedia(this.defaultsOpts).then(this.attachVideo.bind(this)).catch(this.handleError);
		} else {
			Swal.fire({
				icon: 'error',
				title: 'Error...',
				text: 'Error al intentar usar la cámara!',
				footer: 'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.'
			})
		}
	}

	handleError(error) {
		console.error('Error: ', error);
	}

	attachVideo(stream) {
		this.stream = stream;
		this.renderer.setProperty(this.videoElement.nativeElement, 'srcObject', stream);
		this.renderer.listen(this.videoElement.nativeElement, 'play', (event) => {
			this.videoHeight = this.videoElement.nativeElement.videoHeight;
			this.videoWidth = this.videoElement.nativeElement.videoWidth;
		});
	}

	capture() {
		this.renderer.setProperty(this.canvas.nativeElement, 'width', this.videoWidth);
		this.renderer.setProperty(this.canvas.nativeElement, 'height', this.videoHeight);
		this.canvas.nativeElement.getContext('2d').drawImage(this.videoElement.nativeElement, 0, 0);
		this.resizeImage(this.canvas.nativeElement.toDataURL("image/png"), 350, 650).then(result => this.evidencias[this.selected] = result);
		navigator.mediaDevices.getUserMedia(this.constraints).then(mediaStream => {
			const tracks = this.stream.getTracks();
			tracks.forEach(track => track.stop())
		});
	}

	resizeImage(base64Str, maxWidth = 350, maxHeight = 650) {
		return new Promise((resolve) => {
			let img = new Image()
			img.src = base64Str
			img.onload = () => {
				let canvas = document.createElement('canvas')
				const MAX_WIDTH = maxWidth
				const MAX_HEIGHT = maxHeight
				let width = img.width
				let height = img.height

				if (width > height) {
					if (width > MAX_WIDTH) {
						height *= MAX_WIDTH / width
						width = MAX_WIDTH
					}
				} else {
					if (height > MAX_HEIGHT) {
						width *= MAX_HEIGHT / height
						height = MAX_HEIGHT
					}
				}
				canvas.width = width
				canvas.height = height
				let ctx = canvas.getContext('2d')
				ctx.drawImage(img, 0, 0, width, height)
				resolve(canvas.toDataURL())
			}
		})
	}

	storeEco(ecotecnologia) {
		let ecotecnia = ecotecnologia.value;
		this.isLoading = true;
		this.ecotecniasService.storeEcotecnia(ecotecnia).subscribe(res => {
			this.isLoading = false;
			Swal.fire(
				'Guardado!',
				'Ecotecnia guardada correctamente',
				'success'
			)
			// window.location.reload();
		}, error => {
			this.isLoading = false;
			var errorF;
			(error.error.message) != null ?
				errorF = error.error.message :
				errorF = 'Hubo un error al crear la ecotecnia!';
			this.isLoading = false;
			Swal.fire({
				icon: 'error',
				title: 'Error...',
				text: errorF,
				footer: 'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.'
			}).then((result) => {
				if (result.isConfirmed) {
					window.history.back();
				}
			});
		});
	}

	ecotecniaChange(event) {
		this.ecotecnia = event;
		if (this.ecotecnia != null)
			this.allowButton = true;
	}

	private closeModal(): void {
		console.log('cierra modal;')
        this.closeBtn.nativeElement.click();
    }

	cancelarSubida() {
		if (this.stream) {
			this.stream.getTracks().forEach(track => track.stop());
			this.stream = null;
			console.log('cancela subida;')
		}
	}

	focus(){
		setTimeout(function() {
			document.querySelector("video").scrollIntoView({block: "center", behavior: "smooth"});
		}, 1000);
	}
}