<div class="modal-content">
    <div class="modal-header">
        <h2 class="modal-title" id="exampleModalLongTitle">
            <ng-container *ngIf="isUpdate == true;else isStoreTitle">
                Actualizar Nota
            </ng-container>
            <ng-template #isStoreTitle>
                Crear Nota
            </ng-template>
        </h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="onCloseModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="notaForm">
            <ng-container *ngIf="isUpdate === true; else isStoreForm">
                <div class="row">
                    <div class="col col-12">
                        <mat-form-field class="w-100">
                            <mat-label>Nota</mat-label>
                            <textarea rows="4" required type="text" matInput (ngModelChange)="notaChange()" formControlName="nota"></textarea>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col col-12">
                        <label>Vivienda(s)</label>
                        <ng-select [items]="cuvs" formControlName='cuvs' (change)="notaChange()" bindLabel="cuv" bindValue="cuv" multiple="true" clearAllText="Deseleccionar todas" [selectableGroupAsModel]="true" #select>
                            <ng-template ng-option-tmp let-item="item">
                                ID Vivienda: <strong>{{ item.id_vivienda }} </strong>
                                - CUV: <strong>{{ item.cuv }}</strong>
                                - Lote: <strong>{{ item.lote }}</strong>
                                - Manzana: <strong>{{ item.manzana }}</strong>
                            </ng-template>
                        </ng-select>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 py-2">
                        <mat-label for="concepto">Concepto</mat-label>
                        <br>
                        <mat-select class="select" formControlName="concepto" (ngModelChange)="notaChange()">
                            <mat-option>Sin Concepto</mat-option>
                            <mat-optgroup *ngFor="let partida of conceptos" [label]="partida.partida.nombre" [disabled]="partida.disabled">
                                <mat-option *ngFor="let concepto of partida.partida.conceptos" [value]="concepto.id_concepto" selected="idConEdi === concepto.id_concepto">
                                    {{ concepto.nombre }}
                                </mat-option>
                            </mat-optgroup>
                        </mat-select>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 py-2">
                        <mat-checkbox title="Solicitar DTU para los cuvs seleccionados" (ngModelChange)="notaChange()" formControlName="solDTU" id="solDTU" class="solDTU" class="example-margin" [disabled]="checkDisabled">
                            Solicitud de DTU
                        </mat-checkbox>
                    </div>
                </div>
            </ng-container>
            <ng-template #isStoreForm>
                <div class="row">
                    <div class="col col-12">
                        <mat-form-field class="w-100">
                            <mat-label>Nota</mat-label>
                            <textarea rows="4" required type="text" matInput (ngModelChange)="notaChange()" formControlName="nota"></textarea>
                            <mat-error *ngFor="let validation of validationMessages.nota">
                                <mat-error class="error-message" *ngIf="notaForm.get('nota').hasError(validation.type) && (notaForm.get('nota').dirty || notaForm.get('nota').touched)">{{validation.message}}</mat-error>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col col-12">
                        <label>Vivienda(s)</label>
                        <ng-select [items]="cuvs" formControlName='cuvs' (change)="notaChange()" bindLabel="cuv" bindValue="cuv" multiple="true" clearAllText="Deseleccionar todas" [selectableGroupAsModel]="true" #select>
                            <ng-template ng-option-tmp let-item="item">
                                ID Vivienda: <strong>{{ item.id_vivienda }} </strong>
                                - CUV: <strong>{{ item.cuv }}</strong>
                                - Lote: <strong>{{ item.lote }}</strong>
                                - Manzana: <strong>{{ item.manzana }}</strong>
                            </ng-template>
                        </ng-select>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 py-2">
                        <mat-label for="concepto">Concepto</mat-label>
                        <mat-select class="select" formControlName="concepto" (ngModelChange)="notaChange()">
                            <mat-option>Sin Concepto</mat-option>
                            <mat-optgroup *ngFor="let partida of conceptos" [label]="partida.partida.nombre" [disabled]="partida.disabled">
                                <mat-option *ngFor="let concepto of partida.partida.conceptos" [value]="concepto.id_concepto">
                                    {{ concepto.nombre }}
                                </mat-option>
                            </mat-optgroup>
                        </mat-select>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 py-2">
                        <mat-checkbox title="Solicitar DTU para los cuvs seleccionados" (change)="notaChange()" formControlName="solDTU" class="example-margin" [disabled]="checkDisabled">
                            Solicitud de DTU
                        </mat-checkbox>
                    </div>
                </div>
            </ng-template>
        </form>
    </div>
    <div class="modal-footer">
        <ng-container *ngIf="isUpdate == true;else isStoreFooter">
            <button type="button" class="btn btn-primary" (click)="updateNota(notaForm)" [disabled]="!(allowButton)">
                Actualizar
            </button>
        </ng-container>
        <ng-template #isStoreFooter>
            <button type="button" class="btn btn-primary" (click)="storeNota(notaForm)">
                Guardar
            </button>
        </ng-template>
    </div>
</div>