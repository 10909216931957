import { Component, OnInit } from '@angular/core';
import { ReporteService } from 'src/app/services/reporte.service';
import {animate, state, style, transition, trigger} from '@angular/animations';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-historial-reportes',
  templateUrl: './historial-reportes.component.html',
  styleUrls: ['./historial-reportes.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class HistorialReportesComponent implements OnInit {

	public reportes: any;
	public userId: any;
	public userRole: any;
	public columnsToDisplay = ['orden'];
	public subColumnsToDisplay = ['1', '2','3', '4'];
	public expandedElement: any | null;
	constructor(
		private reporteService: ReporteService,
	) { }

  	ngOnInit(): void {
		this.userId = localStorage.getItem('SESSION_USER_ID');
		this.userRole = localStorage.getItem('SESSION_ROLE');
		
		let datos = {};
		datos['id_verificador'] = this.userId;
		datos['user_role'] = this.userRole;
		this.reporteService.getArchivosReportes(datos).subscribe(res => {
			this.reportes = Object.keys(res).map((key) => [Number(key), res[key]]);
			if (this.reportes == null) {
				Swal.fire(
					'Reporte No disponible',
					'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.',
					'question'
				)
			}
		}, error => {
			//loader false
			var errorF;
			(error.error.message) != null ?
				errorF = error.error.message :
				errorF = 'Hubo un error al cargar los reportes!';
			Swal.fire({
				icon: 'error',
				title: 'Error...',
				text: errorF,
				footer: 'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.'
			}).then((result) => {
				if (result.isConfirmed) {
					window.history.back();
				}
			});
		})
	}

	descargarReporte(url, event){
		let datos = {};
		datos['url'] = url.url;
		datos['nombre'] = url.nombre;
		this.reporteService.descargarArchivoReporte(datos).subscribe(res => {
	
			if (res[1] == null) {
				Swal.fire(
					'Reporte No disponible',
					'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.',
					'question'
				)
			}else{
				let mime = res[0].substr(res[0].indexOf('.'));
				var a = document.createElement("a"); //Create <a>
				if(mime == '.png'){
					a.href = "data:image/png;base64," + res[1]; //Image Base64 Goes here
				}
				if(mime == '.pdf'){
					a.href = "data:application/pdf;base64," + res[1]; //Image Base64 Goes here
				}
				a.download = res[0]+mime; //File name Here
				a.click(); //Downloaded file
			}
		}, error => {
			//loader false
			var errorF;
			(error.error.message) != null ?
				errorF = error.error.message :
				errorF = 'Hubo un error al descargar el reporte!';
			Swal.fire({
				icon: 'error',
				title: 'Error...',
				text: errorF,
				footer: 'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.'
			}).then((result) => {
				if (result.isConfirmed) {
					window.history.back();
				}
			});
		})
	}
}
