<section class="animate__animated animate__fadeIn d-flex align-items-center">
    <div class="container">
        <ng-container *ngIf="idOrden != null">
            <ng-container *ngIf="method === 'form'">
                <form [formGroup]="pruebaForm" enctype="multipart/form-data">
                    <div class="modal-content">
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-12 py-2">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-6 col-lg-6">
                                            <mat-label for="manzana">Seleccionar Manzana</mat-label>
                                            <mat-select formControlName="manzana" (selectionChange)="manzanaSeleccionada($event)" #selectManzanas multiple>
                                                <mat-option [value]="0" (click)="selectAllManzanas()">Seleccionar Todas</mat-option>
                                                <mat-option (click)="optionManzanaClick()" *ngFor="let manzana of manzanas | keyvalue" [value]="manzana">
                                                    Manzana: {{ manzana.value[0] }}
                                                </mat-option>
                                            </mat-select>
                                        </div>
                                        <div class="col-sm-12 col-md-6 col-lg-6">
                                            <mat-label for="lote">Seleccionar Lote</mat-label>
                                            <mat-select formControlName="lote" (selectionChange)="loteSeleccionado($event)" #selectLotes multiple [disabled]="!isManzanaSelected">
                                                <mat-option [value]="0" (click)="selectAllLotes()">Seleccionar Todos</mat-option>
                                                <mat-option (click)="optionLoteClick()" *ngFor="let lote of lotes | keyvalue " [value]="lote">
                                                    Manzana {{ lote.value[0].manzana }} Lote: {{ lote.value[0].lote }}
                                                </mat-option>
                                            </mat-select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 py-2" style="border-bottom: 1px solid rgba(213,213,213,1);">
                                    <mat-label for="cuv">Seleccionar CUV a verificar</mat-label>
                                    <mat-select formControlName="cuv" (selectionChange)="cuvSeleccionada($event)" #selectCuvs [disabled]="!isLoteSelected">
                                        <mat-option (click)="optionCuvClick()" *ngFor="let cuv of cuvs" [value]="cuv.cuv">
                                            <div *ngIf="cuv.numero_interior == '' || cuv.numero_interior == null ;else elseTwo">
                                                ID Vivienda: <strong>{{ cuv.id_vivienda }} </strong>
                                                - No. Ext: <strong>{{cuv.numero_exterior}}</strong>
                                                - <strong>{{cuv.prototipo.nombre}}</strong>
                                            </div>
                                            <ng-template #elseTwo>
                                                ID Vivienda: <strong>{{ cuv.id_vivienda }} </strong>
                                                - No. Int: <strong>{{cuv.numero_interior}}</strong>
                                                - No. Ext: <strong>{{cuv.numero_exterior}}</strong>
                                                - <strong>{{cuv.prototipo.nombre}}</strong>
                                            </ng-template>
                                        </mat-option>
                                    </mat-select>
                                </div>
                            </div>
                            <br>
                            <ng-container *ngIf="isCuvSelected">
                                <div class="row">
                                    <div class="col">
                                        <mat-label for="tipo_prueba">Tipo de Prueba</mat-label>
                                        <mat-select formControlName="tipo_prueba" (selectionChange)="tipoSeleccionado($event)" #selectPrueba [disabled]="!isCuvSelected">
                                            <mat-option value="1">Prueba de Laboratorio</mat-option>
                                            <mat-option value="2">Reporte de Estudio</mat-option>
                                            <mat-option value="3">Certificado</mat-option>
                                        </mat-select>
                                        <mat-error *ngFor="let validation of validation_messages.tipo_prueba">
                                            <mat-error class="error-message" *ngIf="pruebaForm.get('tipo_prueba').hasError(validation.type) && (pruebaForm.get('tipo_prueba').dirty || pruebaForm.get('tipo_prueba').touched)">{{validation.message}}</mat-error>
                                        </mat-error>
                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-sm-12 col-md-12 col-lg-12">
                                        <mat-label for="material">Material</mat-label>
                                        <mat-select formControlName="material" (selectionChange)="materialSeleccionado($event)" #selectMaterial>
                                            <mat-option *ngFor="let material of materiales" [value]="material.id">
                                                {{ material.material }}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error *ngFor="let validation of validation_messages.material">
                                            <mat-error class="error-message" *ngIf="pruebaForm.get('material').hasError(validation.type) && (pruebaForm.get('material').dirty || pruebaForm.get('material').touched)">
                                                {{validation.message}}
                                            </mat-error>
                                        </mat-error>
                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-sm-12 col-md-3">
                                        <mat-form-field class="w-100">
                                            <mat-label>Reporte</mat-label>
                                            <input formControlName="reporte" required min="0" type="number" matInput>
                                            <mat-error *ngFor="let validation of validation_messages.reporte">
                                                <mat-error class="error-message" *ngIf="pruebaForm.get('reporte').hasError(validation.type) && (pruebaForm.get('reporte').dirty || pruebaForm.get('reporte').touched)">{{validation.message}}</mat-error>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-sm-12 col-md-3">
                                        <mat-form-field class="w-100">
                                            <mat-label>Días</mat-label>
                                            <input formControlName="dias" required min="0" type="number" matInput>
                                            <mat-error *ngFor="let validation of validation_messages.dias">
                                                <mat-error class="error-message" *ngIf="pruebaForm.get('dias').hasError(validation.type) && (pruebaForm.get('dias').dirty || pruebaForm.get('dias').touched)">{{validation.message}}</mat-error>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-sm-12 col-md-3">
                                        <mat-form-field class="w-100">
                                            <mat-label>Resistencia</mat-label>
                                            <input formControlName="resistencia" required min="0" type="number" matInput>
                                            <mat-error *ngFor="let validation of validation_messages.resistencia">
                                                <mat-error class="error-message" *ngIf="pruebaForm.get('resistencia').hasError(validation.type) && (pruebaForm.get('resistencia').dirty || pruebaForm.get('resistencia').touched)">{{validation.message}}</mat-error>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-sm-12 col-md-3">
                                        <mat-form-field class="w-100">
                                            <mat-label>Humedad</mat-label>
                                            <input formControlName="humedad" required min="0" type="number" matInput>
                                            <mat-error *ngFor="let validation of validation_messages.humedad">
                                                <mat-error class="error-message" *ngIf="pruebaForm.get('humedad').hasError(validation.type) && (pruebaForm.get('humedad').dirty || pruebaForm.get('humedad').touched)">{{validation.message}}</mat-error>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <hr>
                                <button type="submit" class="btn btn-primary" (click)="guardarPrueba(pruebaForm)"
                                    [disabled]="!(isTipoSelected && isCuvSelected && pruebaForm.valid)">Guardar</button>
                            </ng-container>
                        </div>
                    </div>
                </form>
            </ng-container>
            <ng-container *ngIf="method === 'show'">
                <div class="section-title">
                    <h2>Pruebas de Laboratorio</h2>
                    <ng-container *ngIf="isCuvSelected">
                        <h4>Vivienda seleccionada: {{selectedCuv}}</h4>
                    </ng-container>
                </div>
                <br>
                <div class="col-12 py-2" style="border-bottom: 1px solid rgba(213,213,213,1);">
                    <mat-label for="cuv">Seleccionar CUV a verificar</mat-label>
                    <mat-select (selectionChange)="cuvSeleccionada($event)" #selectCuvs>
                        <mat-option (click)="optionCuvClick()" *ngFor="let cuv of cuvs" [value]="cuv.cuv">
                            <div *ngIf="cuv.numero_interior == '' || cuv.numero_interior == null ;else elseTwo">
                                ID Vivienda: <strong>{{ cuv.id_vivienda }} </strong>
                                - No. Ext: <strong>{{cuv.numero_exterior}}</strong>
                                - <strong>{{cuv.prototipo.nombre}}</strong>
                            </div>
                            <ng-template #elseTwo>
                                ID Vivienda: <strong>{{ cuv.id_vivienda }} </strong>
                                - No. Int: <strong>{{cuv.numero_interior}}</strong>
                                - No. Ext: <strong>{{cuv.numero_exterior}}</strong>
                                - <strong>{{cuv.prototipo.nombre}}</strong>
                            </ng-template>
                        </mat-option>
                    </mat-select>
                </div>
                <br>
                <ng-container *ngIf="isCuvSelected">
                    <ng-container *ngIf="pruebas.length > 0; else sinPruebas">
                        <div class="section-content">
                            <div class="row">
                                <div class="col-sm-12 col-md-8 col-lg-8 py-2">
                                    <mat-form-field>
                                        <mat-label>Filtro</mat-label>
                                        <input matInput (keyup)="applyFilter($event)" placeholder="ej. material" #input>
                                    </mat-form-field>
                                </div>
                            </div>

                            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                                <!-- Position Column -->
                                <ng-container matColumnDef="id">
                                    <th mat-header-cell *matHeaderCellDef> ID. </th>
                                    <td mat-cell *matCellDef="let element"> {{element.id}} </td>
                                </ng-container>

                                <!-- Name Column -->
                                <ng-container matColumnDef="material">
                                    <th mat-header-cell *matHeaderCellDef> Material </th>
                                    <td mat-cell *matCellDef="let element"> {{element.material}} </td>
                                </ng-container>

                                <!-- Name Column -->
                                <ng-container matColumnDef="prueba_id">
                                    <th mat-header-cell *matHeaderCellDef> Prueba/Estudio </th>
                                    <td mat-cell *matCellDef="let element">
                                        <ng-container *ngIf="element.tipo_prueba == 1">
                                            Reporte de Estudios
                                        </ng-container>
                                        <ng-container *ngIf="element.tipo_prueba == 2">
                                            Prueba de Laboratorio
                                        </ng-container>
                                    </td>
                                </ng-container>

                                <!-- Name Column -->
                                <ng-container matColumnDef="cuv">
                                    <th mat-header-cell *matHeaderCellDef> Vivienda </th>
                                    <td mat-cell *matCellDef="let element"> {{element.pivot.cuv}} </td>
                                </ng-container>

                                <!-- Name Column -->
                                <ng-container matColumnDef="created_at">
                                    <th mat-header-cell *matHeaderCellDef> Fecha de Creación </th>
                                    <td mat-cell *matCellDef="let element"> {{element.created_at | date: 'dd/MM/yyyy hh:mm a'}} </td>
                                </ng-container>

                                <!-- Position Column -->
                                <ng-container matColumnDef="acciones">
                                    <th mat-header-cell *matHeaderCellDef> Acciones </th>
                                    <td mat-cell *matCellDef="let element">
                                        <button mat-icon-button color="primary" title='Modificar' data-toggle="modal"
                                            data-target="#exampleModalCenter">
                                            <mat-icon>update</mat-icon>
                                        </button>
                                        <button mat-icon-button color="warn" title='Eliminar'>
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                                <!-- Row shown when there is no matching data. -->
                                <tr class="mat-row" *matNoDataRow>
                                    <td class="mat-cell" colspan="4">No hay resultados para: "{{input.value}}"</td>
                                </tr>
                            </table>
                        </div>
                    </ng-container>
                    <ng-template #sinPruebas>
                        <div class="alert alert-warning" role="alert">
                            La vivienda seleccionada no tiene <strong>Pruebas de Laboratiorio / Reporte de Estudios</strong> asociados.
                        </div>
                    </ng-template>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="method === 'index'">
                <p>pruebas-laboratorio index!</p>
            </ng-container>
        </ng-container>
    </div>
</section>
