import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder, FormControl, UntypedFormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Subject } from "rxjs";
import { UsuariosService } from "src/app/services/usuarios.service";
import Swal from 'sweetalert2';

@Component({
	selector: 'app-dialogo-user',
	templateUrl: './dialogo-user.component.html',
	styleUrls: ['./dialogo-user.component.scss']
})

export class DialogoUserComponent implements OnInit {
	public users: any;
	public roles: any;
	public isLoading = false;
	public usuarioActivo = 0;
	public dataSource: any;
	public userForm: UntypedFormGroup;
	public allowButton = false;
	public isUpdate = false;
	public usuario: any = {};
	public selectedRole: number;
	subUse = new Subject<any>();

	constructor(public dialogRef: MatDialogRef<DialogoUserComponent>,
				@Inject(MAT_DIALOG_DATA) public data: any,
				private usuariosService: UsuariosService,
				private formBuilder: UntypedFormBuilder,
	  ) {
		this.userForm = this.data.userForm;
		this.userForm.reset();
		this.userForm.patchValue(this.data.element ? this.data.element : this.data.userForm);
		this.roles = this.data.roles;
		this.selectedRole = this.data.element ? this.data.element.id_rol : null;
		this.usuarioActivo = this.data.element ? this.data.element.activo: null;
		this.isUpdate = this.data.element ? true : false;
		this.subUse = this.data.subUse;
	  }

	ngOnInit(): void {

	}

	close() {
		this.dialogRef.close();
	}

	userChange(event){
		this.usuario = event;
		if (this.usuario != null)
			this.allowButton = true;
	}

	updateUser(event: UntypedFormGroup) {
		let datos = event.value;
		this.isLoading = true;
		this.usuariosService.update(datos).subscribe(res => {
			this.isLoading = false;
			this.subUse.next(res);
			this.dialogRef.close('actalizar');
		}, error => {
			//loader false
			var errorF;
			var rows = error.error.errors ? Object.keys(error.error.errors) : null;
			(error.error.message) != null ?
				errorF = error.error.message+' ('+rows+')' :
				errorF = 'Hubo un error al actualizar la ecotecnia!';
			this.isLoading = false;
			Swal.fire({
				icon: 'error',
				title: 'Error...',
				text: errorF,
				footer: 'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.'
			});
		});
	}

	storeUser(event: UntypedFormGroup) {
		const swalWithBootstrapButtons = Swal.mixin({
			customClass: {
				confirmButton: 'btn btn-primary',
				cancelButton: 'btn btn-danger'
			},
			buttonsStyling: true
		});

		swalWithBootstrapButtons.fire({
			icon: 'warning',
			title: '¿Los datos son correctos?',
			text: 'Verifique que los datos sean correctos.',
			showCancelButton: true,
			confirmButtonText: 'Si, Registar',
			cancelButtonText: 'No, Cancelar',
			reverseButtons: true
		}).then((result) => {
			if (result.isConfirmed) {
				let datos = event.value;
				this.isLoading = true;
				this.usuariosService.store(datos).subscribe(res => {
					this.isLoading = false;
					this.subUse.next(res);
					this.dialogRef.close('guardar');
				}, error => {
					//loader false
					var errorF;
					var rows = error.error.errors ? Object.keys(error.error.errors) : null;
					(error.error.message) != null ?
						errorF = error.error.message+' ('+rows+')' :
						errorF = 'Hubo un error al actualizar la ecotecnia!';
					this.isLoading = false;
					Swal.fire({
						icon: 'error',
						title: 'Error...',
						text: errorF,
						footer: 'Favor de intentar nuevamente. Si el problema persiste comunicarse a soporte.'
					});
				});
			}
		});
	}
}