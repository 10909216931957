<section class="animate__animated animate__fadeIn h-90 d-flex align-items-center">
    <div class="container">
        <div class="section-title">
            <h2>Reportes Disponibles</h2>
        </div>
        <div class="section-content text-center">
            <mat-card *ngFor="let reporte of reportes" 
                class="reporte-disponible"
            >
                <div class="text-center">
                    <div class="header-title">
                      {{ reporte.reporte }}
                    </div>
                    <div class="header-subtitle">
                        {{ reporte.subtitulo }}
                    </div>
                </div>
                <div>

                </div>
                <mat-card-content class="texto">
                  <p title="{{reporte.texto}}">
                      {{ reporte.texto }}
                  </p>
                </mat-card-content>
                <mat-card-actions class="text-center">
                    <a
                        class="btn btn-primary btn-sm"
                        [routerLink]="['/reporte', reporte.link]"
                        [queryParams]="{ orden: idOrden }"
                    >
                        Abrir
                    </a>
                </mat-card-actions>
            </mat-card>
        </div>
    </div>
</section>
<app-loader [isLoading]="isLoading"></app-loader>