<section class="animate__animated animate__fadeIn align-items-center">
    <div id="container">
        <div id="wraper_tabla_reporte" class="table-responsive">
            <mat-tab-group *ngIf="isLoaded">
                <!--******************-->
                <!--GRAFICO DE AVANCES-->
                <!--******************-->
                <mat-tab *ngIf="reporteGraficoAvances" label="Reporte Gráfico de Avances de Obra">
                    <table id="tabla_reporte_p" class="table table-bordered table-sm reporteGraficoAvances"> <!--29 COLUMNAS-->
                        <tbody>
                            <tr>
                                <td id="logo_ISO" class="contenido" colspan="1">
                                    <img class="logo" src="../../../assets/img/iso.png" alt="Logo Calidad ISO" />
                                </td>
                                <td id="titulo_ruv" colspan="7">
                                    <table>
                                        <tr>
                                            REGISTRO ÚNICO DE VIVIENDA
                                        </tr>
                                        <tr><br></tr>
                                        <tr><br></tr>
                                        <tr><br></tr>
                                        <tr><br></tr>
                                        <tr id="titulo_reporte">
                                            REPORTE DE CUMPLIMIENTO A LA CALIDAD
                                        </tr>
                                    </table>
                                </td>
                            </tr><tr>
                                <td id="titulo_no_oferta" class="titulo_left" colspan="1">NO. OFERTA</td>
                                <td id="no_oferta" class="contenido" colspan="1">
                                    <strong>
                                        {{reporte.id_oferta}}
                                    </strong>
                                </td>
                                <td id="titulo_orden_verif" class="titulo_left" colspan="1">ORDEN DE VERIF.</td>
                                <td id="ordern_verif" class="contenido" colspan="1">
                                    <strong>
                                        {{reporte.id_orden}}
                                    </strong>
                                </td>
                                <td id="titulo_no_viviendas" class="titulo_left" colspan="1">NO. DE VIVIENDAS</td>
                                <td id="no_viviendas" class="contenido" colspan="1">
                                    <strong>
                                        {{reporte.cuvs_count}}
                                    </strong>
                                </td>
                                <td id="titulo_viv_ecologica" class="titulo_left" colspan="1">VIV. ECOLÓGICA</td>
                                <td id="viv_ecologica" class="contenido" colspan="1">{{totalEdifEcos}}</td>
                            </tr>
                            <!--SEPARADOR--><tr><td colspan="8" class="separador"></td></tr><!--SEPARADOR-->
                            <tr>
                                <td id="titulo_estado" class="titulo_left" colspan="1">ESTADO</td>
                                <td id="estado" class="contenido" colspan="3">
                                    <strong>
                                        {{obra.estado.nombre}}
                                    </strong>
                                </td>
                                <td id="titulo_localidad" class="titulo_left" colspan="1">LOCALIDAD</td>
                                <td id="localidad" class="contenido" colspan="3">
                                    <strong>
                                        {{obra.municipio.nombre}}
                                    </strong>
                                </td>
                            </tr>
                            <tr>
                                <td id="titulo_nombre_del_frente" class="titulo_left" colspan="1">NOMBRE DEL FRENTE</td>
                                <td id="nombre_del_frente" class="contenido" colspan="7"><strong>{{reporte.nombre_del_frente}}</strong></td>
                            </tr>
                            <tr>
                                <td id="titulo_domicilio" class="titulo_left" colspan="1">DOMICILIO</td>
                                <td id="domicilio" class="contenido" colspan="7">
                                    <strong>
                                        Calle {{reporte.calle}}, Colonia {{reporte.colonia}}, CP: {{reporte.codigo_postal}}
                                    </strong>
                                </td>
                            </tr>
                            <!--SEPARADOR--><tr><td colspan="8" class="separador"></td></tr><!--SEPARADOR-->
                            <tr>
                                <td id="titulo_fecha_inicio_programado" class="titulo_top" colspan="1">INICIO PROGRAMADO</td>
                                <td id="titulo_fecha_terminacion_prog" class="titulo_top" colspan="1">TERMINACIÓN PROG.</td>
                                <td id="titulo_inicio_real" class="titulo_top" colspan="1">INICIO REAL</td>
                                <td id="titulo_plazo_ejecucion" class="titulo_top" colspan="1">PLAZO EJECUCIÓN</td>
                                <td id="titulo_periodo_de_reporte" class="titulo_top" colspan="2">PERIODO DE REPORTE</td>
                                <td id="titulo_no_de_reporte" class="titulo_top" colspan="2">NO. DE REPORTE</td>
                            </tr>
                            <tr>
                                <td id="fecha_inicio_programado" class="contenido" colspan="1">
                                    <strong>
                                        [Pendiente]
                                    </strong>
                                </td>
                                <td id="fecha_terminacion_prog" class="contenido" colspan="1">
                                    <strong>
                                        [Pendiente]
                                    </strong>
                                </td>
                                <td id="inicio_real" class="contenido" colspan="1">
                                    <strong>
                                        {{obra.inicio_real}}
                                    </strong>
                                </td>
                                <td id="plazo_ejecucion" class="contenido" colspan="1">
                                    <strong>
                                        {{obra.plazo_ejecucion}} meses
                                    </strong>
                                </td>
                                <td id="periodo_de_reporte_inicio" class="contenido" colspan="1">
                                    <strong>
                                        {{first | date: 'mediumDate'}}
                                    </strong>
                                </td>
                                <td id="periodo_de_reporte_fin" class="contenido" colspan="1">
                                    <strong>
                                        {{last | date: 'mediumDate'}}
                                    </strong>
                                </td>
                                <td id="no_de_reporte" class="contenido" colspan="2">
                                    <strong>
                                        {{ultimoReporte.numero_reporte+1}}
                                    </strong>
                                </td>
                            </tr>
                        <!--SEPARADOR--><tr><td colspan="8" class="separador"></td></tr><!--SEPARADOR-->
                            <tr>
                                <td id="titulo_constructora" class="titulo_left" colspan="1">CONSTRUCTORA</td>
                                <td id="constructora" class="contenido" colspan="5"><strong>{{obra.constructora}}</strong></td>
                                <td id="titulo_no_registro_constructora" class="titulo_left" colspan="1">NO. REGISTRO</td>
                                <td id="no_registro_constructora" class="contenido" colspan="1"><strong>{{obra.numero_registro}}</strong></td>
                            </tr>
                            <tr>
                                <td id="titulo_supervisor_verificador" class="titulo_left" colspan="1">SUPERVISOR / VERIFICADOR</td>
                                <td id="supervisor_verificador" class="contenido" colspan="5">
                                    <form [formGroup]="verificadoresForm">
                                        <mat-form-field appearance="fill" style="display: inherit;">
                                            <mat-select [(ngModel)]="selectedVerif" formControlName="userVerificador" (selectionChange)="verificadorChange($event)">
                                                <mat-option *ngFor="let verif of allVerificadores" [value]="verif.id">
                                                    {{verif.nombre}} {{verif.primer_apellido}} {{verif.segundo_apellido}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </form>
                                </td>
                                <td id="titulo_no_registro_supervisor" class="titulo_left" colspan="1">NO. REGISTRO</td>
                                <td id="no_registro_supervisor" class="contenido" colspan="1">
                                    <strong *ngIf="obra.verificador != null;else elseCedula">
                                        {{obra.verificador.cedula}}
                                    </strong>
                                    <ng-template #elseCedula>
                                        <strong>Sin Asignar</strong>
                                    </ng-template>
                                </td>
                            </tr>
                            <!--SEPARADOR--><tr><td colspan="8" class="separador"></td></tr><!--SEPARADOR-->
                            <tr>
                                <td id="grafica" colspan="8">	
                                    <div class="chart-container">	 	    		
                                        <canvas #control style="max-width: -webkit-fill-available;"></canvas>
                                    </div>
                                </td>
                            </tr>
                            <!--SEPARADOR--><tr><td colspan="8" class="separador"></td></tr><!--SEPARADOR-->
                            <!-- tabla -->
                            <tr>
                                <!-- <td id="titulo_no_de_reporte_bis" class="titulo_left" colspan="1">NÚMERO DE REPORTE</td> -->
                                <td colspan="8" class="padding-0">
                                    <table class="table">
                                        <tbody>
                                            <tr>
                                                <td>NÚMERO DE REPORTE</td>
                                                <td *ngFor="let avance of chart_labels; let i = index" id="no_de_reporte_bis_{{i+1}}" class="contenido_small">{{i+1}}</td>
                                            </tr>
                                            <tr>
                                                <td id="titulo_avance_programado" class="titulo_left" colspan="1">AVANCE PROGRAMADO</td>
                                                <td *ngFor="let avance of chart_labels; let i = index" id="avance_programado_1" class="contenido_small">{{avanceProgramado[i]}}%</td>
                                            </tr>
                                            <tr>
                                                <td id="titulo_avance_real" class="titulo_left" colspan="1">AVANCE REAL</td>
                                                <td *ngFor="let reporte of historialReportes; let i = index" id="avance_real_1" class="contenido_small">
                                                    <div *ngIf="i==0; else noInput">
                                                        <input type="number" [ngModel]="range" (ngModelChange)="updateInitialReport($event)"
                                                            class="form-control" min='0' max='100' style="padding:0;"
                                                            attr.value="{{reporte.avance}}"
                                                        >
                                                    </div>
                                                    <ng-template #noInput>
                                                        {{reporte.avance}}%
                                                    </ng-template>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td id="titulo_acumulado_viv" class="titulo_left" colspan="1">ACUMULADO VIV. >80%</td>
                                                <td *ngFor="let reporte of historialReportes; let i = index" id="acumulado_viv_1" class="contenido_small">{{ reporte.avance_acumulado_viv }}</td>
                                            </tr>
                                            <tr>
                                                <td id="titulo_acumulado_DTU" class="titulo_left" colspan="1">ACUMULADO DTU'S</td>
                                                <td *ngFor="let reporte of historialReportes; let i = index" id="acumulado_DTU_1" class="contenido_small">{{ reporte.avance_acumulado_DTU }}</td>
                                            </tr>
                                            <br>
                                            <tr>
                                                <td id="titulo_importe_programado" class="titulo_left" colspan="1">IMPORTE PROGRAMADO</td>
                                                <td *ngFor="let avance of chart_labels; let i = index" id="importe_programado_1" class="contenido_small">{{ (totalObra * (avanceProgramado[i] / 100)) | shortNumber }}</td>
                                            </tr>
                                            <tr>
                                                <td id="titulo_importe_ejecutado" class="titulo_left" colspan="1">IMPORTE EJECUTADO</td>
                                                <td *ngFor="let reporte of historialReportes; let i = index"  id="importe_ejecutado_1" class="contenido_small">{{ (totalObra * (reporte.avance / 100)) | shortNumber }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <!-- Tabla -->
                            <!--SEPARADOR--><tr><td colspan="8" class="separador"></td></tr><!--SEPARADOR-->
                            <tr>
                                <td id="titulo_lugar_y_fecha" class="titulo_left" colspan="1">LUGAR Y FECHA</td>
                                <td id="lugar_y_fecha" class="contenido" colspan="7">
                                    <strong> {{obra.localidad.nombre}}, {{obra.municipio.nombre}}, Estado de {{obra.estado.nombre}} - {{fechaActual | date: 'longDate' }} </strong>
                                </td>
                            </tr>
                            <!--SEPARADOR--><tr><td colspan="8" class="separador"></td></tr><!--SEPARADOR-->
                            <tr>
                                <td id="titulo_nombre_y_firma" class="titulo_left" colspan="1">NOMBRE Y FIRMA DEL VERIFICADOR</td>
                                <td id="nombre_y_firma" class="contenido" colspan="7">
                                    <div *ngIf="obra.verificador == null; else verificador">
                                        <strong>Verificador sin asignar.</strong>
                                    </div>
                                    <ng-template #verificador>
                                        <div>
                                            <strong> {{obra.verificador.nombre}} {{obra.verificador.primer_apellido}} {{obra.verificador.segundo_apellido}}. </strong>
                                        </div>
                                        <hr>
                                        <div>
                                            <strong> Firma no disponible. </strong>
                                        </div>
                                    </ng-template>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </mat-tab>
                <!--***********-->
                <!--EDIFICACIÓN-->
                <!--***********-->
                <mat-tab *ngIf="reporteEdificacion" label="Edificación">
                    <table id="tabla_reporte_p" class="table table-bordered table-sm reporteEdificacion"> <!--29 COLUMNAS-->
                        <tbody>
                            <tr>
                                <td id="logo_ISO" class="contenido" colspan="1">
                                    <img class="logo" src="../../../assets/img/iso.png" alt="Logo Calidad ISO" />
                                </td>
                                <td id="titulo_ruv" colspan="7">
                                    <table>
                                        <tr>
                                            REGISTRO ÚNICO DE VIVIENDA
                                        </tr>
                                        <tr><br></tr>
                                        <tr><br></tr>
                                        <tr><br></tr>
                                        <tr><br></tr>
                                        <tr id="titulo_reporte">
                                            REPORTE FOTOGRAFICO (EDIFICACIÓN)
                                        </tr>
                                    </table>
                                </td>
                            </tr><tr>
                                <td id="titulo_no_oferta" class="titulo_left" colspan="1">NO. OFERTA</td>
                                <td id="no_oferta" class="contenido" colspan="1">
                                    <strong>
                                        {{reporte.id_oferta}}
                                    </strong>
                                </td>
                                <td id="titulo_orden_verif" class="titulo_left" colspan="1">ORDEN DE VERIF.</td>
                                <td id="ordern_verif" class="contenido" colspan="1">
                                    <strong>
                                        {{reporte.id_orden}}
                                    </strong>
                                </td>
                                <td id="titulo_no_viviendas" class="titulo_left" colspan="1">NO. DE VIVIENDAS</td>
                                <td id="no_viviendas" class="contenido" colspan="1">
                                    <strong>
                                        {{reporte.cuvs_count}}
                                    </strong>
                                </td>
                                <td id="titulo_viv_ecologica" class="titulo_left" colspan="1">VIV. ECOLÓGICA</td>
                                <td id="viv_ecologica" class="contenido" colspan="1">{{totalEdifEcos}}</td>
                            </tr>
                            <!--SEPARADOR--><tr><td colspan="8" class="separador"></td></tr><!--SEPARADOR-->
                            <tr>
                                <td id="titulo_estado" class="titulo_left" colspan="1">ESTADO</td>
                                <td id="estado" class="contenido" colspan="3">
                                    <strong>
                                        {{obra.estado.nombre}}
                                    </strong>
                                </td>
                                <td id="titulo_localidad" class="titulo_left" colspan="1">LOCALIDAD</td>
                                <td id="localidad" class="contenido" colspan="3">
                                    <strong>
                                        {{obra.municipio.nombre}}
                                    </strong>
                                </td>
                            </tr>
                            <tr>
                                <td id="titulo_nombre_del_frente" class="titulo_left" colspan="1">NOMBRE DEL FRENTE</td>
                                <td id="nombre_del_frente" class="contenido" colspan="7"><strong>{{reporte.nombre_del_frente}}</strong></td>
                            </tr>
                            <tr>
                                <td id="titulo_domicilio" class="titulo_left" colspan="1">DOMICILIO</td>
                                <td id="domicilio" class="contenido" colspan="7">
                                    <strong>
                                        Calle {{reporte.calle}}, Colonia {{reporte.colonia}}, CP: {{reporte.codigo_postal}}
                                    </strong>
                                </td>
                            </tr>
                            <!--SEPARADOR--><tr><td colspan="8" class="separador"></td></tr><!--SEPARADOR-->
                            <tr>
                                <td id="titulo_fecha_inicio_programado" class="titulo_top" colspan="1">INICIO PROGRAMADO</td>
                                <td id="titulo_fecha_terminacion_prog" class="titulo_top" colspan="1">TERMINACIÓN PROG.</td>
                                <td id="titulo_inicio_real" class="titulo_top" colspan="1">INICIO REAL</td>
                                <td id="titulo_plazo_ejecucion" class="titulo_top" colspan="1">PLAZO EJECUCIÓN</td>
                                <td id="titulo_periodo_de_reporte" class="titulo_top" colspan="2">PERIODO DE REPORTE</td>
                                <td id="titulo_no_de_reporte" class="titulo_top" colspan="2">NO. DE REPORTE</td>
                            </tr>
                            <tr>
                                <td id="fecha_inicio_programado" class="contenido" colspan="1">
                                    <strong>
                                        [Pendiente]
                                    </strong>
                                </td>
                                <td id="fecha_terminacion_prog" class="contenido" colspan="1">
                                    <strong>
                                        [Pendiente]
                                    </strong>
                                </td>
                                <td id="inicio_real" class="contenido" colspan="1">
                                    <strong>
                                        {{obra.inicio_real}}
                                    </strong>
                                </td>
                                <td id="plazo_ejecucion" class="contenido" colspan="1">
                                    <strong>
                                        {{obra.plazo_ejecucion}} meses
                                    </strong>
                                </td>
                                <td id="periodo_de_reporte_inicio" class="contenido" colspan="1">
                                    <strong>
                                        {{first | date: 'mediumDate'}}
                                    </strong>
                                </td>
                                <td id="periodo_de_reporte_fin" class="contenido" colspan="1">
                                    <strong>
                                        {{last | date: 'mediumDate'}}
                                    </strong>
                                </td>
                                <td id="no_de_reporte" class="contenido" colspan="2">
                                    <strong>
                                        {{ultimoReporte.numero_reporte+1}}
                                    </strong>
                                </td>
                            </tr>
                        <!--SEPARADOR--><tr><td colspan="8" class="separador"></td></tr><!--SEPARADOR-->
                            <tr>
                                <td id="titulo_constructora" class="titulo_left" colspan="1">CONSTRUCTORA</td>
                                <td id="constructora" class="contenido" colspan="5"><strong>{{obra.constructora}}</strong></td>
                                <td id="titulo_no_registro_constructora" class="titulo_left" colspan="1">NO. REGISTRO</td>
                                <td id="no_registro_constructora" class="contenido" colspan="1"><strong>{{obra.numero_registro}}</strong></td>
                            </tr>
                            <tr>
                                <td id="titulo_supervisor_verificador" class="titulo_left" colspan="1">SUPERVISOR / VERIFICADOR</td>
                                <td id="supervisor_verificador" class="contenido" colspan="5">
                                    <form [formGroup]="verificadoresForm">
                                        <mat-form-field appearance="fill" style="display: inherit;">
                                            <mat-select [(ngModel)]="selectedVerif" formControlName="userVerificador" (selectionChange)="verificadorChange($event)">
                                                <mat-option *ngFor="let verif of allVerificadores" [value]="verif.id">
                                                    {{verif.nombre}} {{verif.primer_apellido}} {{verif.segundo_apellido}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </form>
                                </td>
                                <td id="titulo_no_registro_supervisor" class="titulo_left" colspan="1">NO. REGISTRO</td>
                                <td id="no_registro_supervisor" class="contenido" colspan="1">
                                    <strong *ngIf="obra.verificador != null;else elseCedula">
                                        {{obra.verificador.cedula}}
                                    </strong>
                                    <ng-template #elseCedula>
                                        <strong>Sin Asignar</strong>
                                    </ng-template>
                                </td>
                            </tr>
                            <!--SEPARADOR--><tr><td colspan="8" class="separador"></td></tr><!--SEPARADOR-->
                            <tr>
                                <td id="evidencias" colspan="8">
                                    <strong *ngIf="evidenciasArchivos.length < 1;else elseEvidencias">
                                        No hay evidencias relacionadas.
                                    </strong>
                                    <ng-template #elseEvidencias>
                                        <div class="row">
                                            <div class="col-6" *ngFor="let evidencia of evidenciasArchivos">
                                                <img src="{{ host }}images/{{evidencia.imgName}}" style="max-width: inherit; margin: auto;" class="mx-auto d-block"/>
                                            </div>
                                        </div>
                                    </ng-template>
                                </td>
                            </tr>
                            <!--SEPARADOR--><tr><td colspan="8" class="separador"></td></tr><!--SEPARADOR-->
                            <tr>
                                <td id="titulo_descripcion" class="titulo_left" colspan="1">DESCRIPCIÓN</td>
                                <td id="descripcion" class="contenido" colspan="7">
                                    <textarea placeholder="Sin Descripción" class="form-control" (change)="descripcionChange($event)" rows="3">{{reporte.reportes[0].descripcion}}</textarea>
                                </td>
                            </tr>
                            <!--SEPARADOR--><tr><td colspan="8" class="separador"></td></tr><!--SEPARADOR-->
                            <tr>
                                <td id="titulo_lugar_y_fecha" class="titulo_left" colspan="1">LUGAR Y FECHA</td>
                                <td id="lugar_y_fecha" class="contenido" colspan="7">
                                    <strong> {{obra.localidad.nombre}}, {{obra.municipio.nombre}}, Estado de {{obra.estado.nombre}} - {{fechaActual | date: 'longDate' }} </strong>
                                </td>
                            </tr>
                            <!--SEPARADOR--><tr><td colspan="29" class="separador"></td></tr><!--SEPARADOR-->
                            <tr>
                                <td id="titulo_nombre_firma_cedula" class="titulo_left" colspan="1">NOMBRE, FIRMA Y CÉDULA PROFESIONAL VERIFICADOR</td>
                                <td id="nombre_y_firma" class="contenido" colspan="7">
                                    <div *ngIf="obra.verificador == null; else verificador">
                                        <strong>Verificador sin asignar.</strong>
                                    </div>
                                    <ng-template #verificador>
                                        <div>
                                            <strong> {{obra.verificador.nombre}} {{obra.verificador.primer_apellido}} {{obra.verificador.segundo_apellido}}. </strong>
                                        </div>
                                        <hr>
                                        <div>
                                            <strong> Firma no disponible. </strong>
                                        </div>
                                        <hr>
                                        <div>
                                            <strong> Cedula no disponible. </strong>
                                        </div>
                                    </ng-template>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </mat-tab>
                <!--************-->
                <!--URBANIZACIÓN-->
                <!--************-->
                <mat-tab *ngIf="reporteUrbanizacion" label="Urbanización">
                    <table id="tabla_reporte_p" class="table table-bordered table-sm reporteUrbanizacion"> <!--29 COLUMNAS-->
                        <tbody>
                            <tr>
                                <td id="logo_ISO" class="contenido" colspan="1">
                                    <img class="logo" src="../../../assets/img/iso.png" alt="Logo Calidad ISO" />
                                </td>
                                <td id="titulo_ruv" colspan="7">
                                    <table>
                                        <tr>
                                            REGISTRO ÚNICO DE VIVIENDA
                                        </tr>
                                        <tr><br></tr>
                                        <tr><br></tr>
                                        <tr><br></tr>
                                        <tr><br></tr>
                                        <tr id="titulo_reporte">
                                            REPORTE FOTOGRAFICO (URBANIZACIÓN)
                                        </tr>
                                    </table>
                                </td>
                            </tr><tr>
                                <td id="titulo_no_oferta" class="titulo_left" colspan="1">NO. OFERTA</td>
                                <td id="no_oferta" class="contenido" colspan="1">
                                    <strong>
                                        {{reporte.id_oferta}}
                                    </strong>
                                </td>
                                <td id="titulo_orden_verif" class="titulo_left" colspan="1">ORDEN DE VERIF.</td>
                                <td id="ordern_verif" class="contenido" colspan="1">
                                    <strong>
                                        {{reporte.id_orden}}
                                    </strong>
                                </td>
                                <td id="titulo_no_viviendas" class="titulo_left" colspan="1">NO. DE VIVIENDAS</td>
                                <td id="no_viviendas" class="contenido" colspan="1">
                                    <strong>
                                        {{reporte.cuvs_count}}
                                    </strong>
                                </td>
                                <td id="titulo_viv_ecologica" class="titulo_left" colspan="1">VIV. ECOLÓGICA</td>
                                <td id="viv_ecologica" class="contenido" colspan="1">{{totalEdifEcos}}</td>
                            </tr>
                            <!--SEPARADOR--><tr><td colspan="8" class="separador"></td></tr><!--SEPARADOR-->
                            <tr>
                                <td id="titulo_estado" class="titulo_left" colspan="1">ESTADO</td>
                                <td id="estado" class="contenido" colspan="3">
                                    <strong>
                                        {{obra.estado.nombre}}
                                    </strong>
                                </td>
                                <td id="titulo_localidad" class="titulo_left" colspan="1">LOCALIDAD</td>
                                <td id="localidad" class="contenido" colspan="3">
                                    <strong>
                                        {{obra.municipio.nombre}}
                                    </strong>
                                </td>
                            </tr>
                            <tr>
                                <td id="titulo_nombre_del_frente" class="titulo_left" colspan="1">NOMBRE DEL FRENTE</td>
                                <td id="nombre_del_frente" class="contenido" colspan="7"><strong>{{reporte.nombre_del_frente}}</strong></td>
                            </tr>
                            <tr>
                                <td id="titulo_domicilio" class="titulo_left" colspan="1">DOMICILIO</td>
                                <td id="domicilio" class="contenido" colspan="7">
                                    <strong>
                                        Calle {{reporte.calle}}, Colonia {{reporte.colonia}}, CP: {{reporte.codigo_postal}}
                                    </strong>
                                </td>
                            </tr>
                            <!--SEPARADOR--><tr><td colspan="8" class="separador"></td></tr><!--SEPARADOR-->
                            <tr>
                                <td id="titulo_fecha_inicio_programado" class="titulo_top" colspan="1">INICIO PROGRAMADO</td>
                                <td id="titulo_fecha_terminacion_prog" class="titulo_top" colspan="1">TERMINACIÓN PROG.</td>
                                <td id="titulo_inicio_real" class="titulo_top" colspan="1">INICIO REAL</td>
                                <td id="titulo_plazo_ejecucion" class="titulo_top" colspan="1">PLAZO EJECUCIÓN</td>
                                <td id="titulo_periodo_de_reporte" class="titulo_top" colspan="2">PERIODO DE REPORTE</td>
                                <td id="titulo_no_de_reporte" class="titulo_top" colspan="2">NO. DE REPORTE</td>
                            </tr>
                            <tr>
                                <td id="fecha_inicio_programado" class="contenido" colspan="1">
                                    <strong>
                                        [Pendiente]
                                    </strong>
                                </td>
                                <td id="fecha_terminacion_prog" class="contenido" colspan="1">
                                    <strong>
                                        [Pendiente]
                                    </strong>
                                </td>
                                <td id="inicio_real" class="contenido" colspan="1">
                                    <strong>
                                        {{obra.inicio_real}}
                                    </strong>
                                </td>
                                <td id="plazo_ejecucion" class="contenido" colspan="1">
                                    <strong>
                                        {{obra.plazo_ejecucion}} meses
                                    </strong>
                                </td>
                                <td id="periodo_de_reporte_inicio" class="contenido" colspan="1">
                                    <strong>
                                        {{first | date: 'mediumDate'}}
                                    </strong>
                                </td>
                                <td id="periodo_de_reporte_fin" class="contenido" colspan="1">
                                    <strong>
                                        {{last | date: 'mediumDate'}}
                                    </strong>
                                </td>
                                <td id="no_de_reporte" class="contenido" colspan="2">
                                    <strong>
                                        {{ultimoReporte.numero_reporte+1}}
                                    </strong>
                                </td>
                            </tr>
                        <!--SEPARADOR--><tr><td colspan="8" class="separador"></td></tr><!--SEPARADOR-->
                            <tr>
                                <td id="titulo_constructora" class="titulo_left" colspan="1">CONSTRUCTORA</td>
                                <td id="constructora" class="contenido" colspan="5"><strong>{{obra.constructora}}</strong></td>
                                <td id="titulo_no_registro_constructora" class="titulo_left" colspan="1">NO. REGISTRO</td>
                                <td id="no_registro_constructora" class="contenido" colspan="1"><strong>{{obra.numero_registro}}</strong></td>
                            </tr>
                            <tr>
                                <td id="titulo_supervisor_verificador" class="titulo_left" colspan="1">SUPERVISOR / VERIFICADOR</td>
                                <td id="supervisor_verificador" class="contenido" colspan="5">
                                    <form [formGroup]="verificadoresForm">
                                        <mat-form-field appearance="fill" style="display: inherit;">
                                            <mat-select [(ngModel)]="selectedVerif" formControlName="userVerificador" (selectionChange)="verificadorChange($event)">
                                                <mat-option *ngFor="let verif of allVerificadores" [value]="verif.id">
                                                    {{verif.nombre}} {{verif.primer_apellido}} {{verif.segundo_apellido}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </form>
                                </td>
                                <td id="titulo_no_registro_supervisor" class="titulo_left" colspan="1">NO. REGISTRO</td>
                                <td id="no_registro_supervisor" class="contenido" colspan="1">
                                    <strong *ngIf="obra.verificador != null;else elseCedula">
                                        {{obra.verificador.cedula}}
                                    </strong>
                                    <ng-template #elseCedula>
                                        <strong>Sin Asignar</strong>
                                    </ng-template>
                                </td>
                            </tr>
                            <!--SEPARADOR--><tr><td colspan="8" class="separador"></td></tr><!--SEPARADOR-->
                            <tr>
                                <td id="evidencias" colspan="8">
                                    <strong *ngIf="evidenciasArchivos.length < 1;else elseEvidencias">
                                        No hay evidencias relacionadas.
                                    </strong>
                                    <ng-template #elseEvidencias>
                                        <div class="row">
                                            <div class="col-6" *ngFor="let evidencia of evidenciasArchivos">
                                                <img src="{{ host }}images/{{evidencia.imgName}}" style="max-width: inherit; margin: auto;" class="mx-auto d-block"/>
                                            </div>
                                        </div>
                                    </ng-template>
                                </td>
                            </tr>
                            <!--SEPARADOR--><tr><td colspan="8" class="separador"></td></tr><!--SEPARADOR-->
                            <tr>
                                <td id="titulo_descripcion" class="titulo_left" colspan="1">DESCRIPCIÓN</td>
                                <td id="descripcion" class="contenido" colspan="7">
                                    <textarea placeholder="Sin Descripción" class="form-control" rows="3">{{reporte.reportes[0].descripcion}}</textarea>
                                </td>
                            </tr>
                            <!--SEPARADOR--><tr><td colspan="8" class="separador"></td></tr><!--SEPARADOR-->
                            <tr>
                                <td id="titulo_lugar_y_fecha" class="titulo_left" colspan="1">LUGAR Y FECHA</td>
                                <td id="lugar_y_fecha" class="contenido" colspan="7">
                                    <strong> {{obra.localidad.nombre}}, {{obra.municipio.nombre}}, Estado de {{obra.estado.nombre}} - {{fechaActual | date: 'longDate' }} </strong>
                                </td>
                            </tr>
                            <!--SEPARADOR--><tr><td colspan="29" class="separador"></td></tr><!--SEPARADOR-->
                            <tr>
                                <td id="titulo_nombre_firma_cedula" class="titulo_left" colspan="1">NOMBRE, FIRMA Y CÉDULA PROFESIONAL VERIFICADOR</td>
                                <td id="nombre_y_firma" class="contenido" colspan="7">
                                    <div *ngIf="obra.verificador == null; else verificador">
                                        <strong>Verificador sin asignar.</strong>
                                    </div>
                                    <ng-template #verificador>
                                        <div>
                                            <strong> {{obra.verificador.nombre}} {{obra.verificador.primer_apellido}} {{obra.verificador.segundo_apellido}}. </strong>
                                        </div>
                                        <hr>
                                        <div>
                                            <strong> Firma no disponible. </strong>
                                        </div>
                                        <hr>
                                        <div>
                                            <strong> Cedula no disponible. </strong>
                                        </div>
                                    </ng-template>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </mat-tab>
                <!--***************-->
                <!--INFRAESTRUCTURA-->
                <!--***************-->
                <mat-tab *ngIf="reporteInfraestructura" label="Infraestructura">
                    <table id="tabla_reporte_p" class="table table-bordered table-sm reporteInfraestructura"> <!--29 COLUMNAS-->
                        <tbody>
                            <tr>
                                <td id="logo_ISO" class="contenido" colspan="1">
                                    <img class="logo" src="../../../assets/img/iso.png" alt="Logo Calidad ISO" />
                                </td>
                                <td id="titulo_ruv" colspan="7">
                                    <table>
                                        <tr>
                                            REGISTRO ÚNICO DE VIVIENDA
                                        </tr>
                                        <tr><br></tr>
                                        <tr><br></tr>
                                        <tr><br></tr>
                                        <tr><br></tr>
                                        <tr id="titulo_reporte">
                                            Reporte Fotografico (Infraestructura)
                                        </tr>
                                    </table>
                                </td>
                            </tr><tr>
                                <td id="titulo_no_oferta" class="titulo_left" colspan="1">NO. OFERTA</td>
                                <td id="no_oferta" class="contenido" colspan="1">
                                    <strong>
                                        {{reporte.id_oferta}}
                                    </strong>
                                </td>
                                <td id="titulo_orden_verif" class="titulo_left" colspan="1">ORDEN DE VERIF.</td>
                                <td id="ordern_verif" class="contenido" colspan="1">
                                    <strong>
                                        {{reporte.id_orden}}
                                    </strong>
                                </td>
                                <td id="titulo_no_viviendas" class="titulo_left" colspan="1">NO. DE VIVIENDAS</td>
                                <td id="no_viviendas" class="contenido" colspan="1">
                                    <strong>
                                        {{reporte.cuvs_count}}
                                    </strong>
                                </td>
                                <td id="titulo_viv_ecologica" class="titulo_left" colspan="1">VIV. ECOLÓGICA</td>
                                <td id="viv_ecologica" class="contenido" colspan="1">{{totalEdifEcos}}</td>
                            </tr>
                            <!--SEPARADOR--><tr><td colspan="8" class="separador"></td></tr><!--SEPARADOR-->
                            <tr>
                                <td id="titulo_estado" class="titulo_left" colspan="1">ESTADO</td>
                                <td id="estado" class="contenido" colspan="3">
                                    <strong>
                                        {{obra.estado.nombre}}
                                    </strong>
                                </td>
                                <td id="titulo_localidad" class="titulo_left" colspan="1">LOCALIDAD</td>
                                <td id="localidad" class="contenido" colspan="3">
                                    <strong>
                                        {{obra.municipio.nombre}}
                                    </strong>
                                </td>
                            </tr>
                            <tr>
                                <td id="titulo_nombre_del_frente" class="titulo_left" colspan="1">NOMBRE DEL FRENTE</td>
                                <td id="nombre_del_frente" class="contenido" colspan="7"><strong>{{reporte.nombre_del_frente}}</strong></td>
                            </tr>
                            <tr>
                                <td id="titulo_domicilio" class="titulo_left" colspan="1">DOMICILIO</td>
                                <td id="domicilio" class="contenido" colspan="7">
                                    <strong>
                                        Calle {{reporte.calle}}, Colonia {{reporte.colonia}}, CP: {{reporte.codigo_postal}}
                                    </strong>
                                </td>
                            </tr>
                            <!--SEPARADOR--><tr><td colspan="8" class="separador"></td></tr><!--SEPARADOR-->
                            <tr>
                                <td id="titulo_fecha_inicio_programado" class="titulo_top" colspan="1">INICIO PROGRAMADO</td>
                                <td id="titulo_fecha_terminacion_prog" class="titulo_top" colspan="1">TERMINACIÓN PROG.</td>
                                <td id="titulo_inicio_real" class="titulo_top" colspan="1">INICIO REAL</td>
                                <td id="titulo_plazo_ejecucion" class="titulo_top" colspan="1">PLAZO EJECUCIÓN</td>
                                <td id="titulo_periodo_de_reporte" class="titulo_top" colspan="2">PERIODO DE REPORTE</td>
                                <td id="titulo_no_de_reporte" class="titulo_top" colspan="2">NO. DE REPORTE</td>
                            </tr>
                            <tr>
                                <td id="fecha_inicio_programado" class="contenido" colspan="1">
                                    <strong>
                                        [Pendiente]
                                    </strong>
                                </td>
                                <td id="fecha_terminacion_prog" class="contenido" colspan="1">
                                    <strong>
                                        [Pendiente]
                                    </strong>
                                </td>
                                <td id="inicio_real" class="contenido" colspan="1">
                                    <strong>
                                        {{obra.inicio_real}}
                                    </strong>
                                </td>
                                <td id="plazo_ejecucion" class="contenido" colspan="1">
                                    <strong>
                                        {{obra.plazo_ejecucion}} meses
                                    </strong>
                                </td>
                                <td id="periodo_de_reporte_inicio" class="contenido" colspan="1">
                                    <strong>
                                        {{first | date: 'mediumDate'}}
                                    </strong>
                                </td>
                                <td id="periodo_de_reporte_fin" class="contenido" colspan="1">
                                    <strong>
                                        {{last | date: 'mediumDate'}}
                                    </strong>
                                </td>
                                <td id="no_de_reporte" class="contenido" colspan="2">
                                    <strong>
                                        {{ultimoReporte.numero_reporte+1}}
                                    </strong>
                                </td>
                            </tr>
                        <!--SEPARADOR--><tr><td colspan="8" class="separador"></td></tr><!--SEPARADOR-->
                            <tr>
                                <td id="titulo_constructora" class="titulo_left" colspan="1">CONSTRUCTORA</td>
                                <td id="constructora" class="contenido" colspan="5"><strong>{{obra.constructora}}</strong></td>
                                <td id="titulo_no_registro_constructora" class="titulo_left" colspan="1">NO. REGISTRO</td>
                                <td id="no_registro_constructora" class="contenido" colspan="1"><strong>{{obra.numero_registro}}</strong></td>
                            </tr>
                            <tr>
                                <td id="titulo_supervisor_verificador" class="titulo_left" colspan="1">SUPERVISOR / VERIFICADOR</td>
                                <td id="supervisor_verificador" class="contenido" colspan="5">
                                    <form [formGroup]="verificadoresForm">
                                        <mat-form-field appearance="fill" style="display: inherit;">
                                            <mat-select [(ngModel)]="selectedVerif" formControlName="userVerificador" (selectionChange)="verificadorChange($event)">
                                                <mat-option *ngFor="let verif of allVerificadores" [value]="verif.id">
                                                    {{verif.nombre}} {{verif.primer_apellido}} {{verif.segundo_apellido}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </form>
                                </td>
                                <td id="titulo_no_registro_supervisor" class="titulo_left" colspan="1">NO. REGISTRO</td>
                                <td id="no_registro_supervisor" class="contenido" colspan="1">
                                    <strong *ngIf="obra.verificador != null;else elseCedula">
                                        {{obra.verificador.cedula}}
                                    </strong>
                                    <ng-template #elseCedula>
                                        <strong>Sin Asignar</strong>
                                    </ng-template>
                                </td>
                            </tr>
                            <!--SEPARADOR--><tr><td colspan="8" class="separador"></td></tr><!--SEPARADOR-->
                            <tr>
                                <td id="evidencias" colspan="8">
                                    <strong *ngIf="evidenciasArchivos.length < 1;else elseEvidencias">
                                        No hay evidencias relacionadas.
                                    </strong>
                                    <ng-template #elseEvidencias>
                                        <div class="row">
                                            <div class="col-6" *ngFor="let evidencia of evidenciasArchivos">
                                                <img src="{{ host }}images/{{evidencia.imgName}}" style="max-width: inherit; margin: auto;" class="mx-auto d-block"/>
                                            </div>
                                        </div>
                                    </ng-template>
                                </td>
                            </tr>
                            <!--SEPARADOR--><tr><td colspan="8" class="separador"></td></tr><!--SEPARADOR-->
                            <tr>
                                <td id="titulo_descripcion" class="titulo_left" colspan="1">DESCRIPCIÓN</td>
                                <td id="descripcion" class="contenido" colspan="7">
                                    <textarea placeholder="Sin Descripción" class="form-control" rows="3">{{reporte.reportes[0].descripcion}}</textarea>
                                </td>
                            </tr>
                            <!--SEPARADOR--><tr><td colspan="8" class="separador"></td></tr><!--SEPARADOR-->
                            <tr>
                                <td id="titulo_lugar_y_fecha" class="titulo_left" colspan="1">LUGAR Y FECHA</td>
                                <td id="lugar_y_fecha" class="contenido" colspan="7">
                                    <strong> {{obra.localidad.nombre}}, {{obra.municipio.nombre}}, Estado de {{obra.estado.nombre}} - {{fechaActual | date: 'longDate' }} </strong>
                                </td>
                            </tr>
                            <!--SEPARADOR--><tr><td colspan="29" class="separador"></td></tr><!--SEPARADOR-->
                            <tr>
                                <td id="titulo_nombre_firma_cedula" class="titulo_left" colspan="1">NOMBRE, FIRMA Y CÉDULA PROFESIONAL VERIFICADOR</td>
                                <td id="nombre_y_firma" class="contenido" colspan="7">
                                    <div *ngIf="obra.verificador == null; else verificador">
                                        <strong>Verificador sin asignar.</strong>
                                    </div>
                                    <ng-template #verificador>
                                        <div>
                                            <strong> {{obra.verificador.nombre}} {{obra.verificador.primer_apellido}} {{obra.verificador.segundo_apellido}}. </strong>
                                        </div>
                                        <hr>
                                        <div>
                                            <strong> Firma no disponible. </strong>
                                        </div>
                                        <hr>
                                        <div>
                                            <strong> Cedula no disponible. </strong>
                                        </div>
                                    </ng-template>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </mat-tab>
                <!--*****************-->
                <!--REPORTE CARATULA-->
                <!--*****************-->
                <mat-tab *ngIf="reporteCaratula" label="Reporte de Caratula">
                    <table id="tabla_reporte_p" class="table table-bordered table-sm reporteCaratula"> <!--8 Columnas (excel)-->
                        <tbody>
                            <tr>
                                <td id="logo_ISO" class="contenido" colspan="1">
                                    <img class="logo" src="../../../assets/img/iso.png" alt="Logo Calidad ISO" />
                                </td>
                                <td id="titulo_ruv" colspan="7">
                                    <table>
                                        <tr>
                                            REGISTRO ÚNICO DE VIVIENDA
                                        </tr>
                                        <tr><br></tr>
                                        <tr><br></tr>
                                        <tr><br></tr>
                                        <tr><br></tr>
                                        <tr id="titulo_reporte">
                                            CARATULA DE REPORTE DE OBRA
                                        </tr>
                                    </table>
                                </td>
                            </tr><tr>
                                <td id="titulo_no_oferta" class="titulo_left" colspan="1">NO. OFERTA</td>
                                <td id="no_oferta" class="contenido" colspan="1">
                                    <strong>
                                        {{reporte.id_oferta}}
                                    </strong>
                                </td>
                                <td id="titulo_orden_verif" class="titulo_left" colspan="1">ORDEN DE VERIF.</td>
                                <td id="ordern_verif" class="contenido" colspan="1">
                                    <strong>
                                        {{reporte.id_orden}}
                                    </strong>
                                </td>
                                <td id="titulo_no_viviendas" class="titulo_left" colspan="1">NO. DE VIVIENDAS</td>
                                <td id="no_viviendas" class="contenido" colspan="1">
                                    <strong>
                                        {{reporte.cuvs_count}}
                                    </strong>
                                </td>
                                <td id="titulo_viv_ecologica" class="titulo_left" colspan="1">VIV. ECOLÓGICA</td>
                                <td id="viv_ecologica" class="contenido" colspan="1">{{totalEdifEcos}}</td>
                            </tr>
                            <!--SEPARADOR--><tr><td colspan="8" class="separador"></td></tr><!--SEPARADOR-->
                            <tr>
                                <td id="titulo_estado" class="titulo_left" colspan="1">ESTADO</td>
                                <td id="estado" class="contenido" colspan="3">
                                    <strong>
                                        {{obra.estado.nombre}}
                                    </strong>
                                </td>
                                <td id="titulo_localidad" class="titulo_left" colspan="1">LOCALIDAD</td>
                                <td id="localidad" class="contenido" colspan="3">
                                    <strong>
                                        {{obra.municipio.nombre}}
                                    </strong>
                                </td>
                            </tr>
                            <tr>
                                <td id="titulo_nombre_del_frente" class="titulo_left" colspan="1">NOMBRE DEL FRENTE</td>
                                <td id="nombre_del_frente" class="contenido" colspan="7"><strong>{{reporte.nombre_del_frente}}</strong></td>
                            </tr>
                            <tr>
                                <td id="titulo_domicilio" class="titulo_left" colspan="1">DOMICILIO</td>
                                <td id="domicilio" class="contenido" colspan="7">
                                    <strong>
                                        Calle {{reporte.calle}}, Colonia {{reporte.colonia}}, CP: {{reporte.codigo_postal}}
                                    </strong>
                                </td>
                            </tr>
                            <!--SEPARADOR--><tr><td colspan="8" class="separador"></td></tr><!--SEPARADOR-->
                            <tr>
                                <td id="titulo_fecha_inicio_programado" class="titulo_top" colspan="1">INICIO PROGRAMADO</td>
                                <td id="titulo_fecha_terminacion_prog" class="titulo_top" colspan="1">TERMINACIÓN PROG.</td>
                                <td id="titulo_inicio_real" class="titulo_top" colspan="1">INICIO REAL</td>
                                <td id="titulo_plazo_ejecucion" class="titulo_top" colspan="1">PLAZO EJECUCIÓN</td>
                                <td id="titulo_periodo_de_reporte" class="titulo_top" colspan="2">PERIODO DE REPORTE</td>
                                <td id="titulo_no_de_reporte" class="titulo_top" colspan="2">NO. DE REPORTE</td>
                            </tr>
                            <tr>
                                <td id="fecha_inicio_programado" class="contenido" colspan="1">
                                    <strong>
                                        [Pendiente]
                                    </strong>
                                </td>
                                <td id="fecha_terminacion_prog" class="contenido" colspan="1">
                                    <strong>
                                        [Pendiente]
                                    </strong>
                                </td>
                                <td id="inicio_real" class="contenido" colspan="1">
                                    <strong>
                                        {{obra.inicio_real}}
                                    </strong>
                                </td>
                                <td id="plazo_ejecucion" class="contenido" colspan="1">
                                    <strong>
                                        {{obra.plazo_ejecucion}} meses
                                    </strong>
                                </td>
                                <td id="periodo_de_reporte_inicio" class="contenido" colspan="1">
                                    <strong>
                                        {{first | date: 'mediumDate'}}
                                    </strong>
                                </td>
                                <td id="periodo_de_reporte_fin" class="contenido" colspan="1">
                                    <strong>
                                        {{last | date: 'mediumDate'}}
                                    </strong>
                                </td>
                                <td id="no_de_reporte" class="contenido" colspan="2">
                                    <strong>
                                        {{ultimoReporte.numero_reporte+1}}
                                    </strong>
                                </td>
                            </tr>
                        <!--SEPARADOR--><tr><td colspan="8" class="separador"></td></tr><!--SEPARADOR-->
                            <tr>
                                <td id="titulo_constructora" class="titulo_left" colspan="1">CONSTRUCTORA</td>
                                <td id="constructora" class="contenido" colspan="5"><strong>{{obra.constructora}}</strong></td>
                                <td id="titulo_no_registro_constructora" class="titulo_left" colspan="1">NO. REGISTRO</td>
                                <td id="no_registro_constructora" class="contenido" colspan="1"><strong>{{obra.numero_registro}}</strong></td>
                            </tr>
                            <tr>
                                <td id="titulo_supervisor_verificador" class="titulo_left" colspan="1">SUPERVISOR / VERIFICADOR</td>
                                <td id="supervisor_verificador" class="contenido" colspan="5">
                                    <form [formGroup]="verificadoresForm">
                                        <mat-form-field appearance="fill" style="display: inherit;">
                                            <mat-select [(ngModel)]="selectedVerif" formControlName="userVerificador" (selectionChange)="verificadorChange($event)">
                                                <mat-option *ngFor="let verif of allVerificadores" [value]="verif.id">
                                                    {{verif.nombre}} {{verif.primer_apellido}} {{verif.segundo_apellido}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </form>
                                </td>
                                <td id="titulo_no_registro_supervisor" class="titulo_left" colspan="1">NO. REGISTRO</td>
                                <td id="no_registro_supervisor" class="contenido" colspan="1">
                                    <strong *ngIf="obra.verificador != null;else elseCedula">
                                        {{obra.verificador.cedula}}
                                    </strong>
                                    <ng-template #elseCedula>
                                        <strong>Sin Asignar</strong>
                                    </ng-template>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="8" id="alerta-ultimo-reporte" class="separador">
                                    <div class="alert alert-warning alert-dismissible fade show" role="alert" style="margin:auto;">
                                        Ultimo reporte generado el: <strong>{{ ultimoReporte.created_at | date:'longDate' }}</strong>
                                        <br>
                                        Número de quincena actual: <strong>{{ numeroQuincena }}</strong>
                                        <br>
                                        <ng-template *ngIf='!existeReporte'>
                                            <strong>No se ha generado un reporte esta quincena</strong>
                                        </ng-template>
                                        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                            <!--SEPARADOR--><tr><td colspan="8" class="separador"></td></tr><!--SEPARADOR-->
                            <tr style="background-color: turquoise;">
                                <td id="titulo-desglose-obra" class="titulo_top" colspan="1">DESGLOSE POR TIPO DE OBRA</td>
                                <td id="titulo-cantidad-obra" class="titulo_top" colspan="1">CANTIDAD DE OBRA</td>
                                <td id="titulo-factor" class="titulo_top" colspan="1">FACTOR</td>
                                <td id="titulo-monto" class="titulo_top" colspan="1">MONTO</td>
                                <td id="titulo-avance-programado" class="titulo_top" colspan="2">
                                    AVANCE PROGRAMADO
                                </td>
                                <td id="titulo-avance-real" class="titulo_top" colspan="2">AVANCE REAL</td>
                            </tr>
                            <tr>
                                <td id="desglose-obra" class="contenido" colspan="1" style="padding: 0px;">
                                    <table>
                                        <!-- prototipos -->
                                        <ng-container *ngFor="let prototipo of reporte.prototipos">
                                            <tr>
                                                <td class="prototipoContainer">
                                                    {{prototipo.nombre}}
                                                </td>
                                            </tr>
                                        </ng-container>
                                        <!-- ecologica -->
                                        <tr style="background-color: rgb(0, 236, 0);">
                                            <td>
                                                EDIF. ECOLOGICA
                                            </td>
                                        </tr>
                                        <!-- equipamiento urbano -->
                                        <ng-container *ngFor="let partida of ponderacionesEqUrbano">
                                            <tr>
                                                <td class="prototipoContainer">
                                                    {{partida.partida.nombre}}
                                                </td>
                                            </tr>
                                        </ng-container>
                                        <!-- totales -->
                                        <tr>
                                            <td>
                                                TOTALES
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                                <td id="cantidad-obra" class="contenido" colspan="1" style="padding: 0px;">
                                    <table>
                                        <!-- prototipos -->
                                        <ng-container *ngFor="let prototipo of reporte.prototipos">
                                            <tr>
                                                <td class="prototipoContainer">
                                                    {{prototipo.cuvs_count}}
                                                </td>
                                            </tr>
                                        </ng-container>
                                        <!-- ecologica -->
                                        <tr style="background-color: rgb(0, 236, 0);">
                                            <td>
                                                {{totalEdifEcos}}
                                            </td>
                                        </tr>
                                        <!-- equipamiento urbano -->
                                        <ng-container *ngFor="let partida of ponderacionesEqUrbano">
                                            <tr>
                                                <td class="prototipoContainer">
                                                    -
                                                </td>
                                            </tr>
                                        </ng-container>
                                        <!-- totales -->
                                        <tr>
                                            <td>
                                                <br>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                                <td id="factor" class="contenido" colspan="1" style="padding: 0px;">
                                    <table>
                                        <!-- prototipos -->
                                        <ng-container *ngFor="let prototipo of reporte.prototipos">
                                            <tr>
                                                <td class="prototipoContainer">
                                                    {{ (100 - totalPonderacionEqUrbano) * (prototipo.cuvs_sum_costo_vivienda/reporte.cuvs_sum_costo_vivienda) | number : '1.2-2'}}%
                                                </td>
                                            </tr>
                                        </ng-container>
                                        <!-- ecologica -->
                                        <tr style="background-color: rgb(0, 236, 0);">
                                            <td>
                                                {{ totalMontoEdifEcos / totalMonto * 100 | number : '1.2-2'}}%
                                            </td>
                                        </tr>
                                        <!-- equipamiento urbano -->
                                        <ng-container *ngFor="let partida of ponderacionesEqUrbano">
                                            <tr>
                                                <td class="prototipoContainer">
                                                    {{ partida.ponderacion * 100 | number : '1.2-2' }}%
                                                </td>
                                            </tr>
                                        </ng-container>
                                        <!-- totales -->
                                        <tr>
                                            <td>
                                                {{  (totalFactor * 100 + totalPonderacionEqUrbano) | number : '1.2-2'}}%
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                                <td id="monto" class="contenido" colspan="1" style="padding: 0px;">
                                    <table>
                                        <!-- prototipos -->
                                        <ng-container *ngFor="let prototipo of reporte.prototipos">
                                            <tr>
                                                <ng-container *ngIf="prototipo.cuvs_sum_costo_vivienda != null; else nullPrototipo">
                                                    <td class="prototipoContainer">
                                                        {{((100 - totalPonderacionEqUrbano) * (prototipo.cuvs_sum_costo_vivienda/reporte.cuvs_sum_costo_vivienda) / 100 * (totalMonto)) | number : '1.2-2'}}
                                                    </td>
                                                </ng-container>
                                                <ng-template #nullPrototipo>
                                                    <td class="prototipoContainer">
                                                        0
                                                    </td>
                                                </ng-template>
                                            </tr>
                                        </ng-container>
                                        <!-- ecologica -->
                                        <tr style="background-color: rgb(0, 236, 0);">
                                            <td>
                                                {{totalMontoEdifEcos | number : '1.2-2'}}
                                            </td>
                                        </tr>
                                        <!-- equipamiento urbano -->
                                        <ng-container *ngFor="let partida of ponderacionesEqUrbano">
                                            <tr>
                                                <td class="prototipoContainer">
                                                    {{ (partida.ponderacion) * totalMonto | number : '1.2-2' }}
                                                </td>
                                            </tr>
                                        </ng-container>
                                        <!-- totales -->
                                        <tr>
                                            <td>
                                                {{totalMonto | number : '1.2-2'}}
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                                <td id="avance-programado" class="contenido" colspan="2" style="padding: 0px;">
                                    <table>
                                        <!-- prototipos -->
                                        <ng-container *ngFor="let prototipo of reporte.prototipos">
                                            <tr>
                                                <ng-container *ngIf="prototipo.cuvs_sum_costo_vivienda != null; else nullPrototipo">
                                                    <td class="prototipoContainer">
                                                        {{ (((avanceProgramado[historialReportes[historialReportes.length-1].numero_reporte] / 100) * (prototipo.cuvs_sum_costo_vivienda * ((100 - totalPonderacionEqUrbano)/100)))) | number : '1.2-2'}}
                                                    </td>
                                                </ng-container>
                                                <ng-template #nullPrototipo>
                                                    <td class="prototipoContainer">
                                                        0
                                                    </td>
                                                </ng-template>
                                                <td>
                                                    {{avanceProgramado[historialReportes[historialReportes.length-1].numero_reporte]}}%
                                                </td>
                                            </tr>
                                        </ng-container>
                                        <!-- ecologica -->
                                        <tr style="background-color: rgb(0, 236, 0);">
                                            <td>
                                                {{((avanceProgramado[historialReportes[historialReportes.length-1].numero_reporte] / 100) * totalMontoEdifEcos) | number : '1.2-2'}}
                                            </td>
                                            <td>
                                                {{avanceProgramado[historialReportes[historialReportes.length-1].numero_reporte]}}%
                                            </td>
                                        </tr>
                                        <!-- equipamiento urbano -->
                                        <ng-container *ngFor="let partida of ponderacionesEqUrbano">
                                            <tr>
                                                <td class="prototipoContainer">
                                                    {{ (avanceProgramado[historialReportes[historialReportes.length-1].numero_reporte] / 100) * ((partida.ponderacion) * totalMonto) | number : '1.2-2' }}
                                                </td>
                                                <td>
                                                    {{avanceProgramado[historialReportes[historialReportes.length-1].numero_reporte]}}%
                                                </td>
                                            </tr>
                                        </ng-container>
                                        <!-- totales -->
                                        <tr>
                                            <td>
                                                {{( (avanceProgramado[historialReportes[historialReportes.length-1].numero_reporte] / 100) * totalMonto) | number : '1.2-2'}}
                                            </td>
                                            <td>
                                                {{avanceProgramado[historialReportes[historialReportes.length-1].numero_reporte]}}%
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                                <td id="avance-real" class="contenido" colspan="2" style="padding: 0px;">
                                    <table>
                                        <!-- prototipos -->
                                        <ng-container *ngFor="let prototipo of reporte.prototipos; let i = index">
                                            <tr>
                                                <ng-container *ngIf="prototipo.cuvs_sum_costo_vivienda != null; else nullPrototipo">
                                                    <td class="prototipoContainer">
                                                        {{ (((100 - totalPonderacionEqUrbano) * (prototipo.cuvs_sum_costo_vivienda/reporte.cuvs_sum_costo_vivienda) / 100 * (totalMonto)) * prototipos[i] ) / 100 | number : '1.2-2'}}
                                                    </td>
                                                </ng-container>
                                                <ng-template #nullPrototipo>
                                                    <td class="prototipoContainer">
                                                        0
                                                    </td>
                                                </ng-template>
                                                <td>
                                                    {{prototipos[i]  | number : '1.2-2'}}%
                                                </td>
                                            </tr>
                                        </ng-container>
                                        <!-- ecologica -->
                                        <tr style="background-color: rgb(0, 236, 0);">
                                            <td>
                                                {{ totalMontoEdifEcos * porcentajeEco / 100 | number : '1.2-2'}}
                                            </td>
                                            <td>
                                                {{ porcentajeEco | number : '1.2-3'}}%
                                            </td>
                                        </tr>
                                        <!-- equipamiento urbano -->
                                        <ng-container *ngFor="let partida of ponderacionesEqUrbano; let i = index">
                                            <tr>
                                                <td class="prototipoContainer">
                                                    {{ ((partida.ponderacion) * totalMonto) * (eqUrbano[i]/100) | number : '1.2-3' }}
                                                </td>
                                                <td>
                                                    {{ eqUrbano[i] | number : '1.2-3' }}%
                                                </td>
                                            </tr>
                                        </ng-container>
                                        <!-- totales -->
                                        <tr>
                                            <td>
                                                <!-- {{( (avanceTotal) * totalMonto) | number : '1.2-2'}} -->
                                                {{ totalExp | number : '1.2-3'}}
                                            </td>
                                            <td>
                                                {{ (totalPorcentaje) | number : '1.2-3'}} %
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            <!--SEPARADOR--><tr><td colspan="8" class="separador"></td></tr><!--SEPARADOR-->
                            <tr>
                                <td id="vacio" class="titulo_top" colspan="2"></td>
                                <td id="titulo-atraso" class="titulo_top" colspan="1">ATRASO DE OBRA</td>
                                <td id="atraso" class="titulo_top" colspan="1">
                                    {{ ((avanceProgramado[historialReportes[historialReportes.length-1].numero_reporte] - totalPorcentaje) * 100) / avanceProgramado[historialReportes[historialReportes.length-1].numero_reporte]| number : '1.2-2'}} %
                                </td>
                                <td id="titulo-desfase" class="titulo_top" colspan="1">
                                    DESFASE DE AVANCE
                                </td>
                                <td id="desfase" class="titulo_top" colspan="1">
                                    {{ avanceProgramado[historialReportes[historialReportes.length-1].numero_reporte] - totalPorcentaje | number : '1.2-2'}} %
                                </td>
                                <td id="titulo-avance-real" class="titulo_top" colspan="1">
                                    AVANCE REAL DEL PERIODO (%)
                                </td>
                                <td id="avance-real" class="titulo_top" colspan="1">
                                    {{ totalPorcentaje | number : '1.2-3'}} %
                                </td>
                            </tr>
                            <!--SEPARADOR--><tr><td colspan="8" class="separador"></td></tr><!--SEPARADOR-->
                            <tr>
                                <td style="background-color: #ccffff;" id="titulo-resumen-caracteristica" class="titulo_top" colspan="1">RESUMEN POR CARACTERISTICA</td>
                                <td style="background-color: #ccffff;" id="titulo-numero-viviendas" class="titulo_top" colspan="1">NUM. VIVIENDAS</td>
                                <td style="background-color: #ccffff;" id="vacio" class="titulo_top" colspan="1"></td>
                                <td style="background-color: #ccffff;" id="vacio" class="titulo_top" colspan="1"></td>
                                <td id="titulo-criterios-evaluacion" class="titulo_top" colspan="3">
                                    CALIFICACIÓN DE OBRA EJECUTADA Y ESPECIFICACIONES CON BASE A CRITERIOS DE EVALUACIÓN DE CALIDAD
                                </td>
                                <td id="titulo-total-oferta" class="titulo_top" colspan="1">%  DEL TOTAL DE LA OFERTA</td>
                            </tr>
                            <tr>
                                <td id="resumen-caracteristica" class="contenido" colspan="1" style="padding: 0px;">
                                    <table>
                                        <tr>
                                            <td>ASIGNADAS <strong>(B)</strong></td>
                                        </tr>
                                        <tr>
                                            <td>SIN INICIAR</td>
                                        </tr>
                                        <tr>
                                            <td>EN PROCESO</td>
                                        </tr>
                                        <tr>
                                            <td>TERMINADAS</td>
                                        </tr>
                                        <tr>
                                            <td>CON DTU</td>
                                        </tr>
                                        <tr>
                                            <td>ECOLOGICAS</td>
                                        </tr>
                                        <tr>
                                            <td>CAN. O C/SOL. CAN. <strong>(C)</strong> </td>
                                        </tr>
                                        <tr>
                                            <td>SUSPENDIDAS</td>
                                        </tr>
                                    </table>
                                </td>
                                <td id="numero-viviendas" class="contenido" colspan="1" style="padding: 0px;">
                                    <table>
                                        <tr>
                                            <td>{{reporte.cuvs_count}}</td>
                                        </tr>
                                        <tr>
                                            <td>{{reporte.cuvs_count - iniciadas}}</td>
                                        </tr>
                                        <tr>
                                            <td>{{iniciadas}}</td>
                                        </tr>
                                        <tr>
                                            <td>0</td>
                                        </tr>
                                        <tr>
                                            <td>{{cuvsDtu}}</td>
                                        </tr>
                                        <tr>
                                            <td>{{totalEdifEcos}}</td>
                                        </tr>
                                        <tr>
                                            <td>0</td>
                                        </tr>
                                        <tr>
                                            <td>0</td>
                                        </tr>
                                    </table>
                                </td>
                                <td id="vacio" class="contenido" colspan="1" style="padding: 0px;">
                                    <table>
                                        <tr><td>-</td></tr>
                                        <tr><td>-</td></tr>
                                        <tr><td>-</td></tr>
                                        <tr><td>-</td></tr>
                                        <tr><td>-</td></tr>
                                        <tr><td>-</td></tr>
                                        <tr><td>-</td></tr>
                                        <tr><td>-</td></tr>
                                    </table>
                                </td>
                                <td id="vacio" class="contenido" colspan="1" style="padding: 0px;">
                                    <table>
                                        <tr><td>-</td></tr>
                                        <tr><td>-</td></tr>
                                        <tr><td>-</td></tr>
                                        <tr><td>-</td></tr>
                                        <tr><td>-</td></tr>
                                        <tr><td>-</td></tr>
                                        <tr><td>-</td></tr>
                                        <tr><td>-</td></tr>
                                    </table>
                                </td>
                                <td id="criterios-evaluacion" class="contenido" colspan="4" style="padding: 0px;">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td colspan="1" class="w-25"><strong>CUMPLEN</strong></td>
                                                <td colspan="1" class="w-25">
                                                    <ng-container *ngIf="conCumplimiento[0]>0;else siUncheck">
                                                        <input class="form-check-input" type="checkbox" checked disabled>
                                                    </ng-container>                
                                                    <ng-template #siUncheck>
                                                        <input class="form-check-input" type="checkbox" disabled>
                                                    </ng-template>
                                                    (*)
                                                </td>
                                                <td colspan="1" class="w-25">
                                                    NÚM. VIV. 
                                                    <strong class="text-right">
                                                        {{conCumplimiento[0]}}
                                                    </strong>
                                                </td>
                                                <td colspan="1" class="w-25">{{(conCumplimiento[0]*100)/reporte.cuvs_count}}</td>
                                            </tr>
                                            <tr>
                                                <td colspan="1" class="w-25"><strong>NO CUMPLEN</strong> (1)</td>
                                                <td colspan="1" class="w-25">
                                                    <ng-container *ngIf="conCumplimiento[1]>0;else no1Uncheck">
                                                        <input class="form-check-input" type="checkbox" checked disabled>
                                                    </ng-container>                
                                                    <ng-template #no1Uncheck>
                                                        <input class="form-check-input" type="checkbox" disabled>
                                                    </ng-template>
                                                    (*)
                                                </td>
                                                <td colspan="1" class="w-25">
                                                    NÚM. VIV. 
                                                    <strong class="text-right">
                                                        {{conCumplimiento[1]}}
                                                    </strong>
                                                </td>
                                                <td colspan="1" class="w-25">{{(conCumplimiento[1]*100)/reporte.cuvs_count}}</td>
                                            </tr>
                                            <tr>
                                                <td colspan="1" class="w-25"><strong>NO CUMPLEN</strong> (2)</td>
                                                <td colspan="1" class="w-25">
                                                    <ng-container *ngIf="conCumplimiento[2]>0;else no2Uncheck">
                                                        <input class="form-check-input" type="checkbox" checked disabled>
                                                    </ng-container>                
                                                    <ng-template #no2Uncheck>
                                                        <input class="form-check-input" type="checkbox" disabled>
                                                    </ng-template>
                                                    (*)
                                                </td>
                                                <td colspan="1" class="w-25">
                                                    NÚM. VIV. 
                                                    <strong class="text-right">
                                                        {{conCumplimiento[2]}}
                                                    </strong>
                                                </td>
                                                <td colspan="2" class="w-25">{{(conCumplimiento[0]*100)/reporte.cuvs_count}}</td>
                                            </tr>
                                            <tr>
                                                <td colspan="4" class="w-100">(*) FECHA(S) Y NUMERO(S) DE NOTAS DE BITACÓRA ASOCIADAS:</td>
                                            </tr>
                                            <tr>
                                                <td colspan="2" class="w-50">
                                                    <ng-container *ngIf="notasBitacoraAsociadas[0]; else sinNotas">
                                                        <strong>
                                                            N: {{ notasBitacoraAsociadas[0][1][0].id }} 
                                                            <ng-container *ngIf="notasBitacoraAsociadas[0][1].length > 1">
                                                                - {{ notasBitacoraAsociadas[0][1][notasBitacoraAsociadas[0][1].length-1].id }}
                                                            </ng-container>
                                                        </strong>
                                                        , {{notasBitacoraAsociadas[0][0] | date: 'mediumDate'}}
                                                    </ng-container>
                                                    <ng-template #sinNotas>-</ng-template>
                                                </td>
                                                <td colspan="2" class="w-50">
                                                    <ng-container *ngIf="notasBitacoraAsociadas[1]; else sinNotas">
                                                        <strong>
                                                            N: {{ notasBitacoraAsociadas[1][1][0].id }} 
                                                            <ng-container *ngIf="notasBitacoraAsociadas[1][1].length > 1">
                                                                - {{ notasBitacoraAsociadas[1][1][notasBitacoraAsociadas[1][1].length-1].id }}
                                                            </ng-container>
                                                        </strong>
                                                        , {{notasBitacoraAsociadas[1][0] | date: 'mediumDate'}}
                                                    </ng-container>
                                                    <ng-template #sinNotas>-</ng-template>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="2" class="w-50">
                                                    <ng-container *ngIf="notasBitacoraAsociadas[2]; else sinNotas">
                                                        <strong>
                                                            N: {{ notasBitacoraAsociadas[2][1][0].id }} 
                                                            <ng-container *ngIf="notasBitacoraAsociadas[2][1].length > 1">
                                                                - {{ notasBitacoraAsociadas[2][1][notasBitacoraAsociadas[2][1].length-1].id }}
                                                            </ng-container>
                                                        </strong>
                                                        , {{notasBitacoraAsociadas[2][0] | date: 'mediumDate'}}
                                                    </ng-container>
                                                    <ng-template #sinNotas>-</ng-template>
                                                </td>
                                                <td colspan="2" class="w-50">
                                                    <ng-container *ngIf="notasBitacoraAsociadas[3]; else sinNotas">
                                                        <strong>
                                                            N: {{ notasBitacoraAsociadas[3][1][0].id }} 
                                                            <ng-container *ngIf="notasBitacoraAsociadas[3][1].length > 1">
                                                                - {{ notasBitacoraAsociadas[3][1][notasBitacoraAsociadas[3][1].length-1].id }}
                                                            </ng-container>
                                                        </strong>
                                                        , {{notasBitacoraAsociadas[3][0] | date: 'mediumDate'}}
                                                    </ng-container>
                                                    <ng-template #sinNotas>-</ng-template>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="2" class="w-50">
                                                    <ng-container *ngIf="notasBitacoraAsociadas[4]; else sinNotas">
                                                        <strong>
                                                            N: {{ notasBitacoraAsociadas[4][1][0].id }} 
                                                            <ng-container *ngIf="notasBitacoraAsociadas[4][1].length > 1">
                                                                - {{ notasBitacoraAsociadas[4][1][notasBitacoraAsociadas[4][1].length-1].id }}
                                                            </ng-container>
                                                        </strong>
                                                        , {{notasBitacoraAsociadas[4][0] | date: 'mediumDate'}}
                                                    </ng-container>
                                                    <ng-template #sinNotas>-</ng-template>
                                                </td>
                                                <td colspan="2" class="w-50">
                                                    <ng-container *ngIf="notasBitacoraAsociadas[5]; else sinNotas">
                                                        <strong>
                                                            N: {{ notasBitacoraAsociadas[5][1][0].id }} 
                                                            <ng-container *ngIf="notasBitacoraAsociadas[5][1].length > 1">
                                                                - {{ notasBitacoraAsociadas[5][1][notasBitacoraAsociadas[5][1].length-1].id }}
                                                            </ng-container>
                                                        </strong>
                                                        , {{notasBitacoraAsociadas[5][0] | date: 'mediumDate'}}
                                                    </ng-container>
                                                    <ng-template #sinNotas>-</ng-template>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="2" class="w-50">
                                                    <ng-container *ngIf="notasBitacoraAsociadas[6]; else sinNotas">
                                                        <strong>
                                                            N: {{ notasBitacoraAsociadas[6][1][0].id }} 
                                                            <ng-container *ngIf="notasBitacoraAsociadas[6][1].length > 1">
                                                                - {{ notasBitacoraAsociadas[6][1][notasBitacoraAsociadas[6][1].length-1].id }}
                                                            </ng-container>
                                                        </strong>
                                                        , {{notasBitacoraAsociadas[6][0] | date: 'mediumDate'}}
                                                    </ng-container>
                                                    <ng-template #sinNotas>-</ng-template>
                                                </td>
                                                <td colspan="2" class="w-50">
                                                    <ng-container *ngIf="notasBitacoraAsociadas[7]; else sinNotas">
                                                        <strong>
                                                            N: {{ notasBitacoraAsociadas[7][1][0].id }} 
                                                            <ng-container *ngIf="notasBitacoraAsociadas[7][1].length > 1">
                                                                - {{ notasBitacoraAsociadas[7][1][notasBitacoraAsociadas[7][1].length-1].id }}
                                                            </ng-container>
                                                        </strong>
                                                        , {{notasBitacoraAsociadas[7][0] | date: 'mediumDate'}}
                                                    </ng-container>
                                                    <ng-template #sinNotas>-</ng-template>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <!--SEPARADOR--><tr><td colspan="8" class="separador"></td></tr><!--SEPARADOR-->
                            <tr>
                                <td id="titulo-verificacion-sin-inicio" colspan="4" style="background: #f79646;">
                                    <strong>VERIFICACIÓN CRITERIO "SIN INICIO DE OBRA"</strong>
                                </td>
                                <td id="titulo-verificacion-ecotecnologia" colspan="4" style="background: #00ff00;">
                                    <strong>VERIFICACIÓN ECOTECNOLOGÍAS</strong>
                                </td>
                            </tr>
                            <tr>
                                <td id="verificacion-sin-inicio" class="contenido" colspan="1" style="background: #f79646;">
                                    CONDICION
                                </td>
                                <td id="verificacion-sin-inicio" class="contenido" colspan="1" style="background: #f79646;">
                                    NUM. VIVS.
                                </td>
                                <td id="verificacion-sin-inicio" class="contenido" colspan="1" style="background: #f79646;">
                                    FECHAS(S) NOTA(S)
                                </td>
                                <td id="verificacion-sin-inicio" class="contenido" colspan="1" style="background: #f79646;">
                                    NUM NOTA(S)
                                </td>
                                <td id="verificacion-ecotecnologia" class="contenido" colspan="1" style="background: #00ff00;">
                                    CONDICION
                                </td>
                                <td id="verificacion-ecotecnologia" class="contenido" colspan="1" style="background: #00ff00;">
                                    NUM. VIVS.
                                </td>
                                <td id="verificacion-ecotecnologia" class="contenido" colspan="1" style="background: #00ff00;">
                                    FECHAS(S) NOTA(S)
                                </td>
                                <td id="verificacion-ecotecnologia" class="contenido" colspan="1" style="background: #00ff00;">
                                    NUM  NOTA(S)
                                </td>
                            </tr>
                            <tr>
                                <td id="verificacion-sin-inicio" class="contenido" colspan="1" style="padding: 0px;">
                                    CUMPLEN
                                </td>
                                <td id="verificacion-sin-inicio" class="contenido" colspan="1" style="padding: 0px;">
                                    {{reporte.cuvs_count - iniciadas}}
                                </td>
                                <td id="verificacion-sin-inicio" class="contenido" colspan="1" style="padding: 0px;">
                                    <ng-container *ngIf="totalNotasSinInicio.length > 0;else sinInicionNull">
                                        {{ totalNotasSinInicio[0].created_at | date: 'dd-MM-yyyy' }}
                                        <br> a <br>
                                        {{ totalNotasSinInicio[totalNotasSinInicio.length-1].created_at | date: 'dd-MM-yyyy' }}
                                    </ng-container>
                                    <ng-template #sinInicionNull>
                                        N/A
                                    </ng-template>
                                </td>
                                <td id="verificacion-sin-inicio" class="contenido" colspan="1" style="padding: 0px;">
                                    {{ totalNotasSinInicio.length }}
                                </td>
                                <td id="verificacion-ecotecnologia" class="contenido" colspan="1" style="padding: 0px;">
                                    CUMPLEN
                                </td>
                                <td id="verificacion-ecotecnologia" class="contenido" colspan="1" style="padding: 0px;">
                                    {{ totalEdifEcos }}
                                </td>
                                <td id="verificacion-ecotecnologia" class="contenido" colspan="1" style="padding: 0px;">
                                    <ng-container *ngIf="totalNotasEco.length > 0;else notasEcoNull">
                                        {{ totalNotasEco[0].created_at | date: 'dd-MM-yyyy' }}
                                        <br> a <br>
                                        {{ totalNotasEco[totalNotasEco.length-1].created_at | date: 'dd-MM-yyyy' }}
                                    </ng-container>
                                    <ng-template #notasEcoNull>
                                        N/A
                                    </ng-template>
                                </td>
                                <td id="verificacion-ecotecnologia" class="contenido" colspan="1" style="padding: 0px;">
                                    {{ totalNotasEco.length }}
                                </td>
                            </tr>
                            <tr>
                                <td id="verificacion-sin-inicio" class="contenido red" colspan="1" style="padding: 0px;">
                                    NO CUMPLEN
                                </td>
                                <td id="verificacion-sin-inicio" class="contenido" colspan="1" style="padding: 0px;">
                                    {{ iniciadas }}
                                </td>
                                <td id="verificacion-sin-inicio" class="contenido" colspan="1" style="padding: 0px;">
                                    <ng-container *ngIf="NCNotasSinInicio.length > 0;else NCNotasSinInicioNull">
                                        {{ NCNotasSinInicio[0].created_at | date: 'dd-MM-yyyy' }}
                                        <br> a <br>
                                        {{ NCNotasSinInicio[NCNotasSinInicio.length-1].created_at | date: 'dd-MM-yyyy' }}
                                    </ng-container>
                                    <ng-template #NCNotasSinInicioNull>
                                        N/A
                                    </ng-template>
                                </td>
                                <td id="verificacion-sin-inicio" class="contenido" colspan="1" style="padding: 0px;">
                                    {{ totalNotasSinInicio.length }} 
                                </td>
                                <td id="verificacion-ecotecnologia" class="contenido red" colspan="1" style="padding: 0px;">
                                    NO CUMPLEN
                                </td>
                                <td id="verificacion-ecotecnologia" class="contenido" colspan="1" style="padding: 0px;">
                                    {{ reporte.cuvs_count - totalEdifEcos }}
                                </td>
                                <td id="verificacion-ecotecnologia" class="contenido" colspan="1" style="padding: 0px;">
                                    <ng-container *ngIf="NCNotasEco.length > 0;else NCNotasEcoNull">
                                        {{ NCNotasEco[0].created_at | date: 'dd-MM-yyyy' }}
                                        <br> a <br>
                                        {{ NCNotasEco[NCNotasEco.length-1].created_at | date: 'dd-MM-yyyy' }}
                                    </ng-container>
                                    <ng-template #NCNotasEcoNull>
                                        N/A
                                    </ng-template>
                                </td>
                                <td id="verificacion-ecotecnologia" class="contenido" colspan="1" style="padding: 0px;">
                                    {{ NCNotasEco.length }}
                                </td>
                            </tr>
                            <tr>
                                <td colspan="4">
                                    <div>
                                        <strong>Descripción Incumplimientos</strong>
                                    </div>
                                    <div>
                                        <ng-container *ngIf="totalNotasSinInicio.length > 0; else sinNotasSI">
                                            <ng-container *ngIf="totalNotasSinInicio[0].comentario != null;else sinNotasSINC">
                                                {{ totalNotasSinInicio[0].comentario.comentario }}
                                            </ng-container>
                                            <ng-template #sinNotasSINC>
                                                <strong>N: {{totalNotasSinInicio[0].id}}</strong> - Nota sin comentario
                                            </ng-template>
                                        </ng-container>
                                        <ng-template #sinNotasSI>
                                            N/A
                                        </ng-template>
                                    </div>
                                </td>
                                <td colspan="4">
                                    <div>
                                        <strong>Descripción Incumplimientos</strong>
                                    </div>
                                    <div>
                                        <ng-container *ngIf="totalNotasEco.length > 0;else sinNotasECO">
                                            <ng-container *ngIf="totalNotasEco[0].comentario != null;else sinNotasECONC">
                                                {{ totalNotasEco[0].comentario.comentario }}
                                            </ng-container>
                                            <ng-template #sinNotasECONC>
                                                <strong>N: {{totalNotasEco[0].id}}</strong> - Nota sin comentario
                                            </ng-template>
                                        </ng-container>
                                        <ng-template #sinNotasECO>
                                            N/A
                                        </ng-template>
                                    </div>
                                </td>
                            </tr>
                            <!--SEPARADOR--><tr><td colspan="8" class="separador"></td></tr><!--SEPARADOR-->
                            <tr>
                                <td colspan="8" id="titulo-verificacion-sin-inicio" style="background: #f79646;">
                                    <strong>LABORATORIO DE CONTROL DE CALIDAD DE MATERIALES (Razon social y rfc)</strong>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="8">
                                    <input type="text" placeholder="Sin Laboratorio" class="form-control" (change)="descripcionChange($event)" value="{{reporte.reportes[0].descripcion}}">
                                </td>
                            </tr>
                            <!--SEPARADOR--><tr><td colspan="8" class="separador"></td></tr><!--SEPARADOR-->
                            <tr class="b-blue">
                                <td colspan="6">
                                    <strong>SEGUIMIENTO PREVENTIVO A LA EMISIÓN DE DTU PARA VIVIENDAS CON MARCA DE 80%</strong>
                                    <br>
                                    (TODAS LAS VIVIENDAS: CON O SIN ECOTECNOLOGÍAS)
                                </td>
                                <td colspan="1">
                                    NUM. VIVS. DEL PERIODO
                                </td>
                                <td class="b-blue-g" colspan="1">
                                    <h3>
                                        <strong>
                                            {{ cuvsAvancePeriodo }}
                                        </strong>
                                    </h3>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="1" class="text-left">
                                    <strong>
                                        DE LOS SERVICIOS
                                    </strong>
                                </td>
                                <td colspan="1" class="text-left">
                                    <strong>
                                        CUMPLE <br> SI/NO
                                    </strong>
                                </td>
                                <td colspan="1" class="text-left">
                                    <strong>
                                        DE LAS OBRAS
                                    </strong>
                                </td>
                                <td colspan="1">
                                    <strong>
                                        CUMPLE <br> SI/NO o N/A
                                    </strong>
                                </td>
                                <td colspan="1" class="text-left">
                                    <strong>
                                        DEL EQUIPAMENTO
                                    </strong>
                                </td>
                                <td colspan="1">
                                    <strong>
                                        CUMPLE SI/NO
                                    </strong>
                                </td>
                                <td colspan="1" class="text-left">
                                    <strong>
                                        DE LAS VIVIENDAS
                                    </strong>
                                </td>
                                <td colspan="1">
                                    <strong>
                                        CUMPLE SI/NO
                                    </strong>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="1" class="text-left">
                                    AGUA POTABLE
                                </td>
                                <td colspan="1">
                                    <select name="" id="" style="width:100%;">
                                        <option value="SI">SI</option>
                                        <option value="NO">NO</option>
                                    </select>
                                </td>
                                <td colspan="1" class="text-left">
                                    URBANIZACIÓN
                                </td>
                                <td colspan="1">
                                    <select name="" id="" style="width:100%;">
                                        <option value="SI">SI</option>
                                        <option value="NO">NO</option>
                                        <option value="NA">N/A</option>
                                    </select>
                                </td>
                                <td colspan="1" class="text-left">
                                    URBANO
                                </td>
                                <td colspan="1">
                                    <select name="" id="" style="width:100%;">
                                        <option value="SI">SI</option>
                                        <option value="NO">NO</option>
                                    </select>
                                </td>
                                <td colspan="1" class="text-left">
                                    CALIDAD
                                </td>
                                <td colspan="1">
                                    <select name="" id="" style="width:100%;">
                                        <option value="SI">SI</option>
                                        <option value="NO">NO</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="1" class="text-left">
                                    DRENAJE MUNICIPAL
                                </td>
                                <td colspan="1">
                                    <select name="" id="" style="width:100%;">
                                        <option value="SI">SI</option>
                                        <option value="NO">NO</option>
                                    </select>
                                </td>
                                <td colspan="1" class="text-left">
                                    OBRA EXTERIOR
                                </td>
                                <td colspan="1">
                                    <select name="" id="" style="width:100%;">
                                        <option value="SI">SI</option>
                                        <option value="NO">NO</option>
                                        <option value="NA">N/A</option>
                                    </select>
                                </td>
                                <td colspan="1" class="text-left">
                                    OTRO
                                </td>
                                <td colspan="1">
                                    <select name="" id="" style="width:100%;">
                                        <option value="SI">SI</option>
                                        <option value="NO">NO</option>
                                    </select>
                                </td>
                                <td colspan="1">
                                    ATENCIÓN A OBS.
                                </td>
                                <td colspan="1">
                                    <select name="" id="" style="width:100%;">
                                        <option value="SI">SI</option>
                                        <option value="NO">NO</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="1" class="text-left">
                                    ELECTRIFICACIÓN
                                </td>
                                <td colspan="1">
                                    <select name="" id="" style="width:100%;">
                                        <option value="SI">SI</option>
                                        <option value="NO">NO</option>
                                    </select>
                                </td>
                                <td colspan="6" class="b-blue">
                                    FECHA(S) Y NUMERO(S) DE NOTA DE BITÁCORA ASOCIADAS AL SEGUIMIENTO:
                                </td>
                            </tr>
                            <tr>
                                <td colspan="1" class="text-left">
                                    ALUMBRADO PUBLICO COMPLETO
                                </td>
                                <td colspan="1">
                                    <select name="" id="" style="width:100%;">
                                        <option value="SI">SI</option>
                                        <option value="NO">NO</option>
                                    </select>
                                </td>
                                <td colspan="6">
                                    <ng-container *ngFor="let nota of notasBitacora">
                                        <strong title="{{nota.nota}}">Nota: {{ nota.id }}</strong>, {{ nota.created_at | date: 'longDate' }}
                                    </ng-container>
                                </td>
                            </tr>
                            <!--SEPARADOR--><tr><td colspan="8" class="separador"></td></tr><!--SEPARADOR-->
                            <tr>
                                <td id="titulo_lugar_y_fecha" class="titulo_left" colspan="1">LUGAR Y FECHA</td>
                                <td id="lugar_y_fecha" class="contenido" colspan="7">
                                    <strong> {{obra.localidad.nombre}}, {{obra.municipio.nombre}}, Estado de {{obra.estado.nombre}} - {{fechaActual | date: 'longDate' }} </strong>
                                </td>
                            </tr>
                            <!--SEPARADOR--><tr><td colspan="8" class="separador"></td></tr><!--SEPARADOR-->
                            <tr>
                                <td id="titulo_nombre_firma_cedula" class="titulo_left" colspan="1">NOMBRE, FIRMA Y CÉDULA PROFESIONAL VERIFICADOR</td>
                                <td id="nombre_y_firma" class="contenido" colspan="7">
                                    <div *ngIf="obra.verificador == null; else verificador">
                                        <strong>Verificador sin asignar.</strong>
                                    </div>
                                    <ng-template #verificador>
                                        <div>
                                            <strong> {{obra.verificador.nombre}} {{obra.verificador.primer_apellido}} {{obra.verificador.segundo_apellido}}. </strong>
                                        </div>
                                        <hr>
                                        <div>
                                            <strong> Firma no disponible. </strong>
                                        </div>
                                        <hr>
                                        <div>
                                            <strong> Cedula no disponible. </strong>
                                        </div>
                                    </ng-template>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    
                </mat-tab>
                <!--******************************-->
                <!--REPORTE EVALUACION DE CALIDAD-->
                <!--******************************-->
                <mat-tab *ngIf="evaluacionCalidad" label="Reporte de Evaluacion de Calidad">
                    <table id="tabla_reporte_p" class="table table-bordered table-sm evaluacionCalidad"> <!--8 Columnas (excel)-->
                        <tbody>
                            <tr>
                                <td id="logo_ISO" class="contenido" colspan="1">
                                    <img class="logo" src="../../../assets/img/iso.png" alt="Logo Calidad ISO" />
                                </td>
                                <td id="titulo_ruv" colspan="7">
                                    <table>
                                        <tr>
                                            REGISTRO ÚNICO DE VIVIENDA
                                        </tr>
                                        <tr><br></tr>
                                        <tr><br></tr>
                                        <tr><br></tr>
                                        <tr><br></tr>
                                        <tr id="titulo_reporte">
                                            CRITERIOS PARA LA EVALUACIÓN DE LA CALIDAD DE LA VIVIENDA
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            <tr class="border-0">
                                <td class="border-0 border-left" colspan="1"></td>
                                <td class="border-0" colspan="1"></td>
                                <td class="border-0" colspan="1"></td>
                                <td class="border-0" colspan="1"></td>
                                <td class="border-0" colspan="1"></td>
                                <td class="border-0" colspan="1"></td>
                                <td class="border-0" colspan="1"></td>
                                <td class="border-0 border-right" colspan="1"></td>
                            </tr>
                            <tr>
                                <td class="contenido" colspan="8">
                                    <br>
                                    <img src="../../../assets/img/eval_calidad.png" alt="Logo Calidad ISO" />
                                    <br>
                                </td>
                            </tr>
                            <!--SEPARADOR--><tr><td colspan="8" class="separador"></td></tr><!--SEPARADOR-->
                        </tbody>
                    </table>
                    
                </mat-tab>
                <!--************************************-->
                <!--REPORTE DE CUMPLIMIENTO A LA CALIDAD-->
                <!--************************************-->
                <mat-tab *ngIf="cumplimientoCalidad" label="Reporte de Cumplimiento a la Calidad">
                    <table id="tabla_reporte_p" class="table table-bordered table-sm cumplimientoCalidad"> <!--8 Columnas (excel)-->
                        <tbody>
                            <tr>
                                <td id="logo_ISO" class="contenido" colspan="1">
                                    <img class="logo" src="../../../assets/img/iso.png" alt="Logo Calidad ISO" />
                                </td>
                                <td id="titulo_ruv" colspan="7">
                                    <table>
                                        <tr>
                                            REGISTRO ÚNICO DE VIVIENDA
                                        </tr>
                                        <tr><br></tr>
                                        <tr><br></tr>
                                        <tr><br></tr>
                                        <tr><br></tr>
                                        <tr id="titulo_reporte">
                                            REPORTE DE CUMPLIMIENTO A LA CALIDAD
                                        </tr>
                                    </table>
                                </td>
                            </tr><tr>
                                <td id="titulo_no_oferta" class="titulo_left" colspan="1">NO. OFERTA</td>
                                <td id="no_oferta" class="contenido" colspan="1">
                                    <strong>
                                        {{reporte.id_oferta}}
                                    </strong>
                                </td>
                                <td id="titulo_orden_verif" class="titulo_left" colspan="1">ORDEN DE VERIF.</td>
                                <td id="ordern_verif" class="contenido" colspan="1">
                                    <strong>
                                        {{reporte.id_orden}}
                                    </strong>
                                </td>
                                <td id="titulo_no_viviendas" class="titulo_left" colspan="1">NO. DE VIVIENDAS</td>
                                <td id="no_viviendas" class="contenido" colspan="1">
                                    <strong>
                                        {{reporte.cuvs_count}}
                                    </strong>
                                </td>
                                <td id="titulo_viv_ecologica" class="titulo_left" colspan="1">VIV. ECOLÓGICA</td>
                                <td id="viv_ecologica" class="contenido" colspan="1">{{totalEdifEcos}}</td>
                            </tr>
                            <!--SEPARADOR--><tr><td colspan="8" class="separador"></td></tr><!--SEPARADOR-->
                            <tr>
                                <td id="titulo_estado" class="titulo_left" colspan="1">ESTADO</td>
                                <td id="estado" class="contenido" colspan="3">
                                    <strong>
                                        {{obra.estado.nombre}}
                                    </strong>
                                </td>
                                <td id="titulo_localidad" class="titulo_left" colspan="1">LOCALIDAD</td>
                                <td id="localidad" class="contenido" colspan="3">
                                    <strong>
                                        {{obra.municipio.nombre}}
                                    </strong>
                                </td>
                            </tr>
                            <tr>
                                <td id="titulo_nombre_del_frente" class="titulo_left" colspan="1">NOMBRE DEL FRENTE</td>
                                <td id="nombre_del_frente" class="contenido" colspan="7"><strong>{{reporte.nombre_del_frente}}</strong></td>
                            </tr>
                            <tr>
                                <td id="titulo_domicilio" class="titulo_left" colspan="1">DOMICILIO</td>
                                <td id="domicilio" class="contenido" colspan="7">
                                    <strong>
                                        Calle {{reporte.calle}}, Colonia {{reporte.colonia}}, CP: {{reporte.codigo_postal}}
                                    </strong>
                                </td>
                            </tr>
                            <!--SEPARADOR--><tr><td colspan="8" class="separador"></td></tr><!--SEPARADOR-->
                            <tr>
                                <td id="titulo_fecha_inicio_programado" class="titulo_top" colspan="1">INICIO PROGRAMADO</td>
                                <td id="titulo_fecha_terminacion_prog" class="titulo_top" colspan="1">TERMINACIÓN PROG.</td>
                                <td id="titulo_inicio_real" class="titulo_top" colspan="1">INICIO REAL</td>
                                <td id="titulo_plazo_ejecucion" class="titulo_top" colspan="1">PLAZO EJECUCIÓN</td>
                                <td id="titulo_periodo_de_reporte" class="titulo_top" colspan="2">PERIODO DE REPORTE</td>
                                <td id="titulo_no_de_reporte" class="titulo_top" colspan="2">NO. DE REPORTE</td>
                            </tr>
                            <tr>
                                <td id="fecha_inicio_programado" class="contenido" colspan="1">
                                    <strong>
                                        [Pendiente]
                                    </strong>
                                </td>
                                <td id="fecha_terminacion_prog" class="contenido" colspan="1">
                                    <strong>
                                        [Pendiente]
                                    </strong>
                                </td>
                                <td id="inicio_real" class="contenido" colspan="1">
                                    <strong>
                                        {{obra.inicio_real}}
                                    </strong>
                                </td>
                                <td id="plazo_ejecucion" class="contenido" colspan="1">
                                    <strong>
                                        {{obra.plazo_ejecucion}} meses
                                    </strong>
                                </td>
                                <td id="periodo_de_reporte_inicio" class="contenido" colspan="1">
                                    <strong>
                                        {{first | date: 'mediumDate'}}
                                    </strong>
                                </td>
                                <td id="periodo_de_reporte_fin" class="contenido" colspan="1">
                                    <strong>
                                        {{last | date: 'mediumDate'}}
                                    </strong>
                                </td>
                                <td id="no_de_reporte" class="contenido" colspan="2">
                                    <strong>
                                        {{ultimoReporte.numero_reporte+1}}
                                    </strong>
                                </td>
                            </tr>
                        <!--SEPARADOR--><tr><td colspan="8" class="separador"></td></tr><!--SEPARADOR-->
                            <tr>
                                <td id="titulo_constructora" class="titulo_left" colspan="1">CONSTRUCTORA</td>
                                <td id="constructora" class="contenido" colspan="5"><strong>{{obra.constructora}}</strong></td>
                                <td id="titulo_no_registro_constructora" class="titulo_left" colspan="1">NO. REGISTRO</td>
                                <td id="no_registro_constructora" class="contenido" colspan="1"><strong>{{obra.numero_registro}}</strong></td>
                            </tr>
                            <tr>
                                <td id="titulo_supervisor_verificador" class="titulo_left" colspan="1">SUPERVISOR / VERIFICADOR</td>
                                <td id="supervisor_verificador" class="contenido" colspan="5">
                                    <form [formGroup]="verificadoresForm">
                                        <mat-form-field appearance="fill" style="display: inherit;">
                                            <mat-select [(ngModel)]="selectedVerif" formControlName="userVerificador" (selectionChange)="verificadorChange($event)">
                                                <mat-option *ngFor="let verif of allVerificadores" [value]="verif.id">
                                                    {{verif.nombre}} {{verif.primer_apellido}} {{verif.segundo_apellido}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </form>
                                </td>
                                <td id="titulo_no_registro_supervisor" class="titulo_left" colspan="1">NO. REGISTRO</td>
                                <td id="no_registro_supervisor" class="contenido" colspan="1">
                                    <strong *ngIf="obra.verificador != null;else elseCedula">
                                        {{obra.verificador.cedula}}
                                    </strong>
                                    <ng-template #elseCedula>
                                        <strong>Sin Asignar</strong>
                                    </ng-template>
                                </td>
                            </tr>
                            <tr>
                                <td class="padding-tb" colspan="8">
                                    <ng-container *ngIf="totalNotas.length > 0; else sinComentarios">
                                        <!-- <mat-form-field>
                                            <mat-label>Filtro</mat-label>
                                            <input matInput (keyup)="applyFilter($event)" placeholder="Ex. ium" #input>
                                        </mat-form-field> -->
                                            
                                        <table mat-table [dataSource]="comentariosAgrupados" class="mat-elevation-z8 mat-table full-width-table comentsTable">
                                            
                                            <ng-container matColumnDef="nombre">
                                                <th mat-header-cell *matHeaderCellDef> CONCEPTO </th>
                                                <td mat-cell *matCellDef="let element"> {{element.comentario.concepto}} </td>
                                            </ng-container>
            
                                            <ng-container matColumnDef="si">
                                                <th mat-header-cell *matHeaderCellDef> SI </th>
                                                <td mat-cell *matCellDef="let element">
                                                    <ng-container *ngIf="element.comentario.si != null;else siNull">
                                                        {{element.comentario.si}}
                                                    </ng-container>
                                                    <ng-template #siNull>
                                                        -
                                                    </ng-template>
                                                </td>
                                            </ng-container>
                                            
                                            <ng-container matColumnDef="no1">
                                                <th mat-header-cell *matHeaderCellDef> NO </th>
                                                <td mat-cell *matCellDef="let element">
                                                    <ng-container *ngIf="element.comentario.no1 != null;else no1Null">
                                                        {{element.comentario.no1}}
                                                    </ng-container>
                                                    <ng-template #no1Null>
                                                        -
                                                    </ng-template>
                                                </td>
                                            </ng-container>
                                            
                                            <ng-container matColumnDef="no2">
                                                <th mat-header-cell *matHeaderCellDef> NO </th>
                                                <td mat-cell *matCellDef="let element">
                                                    <ng-container *ngIf="element.comentario.no2 != null;else no2Null">
                                                        {{element.comentario.no2}}
                                                    </ng-container>
                                                    <ng-template #no2Null>
                                                        -
                                                    </ng-template>
                                                </td>
                                            </ng-container>

                                            <ng-container matColumnDef="comentario">
                                                <th mat-header-cell *matHeaderCellDef> COMENTARIO </th>
                                                <td mat-cell *matCellDef="let element">
                                                    <ng-container *ngIf="element.comentario.comentario != null;else cmntNull">
                                                        {{element.comentario.comentario}}
                                                    </ng-container>
                                                    <ng-template #cmntNull>
                                                        -
                                                    </ng-template>
                                                </td>
                                            </ng-container>

                                            <ng-container matColumnDef="vivienda">
                                                <th mat-header-cell *matHeaderCellDef> ID Viviendas</th>
                                                <td mat-cell *matCellDef="let element">
                                                    <!-- <ng-container *ngFor="let cuv of element.cuvs; let indexC = index">
                                                        <ng-container  *ngIf="toInt(indexC) === 0; else limiteInf">
                                                            {{cuv.id_vivienda}},
                                                        </ng-container>
                                                        <ng-template #limiteInf>
                                                            <ng-container *ngIf="continuo(indexC, resta(element.cuvs.length, 1))">
                                                                {{cuv.id_vivienda}}
                                                            </ng-container>
                                                        </ng-template>
                                                        
                                                        <ng-container *ngIf="indexC != 0 && element.cuvs[suma(indexC,1)] != undefined">
                                                            <ng-container *ngIf="continuo(toInt(suma(element.cuvs[indexC].id_vivienda, 1)) , toInt(element.cuvs[suma(indexC,1)].id_vivienda)); else noContinuo">
                                                                al
                                                            </ng-container>
                                                            <ng-template #noContinuo>
                                                                {{ cuv.id_vivienda }},
                                                            </ng-template>
                                                            
                                                        </ng-container>
                                                    </ng-container>
                                                    <ng-template #cmntNull>
                                                        -
                                                    </ng-template> -->
                                                    <!-- <ng-container *ngFor="let cuv of element.cuvs; let index = index">
                                                        <ng-container *ngIf="index === 0">
                                                            {{ cuv.id_vivienda }},
                                                        </ng-container>
                                                    
                                                        <ng-container *ngIf="index === element.cuvs.length - 1">
                                                            {{ cuv.id_vivienda }}
                                                        </ng-container>
                                                    
                                                        <ng-container *ngIf="index !== 0 && element.cuvs[index+1] !== undefined">
                                                            <ng-container *ngIf="element.cuvs[index-1].omitir == false && cuv.omitir == true; else noContinuo">
                                                                al
                                                            </ng-container>
                                                            
                                                            <ng-template #noContinuo>
                                                                <ng-container *ngIf="cuv.omitir == false">
                                                                    {{ cuv.id_vivienda }},
                                                                </ng-container>
                                                            </ng-template>
                                                        </ng-container>
                                                    </ng-container> -->
                                                    <ng-container *ngFor="let cuv of element.cuvs; let index = index">
                                                        <ng-container *ngIf="element.cuvs[index-1] === undefined && cuv.omitir == false">
                                                            {{cuv.id_vivienda}}
                                                        </ng-container>

                                                        <ng-container *ngIf="index > 0 && index < element.cuvs.length-1 && cuv.omitir == false && element.cuvs[index+1].omitir == false && element.cuvs[index-1].omitir == false">
                                                           , {{cuv.id_vivienda}}
                                                        </ng-container>

                                                        <ng-container *ngIf="element.cuvs[index-1] !== undefined && cuv.omitir == false && element.cuvs[index-1].omitir == true">
                                                            al {{cuv.id_vivienda}}
                                                        </ng-container>
                                                        
                                                        <ng-container *ngIf="index > 0 && index < element.cuvs.length-1 && cuv.omitir == false && element.cuvs[index+1].omitir == true && element.cuvs[index-1].omitir == false">
                                                           , {{cuv.id_vivienda}}
                                                        </ng-container>
                                                    </ng-container>
                                                </td>
                                            </ng-container>
                                            
                                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                            
                                            <!-- <tr class="mat-row" *matNoDataRow>
                                                <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
                                            </tr> -->
                                        </table>
                                        <!-- <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 mat-table full-width-table comentsTable">
                                            
                                            <ng-container matColumnDef="nombre">
                                                <th mat-header-cell *matHeaderCellDef> CONCEPTO </th>
                                                <td mat-cell *matCellDef="let element"> {{element.comentarios_verificaciones.concepto.nombre}} </td>
                                            </ng-container>
            
                                            <ng-container matColumnDef="si">
                                                <th mat-header-cell *matHeaderCellDef> SI </th>
                                                <td mat-cell *matCellDef="let element">
                                                    <ng-container *ngIf="element.si != null;else siNull">
                                                        {{element.si}}
                                                    </ng-container>
                                                    <ng-template #siNull>
                                                        -
                                                    </ng-template>
                                                </td>
                                            </ng-container>
                                            
                                            <ng-container matColumnDef="no1">
                                                <th mat-header-cell *matHeaderCellDef> NO </th>
                                                <td mat-cell *matCellDef="let element">
                                                    <ng-container *ngIf="element.no1 != null;else no1Null">
                                                        {{element.no1}}
                                                    </ng-container>
                                                    <ng-template #no1Null>
                                                        -
                                                    </ng-template>
                                                </td>
                                            </ng-container>
                                            
                                            <ng-container matColumnDef="no2">
                                                <th mat-header-cell *matHeaderCellDef> NO </th>
                                                <td mat-cell *matCellDef="let element">
                                                    <ng-container *ngIf="element.no2 != null;else no2Null">
                                                        {{element.no2}}
                                                    </ng-container>
                                                    <ng-template #no2Null>
                                                        -
                                                    </ng-template>
                                                </td>
                                            </ng-container>

                                            <ng-container matColumnDef="comentario">
                                                <th mat-header-cell *matHeaderCellDef> COMENTARIO </th>
                                                <td mat-cell *matCellDef="let element">
                                                    <ng-container *ngIf="element.comentario != null;else cmntNull">
                                                        {{element.comentario.comentario}}
                                                    </ng-container>
                                                    <ng-template #cmntNull>
                                                        -
                                                    </ng-template>
                                                </td>
                                            </ng-container>

                                            <ng-container matColumnDef="vivienda">
                                                <th mat-header-cell *matHeaderCellDef> Datos CUV </th>
                                                <td mat-cell *matCellDef="let element">
                                                    <ng-container *ngIf="element.comentarios_verificaciones">
                                                        <div>
                                                            <strong>N:</strong>{{element.id}},
                                                            <strong>LT:</strong>{{element.comentarios_verificaciones.cuv.lote}},
                                                            <strong>MZ:</strong>{{element.comentarios_verificaciones.cuv.manzana}},
                                                        </div>
                                                        <div>
                                                            {{element.comentarios_verificaciones.cuv.cuv}}, 
                                                        </div>
                                                    </ng-container>
                                                    <ng-template #cmntNull>
                                                        -
                                                    </ng-template>
                                                </td>
                                            </ng-container>
                                            
                                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                            
                                            <tr class="mat-row" *matNoDataRow>
                                                <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
                                            </tr>
                                        </table> -->
                                    </ng-container>
                                    <ng-template #sinComentarios>
                                        No hay Comentarios en la quincena actual. 
                                    </ng-template>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    
                </mat-tab>
            </mat-tab-group>
            <hr>
            <div class="row">
                <div class="col">
                    <button class="btn btn-sm btn-secondary btn-reporte-avances btn-sm-block" id="boton_descargar" (click)="download($event)">DESCARGAR PDF</button>
                    <button class="btn btn-sm btn-secondary btn-reporte-avances btn-sm-block" id="boton_generar_avance" (click)="generarAvance($event)">GENERAR REPORTE DE AVANCES</button>
                    <button class="btn btn-sm btn-secondary btn-reporte-avances btn-sm-block" id="descargar_reportes" (click)="descargarReportes($event)">DESCARGAR TODOS LOS REPORTES</button>
                    <ng-container *ngIf="allowEvidencias">
                        <button class="btn btn-sm btn-secondary btn-reporte-avances btn-seleccionar-evidencias btn-sm-block" data-toggle="modal" 
                            data-target="#indicaciones" aria-label="Indicaciones" aria-hidden="false" title="Indicaciones" id="seleccionar_evidencias"
                            (click)="getEvidencias($event)">Evidencias</button>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
    <!--****************-->
    <!--MODAL EVIDENCIAS-->
    <!--****************-->
    <div class="modal fade align-items-center justify-content-center" id="indicaciones" tabindex="-1" role="dialog" aria-labelledby="indicacionesTitle" aria-hidden="false">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Evidencias</h5>
                </div>
                <div class="modal-body">
                    <table style="width:100%">
                        <thead>
                        </thead>
                        <tbody>
                            <tr *ngFor="let evidencia of evidencias;">
                                <td class="text-center">
                                    <div class="imageButtonContent">
                                        <img class="img-thumbnail" title="{{evidencia[0].created_at | date: 'dd/MM/yyyy hh:mm a'}}" src="{{ host }}images/{{evidencia[0].imgName}}"/>
                                        <div *ngIf="evidencia[0].evidencias_reportes.length>0;else elseConfirmado">
                                            <button mat-stroked-button (click)="updateEvidencias(evidencia[0].evidencias_reportes[0].id_reporte_evidencia)"
                                                color="warn" class="widthButton">
                                                Deseleccionar
                                            </button>
                                        </div>
                                        <ng-template #elseConfirmado>
                                            <button mat-stroked-button (click)="setEvidencias(evidencia[0].id_evidencia)"
                                                color="primary" class="widthButton">
                                                Seleccionar
                                            </button>
                                        </ng-template>
                                    </div>
                                </td>
                                
                                <strong *ngIf="evidencia.length <= 1;else elseEvidenciasFotos">
                                </strong>
                                <ng-template #elseEvidenciasFotos>
                                    <td class="text-center">
                                        <div class="imageButtonContent">
                                            <img class="img-thumbnail" title="{{evidencia[1].created_at | date: 'dd/MM/yyyy hh:mm a'}}" src="{{ host }}images/{{evidencia[1].imgName}}"/>
                                            <div *ngIf="evidencia[1].evidencias_reportes.length>0;else elseConfirmado">
                                                <button mat-stroked-button (click)="updateEvidencias(evidencia[1].evidencias_reportes[0].id_reporte_evidencia)"
                                                    color="warn" class="widthButton">
                                                    Deseleccionar
                                                </button>
                                            </div>
                                            <ng-template #elseConfirmado>
                                                <button mat-stroked-button (click)="setEvidencias(evidencia[1].id_evidencia)"
                                                    color="primary" class="widthButton">
                                                    Seleccionar
                                                </button>
                                            </ng-template>
                                        </div>
                                    </td>
                                </ng-template>
                            </tr>
                        </tbody>
                      </table>
                </div>
            </div>
        </div>
    </div>
</section>
<app-loader [isLoading]="isLoading"></app-loader>
